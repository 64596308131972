/**
 * 全局顶部导航栏容器（从而支持定制）：
 * - [ ] fixed 在页面上但不会遮盖下面的内容
 * - [ ] 调慢网络打开 /creator 页面，loading bar 样式正常
 */

import cx from 'classnames'
import {useEffect} from 'react'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import loadingState from 'state/loading'
import LoadingBar from 'components/LoadingBar'
import styles from './TopNav.module.css'

function TopNav({loading, children, className, rootClass, transparent}) {
  const loadingValue = useRecoilValue(loadingState)

  return (
    <div
      className={cx(rootClass, styles.wrapper, {
        [styles.transparent]: transparent,
      })}
    >
      <nav className={cx(styles.nav, className)}>
        {children}
        {(loading || loadingValue) && <LoadingBar />}
      </nav>
    </div>
  )
}

export function useLoadingBar(loading) {
  const setLoading = useSetRecoilState(loadingState)

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])
}

export default TopNav
