// fetch helper:
// - [ ] body 为普通对象时会自动 JSON.stringify，body 为 object、form 或 undefined 请求正常。
// - [ ] 根据 body 类型判断指定 Content-Type，form 和 json 请求 Content-Type 均正确。
// - [ ] 自动转化 Response body 为 camelcase，Request body 为 snakecase。
// - [ ] 网络错误会抛出错误
// - [ ] 默认 API Version 正确，可通过 query 指定默认值

import queryString from 'query-string'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

const query = queryString.parse(window.location.search)
const defaultAPIVersion = query['api-version'] || '1.0.2'

class ResponseError extends Error {
  constructor(response, payload) {
    const status = response.status
    const url = response.url
    const message = `Request ${url} failed (${status}).`
    super(message)
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, ResponseError.prototype)
    }
    this.name = 'ResponseError'
    this.status = status
    this.url = url

    if (status === 504 || status === 502) {
      this.body = {message: '服务端异常'}
    } else if (typeof payload === 'string') {
      this.body = {message: payload || '未知错误'}
    } else {
      const message =
        payload.message === 'Validation error'
          ? '提交的信息有误'
          : payload.message
      this.body = {
        ...payload,
        message: message || '未知错误',
      }
    }
  }
}

let config = {
  defaultHeaders: {},
}

const http = (url, options = {}) =>
  fetch(`${process.env.REACT_APP_API_PREFIX}${url}`, {
    ...options,
    body: isPlainObject(options.body)
      ? JSON.stringify(snakecaseKeys(options.body, {deep: true}))
      : options.body,
    headers: {
      'X-API-Version': defaultAPIVersion,
      ...(!(options.body instanceof FormData) && {
        'Content-Type': 'application/json',
      }),
      ...config.defaultHeaders,
      ...options.headers,
    },
  })
    .then((res) =>
      isJSON(res)
        ? res.json().then((payload) => ({
            res,
            payload: camelcaseKeys(payload, {deep: true}),
          }))
        : res.text().then((payload) => ({res, payload}))
    )
    .then(
      ({res, payload}) => {
        if (!res.ok) {
          throw new ResponseError(res, payload)
        }

        return payload
      },
      () => Promise.reject({message: '网络错误'})
    )

export function setDefaultHeaders(headers) {
  Object.assign(config.defaultHeaders, headers)
}

function isJSON(response) {
  if (response.status === 204) {
    return false
  }

  if (response.headers.get('content-length') === '0') {
    return false
  }

  const type = response.headers.get('content-type')
  return type && type.indexOf('application/json') !== -1
}

function isPlainObject(o) {
  return typeof o === 'object' && o.constructor === Object
}

export default http
