import cx from 'classnames'
import styles from './LoadingPlaceholder.module.css'

function Rectangle({className, ...props}) {
  return (
    <div
      className={cx(styles.placeholder, styles.rectangle, className)}
      {...props}
    ></div>
  )
}

function Circle({className, ...props}) {
  return (
    <div
      className={cx(styles.placeholder, styles.circle, className)}
      {...props}
    ></div>
  )
}

const LoadingPlaceholder = {
  Rectangle,
  Circle,
}

export default LoadingPlaceholder
