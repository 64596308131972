/**
 * 作品页
 * - [ ] 作品页 & 草稿预览页均能正确渲染，主题色正确
 * - [ ] 打开页面后会同时请求 post & publication，所有请求成功才渲染页面，加载/错误状态正确，可正常重试
 * - [ ] 文章所属的 newsletter 的 token 和 subdomin 不一致是会提示「该页面不存在」
 * - [ ] 作品页 & 草稿预览页页面 title 正确（post.title & publication.name）
 * - [ ] 网页预览 token 错误/过期时无法访问
 * - [ ] 文章页 loading, error、重试状态正确，404 时没有重试。
 * - [ ] 能正确展示各种富文本。
 * - [ ] Newsletter 名称过长时会省略
 * - [ ] Newsletter 名称不会和 menu button 重合
 * - [ ] 发布时间显示正确：刚刚、x 分钟前、x 小时前、昨天、日期
 * - [ ] 作者视角有操作按钮，点击菜单样式正确，能够正常跳转到编辑页面
 * - [ ] 删除操作 loading、错误逻辑正常，成功后跳转创作者中心
 * - [ ] 标题会将软换行和多个空格合并成一个空格
 * - [ ] 标题文本超出宽度限制会按单词折行，默认不会把单词折断
 * - [ ] 标题如果一个超过宽度限制的长单词，则会从单词中间折断换行避免溢出
 * - [ ] 底部专栏模块样式正常，内容区不够高时会出现在屏幕底部。
 * - [ ] 屏幕较窄时隐藏顶部导航栏 & 菜单按钮
 * - [ ] 从列表页面跳转详情页后页面会自动 scroll 到顶部
 * - [ ] 预览页面可以编辑但不能删除
 * - [ ] 点击作者头像可以跳去 newsletter 页
 * - [ ] 在微信内访问订阅按钮会变成打开小程序按钮，能够正常打开测试小程序
 * - [ ] 打开小程序的订阅按钮能在有/无设置主题色样式均正确
 * - [ ] 不带参数/不在微信内/访问非测试 newsletter 则是正常邮箱订阅按钮
 * - [ ] 预览设置付费墙的内容时，默认显示完整内容，可点击按钮切换预览免费内容，query 变化正确
 * - [ ] 预览未设置付费墙的内容时不显示切换按钮
 * - [ ] 付费订阅后会自动请求更新作品内容
 */
import {useEffect} from 'react'
import {Link, useHistory, useLocation, useParams} from 'react-router-dom'
import queryString from 'query-string'
import {getRelativeTimeString} from 'lib/time'
import getPublicationToken from 'lib/getPublicationToken'
import {getZhubaiURL} from 'lib/url'
import {LaunchWechatAppMask, useShareInfo} from 'lib/wechat'
import {usePost, usePreview} from 'swr/post'
import {usePublication} from 'swr/publication'
import {usePageShow} from 'hooks/usePageShow'
import useThemeColor from 'hooks/useThemeColor'
import usePrevious from 'hooks/usePrevious'
import withWechatMiniAppPage from 'hocs/withWechatMiniAppPage'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Error from 'components/Error'
import Avatar from 'components/Avatar'
import PageTitle from 'components/PageTitle'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import PostActionButton from 'components/PostActionButton'
import GlobalSideMenu from 'components/GlobalSideMenu'
import ShareButton from 'components/ShareButton'
import {SubscribeButton} from 'components/Subscribe'
import Tooltip from 'components/Tooltip'
import {PrimaryButton} from 'components/Button'
import {getContent} from 'components/Editor/PaywallPlugin'
import RichText from 'components/Editor/RichText'
import SubscribeModal from 'components/Subscribe/SubscribeModal'
import {ReactComponent as ShareIcon} from 'icons/share.svg'
import styles from './PostPage.module.css'

function PostPage() {
  const {postId, draftId} = useParams()
  const location = useLocation()

  const query = queryString.parse(location.search)
  const {token} = query
  const previewType = query.type === 'free' ? 'free' : 'paid'
  const isPreview = Boolean(draftId)
  const useContent = isPreview ? usePreview : usePost

  return (
    <Page
      useContent={useContent}
      id={isPreview ? draftId : postId}
      isPreview={isPreview}
      previewToken={token}
      previewType={previewType}
    />
  )
}

function Page({useContent, isPreview, id, previewToken, previewType}) {
  const {
    post,
    error: postError,
    isValidating: isValidatingPost,
    mutate: mutatePost,
  } = useContent(id, previewToken)

  // - [ ] 编辑已发布内容预览时请求已发布 post，编辑新内容预览时则不请求
  const {post: editedPost} = usePost(post?.postId !== '0' ? post?.postId : null)

  const publicationToken = getPublicationToken()
  const {
    publication,
    error: publicationError,
    isValidating: isValidatingPublication,
    mutate: mutatePublication,
  } = usePublication(publicationToken)

  // - [ ] post & publication 未请求成功或预览下均不上报 PageShow
  const entity =
    post && publication && !isPreview ? {type: 'Post', id: post.id} : null
  usePageShow({entity, isSubscriber: publication?.subscription.hasSubscribed})

  useThemeColor(publication?.theme?.primaryColor)

  useShareInfo(
    post && publication
      ? {
          title: post.title,
          link: window.location.href,
          imgUrl: publication.author.avatar,
          desc: `发布自「${publication.name}」`,
        }
      : null
  )

  // - [ ] 付费订阅后会自动请求更新作品内容
  const previousPublication = usePrevious(publication)
  useEffect(() => {
    if (
      previousPublication?.subscription?.type === 'free' &&
      publication.subscription?.type === 'paid' &&
      publication.subscription?.hasSubscribed
    ) {
      mutatePost()
    }
  }, [previousPublication, publication, mutatePost])

  if (!post) {
    return (
      <Layout.Page>
        <TopNav
          loading={!postError || isValidatingPost}
          rootClass={styles.navRoot}
        />
        <Layout.PageContent>
          <Error
            error={postError}
            handleRetry={postError?.status === 404 ? null : () => mutatePost()}
          />
        </Layout.PageContent>
        <GlobalSideMenu buttonClass={styles.sideMenuButton} />
      </Layout.Page>
    )
  }

  if (!publication) {
    return (
      <Layout.Page>
        <TopNav
          loading={!publicationError || isValidatingPublication}
          rootClass={styles.navRoot}
        />
        <Layout.PageContent>
          <Error
            error={publicationError}
            handleRetry={
              publicationError?.status === 404
                ? null
                : () => mutatePublication()
            }
          />
        </Layout.PageContent>
        <GlobalSideMenu buttonClass={styles.sideMenuButton} />
      </Layout.Page>
    )
  }

  if (post.publication.id !== publication.id) {
    return (
      <Layout.Page>
        <TopNav rootClass={styles.navRoot} />
        <Layout.PageContent>
          <Error error={{message: '该页面不存在'}} />
        </Layout.PageContent>
        <GlobalSideMenu buttonClass={styles.sideMenuButton} />
      </Layout.Page>
    )
  }

  const {isAuthor} = publication.relationship
  const author = publication.author
  post.author = {...author} // 兼容预览内容没有作者
  post.publication = {...post.publication, ...publication} // post.publication 可能缺字段

  let content = getContent({
    post,
    publication,
    onlyFreeContent: isPreview && previewType === 'free',
  })

  // - [ ] 已发布付费作品显示付费标签，免费作品不显示，作者名称过长时可正常缩略
  // - [ ] 预览设置了付费墙内容时显示付费标签、预览未设置付费墙内容不显示
  const showPaidTag = post.isPaidContent || (isPreview && Boolean(post.paywall))

  return (
    <Layout.Page>
      <PageTitle
        title={post.title + (publication?.name ? ` | ${publication.name}` : '')}
      />
      <ScrollToTopOnMount />
      <Nav
        loading={isValidatingPost || isValidatingPublication}
        publication={publication}
        isPreview={isPreview}
      />
      <Layout.PageContent>
        <Layout.View slim className={styles.post}>
          {isPreview && (
            <div className={styles.tip}>预览内容仅 1 小时内可见，请勿传播</div>
          )}
          <h1 className={styles.title}>{post.title}</h1>
          <Link className={styles.info} to="/">
            <Avatar src={author.avatar} size={32} className={styles.avatar} />
            <div className={styles.author}>{author.name} </div>
            <PostTime
              post={post}
              editedPost={editedPost}
              isPreview={isPreview}
            />
            {showPaidTag && <div className={styles.paidTag}>付费内容</div>}
          </Link>
          <RichText className={styles.content} content={content} />
          <div className={styles.actions}>
            <PostShareButton
              post={post}
              isPreview={isPreview}
              previewToken={previewToken}
              publication={publication}
            />
            {isAuthor && (
              <PostActionButton
                post={post}
                publication={publication}
                isPreview={isPreview}
                onDelete={() => {
                  window.location.href = getZhubaiURL(
                    '/creator/posts/published'
                  )
                }}
              />
            )}
          </div>
        </Layout.View>
        <Footer publication={publication} isPreview={isPreview} />
      </Layout.PageContent>
      <GlobalSideMenu buttonClass={styles.sideMenuButton} isAuthor={isAuthor} />
      {isPreview && (
        <PreviewSwitchButton post={post} previewType={previewType} />
      )}
      <SubscribeModal publication={publication} />
    </Layout.Page>
  )
}

function Nav({loading, publication, isPreview}) {
  return (
    <TopNav loading={loading} className={styles.nav} rootClass={styles.navRoot}>
      <Layout.View slim className={styles.navContent}>
        <Link className={styles.publication} to="/">
          {publication.name}
        </Link>
        {publication && (
          <SubscribeButton publication={publication} isPreview={isPreview} />
        )}
      </Layout.View>
    </TopNav>
  )
}

// - [ ] Footer 中专栏名字过长省略时按钮样式正常
function Footer({publication, isPreview}) {
  if (!publication) {
    return null
  }

  return (
    <div className={styles.footer}>
      <Layout.View slim>
        <div className={styles.footerTip}>本文来自</div>
        <div className={styles.footerHead}>
          <Link to="/" className={styles.footerName}>
            {publication.name}
          </Link>
          <SubscribeButton
            className={styles.footerButton}
            publication={publication}
            isPreview={isPreview}
          />
        </div>
        <div className={styles.footerDescription}>
          {publication.description}
        </div>
        <div className={styles.footerSign}>
          —— 由{' '}
          <span className={styles.footerAuthor}>{publication.author.name}</span>{' '}
          创作
        </div>
      </Layout.View>
    </div>
  )
}

function PostShareButton({post, isPreview, previewToken, publication}) {
  const scene = isPreview ? `p-${post.id}-${previewToken}` : `id=${post.id}`

  return (
    <ShareButton
      buttonClass={styles.shareButton}
      poster={!isPreview && `/api/posts/${post.id}/share_image`}
      disableSavePoster={isPreview && '发布后才能生成海报'}
      page="pages/post/post"
      scene={scene}
      publication={publication}
    >
      <ShareIcon className={styles.shareButtonIcon} />
      分享给朋友
      <LaunchWechatAppMask
        width="149px"
        height="47px"
        path={`/pages/post/post?id=${post.id}`}
      />
    </ShareButton>
  )
}

// - [ ] 已发布作品统一显示首次发布时间
// - [ ] 发布新作品时预览统一显示「刚刚」
// - [ ] 修改已有作品时预览统一显示作品首次发布时间，若 post 请求失败则不显示
// - [ ] 已发布作品发布和更新时间不一致时 hover 时显示更新时间，预览时不显示
function PostTime({post, editedPost, isPreview}) {
  let time
  if (isPreview && Boolean(post.postId) && post.postId !== '0') {
    time = editedPost?.createdAt
  } else if (isPreview) {
    time = Date.now()
  } else {
    time = post.createdAt
  }

  if (!time) {
    return null
  }

  let tip
  if (!isPreview && post.createdAt !== post.updatedAt) {
    tip = `编辑于：${getRelativeTimeString(post.updatedAt)}`
  }

  return (
    <div className={styles.time}>
      <span className={styles.dot}>·</span>
      <Tooltip tip={tip} placement="right">
        <span>{getRelativeTimeString(time)}</span>
      </Tooltip>
    </div>
  )
}

// - [ ] 预览设置付费墙的内容时，默认显示完整内容，可点击按钮切换预览免费内容，query 变化正确
// - [ ] 预览未设置付费墙的内容时不显示切换按钮
function PreviewSwitchButton({post, previewType}) {
  const {paywall} = post

  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)

  if (!paywall) {
    return null
  }

  function handleClick() {
    const search = queryString.stringify({
      ...query,
      type: previewType === 'free' ? 'paid' : 'free',
    })
    history.replace(`${location.pathname}?${search}`)
  }

  return (
    <PrimaryButton className={styles.previewSwitch} onClick={handleClick}>
      切换为{previewType === 'free' ? '付费' : '免费'}版本
    </PrimaryButton>
  )
}

// - [ ] 小程序内点击作品页链接可正常跳转到小程序
// TODO: 处理预览页面
export default withWechatMiniAppPage(PostPage, ({postId}) => {
  return `/pages/post/post?id=${postId}`
})
