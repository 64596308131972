/**
 * 邮箱订阅弹窗：
 * - [ ] Loading、非空错误、Email 不合法、服务错误（已订阅）、网络错误逻辑正确
 * - [ ] 提示错误时弹窗高度不会有变化
 * - [ ] 成功后引导去邮箱验证，倒计时之后可重新发送
 * - [ ] 加载时、成功后 input 禁止编辑
 * - [ ] 加载时、成功后倒计时时按钮无法点击
 * - [ ] 不同宽度下样式正确
 */

import {useState} from 'react'
import http from 'lib/http'
import {FormInput, FormError, FormSubmit} from 'components/Form'
import Spinner from 'components/Spinner'
import useCountdown from 'hooks/useCountdown'
import {
  SubscribeView,
  SubscribeViewDescription,
  SubscribeViewTitle,
} from './SubscribeView'
import styles from './EmailSubscribeView.module.css'

function EmailSubscribeView({publication}) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const [countdown, setCountdown] = useState(0)
  const {done, seconds} = useCountdown(countdown)

  function handleEmailInputChange(event) {
    setError(null)
    setEmail(event.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (email.length === 0) {
      setError({email: true})
      return
    }

    setLoading(true)
    http(`/publications/${publication.id}/subscription_confirmation_email`, {
      method: 'POST',
      body: {email},
    }).then(
      () => {
        setError(null)
        setLoading(false)
        setSuccess(true)
        setCountdown(Date.now() + 60 * 1000)
      },
      (error) => {
        setError(
          error.body?.validationErrors?.email[0] || error.body?.message || error
        )
        setLoading(false)
      }
    )
  }

  return (
    <SubscribeView className={styles.view}>
      <SubscribeViewTitle>
        {success ? '已发送验证邮件' : '输入邮箱地址'}
      </SubscribeViewTitle>
      <SubscribeViewDescription>
        {success
          ? '前往邮箱查收和验证后即可成功订阅'
          : '每期内容将以邮件的形式发送至邮箱'}
      </SubscribeViewDescription>
      <form className={styles.form} onSubmit={handleSubmit}>
        <FormInput
          className={styles.input}
          type="text"
          placeholder={error?.email ? '输入邮箱' : '邮箱'}
          value={email}
          error={Boolean(error)}
          name="email"
          disabled={success || loading}
          onChange={handleEmailInputChange}
        />
        <FormSubmit className={styles.button} disabled={!done || loading}>
          {loading && <Spinner size={25} />}
          {!loading && (success ? '重发邮件' : '订阅')}
          {!loading && !done && ` (${seconds})`}
        </FormSubmit>
        <FormError error={error} placeholder={true} />
      </form>
    </SubscribeView>
  )
}

export default EmailSubscribeView
