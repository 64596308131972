import {useEffect} from 'react'
import {Editor, Node, Range, Transforms} from 'slate'
import {useSelected, useFocused, useSlate} from 'slate-react'
import styles from './RichText.module.css'

export function withDivider(editor) {
  const {isVoid} = editor

  editor.isVoid = (element) => {
    return element.type === 'divider' ? true : isVoid(element)
  }

  return editor
}

/**
 * 插入分割线：
 *
 * - [ ] 默认插入到下一个行
 * - [ ] 如果当前行是一个空段落，则插入后删除前一个空行
 * - [ ] 如果在文档的第一行插入，则保留前一个空行
 */
export function insertDivider(editor) {
  Transforms.insertNodes(editor, {type: 'divider', children: [{text: ''}]})

  const previousPath = [editor.selection.focus.path[0] - 1]

  const [match] = Editor.nodes(editor, {
    at: previousPath,
    match: (node, path) =>
      path[0] !== 0 &&
      node.type === 'paragraph' &&
      Node.string(node).length === 0,
  })

  if (Boolean(match)) {
    Transforms.removeNodes(editor, {at: previousPath})
  }
}

// 编辑器内分割线组件：
// - [ ] 点击可以选中块级组件，选中样式正确
// - [ ] 选中后可退格删除
// - [ ] 选中后回车在下一行插入空行
export function EditableDivider({attributes, children}) {
  const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{text: ''}],
        })
        return
      }

      // TODO：焦点不在编辑器内时插入分割线无法正常删除，待确认原因后移除此事件
      // - [ ] 焦点不在编辑器内时通过工具栏插入分割线点击单独选中分割线后可以正常删除
      // - [ ] 选中多个元素（包括分割线）时退格能够正常删除
      if (event.key === 'Backspace' && Range.isCollapsed(editor.selection)) {
        event.preventDefault()
        Transforms.removeNodes(editor, {at: [editor.selection.focus.path[0]]})
        return
      }
    }

    if (selected && focused) {
      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selected, focused, editor])

  return (
    <div {...attributes}>
      <div
        contentEditable={false}
        className={styles.hrRoot}
        style={{
          boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
        }}
      >
        <hr />
      </div>
      {children}
    </div>
  )
}

export function Divider() {
  return (
    <div>
      <div className={styles.hrRoot}>
        <hr />
      </div>
    </div>
  )
}
