/**
 * Dropdown：
 * - [ ] 默认出现在按钮下方，如果下方空间不够则会出现在按钮上方
 * - [ ] 点击其他区域或 menu 后 dropdown 会消失
 */
import cx from 'classnames'
import {isBelowView} from 'lib/dom'
import {useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import styles from './Dropdown.module.css'

function Dropdown({setShowDropdown, children, className, defaultPlacement}) {
  const ref = useRef()
  const [placement, setPlacement] = useState(defaultPlacement || 'bottomLeft')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (!defaultPlacement && isBelowView(ref.current, window.document.body)) {
      setPlacement('topLeft')
    }

    setVisible(true)
  }, [setPlacement, setVisible, defaultPlacement])

  useEffect(() => {
    const hide = (e) => setShowDropdown(false)
    window.document.addEventListener('click', hide)

    return () => window.document.removeEventListener('click', hide)
  }, [setShowDropdown])

  return (
    <div
      className={cx(
        styles.dropdown,
        {
          [styles.visible]: visible,
          [styles.topLeft]: placement === 'topLeft',
          [styles.bottomRight]: placement === 'bottomRight',
        },
        className
      )}
      ref={ref}
    >
      {children}
    </div>
  )
}

export function DropdownMenu({
  children,
  danger,
  className,
  to,
  href,
  disabled,
  ...props
}) {
  function handleClick(e) {
    if (disabled) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
    }
  }

  if (to) {
    return (
      <Link
        {...props}
        className={cx(styles.menuButton, {[styles.danger]: danger}, className)}
        to={to}
      >
        {children}
      </Link>
    )
  }

  if (href) {
    return (
      <a
        {...props}
        className={cx(styles.menuButton, {[styles.danger]: danger}, className)}
        href={href}
      >
        {children}
      </a>
    )
  }

  return (
    <button
      onClick={handleClick} // 仅在 disabled 的时候使用
      {...props}
      className={cx(
        styles.menuButton,
        {[styles.danger]: danger, [styles.disabled]: disabled},
        className
      )}
    >
      {children}
    </button>
  )
}

export default Dropdown
