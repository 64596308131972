/**
 * 绑定手机号：
 * - [ ] 发送验证码非空错误、手机号校验错误、网络请求错误、服务端错误显示正常
 * - [ ] 发送验证码后倒计时 60s 才能重发（前端限制）。
 * - [ ] 绑定非空错误、加载状态、网络请求错误、校验错误、其他服务端错误显示正常。
 * - [ ] 绑定成功后立即更新 self 并正常执行回调，例如订阅时提示并显示选择会员计划视图。
 */

import React, {useEffect, useState} from 'react'
import http from 'lib/http'
import useCountdown from 'hooks/useCountdown'
import {useSelf} from 'swr/self'
import Form, {
  FormTitle,
  FormInput,
  FormSubmit,
  FormError,
} from 'components/Form'
import {DefaultButton} from 'components/Button'
import styles from './PhoneBindingView.module.css'

function PhoneBindingView({onSuccess, title, subtitle}) {
  const [cellphone, setCellphone] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [countdown, setCountdown] = useState(0)

  const phoneInputRef = React.createRef()
  const verificationCodeInputRef = React.createRef()

  useEffect(() => {
    setCountdown(0)
  }, [cellphone])

  function handleSendverificationCode() {
    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    setError(null)
    setCountdown(Date.now() + 60 * 1000)
    http('/self/cellphone_verification_code', {
      method: 'POST',
      body: {cellphone},
    }).catch((error) => {
      setError({
        cellphone: error.body?.validationErrors?.cellphone?.[0],
        message:
          !error.body?.validationErrors &&
          (error.body?.message || error.message || '发送验证码失败'),
      })
      setCountdown(0)
    })
  }

  function handlePhoneInputChange(e) {
    setCellphone(e.target.value)
    setError(null)
  }

  function handleVerificationCodeInputChange(e) {
    setVerificationCode(e.target.value)
    setError(null)
  }

  const {self, mutate} = useSelf()
  function handleSubmit(e) {
    e.preventDefault()

    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    if (verificationCode.length === 0) {
      verificationCodeInputRef.current.focus()
      setError({verificationCode: true})
      return
    }

    setLoading(true)
    setError(null)
    http('/self/cellphone', {
      method: 'PUT',
      body: {cellphone, verificationCode},
    }).then(
      () => {
        setLoading(false)
        mutate({
          ...self,
          account: {
            ...self.account,
            cellphone,
          },
        })
        onSuccess()
      },
      (error) => {
        setError({
          cellphone: error.body?.validationErrors?.cellphone?.[0],
          verificationCode: error.body?.validationErrors?.verificationCode?.[0],
          message:
            !error.body?.validationErrors &&
            (error.body?.message || error.message || '绑定失败'),
        })
        setLoading(false)
      }
    )
  }

  return (
    <Form onSubmit={handleSubmit} className={styles.form}>
      <FormTitle title={title} subtitle={subtitle} />
      <FormInput
        rootClass={styles.phoneInputRoot}
        className={styles.phoneInput}
        placeholder={error?.cellphone ? '输入手机号' : '手机号'}
        type="tel"
        ref={phoneInputRef}
        value={cellphone}
        error={error?.cellphone}
        name="cellphone"
        onChange={handlePhoneInputChange}
        style={{paddingLeft: '66px'}}
        renderRight={() => (
          <div
            className={styles.areaCode}
            onClick={() =>
              window.alert('暂时仅支持中国手机号，如有需求请联系我们')
            }
          >
            +86
          </div>
        )}
      />
      <FormInput
        placeholder={error?.verificationCode ? '输入验证码' : '验证码'}
        type="tel"
        ref={verificationCodeInputRef}
        value={verificationCode}
        name="verification_code"
        onChange={handleVerificationCodeInputChange}
        error={error?.verificationCode}
        autoComplete="off"
        renderRight={() => (
          <VerificationCodeButton
            handleClick={handleSendverificationCode}
            countdown={countdown}
          />
        )}
      />
      <FormSubmit loading={loading}>绑定</FormSubmit>
      <FormError error={error} placeholder />
    </Form>
  )
}

function VerificationCodeButton({handleClick, countdown}) {
  const {done, seconds} = useCountdown(countdown)

  return (
    <DefaultButton
      className={styles.verificationCodeButton}
      onClick={done ? handleClick : () => {}}
      type="button"
      disabled={!done}
    >
      {done ? '发送验证码' : `已发送 (${seconds})`}
    </DefaultButton>
  )
}

export default PhoneBindingView
