/**
 * 空白页
 */

import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'

function EmptyPage() {
  return (
    <Layout.Page>
      <GlobalTopNav />
    </Layout.Page>
  )
}

export default EmptyPage
