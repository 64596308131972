/**
 * - [ ] 小程序内点击专栏页链接可正常跳转到小程序对应的页面，返回后页面正确
 * - [ ] 小程序内访问专栏页时在跳转前不会有原页面的请求，仅请求 SDK
 * - [ ] 加载、错误状态正确，可正常重试
 */

import {useCallback, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import getDisplayName from 'lib/getDisplayName'
import {parseUA} from 'lib/userAgent'
import wechat from 'lib/wechat'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Error from 'components/Error'

const userAgent = parseUA(window.navigator.userAgent)

function withWechatMiniAppPage(Page, getURL) {
  function WithWechatMiniAppPage(props) {
    const params = useParams()
    const [error, setError] = useState(null)

    const redirect = useCallback(() => {
      setError(null)
      wechat.loadSDK().then(
        () => {
          window.wx.miniProgram.redirectTo({url: getURL(params)})
        },
        () => setError({message: '请求失败'})
      )
    }, [params])

    useEffect(() => {
      if (userAgent.WechatMiniProgram) {
        redirect()
      }
    }, [redirect])

    if (userAgent.WechatMiniProgram) {
      return (
        <Layout.Page>
          {!error && <Spinner />}
          <Error error={error} handleRetry={redirect} />
        </Layout.Page>
      )
    }

    return <Page {...props} />
  }

  WithWechatMiniAppPage.displayName = `WithWechatMiniAppPage${getDisplayName(
    Page
  )}`

  return WithWechatMiniAppPage
}

export default withWechatMiniAppPage
