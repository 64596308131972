// - [ ] 不同宽度下样式正确
// - [ ] 设置页点击开关可设置订阅方式，点击后开关状态立刻改变，导航栏显示加载中，成功后有提示。
// - [ ] 设置失败时能正常提示错误消息，并撤回按钮状态。
// - [ ] 关闭一种订阅方式后，禁用另一个按钮，绕过前端限制设置提示仅能关闭一种方式。
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import loadingState from 'state/loading'
import {ToggleButton} from 'components/Button'
import {notificationState} from 'components/Notification'
import {useSelf} from 'swr/self'
import SettingsField, {SettingsTip} from './SettingsField'
import styles from './SettingsField.module.css'

function SubscriptionMethodsField({self}) {
  const {publication} = self
  const {mutate} = useSelf()

  const {approach} = publication || {}

  const setNoti = useSetRecoilState(notificationState)
  const setLoading = useSetRecoilState(loadingState)

  if (!publication) {
    return null
  }

  function handleSetApproach({email, wechat}) {
    mutate(
      {
        ...self,
        publication: {
          ...publication,
          approach: {email, wechat},
        },
      },
      false
    )

    setLoading(true)
    http(`/publications/${publication.id}`, {
      method: 'PUT',
      body: {approach: {email, wechat}},
    })
      .then(
        () => {
          setNoti({
            visible: true,
            text: '设置成功',
          })
          mutate()
        },
        (error) => {
          setNoti({
            visible: true,
            warning: true,
            text: error.body?.message || error.message || '设置失败',
          })

          mutate({
            ...self,
            publication: {
              ...self.publication,
              approach: {...approach},
            },
          })
        }
      )
      .finally(() => setLoading(false))
  }

  return (
    <SettingsField
      name="订阅"
      renderContent={() => (
        <>
          <div className={styles.toggle}>
            <span>开启邮箱订阅</span>
            <ToggleButton
              value={approach.email}
              onClick={() =>
                handleSetApproach({
                  wechat: approach.wechat,
                  email: !approach.email,
                })
              }
              disabled={!approach.wechat}
            />
          </div>
          <div className={styles.toggle}>
            <span>开启微信订阅</span>
            <ToggleButton
              value={approach.wechat}
              onClick={() =>
                handleSetApproach({
                  wechat: !approach.wechat,
                  email: approach.email,
                })
              }
              disabled={!approach.email}
            />
          </div>
          <SettingsTip>
            关闭后将不能通过该方式订阅，已订阅用户不受影响
          </SettingsTip>
        </>
      )}
    />
  )
}

export default SubscriptionMethodsField
