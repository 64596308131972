import {isLoggedIn} from 'lib/auth'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import styles from './HomePage.module.css'

function HomePage() {
  return (
    <Layout.Page>
      <GlobalTopNav />
      <Layout.PageContent className={styles.content}>
        <h1>Coming Soon</h1>
        {!isLoggedIn() && (
          <div>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href="https://jinshuju.net/f/SpAmu9" target="_blank">
              申请早期试用
            </a>
            ，我们会尽快联系你
          </div>
        )}
        <footer className={styles.footer}>
          © 2022 竹白 ·{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://beian.miit.gov.cn/" target="_blank">
            京 ICP 备 2021023147 号 - 1
          </a>
        </footer>
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default HomePage
