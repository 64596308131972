import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import styles from './NotFoundPage.module.css'

function NotFoundPage() {
  return (
    <Layout.Page>
      <GlobalTopNav />
      <div className={styles.content}>该页面不存在</div>
    </Layout.Page>
  )
}

export default NotFoundPage
