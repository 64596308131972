import {useEffect} from 'react'
import {useSelf} from 'swr/self'
import useInterval from 'hooks/useInterval'
import {useNotification} from 'components/Notification'
import {
  SubscribeView,
  SubscribeViewDescription,
  SubscribeViewTitle,
} from './SubscribeView'

// - [ ] 不同宽度下样式正常
// - [ ] 轮询请求更新当前用户信息，关注成功后自动订阅
function SubscribeOfficalAccountView({setView}) {
  const {self, mutate} = useSelf()

  const noti = useNotification()
  useEffect(() => {
    if (self.hasSubscribedMp) {
      setView('select-approach')
      noti('已关注公众号')
    }
  }, [self, setView, noti])

  useInterval(() => {
    mutate()
  }, 2000)

  return (
    <SubscribeView>
      <SubscribeViewTitle>扫码关注公众号</SubscribeViewTitle>
      <SubscribeViewDescription>
        每期内容将通过公众号通知发送给你
      </SubscribeViewDescription>
      <img
        style={{margin: 'auto', background: '#eee'}}
        src="https://img.zhubai.love/c6c29525f41e4e1b8818d1b27acd2c96.png"
        width="280px"
        height="280px"
        alt="竹白公众号二维码"
      />
    </SubscribeView>
  )
}

export default SubscribeOfficalAccountView
