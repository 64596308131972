export function isBelowView(element, scrollRoot) {
  const height = element.offsetHeight

  let top = 0
  do {
    top += element.offsetTop
    element = element.offsetParent
  } while (element !== scrollRoot)

  return top + height > scrollRoot.scrollTop + scrollRoot.offsetHeight
}
