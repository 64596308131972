/**
 * - [ ] 换行、退格、输入溢出时能够保证当前元素在 viewport 内部，不会被顶栏、Toolbar 和底栏遮挡（Safair & Chrome）
 * - [ ] 聚焦时不显示 placeholder，失去焦点后正常显示
 * - [ ] 在中文输入法下，全选然后输入内容能够正常输入
 */
import React, {useMemo, useCallback, useEffect, useState} from 'react'
import cx from 'classnames'
import {
  Editor as SlateEditor,
  Node,
  createEditor,
  Transforms,
  Element as SlateElement,
  Text as SlateText,
  Range,
  Point,
} from 'slate'
import {Slate, Editable, withReact, ReactEditor} from 'slate-react'
import {withHistory} from 'slate-history'
import withPastingHTML from './withPastingHTML'
import {fixLinkSelection, withLinks} from './LinkPlugin'
import {withImages} from './ImagePlugin'
import {withDivider} from './DividerPlugin'
import {withShortcuts} from './MarkdownPlugin'
import {handleShortCuts} from './EditorShorcutsPlugin'
import {withPaywall} from './PaywallPlugin'
import {EditableElement} from './Element'
import {EditableLeaf} from './Leaf'
import EditorToolbar from './EditorToolbar'
import scrollIntoViewIfNecessary from './scrollIntoViewIfNecessary'
import richtextStyles from './RichText.module.css'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

function Editor({
  className,
  toolbarClassName,
  placeholder: intialPlaceholder,
  placeholderStyles,
  value,
  setValue,
  setEditor,
  scrollRoot,
  readOnly,
  disabled,
  onBeforeInput,
  enablePaywall,
}) {
  const editor = useMemo(
    () =>
      withPastingHTML(
        withShortcuts(
          withPaywall(
            withDivider(
              withImages(
                withLinks(withNormalize(withHistory(withReact(createEditor()))))
              )
            )
          )
        )
      ),
    []
  )

  const renderLeaf = useCallback((props) => <EditableLeaf {...props} />, [])
  const renderElement = useCallback(
    (props) => <EditableElement {...props} />,
    []
  )

  const [placeholder, setPlaceholder] = useState(intialPlaceholder)

  useEffect(() => {
    if (setEditor) {
      setEditor(editor)
    }
  }, [editor, setEditor])

  function handleBeforeInput(event) {
    if (onBeforeInput) {
      onBeforeInput(event)
    }

    const {inputType} = event
    const {selection} = editor

    // Block code Enter 默认插入 \n
    if (inputType === 'insertParagraph') {
      const [matchDefaultSoftBreak] = SlateEditor.nodes(editor, {
        match: (n) => n.type === 'block-code',
      })

      if (Boolean(matchDefaultSoftBreak)) {
        event.preventDefault()
        editor.insertText('\n')
        return
      }
    }

    // 空行 Enter 退出当前块级样式
    if (inputType === 'insertParagraph' && Range.isCollapsed(selection)) {
      const [matchExitAfterEmptyEnter] = SlateEditor.nodes(editor, {
        match: (n) =>
          ['heading-one', 'heading-two', 'block-quote', 'list-item'].includes(
            n.type
          ) && Node.string(n).length === 0,
      })

      if (Boolean(matchExitAfterEmptyEnter)) {
        event.preventDefault()
        Transforms.setNodes(editor, {type: 'paragraph'})
        return
      }
    }

    // 标题 Enter 退出标题样式
    // - [ ] 文档第一行为标题，光标在标题行首时按回车，当前行退出标题样式（空行），下一行仍是标题
    // - [ ] 光标在标题中间、末尾或选中部分文本时按回车，下一行退出标题样式
    if (inputType === 'insertParagraph') {
      const [matchExitAfterEnter] = SlateEditor.nodes(editor, {
        match: (n) => n.type === 'heading-one' || n.type === 'heading-two',
      })

      if (Boolean(matchExitAfterEnter)) {
        event.preventDefault()

        const [, path] = matchExitAfterEnter
        const start = SlateEditor.start(editor, path)

        if (
          Range.isCollapsed(editor.selection) &&
          Point.equals(editor.selection.focus, start)
        ) {
          SlateEditor.insertBreak(editor)
          Transforms.setNodes(editor, {type: 'paragraph'}, {at: path})
        } else {
          Transforms.splitNodes(editor, {
            // 避免原地 setNodes
            always: true,
          })
          Transforms.setNodes(editor, {type: 'paragraph'})
        }

        return
      }
    }

    // Backspace
    // - [ ] 空行标题、引用、块级代码、列表退出当前样式，嵌套、被嵌套的列表则忽略该按键
    // - [ ] 上一行是图片/分割线时，在行首按 backspace 后选中图片/分割线，不做删除操作
    // - [ ] 在文档开始按 backspace 不会有错误
    if (inputType === 'deleteContentBackward' && Range.isCollapsed(selection)) {
      const [matchExitAfterEmptyBackspace] = SlateEditor.nodes(editor, {
        match: (node) =>
          Node.string(node).length === 0 &&
          (['heading-one', 'heading-two', 'block-quote', 'block-code'].includes(
            node.type
          ) ||
            // 避免在 list-item 嵌套列表时触发
            (node.type === 'list-item' && node.children.length < 2)),
      })

      if (Boolean(matchExitAfterEmptyBackspace)) {
        event.preventDefault()
        Transforms.setNodes(editor, {type: 'paragraph'})
        return
      }

      const [previousNode, path] = SlateEditor.previous(editor) || []

      if (
        previousNode?.type === 'image' ||
        previousNode?.type === 'divider' ||
        previousNode?.type === 'paywall-divider'
      ) {
        event.preventDefault()
        const point = {
          path: [...path, 0],
          offset: 0,
        }
        Transforms.select(editor, {anchor: point, focus: point})
        return
      }

      // - [ ] 在文档首行行首通过工具栏启用加粗 & 斜体，退格后退出所有行内样式
      if (!previousNode) {
        const marks = SlateEditor.marks(editor)

        if (!marks) {
          return
        }

        Object.keys(marks).forEach((mark) =>
          SlateEditor.removeMark(editor, mark)
        )

        return
      }
    }

    // Command + D
    // - [ ] 当前行是空行且下一行是图片时，按 Command + D 能够删除当前行并选中图片
    if (inputType === 'deleteContentForward' && Range.isCollapsed(selection)) {
      const [match] = SlateEditor.nodes(editor, {
        match: (node) =>
          Node.string(node).length === 0 && node.type === 'paragraph',
      })

      const [node, path] = SlateEditor.next(editor) || []
      if (node?.type === 'image' && match) {
        event.preventDefault()
        const point = {
          path: [...path, 0],
          offset: 0,
        }
        Transforms.select(editor, {anchor: point, focus: point})
        Transforms.removeNodes(editor, {at: match[1]})
        return
      }
    }
  }

  function hanldeKeyDown(event) {
    const {key, shiftKey} = event

    handleShortCuts(event, editor)

    // Safari input 事件没有 insertLineBreak 事件，用 keydown 实现保证兼容性
    if (key === 'Enter' && shiftKey) {
      event.preventDefault()
      editor.insertText('\n')
      return
    }

    if (key === 'Tab' && !shiftKey) {
      const [matchListItem] = SlateEditor.nodes(editor, {
        match: (n) => n.type === 'list-item',
        mode: 'lowest',
      })

      if (!matchListItem) {
        return
      }

      event.preventDefault()

      const [, path] = matchListItem
      const [previousNode, previousPath] =
        SlateEditor.previous(editor, {at: path}) || []
      const parent = SlateEditor.parent(editor, path)

      if (previousNode?.type !== 'list-item') {
        return
      }

      Transforms.wrapNodes(
        editor,
        {type: parent[0].type, children: [], temp: true},
        {at: path}
      )

      Transforms.moveNodes(editor, {
        at: path,
        to: [...previousPath, previousNode.children.length],
      })

      Transforms.unsetNodes(editor, 'temp', {
        match: (node) => node.type === parent[0].type,
      })

      return
    }

    if (key === 'Tab' && shiftKey) {
      const [matchListItem] = SlateEditor.nodes(editor, {
        match: (n) => n.type === 'list-item',
        mode: 'lowest',
      })

      if (!matchListItem) {
        return
      }

      event.preventDefault()
      Transforms.unwrapNodes(editor, {
        match: (n) => LIST_TYPES.includes(n.type),
        split: true,
      })
      return
    }
  }

  /**
   * HACK: 处理中文输入法输入时 Placeholder 带来的问题：
   *
   * 1. 不会正确的消失
   * 2. 全选后输入失去焦点
   *
   * 详情见:
   *
   * - https://github.com/ianstormtaylor/slate/issues/4353
   * - https://github.com/ianstormtaylor/slate/pull/4352
   * - https://github.com/ianstormtaylor/slate/pull/4246
   */
  function handleFocus() {
    setPlaceholder(null)
  }

  function handleBlur() {
    setPlaceholder(intialPlaceholder)
  }

  return (
    <Slate
      editor={editor}
      value={value}
      onChange={(newValue) => setValue(newValue)}
    >
      {!readOnly && (
        <EditorToolbar
          className={toolbarClassName}
          enablePaywall={enablePaywall}
        />
      )}
      <Editable
        readOnly={readOnly || disabled}
        placeholder={placeholder}
        renderPlaceholder={({children, attributes}) => (
          <span
            {...attributes}
            id="davinci-slate-editor-placeholder"
            style={{
              ...attributes.style,
              opacity: 1,
              color: '#74809E',
              ...placeholderStyles,
            }}
          >
            {children}
          </span>
        )}
        className={cx(className, richtextStyles.richtext)}
        renderLeaf={renderLeaf}
        renderElement={renderElement}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onDOMBeforeInput={handleBeforeInput}
        onKeyDown={hanldeKeyDown}
        // Fix Chrome & scoll in to view
        // see https://github.com/ianstormtaylor/slate/pull/4369
        onSelect={() => {
          fixLinkSelection(editor)

          /**
           * Chrome doesn't scroll at bottom of the page. This fixes that.
           */
          if (editor.selection == null) return
          try {
            /**
             * Need a try/catch because sometimes you get an error like:
             *
             * Error: Cannot resolve a DOM node from Slate node: {"type":"p","children":[{"text":"","by":-1,"at":-1}]}
             */
            const domPoint = ReactEditor.toDOMPoint(
              editor,
              editor.selection.focus
            )
            const node = domPoint[0]
            if (node == null) return
            const element = node.parentElement
            if (element == null) return

            scrollIntoViewIfNecessary(
              element,
              scrollRoot,
              42 // Toolbar 高度
            )
          } catch (e) {
            /**
             * Empty catch. Do nothing if there is an error.
             */
          }
        }}
      />
    </Slate>
  )
}

function withNormalize(editor) {
  const {normalizeNode} = editor

  editor.normalizeNode = (entry) => {
    try {
      const [node, path] = entry

      /**
       * 避免空的行内样式
       *
       * - [ ] 行首有行内样式时，退格删除完内容的同时退出所有样式
       */
      if (
        SlateText.isText(node) &&
        node.text.length === 0 &&
        (node.bold ||
          node.italic ||
          node.code ||
          node.underline ||
          node.strikethrough)
      ) {
        Transforms.unsetNodes(
          editor,
          ['bold', 'italic', 'code', 'underline', 'strikethrough'],
          {at: path}
        )
        return
      }

      /**
       * 避免空文档
       */
      if (SlateEditor.isEditor(node) && node.children.length === 0) {
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{text: ''}],
        })
        return
      }

      /**
       * 处理图片上传状态
       *
       * - [ ] 图片上传成功后，保存草稿、发布时 JSON 结构正确（没有 objectURL、downloadURL）
       */
      if (node.type === 'image') {
        if (node.url && node.objectURL) {
          Transforms.unsetNodes(editor, ['objectURL', 'downloadURL'], {
            at: path,
          })
          return
        }
      }

      /**
       * 避免空链接
       *
       * - [ ] 一个个退格时能把链接删除干净
       */
      if (node.type === 'link') {
        if (Node.string(node).length === 0) {
          Transforms.unwrapNodes(editor, {at: path})
          return
        }
      }

      /**
       * Paragraph 默认不可嵌套其他块级样式，如果嵌套则自动 lift node（保留样式）。
       */
      if (node.type === 'paragraph') {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (SlateElement.isElement(child) && !editor.isInline(child)) {
            Transforms.liftNodes(editor, {at: childPath})
            return
          }
        }
      }

      /**
       * 普通块级样式默认不可嵌套（列表特殊处理），如果子元素为非 inline block，则自动 unwrap。
       */
      const disabledNestedBlocks = ['heading-one', 'heading-two', 'block-code']

      if (disabledNestedBlocks.includes(node.type)) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (SlateElement.isElement(child) && !editor.isInline(child)) {
            Transforms.unwrapNodes(editor, {at: childPath})
            return
          }
        }
      }

      /**
       * 引用块嵌套引用则 lift node，其他元素则 set node block-quote 从而自动 lift。
       */
      if (node.type === 'block-quote') {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (SlateElement.isElement(child) && !editor.isInline(child)) {
            if (child.type === 'block-quote') {
              Transforms.liftNodes(editor, {at: childPath})
            } else {
              Transforms.setNodes(
                editor,
                {type: 'block-quote'},
                {at: childPath}
              )
            }

            return
          }
        }
      }

      /**
       * 避免为最后一个元素（代码块）
       * - [ ] 插入时如果是最后一个元素，则自动在下一行插入一个空行从而可以继续编辑
       */
      if (node.type === 'block-code') {
        const next = SlateEditor.next(editor, {at: path})

        if (!next) {
          const nextPath = [...path]
          nextPath[nextPath.length - 1]++
          Transforms.insertNodes(
            editor,
            {type: 'paragraph', children: [{text: ''}]},
            {at: nextPath}
          )
          return
        }
      }

      /**
       * 列表 (ol/ul) 仅支持嵌套 list-item:
       * - 如果子元素为 text node，则 wrap list-item，不处理多个连续的 text node。
       * - 如果子元素不为 list-item，则 lift node（取消列表样式也依赖此逻辑）
       */
      if (LIST_TYPES.includes(node.type)) {
        for (const [child, childPath] of Node.children(editor, path)) {
          if (SlateText.isText(child) || editor.isInline(child)) {
            Transforms.wrapNodes(editor, {type: 'list-item'}, {at: childPath})
            return
          }

          if (
            SlateElement.isElement(child) &&
            child.type !== 'list-item' &&
            !child.temp
          ) {
            Transforms.liftNodes(editor, {at: childPath})
            return
          }
        }
      }

      /**
       * ListItem 仅在嵌套列表时支持包含 paragraph 和 list:
       *
       * 1. 如果 child 不为 text、paragraph 或 list，则 liftNode。
       * 2. 如果 child 不只为 text，合并所有相邻的 text，并 wrap paragraph。
       * 3. 如果 child 为 paragraph 且不紧接着 list，则 setNode list-item & liftNode。嵌套时换行依赖此逻辑。
       * 4. 如果 child 为 list：
       *    1. 转换不同类型的 list
       *    2. 合并相邻的同类型的 list
       *    3. 如果 list 不是前两个 child，wrap 前一个 paragraph，然后 lift node。
       *    4. 如果 list 是第一个 child 为，则尝试 merge 当前 list-item 到前一个 list item，如果没有前一个则 insert paragrah。嵌套时 backspace 依赖此逻辑。
       */
      if (node.type === 'list-item') {
        const children = Array.from(Node.children(editor, path))

        for (const [child, childPath] of Node.children(editor, path)) {
          if (
            SlateElement.isElement(child) &&
            !editor.isInline(child) &&
            child.type !== 'paragraph' &&
            !LIST_TYPES.includes(child.type) &&
            !child.temp
          ) {
            Transforms.liftNodes(editor, {at: childPath})
            return
          }
        }

        let textNodes = []
        for (let i = 0; i < children.length; i++) {
          const [child, childPath] = children[i]

          if (SlateText.isText(child) || editor.isInline(child)) {
            textNodes.push([child, childPath])
          }

          if (
            textNodes.length > 0 &&
            ((SlateElement.isElement(child) && !editor.isInline(child)) ||
              (textNodes.length !== children.length &&
                i === children.length - 1))
          ) {
            textNodes.forEach((node) => {
              Transforms.wrapNodes(
                editor,
                {type: 'paragraph', temp: true},
                {at: node[1]}
              )
            })

            textNodes.reverse().forEach((node, index) => {
              if (index === textNodes.length - 1) {
                Transforms.unsetNodes(editor, 'temp', {at: node[1]})
              } else {
                Transforms.mergeNodes(editor, {at: node[1]})
              }
            })
            return
          }
        }

        for (const [child, childPath] of Node.children(editor, path)) {
          const [nextNode] = SlateEditor.next(editor, {at: childPath}) || []
          if (
            SlateElement.isElement(child) &&
            child.type === 'paragraph' &&
            !child.temp &&
            !LIST_TYPES.includes(nextNode?.type)
          ) {
            Transforms.setNodes(editor, {type: 'list-item'}, {at: childPath})
            Transforms.liftNodes(editor, {at: childPath})
            return
          }
        }

        for (const [index, [child, childPath]] of children.entries()) {
          const [previousNode] = children[index - 1] || []
          const [nextNode, nextPath] = children[index + 1] || []

          const [parentList] = SlateEditor.nodes(editor, {
            at: childPath,
            match: (node) => LIST_TYPES.includes(node.type),
            mode: 'highest',
          })

          if (LIST_TYPES.includes(child.type)) {
            if (parentList && child.type !== parentList[0].type) {
              Transforms.setNodes(
                editor,
                {type: parentList[0].type},
                {at: childPath}
              )
              return
            }
          }

          if (
            LIST_TYPES.includes(child.type) &&
            child.type === previousNode?.type
          ) {
            Transforms.mergeNodes(editor, {at: childPath})
            return
          }

          if (
            LIST_TYPES.includes(child.type) &&
            child.type === nextNode?.type
          ) {
            Transforms.mergeNodes(editor, {at: nextPath})
            return
          }
        }

        for (const [index, [child, childPath]] of children.entries()) {
          const [previousNode, previousPath] =
            SlateEditor.previous(editor, {at: childPath}) || []

          if (index > 1 && LIST_TYPES.includes(child.type)) {
            if (previousNode && previousNode.type === 'paragraph') {
              Transforms.setNodes(editor, {temp: true}, {at: previousPath})
              Transforms.wrapNodes(
                editor,
                {type: 'list-item'},
                {at: previousPath}
              )
              Transforms.moveNodes(editor, {
                at: childPath,
                to: [...previousPath, 1],
              })
              Transforms.unsetNodes(editor, 'temp', {at: [...previousPath, 0]})
              Transforms.unsetNodes(editor, 'temp', {at: previousPath})
              return
            }
          }
        }

        for (const [index, [child, childPath]] of children.entries()) {
          const [previousNode, previousPath] =
            SlateEditor.previous(editor, {at: path}) || []

          if (index === 0 && LIST_TYPES.includes(child.type)) {
            if (previousNode) {
              // Previous list-item child 为 text node 时直接 merge 会混乱，所以先 wrap 一个 paragraph
              Transforms.setNodes(
                editor,
                {type: 'paragraph', temp: true},
                {at: previousPath}
              )
              Transforms.wrapNodes(
                editor,
                {type: 'list-item'},
                {at: previousPath}
              )
              Transforms.unsetNodes(editor, 'temp', {at: [...previousPath, 0]})
              Transforms.mergeNodes(editor, {at: path})
              return
            } else {
              Transforms.insertNodes(
                editor,
                {type: 'paragraph', children: [{text: ''}]},
                {at: childPath}
              )
              return
            }
          }
        }
      }

      /**
       * 合并上下类型相同的列表。
       * 为避免 normalize 不会触发，需要特殊处理嵌套 list 的 list-item
       */
      if (LIST_TYPES.includes(node.type)) {
        const previous = SlateEditor.previous(editor, {at: path})
        const next = SlateEditor.next(editor, {at: path})

        if (previous?.[0].type === node.type) {
          Transforms.mergeNodes(editor, {at: path})
          return
        }

        if (next?.[0].type === node.type) {
          Transforms.mergeNodes(editor, {at: next[1]})
          return
        }
      }

      if (node.type === 'list-item') {
        const children = Array.from(Node.children(editor, path))
        for (const [index, [child, childPath]] of children.entries()) {
          const [previousNode] = children[index - 1] || []
          const [nextNode, nextPath] = children[index + 1] || []
          if (
            LIST_TYPES.includes(child.type) &&
            child.type === previousNode?.type
          ) {
            Transforms.mergeNodes(editor, {at: childPath})
            return
          }

          if (
            LIST_TYPES.includes(child.type) &&
            child.type === nextNode?.type
          ) {
            Transforms.mergeNodes(editor, {at: nextPath})
            return
          }
        }
      }

      /**
       * HACK list 删不干净的 bug，
       */
      if (node.type === 'list-item') {
        const [parentNode] = SlateEditor.parent(editor, path)

        if (SlateEditor.isEditor(parentNode) && !node.temp) {
          Transforms.setNodes(editor, {type: 'paragraph'})
          return
        }
      }

      /**
       * 避免多个付费墙分割线
       * - [ ] 如果文档中出现多个付费墙分割线（例如分别在分割线前后复制粘贴），保留第一个。
       */
      if (node.type === 'paywall-divider') {
        const dividers = editor.children
          .map((node, index) => [node, index])
          .filter(([node]) => node.type === 'paywall-divider')
          .map(([, index]) => [index])
        if (dividers.length > 1) {
          dividers.reverse().forEach((path, index) => {
            if (index !== dividers.length - 1) {
              Transforms.removeNodes(editor, {at: [path]})
            }
          })
          return
        }
      }
    } catch (e) {
      console.error(e)
    }
    normalizeNode(entry)
  }

  return editor
}

export default Editor
