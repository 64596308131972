/**
 * 作品操作按钮：
 *
 * - [ ] 点击后 Dropdown 能够正常显示
 * - [ ] 在主域名、子域名下均能正确跳转到作品编辑页面
 * - [ ] 未发布草稿预览 & 已发布内容草稿预览均可正常编辑，预览内容无法删除
 * - [ ] 能够正常删除作品并执行回调，加载状态、错误提示正常
 */

import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {getPostURL, getZhubaiURL, isZhubaiURL} from 'lib/url'
import loadingState from 'state/loading'
import {DefaultButton} from 'components/Button'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {notificationState} from 'components/Notification'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import styles from './PostActionButton.module.css'

function PostActionButton({post, onDelete, isPreview, publication}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const history = useHistory()
  function handleEdit({editType}) {
    if (isZhubaiURL()) {
      window.location.href =
        getPostURL(post) + `/edit${editType ? '?edit-type=1' : ''}`
    } else if (isPreview && (!post.postId || post.postId === '0')) {
      window.location.href = getZhubaiURL(
        `/drafts/${post.id}/edit${editType ? '?edit-type=1' : ''}`
      )
    } else {
      history.push(
        `/posts/${isPreview ? post.postId : post.id}/edit${
          editType ? '?edit-type=1' : ''
        }`
      )
    }
  }

  const setNoti = useSetRecoilState(notificationState)
  const setLoading = useSetRecoilState(loadingState)
  function handleDelete() {
    if (isPreview) {
      window.alert('预览内容暂无法删除')
      return
    }

    if (window.confirm('确认要删除这个作品？')) {
      setLoading(true)
      http(`/posts/${post.id}`, {method: 'DELETE'}).then(
        () => {
          setLoading(false)
          onDelete(post)
        },
        (error) => {
          setNoti({
            visible: true,
            warning: true,
            text: error.body?.message || error.message,
          })
          setLoading(false)
        }
      )
    }
  }

  return (
    <div className={styles.actionButtonRoot}>
      <DefaultButton
        className={styles.actionButton}
        onClick={() => setShowDropdown(true)}
      >
        操作
        <ArrowIcon className={styles.actionButtonIcon} />
      </DefaultButton>
      {showDropdown && (
        <Dropdown
          className={styles.dropdown}
          setShowDropdown={setShowDropdown}
          defaultPlacement="bottomRight"
        >
          <DropdownMenu onClick={handleEdit}>编辑作品内容</DropdownMenu>
          {Boolean(publication?.membership) && (
            <DropdownMenu onClick={() => handleEdit({editType: true})}>
              修改作品类型
            </DropdownMenu>
          )}
          <DropdownMenu danger onClick={handleDelete}>
            删除作品
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}

export default PostActionButton
