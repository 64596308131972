export function getSubscriptionName({userId, subscriberName, subscriberEmail}) {
  let name = ''
  if (subscriberName && subscriberEmail) {
    name = `${subscriberName} (${subscriberEmail})`
  } else if (subscriberName) {
    name = subscriberName
  } else if (subscriberEmail) {
    name = subscriberEmail
  } else {
    name = `用户 ${userId.slice(-8)}`
  }
  return name
}
