const uaTesters = {
  iPad: /Macintosh/,
  iPhone: /iPhone/,
  Android: /Android/,
  Chrome: /Chrome/,
  Wechat: /MicroMessenger/,
  WechatMiniProgram: /miniProgram/,
}

export function parseUA(userAgent) {
  const result = Object.keys(uaTesters).reduce((ret, key) => {
    ret[key] = uaTesters[key].test(userAgent)
    return ret
  }, {})

  result.origin = userAgent

  result.iPad = result.iPad && 'ontouchstart' in window
  return result
}
