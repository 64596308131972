import Tooltip from 'components/Tooltip'
import {ReactComponent as Icon} from 'icons/info.svg'
import styles from './CreatorDataTip.module.css'

function CreatorDataTip({tip, offsetY}) {
  return (
    <Tooltip tip={tip || '了解数据详情'}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        className={styles.tip}
        target="_blank"
        href="https://news.zhubai.love/posts/2117174928636788736"
      >
        <Icon style={{transform: `translateY(${offsetY})`}} />
      </a>
    </Tooltip>
  )
}

export default CreatorDataTip
