/**
 * 预览：
 *
 * - [ ] 标题、正文为空时按钮无法点击
 * - [ ] Menu 能正确出现在按钮上方
 * - [ ] 填写邮箱后能够收到预览邮件，并有通知提示，非空、错误、加载状态逻辑正确
 * - [ ] 邮件预览时如果绑定邮箱则自动填写绑定的邮箱
 * - [ ] 点击「网页预览」&「微信预览」时先获取预览 token，加载/错误状态正常
 * - [ ] 点击「微信预览」时，获取 token 后可正常显示小程序码
 * - [ ] 预览设置付费墙内容时，网页/微信/邮件两个版本的内容正确
 * - [ ] 预览未设置付费墙内容时，网页/微信/邮件的内容正确
 * - [ ] 设置主题色的专栏预览时，网页/邮件链接颜色正确
 */
import {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import loadingState from 'state/loading'
import http from 'lib/http'
import {getPublicationURL} from 'lib/url'
import {DefaultButton} from 'components/Button'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {notificationState} from 'components/Notification'
import {ModalDialog} from 'components/Modal'
import {FormInput, FormError, FormSubmit} from 'components/Form'
import Spinner from 'components/Spinner'
import Tooltip from 'components/Tooltip'
import WechatQRCode from 'components/WechatQRCode'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import {parseContent} from './parseContent'
import styles from './PreviewButton.module.css'

function PreviewButton({
  draft,
  title,
  self,
  content,
  publication,
  disableStatus,
}) {
  const [showDropdown, setShowDropdown] = useState()
  const [showModal, setShowModal] = useState(false)

  const parsedContent = parseContent(content, publication)

  function requestEmailPreview(email) {
    return http(`/drafts/${draft.id}/email-preview`, {
      method: 'POST',
      body: {
        title,
        ...parsedContent,
        emails: [email],
      },
    })
  }

  const [previewToken, setPreviewToken] = useState()
  const setNoti = useSetRecoilState(notificationState)
  const setLoading = useSetRecoilState(loadingState)

  function handleWebPreview() {
    setLoading(true)
    requestPreview({draft, title, parsedContent})
      .then(
        ({token}) => {
          let previewURL = getPublicationURL(
            self.publication,
            `/drafts/${draft?.id}/preview?token=${token}`
          )

          window.location.href = previewURL
        },
        (error) =>
          setNoti({
            visible: true,
            warning: true,
            text: error.body?.message || error.message || '预览失败',
          })
      )
      .finally(() => setLoading(false))
  }

  const [showWechatModal, setShowWechatModal] = useState(false)
  function handleWechatPreview() {
    setLoading(true)
    requestPreview({draft, title, parsedContent})
      .then(
        ({token}) => {
          setPreviewToken(token)
          setShowWechatModal(true)
        },
        (error) =>
          setNoti({
            visible: true,
            warning: true,
            text: error.body?.message || error.message || '预览失败',
          })
      )
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.root}>
      <DefaultButton
        className={styles.button}
        onClick={() => setShowDropdown(true)}
        disabled={disableStatus.disable}
      >
        预览
        <ArrowIcon className={styles.buttonIcon} />
      </DefaultButton>
      {disableStatus.disable && (
        <Tooltip tip={disableStatus.tip}>
          <div className={styles.disableMask}></div>
        </Tooltip>
      )}
      {showDropdown && (
        <Dropdown
          className={styles.dropdown}
          setShowDropdown={setShowDropdown}
          defaultPlacement="topLeft"
        >
          <DropdownMenu onClick={() => setShowModal(true)}>邮件</DropdownMenu>
          <DropdownMenu onClick={() => handleWechatPreview()}>
            微信
          </DropdownMenu>
          <DropdownMenu onClick={() => handleWebPreview()}>网页</DropdownMenu>
        </Dropdown>
      )}
      {showModal && (
        <EmailPreviewModal
          self={self}
          requestPreview={requestEmailPreview}
          setVisible={setShowModal}
        />
      )}
      {showWechatModal && (
        <WechatPreviewModal
          setVisible={setShowWechatModal}
          previewToken={previewToken}
          draft={draft}
          publication={self.publication}
        />
      )}
    </div>
  )
}

function EmailPreviewModal({requestPreview, self, setVisible}) {
  const [email, setEmail] = useState(self.email || '')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const setNoti = useSetRecoilState(notificationState)

  function handleEmailInputChange(event) {
    setError(null)
    setEmail(event.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (email.length === 0) {
      setError({email: true})
      return
    }

    setLoading(true)
    requestPreview(email).then(
      () => {
        setVisible(false)
        setNoti({
          visible: true,
          text: '已发送预览邮件',
        })
      },
      (error) => {
        setError(error.body?.validationErrors || error.body?.message || error)
        setLoading(false)
      }
    )
  }

  return (
    <ModalDialog setVisible={setVisible} hasCloseButton={true}>
      <form className={styles.dialog} onSubmit={handleSubmit}>
        <h1 className={styles.title}>邮件预览</h1>
        <FormInput
          className={styles.input}
          type="text"
          placeholder={error?.email ? '输入邮箱' : '邮箱'}
          value={email}
          error={error?.emails?.['0'][0] || error?.email}
          name="email"
          onChange={handleEmailInputChange}
        />
        <FormSubmit className={styles.submit}>发送测试邮件</FormSubmit>
        <FormError error={error} />
        {loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
      </form>
    </ModalDialog>
  )
}

function WechatPreviewModal({setVisible, previewToken, draft, publication}) {
  return (
    <ModalDialog setVisible={setVisible} hasCloseButton={true}>
      <div className={styles.dialog}>
        <h1 className={styles.title}>小程序预览</h1>
        <div className={styles.description}>使用微信扫描二维码</div>
        <WechatQRCode
          className={styles.image}
          width={520}
          page="pages/post/post"
          scene={`p-${draft.id}-${previewToken}`}
          publication={publication}
        />
      </div>
    </ModalDialog>
  )
}

function requestPreview({draft, title, parsedContent}) {
  return http(`/drafts/${draft.id}/preview`, {
    method: 'POST',
    body: {
      title,
      ...parsedContent,
    },
  })
}

export default PreviewButton
