// - [ ] 点击邮箱中的链接自动请求订阅，加载/错误/成功状态正确

import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import http from 'lib/http'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Error from 'components/Error'

function EmailSubscriptionPage() {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const query = queryString.parse(location.search) || {}
    const {publication_id: publicationId, ...rest} = query

    setLoading(true)
    setError(null)
    http(`/publications/${publicationId}/subscription_confirmation`, {
      method: 'POST',
      body: {...rest},
    }).then(
      () => setLoading(false),
      (error) => {
        setLoading(false)
        setError(error)
      }
    )
  }, [location])

  return (
    <Layout.Page>
      <Layout.PageContent>
        {loading && <Spinner />}
        {error && (
          <Error error={error} handleRetry={() => window.location.reload()} />
        )}
        {!loading && !error && (
          <div style={{margin: 'auto', fontSize: '22px'}}>订阅成功 🎉</div>
        )}
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default EmailSubscriptionPage
