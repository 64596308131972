/**
 * 写文章页：
 * - [ ] 编辑新作品、未发布草稿、无草稿的已发布作品、有草稿的已发布作品编辑器初始内容正确（有无付费墙），均能正常编辑、预览、发布
 * - [ ] 点击正文下方的空白区域能够聚焦编辑器末尾
 * - [ ] 发布时 loading、请求错误、服务端错误显示正常
 * - [ ] Toolbar stikcy 样式正常
 * - [ ] 在 iPhone & Andorid 设备上提示去电脑端使用
 * - [ ] 编辑器的可视区域会剔除顶栏、底栏、工具栏
 * - [ ] 编辑标题、正文时自动保存草稿，debounce 1.5s
 * - [ ] 草稿保存 loading、错误、重试逻辑正确
 * - [ ] 页面加载完成之后未编辑时不会自动保存草稿
 * - [ ] 新建内容时，保存草稿成功后则会替换 URL，页面不会重新加载
 * - [ ] 编辑有草稿的已发布内容时，页面加载完成且未编辑前提示在编辑草稿且支持恢复内容（删除草稿）
 * - [ ] 能够正常恢复内容（删除草稿），加载状态、错误提示正常
 * - [ ] 标题为空、正文为空、发布中、恢复已发布内容中均禁用预览和发布，并有对应的提示。
 * - [ ] 未保存草稿（没来得及保存、保存中、保存失败、编辑已发布内容但没有做任何改动）禁用预览和发布，并有对应的提示。
 * - [ ] 有上传中/失败的图片时不会保存草稿，不能预览/发布，上传成功后自动保存草稿
 * - [ ] 开通会员计划的专栏编辑已发布作品时，无需变更（保存草稿）即可设置作品类型并发布
 * - [ ] 可正常保存付费墙分隔线到草稿，发布后再编辑时分隔线可见且位置正确
 * - [ ] 开通会员计划的专栏编辑页面工具显示插入付费墙按钮，未开通则不显示
 */
import cx from 'classnames'
import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useSetRecoilState} from 'recoil'
import debounce from 'lodash.debounce'
import http from 'lib/http'
import {parseUA} from 'lib/userAgent'
import usePrevious from 'hooks/usePrevious'
import selfPublicationRequired from 'hocs/selfPublicationRequired'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Button from 'components/Button'
import Editor, {getCharactersCount} from 'components/Editor'
import {notificationState} from 'components/Notification'
import ContactButton from 'components/ContactButton'
import PageTitle from 'components/PageTitle'
import {parsePaywallToEditable} from 'components/Editor/PaywallPlugin'
import PreviewButton from './PreviewButton'
import WritePageBackLink from './WritePageBackLink'
import WritePageSubmitButton from './WritePageSubmitButton'
import {parseContent} from './parseContent'
import styles from './WritePage.module.css'

function WritePage({self, draft, post, publication, mutateDraft}) {
  const userAgent = parseUA(window.navigator.userAgent)

  if (userAgent.iPhone || userAgent.Android) {
    return <MobileWritePage />
  }

  return (
    <DesktopWritePage
      self={self}
      defaultDraft={draft}
      post={post}
      publication={publication}
      mutateDraft={mutateDraft}
    />
  )
}

function MobileWritePage() {
  return (
    <Layout.Page>
      <TopNav className={styles.nav}>
        <WritePageBackLink />
      </TopNav>
      <Layout.PageContent>
        <div className={styles.mobileMessage}>
          暂不支持手机端编辑，请用电脑访问
        </div>
      </Layout.PageContent>
    </Layout.Page>
  )
}

const debouncedSaveDraft = debounce(saveDraft, 1500, {maxWait: 60 * 1000})

function DesktopWritePage({
  self,
  post,
  defaultDraft,
  publication,
  mutateDraft,
}) {
  const hasMembership = Boolean(publication.membership)

  const [editor, setEditor] = useState()

  const scrollRootRef = useRef()

  const [publishStatus, setPublishStatus] = useState({
    loading: false,
    error: null,
  })

  const [draft, setDraft] = useState(defaultDraft)

  // - [ ] 新文章、编辑未发布草稿、编辑无草稿的已发布内容、（从草稿列表页）编辑已发布内容草稿时，页面标题、提交按钮文案正确
  const isEditing = (!draft && post) || (draft?.postId && draft.postId !== '0')

  const intialTitle = draft?.title || post?.title || ''
  const [title, setTitle] = useState(intialTitle)

  const intialContent = getInitalContent(draft || post)
  const [content, setContent] = useState(intialContent)

  const isEmpty = isContentEmpty(content)
  const hasUploadingImage = contentHasUploadingImage(content)

  const setNoti = useSetRecoilState(notificationState)

  const [draftStatus, setDraftStatus] = useState('initial')
  const [draftError, setDraftError] = useState(null)

  const handleSaveDraft = useCallback(() => {
    setDraftError(null)
    setDraftStatus('unsaved')

    if (title.length === 0 && isEmpty) {
      return
    }

    if (hasUploadingImage) {
      return
    }

    debouncedSaveDraft({
      draft,
      title,
      content,
      post,
      publication,
      handleStart: () => setDraftStatus('saving'),
      handleSuccess: (draft) => {
        setDraft(draft)
        if (mutateDraft) {
          mutateDraft(draft, false)
        }

        setDraftStatus('saved')
      },
      handleFail: (error) => {
        setDraftError(error)
        setDraftStatus('unsaved')
        setNoti({
          visible: true,
          warning: true,
          text: error.body?.message || error.message || '草稿保存失败，请重试',
        })
      },
    })
  }, [
    draft,
    title,
    content,
    post,
    isEmpty,
    setNoti,
    hasUploadingImage,
    mutateDraft,
    publication,
  ])

  // TODO: 为了避免 redirect 暂时用 window.history hack
  useEffect(() => {
    if (draft && window.location.pathname === '/write') {
      window.history.replaceState(
        null,
        '',
        `${window.location.origin}/drafts/${draft.id}/edit${window.location.search}`
      )
    }
  }, [draft])

  // - [ ] 恢复成已发布内容时，可成功删除草稿，不论有无设置付费墙编辑器内容正确
  // - [ ] 删除草稿后默认禁止预览和发布
  // - [ ] 恢复已发布内容过程中禁止编辑标题和草稿
  const [restoring, setRestoring] = useState(false)
  function handleRestore() {
    if (window.confirm('确认删除当前草稿恢复成已发布的内容？')) {
      setRestoring(true)
      http(`/drafts/${draft.id}`, {method: 'DELETE'}).then(
        () => {
          setDraft(null)
          if (mutateDraft) {
            mutateDraft(null, false)
          }

          setDraftStatus('initial')
          setTitle(post.title)
          setContent(getInitalContent(post))
          setRestoring(false)
        },
        (error) => {
          setNoti({
            visible: true,
            warning: true,
            text:
              error.body?.message || error.message || '删除草稿失败，请重试',
          })
          setRestoring(false)
        }
      )
    }
  }

  // - [ ] 打开编辑页面后未做变更时不会保存草稿（例如编辑新作品、没有草稿的已发布作品时）。
  // - [ ] 编辑没有草稿的已发布作品时，输入内容/修改样式均会触发保存草稿。
  // - [ ] 恢复已发布内容（删除草稿）后，不会触发保存草稿。
  const previousTitle = usePrevious(title)
  const previousContent = usePrevious(content)
  useEffect(() => {
    if (
      typeof previousTitle === 'undefined' &&
      typeof previousContent === 'undefined'
    ) {
      return
    }

    if (restoring) {
      return
    }

    handleSaveDraft()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, content])

  // 尝试支持 Tab 后聚焦到编辑器，但暂不知道如何靠谱的聚焦
  // function handleKeyDown(event) {
  //   if (event.key === 'Tab' && editor) {
  //     event.preventDefault()
  //     const [path] = SlateEditor.nodes(editor, {
  //       match: (node) => console.log(node) || SlateText.isText(node),
  //     })
  //     ReactEditor.focus(editor, editor)
  //     Transforms.select(editor, {
  //       anchor: {path: [0, 0], offset: 0},
  //       focus: {path: [0, 0], offset: 0},
  //     })
  //   }
  // }

  let disableStatus = {disable: false, tip: ''}

  if (title.length === 0) {
    disableStatus = {disable: true, tip: '标题不能为空'}
  } else if (isEmpty) {
    disableStatus = {disable: true, tip: '正文必须要有文字'}
  } else if (hasUploadingImage) {
    disableStatus = {disable: true, tip: '有未上传成功的图片'}
  } else if (draftStatus === 'saving') {
    disableStatus = {disable: true, tip: '保存草稿中'}
  } else if (draftStatus === 'unsaved') {
    disableStatus = {disable: true, tip: '未保存草稿'}
  } else if (!draft) {
    disableStatus = {disable: true, tip: '没有做任何改变', type: 'no-change'}
  } else if (publishStatus.loading) {
    disableStatus = {disable: true, tip: '作品发布中'}
  } else if (restoring) {
    disableStatus = {disable: true, tip: '恢复已发布内容中'}
  }

  return (
    <Layout.Page className={styles.page}>
      <PageTitle
        title={`${isEditing ? '修改作品' : '新作品'} - ${
          title.length > 0 ? title : '无标题'
        }`}
      />
      <TopNav
        className={styles.nav}
        rootClass={styles.pageNav}
        loading={publishStatus.loading || draftStatus === 'saving' || restoring}
      >
        <WritePageBackLink />
      </TopNav>
      <Layout.PageContent className={styles.pageContent} ref={scrollRootRef}>
        <Layout.View slim className={styles.view}>
          <AutoGrowingTextArea
            className={styles.titleInput}
            placeholder="标题"
            rows={1}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value)
            }}
            disabled={restoring}
          ></AutoGrowingTextArea>
          <Editor
            className={styles.editor}
            toolbarClassName={styles.toolbar}
            placeholder="正文"
            value={content}
            setValue={setContent}
            scrollRoot={scrollRootRef.current}
            setEditor={setEditor}
            disabled={restoring}
            enablePaywall={hasMembership}
          />
        </Layout.View>
      </Layout.PageContent>
      <div className={styles.pageFooter}>
        <div className={styles.actionsFooter}>
          <Layout.View slim className={styles.actionsFooterContent}>
            <div>
              <DraftStatus
                status={draftStatus}
                error={draftError}
                handleRetry={handleSaveDraft}
                draft={draft}
                post={post}
                handleRestore={handleRestore}
              />
            </div>
            <div className={styles.buttons}>
              <PreviewButton
                self={self}
                title={title}
                draft={draft}
                content={content}
                disableStatus={disableStatus}
                publication={publication}
              />
              <WritePageSubmitButton
                self={self}
                publication={publication}
                title={title}
                content={content}
                draft={draft}
                post={post}
                isEditing={isEditing}
                setPublishStatus={setPublishStatus}
                disableStatus={disableStatus}
                editor={editor}
              />
            </div>
          </Layout.View>
        </div>
      </div>
      <ContactButton className={styles.contactButton} />
    </Layout.Page>
  )
}

/**
 * Auto-growing TextArea
 * - [ ] 新增、删减文本时高度会正确变化
 * - [ ] 默认值超出高度时高度正确
 *
 * TODO：
 * - [ ] 默认值超出初始长度时，第一次渲染是被阶段的高度，然后有闪动
 *
 * 详情见：https://stackoverflow.com/a/24676492
 */
function AutoGrowingTextArea({children, ...props}) {
  const [height, setHeight] = useState('auto')
  const elementRef = useRef()

  useEffect(() => {
    setHeight(`${elementRef.current?.scrollHeight}px`)
  }, [height])

  function handleInput() {
    setHeight('auto')
  }

  return (
    <textarea
      {...props}
      onInput={handleInput}
      style={{height: height}}
      ref={elementRef}
    >
      {children}
    </textarea>
  )
}

function DraftStatus({post, draft, status, error, handleRetry, handleRestore}) {
  const showSaved = status === 'saved' && !error
  const showError = Boolean(error)
  const showRestore = status === 'initial' && draft && post

  const visible = showSaved || showError || showRestore

  return (
    <div className={cx(styles.draftStatus, {[styles.isVisible]: visible})}>
      {showSaved && '已保存草稿'}
      {showError && (
        <>
          <span>草稿保存失败&nbsp;&nbsp;·&nbsp;&nbsp;</span>
          <Button className={styles.retryButton} onClick={handleRetry}>
            重试
          </Button>
        </>
      )}
      {showRestore && (
        <>
          <span>编辑草稿中&nbsp;&nbsp;·&nbsp;&nbsp;</span>
          <Button className={styles.restoreButton} onClick={handleRestore}>
            恢复成已发布内容
          </Button>
        </>
      )}
    </div>
  )
}

// - [ ] 创建/更新草稿后 draft 对象内容正确
function saveDraft({
  draft,
  title,
  post,
  content,
  publication,
  handleStart,
  handleSuccess,
  handleFail,
}) {
  handleStart()

  const parsedContent = parseContent(content, publication)

  if (draft) {
    http(`/drafts/${draft.id}`, {
      method: 'PUT',
      body: {
        title,
        ...parsedContent,
      },
    }).then(
      () =>
        handleSuccess({
          ...draft,
          title,
          content: parsedContent.content,
          paywall: parsedContent.paywall,
        }),
      handleFail
    )
  } else {
    http('/drafts', {
      method: 'POST',
      body: {
        title,
        ...parsedContent,
        postId: post?.id,
      },
    }).then(handleSuccess, handleFail)
  }
}

function isContentEmpty(content) {
  return getCharactersCount(content) === 0
}

function contentHasUploadingImage(content) {
  return content.filter((node) => node.type === 'image' && !node.url).length > 0
}

// - [ ] 编辑新作品、未发布草稿、无草稿的已发布作品、有草稿的已发布作品编辑器初始内容正确（有无付费墙）
function getInitalContent(entity) {
  if (!entity) {
    return [
      {
        type: 'paragraph',
        children: [{text: ''}],
      },
    ]
  }

  const {paywall} = entity

  if (!paywall) {
    return JSON.parse(entity.content)
  }

  return parsePaywallToEditable(JSON.parse(entity.content), paywall)
}

export default selfPublicationRequired(WritePage)
