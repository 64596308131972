/**
 * Newsletter 创建页：
 * - [ ] 未登录时跳去登录，未填资料则跳去填资料
 * - [ ] 已创建 newsletter 时则提示错误无法创建
 * - [ ] 表单提交加载状态、非空错误、请求错误、服务错误正常
 * - [ ] 创建成功后跳转到对应的 newsletter 页面
 */

import React, {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {getPublicationURL} from 'lib/url'
import loadingState from 'state/loading'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import Form, {
  FormTitle,
  FormInput,
  FormTextarea,
  FormSubmit,
  FormError,
} from 'components/Form'
import ContactButton from 'components/ContactButton'
import selfRequired from 'hocs/selfRequired'
import styles from './PublicationCreatePage.module.css'

function PublicationCreatePage({self}) {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [token, setToken] = useState('')
  const [error, setError] = useState(null)

  const setLoading = useSetRecoilState(loadingState)

  const nameInputRef = React.createRef()
  const tokenInputRef = React.createRef()
  const descriptionInputRef = React.createRef()

  function handleNameInputChange(e) {
    setName(e.target.value)
    setError(null)
  }

  function handleDescriptionInputChange(e) {
    setDescription(e.target.value)
    setError(null)
  }

  function handleTokenInputChange(e) {
    setToken(e.target.value)
    setError(null)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (description.length === 0) {
      descriptionInputRef.current.focus()
      setError((error) => ({...error, description: true}))
    }

    if (token.length === 0) {
      tokenInputRef.current.focus()
      setError((error) => ({...error, token: true}))
    }

    if (name.length === 0) {
      nameInputRef.current.focus()
      setError((error) => ({...error, name: true}))
    }

    if (name.length === 0 || token.length === 0 || description.length === 0) {
      return
    }

    setLoading(true)
    http('/publications', {
      method: 'POST',
      body: {
        name,
        description,
        token,
      },
    }).then(
      (publication) => {
        window.location.href = getPublicationURL(publication)
      },
      (error) => {
        setError(error.body?.validationErrors || error.body?.message || error)
        setLoading(false)
      }
    )
  }

  return (
    <Layout.Page>
      <GlobalTopNav />
      <Layout.PageContent>
        {self.publication ? (
          <div className={styles.error}>
            <div className={styles.errorTitle}>目前仅支持创建 1 个专栏</div>
            <div className={styles.errorSubtitle}>如有特殊情况可联系我们</div>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <FormTitle title="创建专栏" subtitle="创建你的首个专栏开始创作" />
            <FormInput
              type="text"
              placeholder={error?.name ? '输入专栏名称' : '专栏名称'}
              value={name}
              name="name"
              autoComplete="off"
              ref={nameInputRef}
              onChange={handleNameInputChange}
              error={error?.name?.[0] || error?.name}
            />
            <FormInput
              className={styles.subdomain}
              type="text"
              placeholder={'个性域名'}
              value={token}
              name="token"
              error={error?.token?.[0] || error?.token}
              autoComplete="off"
              onChange={handleTokenInputChange}
              ref={tokenInputRef}
              renderRight={() => (
                <div className={styles.domain}>.zhubai.love</div>
              )}
            />
            <FormTextarea
              type="text"
              placeholder="专栏介绍（帮助大家了解你的创作方向）"
              value={description}
              name="description"
              error={error?.description?.[0] || error?.description}
              autoComplete="off"
              onChange={handleDescriptionInputChange}
              ref={descriptionInputRef}
            />
            <FormSubmit>完成</FormSubmit>
            <FormError error={error} />
          </Form>
        )}
      </Layout.PageContent>
      <ContactButton />
    </Layout.Page>
  )
}

export default selfRequired(PublicationCreatePage)
