/**
 * PageShow 统计:
 * - [ ] 访问 Newsletter 页 & 内容页有且只有一次 PageShow
 * - [ ] 能够正确从 cookie 中读取 user id
 * - [ ] 能够正确从 cookie 中读取 client id，如果没有则生成一个并正确设置 cookie（Domain, Expires, Path）
 * - [ ] 直接访问 Newsletter 页面 referrer 正确（为空）
 * - [ ] 从 Newsletter 页面新标签页打开内容页 referrer 正确
 * - [ ] 从 Newsletter 页面原地打开内容页 referrer 正确
 * - [ ] 访问 Newsletter 页 & 内容页 entity 正确
 * - [ ] DEV 环境下默认只 log，不上报
 */

import Cookie from 'js-cookie'
import {useEffect} from 'react'
import {uuidv4} from 'lib/uuid'
import {getLocationHistory} from 'lib/locationHistory'
import http from 'lib/http'

export function usePageShow({entity}) {
  const {type, id} = entity || {}

  useEffect(() => {
    if (!type || !id) {
      return null
    }

    const userId = Cookie.get('uid')

    let clientId = Cookie.get('cid')
    if (!clientId) {
      clientId = uuidv4()

      const domain = window.location.hostname.includes('zhubai.love')
        ? process.env.REACT_APP_COOKIE_DOMAIN
        : process.env.REACT_APP_COOKIE_DOMAIN_OLD

      Cookie.set('cid', clientId, {
        domain,
        path: '/',
        expires: 10 * 365,
      })
    }

    let referrer = window.document.referrer
    const history = getLocationHistory()

    if (!referrer && history.length > 1) {
      referrer = window.location.origin + history[history.length - 2]
    }

    const body = {
      type: 'PageShow',
      platform: 'Web',
      meta: {
        userId,
        clientId,
        url: window.location.href,
        referrer,
        isSubscriber: false,
        pushSource: null,
        created_at: Date.now(),
      },
      entity: {type, id},
    }

    if (process.env.REACT_APP_DEV === 'true') {
      console.log(body)
    } else {
      http('/collect', {
        method: 'POST',
        body,
      }).catch((error) => console.error(error))
    }
  }, [type, id])
}
