import {getSubscriptionName} from 'pages/CreatorPage/CreatorPageUtils'
import useSWR from 'swr'

export function usePostsSummaryAnalytics() {
  const {data, error, ...rest} = useSWR('/dashboard/post_summary')

  return {
    ...rest,
    data,
    error,
    loading: !error && !data,
  }
}

export function usePostsAnalytics(pageIndex) {
  const {data, error, ...rest} = useSWR(
    pageIndex > 0 ? `/dashboard/posts?limit=20&page=${pageIndex}` : null,
    {
      revalidateOnFocus: false, // 暂时为避免删除 comfirm 导致失去焦点后更新错序
    }
  )

  return {
    ...rest,
    error,
    data,
    analytics: data?.data,
    loading: !error && !data,
  }
}

export function useSubscribersSummaryAnalytics() {
  const {data, error, ...rest} = useSWR('/dashboard/subscriber_summary')

  return {
    ...rest,
    data,
    error,
    loading: !error && !data,
  }
}

export function useSubscribersAnalytics(pageIndex = 1) {
  const {data, error, ...rest} = useSWR(
    pageIndex > 0 ? `/dashboard/subscribers?limit=20&page=${pageIndex}` : null,
    {
      revalidateOnFocus: false, // 暂时为避免删除 comfirm 导致失去焦点后更新错序
    }
  )

  return {
    ...rest,
    error,
    data,
    analytics: data?.data,
    loading: !error && !data,
  }
}

export function useSubscribersAnalyticsV2(pageIndex = 1) {
  const {data, error, ...rest} = useSWR(
    pageIndex > 0
      ? `/dashboard/subscriptions?limit=20&page=${pageIndex}`
      : null,
    {
      revalidateOnFocus: false, // 暂时为避免删除 comfirm 导致失去焦点后更新错序
    }
  )

  return {
    ...rest,
    error,
    data,
    analytics: data?.data,
    loading: !error && !data,
  }
}

// - [x] 聚焦搜索框且没有搜索词时显示没有匹配的订阅者
export function useSearchSubscribers(query) {
  const {error, data, ...rest} = useSWR('/dashboard/searchable_subscriptions', {
    revalidateOnFocus: false, // 暂时为避免删除 comfirm 导致失去焦点后更新错序
  })

  return {
    ...rest,
    error,
    data,
    searchResults:
      query === ''
        ? []
        : data?.data.filter((subscription) =>
            getSubscriptionName(subscription)
              .toLowerCase()
              .includes(query?.toLowerCase())
          ),
    loading: !error && !data,
  }
}

export function useRevenueSummary() {
  const {data, error, ...rest} = useSWR('/dashboard/revenue_summary')

  return {
    ...rest,
    summary: data,
    error,
    loading: !error && !data,
  }
}

export function useRevenueOrders(pageIndex = 1) {
  const {data, error, ...rest} = useSWR(
    pageIndex > 0
      ? `/dashboard/revenue/orders?limit=20&page=${pageIndex}`
      : null
  )

  return {
    ...rest,
    data,
    orders: data?.data,
    error,
    loading: !error && !data,
  }
}
