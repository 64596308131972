// - [ ] 可正确显示折行
// - [ ] 气泡最大宽度正确，长单词可以正确折行
// - [ ] 当对方有专栏时点击头像在新标签页打开专栏页，在微信内则 confirm 后打开
import cx from 'classnames'
import {UserAvatar} from 'components/Avatar'
import {getRelativeTimeString} from 'lib/time'
import {getPublicationURL} from 'lib/url'
import {parseUA} from 'lib/userAgent'
import {useSelf} from 'swr/self'
import styles from './MessageItem.module.css'

const userAgent = parseUA(window.navigator.userAgent)

function MessageItem({message, user, publication}) {
  const {self} = useSelf()
  const isSelf = message.userId === self.id

  function handleClick(e) {
    if (!userAgent.Wechat) {
      return
    }

    if (window.confirm('离开当前对话访问对方专栏页？')) {
    } else {
      e.preventDefault()
    }
  }

  return (
    <div className={cx(styles.message, {[styles.isSelf]: isSelf})}>
      {/* eslint-disable react/jsx-no-target-blank */}
      {publication && !isSelf ? (
        <a
          target="_blank"
          href={getPublicationURL(publication)}
          onClick={handleClick}
        >
          <UserAvatar
            className={styles.avatar}
            user={isSelf ? self : user}
            size={46}
          />
        </a>
      ) : (
        <UserAvatar
          className={styles.avatar}
          user={isSelf ? self : user}
          size={46}
        />
      )}
      <div className={styles.content}>
        <div className={styles.bubble}>{message.content.text}</div>
        <div className={styles.time}>
          {getRelativeTimeString(message.createdAt, {precise: true})}
        </div>
      </div>
    </div>
  )
}

export default MessageItem
