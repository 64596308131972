// - [ ] 空段落可正常渲染，空 <span> 会被过滤
export function Leaf({children, leaf}) {
  if (!children && children.length === 0) {
    return null
  }

  return (
    <span
      style={{
        ...(leaf.bold && {fontWeight: 'bold'}),
        ...(leaf.italic && {fontStyle: 'italic'}),
        ...((leaf.underline || leaf.strikethrough) && {
          textDecoration: `${leaf.underline ? 'underline' : ''} ${
            leaf.strikethrough ? 'line-through' : ''
          }`,
        }),
        ...(leaf.code && {fontFamily: 'monospace'}),
      }}
    >
      {children}
    </span>
  )
}

export function EditableLeaf({attributes, children, leaf}) {
  return (
    <span
      {...attributes}
      style={{
        ...(leaf.bold && {fontWeight: 'bold'}),
        ...(leaf.italic && {fontStyle: 'italic'}),
        ...((leaf.underline || leaf.strikethrough) && {
          textDecoration: `${leaf.underline ? 'underline' : ''} ${
            leaf.strikethrough ? 'line-through' : ''
          }`,
        }),
        ...(leaf.code && {fontFamily: 'monospace'}),
      }}
    >
      {children}
    </span>
  )
}
