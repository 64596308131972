import queryString from 'query-string'
import {useEffect} from 'react'

const host = window.location.hostname.includes('zhubai.love')
  ? process.env.REACT_APP_HOST
  : process.env.REACT_APP_HOST_OLD

const baseHost = window.location.hostname.includes('zhubai.love')
  ? process.env.REACT_APP_BASE_HOST
  : process.env.REACT_APP_BASE_HOST_OLD

export function getPublicationURL(publication, pathname = '/') {
  return `//${publication.token}.${baseHost}${pathname}`
}

export function getPostURL(post) {
  return `//${post.publication.token}.${baseHost}/posts/${post.id}`
}

export function getZhubaiURL(pathname) {
  return `//${host}${pathname}`
}

export function isZhubaiURL() {
  return window.location.host === host
}

export function getURLWithExtraQuery({url, extraQuery}) {
  const location = new URL(url)
  const searchString = queryString.stringify({
    ...queryString.parse(location.search),
    ...extraQuery,
  })
  return `${location.origin}${location.pathname}?${searchString}`
}

// - [ ] 可正常获取指定 query，URL 中没有任何 query 时不会报错
// - [ ] 当 clean 为 true 时，正常获取指定 query 后清除对应 query 且页面不会刷新
// - [ ] 清除 query 时会保留已有 query，若清楚后没有 query URL 结尾则没有 ?
export function useQuery(key, {clean} = {clean: false}) {
  const value = queryString.parse(window.location.search)[key]

  useEffect(() => {
    if (!clean) {
      return
    }

    const query = queryString.parse(window.location.search)
    delete query[key]
    const searchString = queryString.stringify(query)

    window.history.replaceState(
      null,
      '',
      `${window.location.origin}${window.location.pathname}${
        searchString.length > 0 ? '?' + searchString : ''
      }`
    )
  }, [clean, key])

  return value
}
