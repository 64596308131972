import {useRef, useEffect} from 'react'

/**
 * 使用 setInterval 的 hook 版本
 *
 * @param {Function} callback
 * @param {number} delay
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export default function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
