/**
 * Auth Required:
 * - [ ] 未登录访问 /users/self 页面时会跳登录页面，当前 URL 会设为 next query
 * - [ ] 主域名 & 自定义域名下均能正确跳转
 */

import {Redirect, useLocation} from 'react-router-dom'
import {isLoggedIn} from 'lib/auth'
import getDisplayName from 'lib/getDisplayName'
import {getPublicationURL, getZhubaiURL, isZhubaiURL} from 'lib/url'
import getPublicationToken from 'lib/getPublicationToken'

function authRequired(Page) {
  function AuthRequiredPage(props) {
    const location = useLocation()
    const next = encodeURIComponent(location.pathname + location.search)

    if (isLoggedIn()) {
      return <Page {...props} />
    } else if (isZhubaiURL()) {
      return <Redirect to={`/auth/login?next=${next}`} />
    } else {
      const token = getPublicationToken()
      window.location.href = getZhubaiURL(
        `/auth/login?next=${getPublicationURL({token}, next)}`
      )
    }
  }

  AuthRequiredPage.displayName = `AuthRequired${getDisplayName(Page)}`

  return AuthRequiredPage
}

export default authRequired
