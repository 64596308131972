import {Editor} from 'slate'

// - [ ] 移除 withPastingHTML 中的对 FIGURE 的处理，右键点击从作品详情页复制过来的图片提示错误但不会白屏
export function isMarkActive(editor, format) {
  try {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
  } catch (e) {
    console.error(e)
  }
}

export function toggleMark(editor, format) {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}
