// - [ ] 所有菜单都出现在按钮下方且不会被截断
// - [ ] 更新时间样式正常

import {useEffect, useState} from 'react'
import {formatNumber} from 'lib/numbers'
import {getPostURL} from 'lib/url'
import {getRelativeTimeString} from 'lib/time'
import {usePostsAnalytics, usePostsSummaryAnalytics} from 'swr/analytic'
import Layout from 'components/Layout'
import PostActionButton from 'components/PostActionButton'
import {useLoadingBar} from 'components/TopNav'
import PageTitle from 'components/PageTitle'
import {DefaultButton} from 'components/Button'
import ComingSoonModal from 'components/ComingSoonModal'
import Tooltip from 'components/Tooltip'
import {ReactComponent as LockIcon} from 'icons/lock.svg'
import CreatorDataSummary, {
  CreatorDataSummaryItem,
  CreatorDataSummarySection,
} from './CreatorDataSummary'
import CreatorTable, {
  CreatorTableEmpty,
  CreatorTableError,
  CreatorTableLoading,
} from './CreatorTable'
import CreatorDataTip from './CreatorDataTip'
import CreatorPagination, {usePageIndex} from './CreatorPagination'
import DetailButton from './CreatorDetailButton'
import styles from './CreatorPostsPage.module.css'

function CreatorPostsPage({publication}) {
  const {data, error, loading, isValidating, mutate} =
    usePostsSummaryAnalytics()

  useLoadingBar(loading || isValidating)

  return (
    <Layout.View className={styles.page}>
      <PageTitle title="我的作品" />
      <CreatorDataSummary error={error} onRetry={() => mutate()}>
        <CreatorDataSummarySection consecutive>
          <CreatorDataSummaryItem
            number={data?.viewCount}
            diff={data && data.viewCount - data.sevenDaysAgoViewCount}
            text="总阅读量"
            style={{whiteSpace: 'pre'}}
            tooltip="所有作品的访问次数"
          />
          <CreatorDataSummaryItem
            number={data?.emailViewCount}
            diff={data && data.emailViewCount - data.sevenDaysAgoEmailViewCount}
            text="邮件阅读量"
            tooltip="邮箱内所有订阅者的访问次数"
          />
        </CreatorDataSummarySection>
        <CreatorDataSummarySection consecutive>
          <CreatorDataSummaryItem
            number={data?.wechatViewCount}
            diff={
              data && data.wechatViewCount - data.sevenDaysAgoWechatViewCount
            }
            text="微信阅读量"
            tooltip="微信内所有订阅者的访问次数"
          />
          <CreatorDataSummaryItem
            number={data?.othersViewCount}
            diff={
              data && data.othersViewCount - data.sevenDaysAgoOthersViewCount
            }
            text="非订阅者阅读量"
            tooltip="所有非订阅者的访问次数"
          />
        </CreatorDataSummarySection>
      </CreatorDataSummary>
      <Posts dataSummary={data} publication={publication} />
    </Layout.View>
  )
}

// - [ ] 删除文章时前弹窗确认（不会自动更新列表）
// - [ ] 翻页逻辑正常
// - [ ] 能够正常加载并显示，加载状态、错误、重试、空状态正常
// - [ ] 最后一行的菜单能够正常显示
function Posts({dataSummary, publication}) {
  const {updatedAt} = dataSummary || {}
  const pageIndex = usePageIndex()

  const {analytics, data, mutate, error, loading, isValidating} =
    usePostsAnalytics(pageIndex)

  useLoadingBar(isValidating)

  // Prefetch
  usePostsAnalytics(pageIndex + 1)
  usePostsAnalytics(pageIndex - 1)

  function handleDelete(deletedPost) {
    mutate({
      ...data,
      data: analytics.filter(({post}) => post.id !== deletedPost.id),
    })
  }

  const [showComingSoon, setShowComingSoon] = useState(false)

  return (
    <div className={styles.tableRoot}>
      <CreatorTable rootClass={styles.table}>
        <thead>
          <tr>
            <th scope="col">作品</th>
            <th scope="col">
              推送送达人数
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col">
              推送打开人数
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col" style={{width: '190px'}}>
              推送点击链接人数
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col">
              订阅者阅读量
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col">
              非订阅者阅读量
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col" className={styles.actionsColumn}>
              <DefaultButton onClick={() => setShowComingSoon(true)}>
                导入/导出
              </DefaultButton>
              {showComingSoon && (
                <ComingSoonModal setVisible={setShowComingSoon} />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && <CreatorTableLoading />}
          {error && (
            <CreatorTableError
              error={error}
              handleRetry={() => mutate()}
              colSpan={7}
            />
          )}
          {analytics?.length === 0 && (
            <CreatorTableEmpty colSpan={7}>还没有作品</CreatorTableEmpty>
          )}
          {analytics?.map((analytic) => (
            <PostItem
              key={analytic.post.id}
              analytic={analytic}
              onDelete={handleDelete}
              publication={publication}
              updatedAt={updatedAt}
            />
          ))}
        </tbody>
      </CreatorTable>
      <div className={styles.footer}>
        <div>
          <CreatorPagination
            pagination={data?.pagination}
            className={styles.pagination}
          />
        </div>
        <div className={styles.updated}>
          {analytics?.length > 0 &&
            updatedAt &&
            `以上数据更新于 ${getRelativeTimeString(updatedAt)}`}
        </div>
      </div>
    </div>
  )
}

// - [ ] 数据更新早于作品发布 1 小时之前时，数据显示为 `-`，hover 后显示统计中 tooltip
function PostItem({analytic, onDelete, publication, updatedAt}) {
  const {post, data} = analytic
  const [isReady, setReady] = useState(false)

  const openedUserCount =
    typeof data.openedUsersCountViaPush === 'undefined'
      ? data.openedUserCount
      : data.openedUsersCountViaPush

  useEffect(() => {
    setReady(updatedAt > post.createdAt + 1000 * 60 * 60)
  }, [updatedAt, post.createdAt])

  return (
    <tr>
      <th scope="row">
        {post.isPaidContent && (
          <Tooltip tip="付费作品">
            <LockIcon className={styles.icon} />
          </Tooltip>
        )}
        <a href={getPostURL(post)}>{post.title}</a>
      </th>
      <td>
        {isReady ? (
          formatNumber(data.deliveredUserCount)
        ) : (
          <Tooltip tip="数据统计中" placement="left">
            <div>-</div>
          </Tooltip>
        )}
      </td>
      <td>
        {isReady ? (
          formatNumber(openedUserCount)
        ) : (
          <Tooltip tip="数据统计中" placement="left">
            <div>-</div>
          </Tooltip>
        )}
      </td>
      <td>
        {isReady ? (
          formatNumber(data.linkClickedSubscriberCount)
        ) : (
          <Tooltip tip="数据统计中" placement="left">
            <div>-</div>
          </Tooltip>
        )}
      </td>
      <td>
        {isReady ? (
          formatNumber(data.subscriberViewCount)
        ) : (
          <Tooltip tip="数据统计中" placement="left">
            <div>-</div>
          </Tooltip>
        )}
      </td>
      <td>
        {isReady ? (
          formatNumber(data.nonsubscriberViewCount)
        ) : (
          <Tooltip tip="数据统计中" placement="left">
            <div>-</div>
          </Tooltip>
        )}
      </td>
      <td>
        <div className={styles.actions}>
          <DetailButton analytic={analytic} />
          <PostActionButton
            post={post}
            onDelete={onDelete}
            publication={publication}
          />
        </div>
      </td>
    </tr>
  )
}

export default CreatorPostsPage
