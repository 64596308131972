/**
 * 头像
 * - [ ] 图片加载时有背景色能看清轮廓，该背景色不会导致在深色背景下由于渲染问题导致的边缘「漏光」
 * - [ ] 有滤镜能看清白色头像的轮廓
 * - [ ] 头像图片为长方形时会被剪裁而不是压缩
 */

import cx from 'classnames'
import {ReactComponent as DefaultAvatar} from 'icons/default-avatar.svg'
import styles from './Avatar.module.css'

function Avatar({className, size, src, alt, ...props}) {
  return (
    <img
      {...props}
      className={cx(styles.avatar, className)}
      width={size}
      height={size}
      src={src}
      alt={alt}
    />
  )
}

export function UserAvatar({user, size, ...props}) {
  if (!user.avatar) {
    return <DefaultAvatar {...props} width={size} height={size} />
  }

  return <Avatar {...props} src={user.avatar} alt={user.name} size={size} />
}

export default Avatar
