// - [ ] 桌面端滚动到顶部时可正常请求加载前一页，移动端点击加载按钮后请求前一页
// - [ ] 加载前一页后消息顺序正确
// - [ ] 加载前一页后能 scroll 位置正确，切换对话后会 scroll 到底部
// - [ ] 切换对话后列表内容正确，可正常渲染之前加载的消息，也可正常加载前一页
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import http from 'lib/http'
import {parseUA} from 'lib/userAgent'
import Spinner from 'components/Spinner'
import {TextButton} from 'components/Button'
import {useLoadingBar} from 'components/TopNav'
import Error from 'components/Error'
import useMessagesInfinite from './useMessagesInfinite'
import MessageItem from './MessageItem'
import styles from './MessagesList.module.css'

const userAgent = parseUA(window.navigator.userAgent)

const MessagesList = React.forwardRef(
  ({conversation, setMessagesLoaded}, ref) => {
    const conversationId = conversation.id

    const {
      messages,
      messagesConversationId,
      size,
      setSize,
      hasNext,
      isEmpty,
      error,
      isLoading,
      mutate,
      isValidating,
    } = useMessages({
      conversationId,
      ref,
    })

    const loadingPlaceholderRef = useRef()

    const enableAutoLoad =
      !userAgent.iPhone && !userAgent.Android && !userAgent.iPad

    useLoadingBar(isValidating)

    useAutoLoadPreviousPage({
      loadingPlaceholderRef,
      isLoading,
      size,
      setSize,
      messages,
      ref,
      enabled: enableAutoLoad,
    })

    // - [ ] 首页未加载完成前禁止输入
    // - [ ] 切换到加载过的对话后（conversationId & messages 不一致）时，返回 false
    useEffect(() => {
      setMessagesLoaded(conversationId === messagesConversationId)
    }, [conversationId, messagesConversationId, setMessagesLoaded])

    // - [ ] 加载完首页消息列表后会请求标记已读，conversation & message id 正确
    // - [ ] 切换对话后可正确标记已读，conversation & message id 正确
    // - [ ] 切换对话并更新首页后可正确标记已读，message id 正确
    // - [ ] 发布消息本地更新消息列表后不会请求标记已读
    const lastMessage = messages?.[messages.length - 1]
    const lastReadMessageId = lastMessage?.id
    useEffect(() => {
      if (!lastReadMessageId || lastReadMessageId.includes('local')) {
        return
      }

      http(`/conversations/${messagesConversationId}`, {
        method: 'PUT',
        body: {lastReadMessageId},
      }).catch((e) => console.error(e))

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastReadMessageId])

    return (
      <div className={styles.content} ref={ref}>
        {hasNext && !error && (
          <Loading
            messages={messages}
            isLoading={isLoading}
            ref={loadingPlaceholderRef}
            enableAutoLoad={enableAutoLoad}
            handleLoadPrevious={() => setSize(size + 1)}
          />
        )}
        {error && (
          <Error
            className={styles.error}
            error={error}
            handleRetry={() => mutate()}
          />
        )}
        {/* - [ ] 加载最后一页后不会跳动 */}
        {!hasNext && !isEmpty && (
          <div className={styles.loading}>没有更多了~</div>
        )}
        {messages?.map((message) => (
          <MessageItem
            message={message}
            key={message.id}
            user={conversation.user}
            publication={conversation.publication}
          />
        ))}
        {isEmpty && <Empty />}
      </div>
    )
  }
)

function Empty() {
  return <div>暂无消息</div>
}

// - [ ] 首屏 loading 样式正确
// - [ ] 桌面端自动加载加载前一页时 loading 样式正确
// - [ ] 移动端显示加载按钮，点击后 loading 样式正确
const Loading = React.forwardRef(
  ({enableAutoLoad, handleLoadPrevious, messages, isLoading}, ref) => {
    if (!messages) {
      return <Spinner size={30} />
    }

    if (enableAutoLoad) {
      return (
        <div ref={ref} className={styles.loading}>
          <Spinner size={26} />
        </div>
      )
    }

    return (
      <div ref={ref} className={styles.loading}>
        <TextButton onClick={handleLoadPrevious} disabled={isLoading}>
          {isLoading ? <Spinner size={26} /> : '点击加载更多'}
        </TextButton>
      </div>
    )
  }
)

function useAutoLoadPreviousPage({
  ref,
  loadingPlaceholderRef,
  isLoading,
  size,
  setSize,
  messages,
  enabled,
}) {
  const isLoadingRef = useRef(isLoading)
  isLoadingRef.current = isLoading

  const sizeRef = useRef(size)
  sizeRef.current = size

  const messsagesRef = useRef()
  messsagesRef.current = messages

  useEffect(() => {
    if (!enabled) {
      return
    }

    if (!loadingPlaceholderRef.current) {
      return
    }

    // - [ ] 成功加载首页后再初始化 observer
    if (!messages) {
      return
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          !isLoadingRef.current
          // HACK: iOS scroll bounce 后会触发两次 intersecting 事件
          // - [ ] iPad scroll bounce 后不会多加载一页从而产生跳动
          // ref.current.scrollTop > -10
        ) {
          setSize(sizeRef.current + 1)
        }
      },
      {
        root: ref.current,
      }
    )
    observer.observe(loadingPlaceholderRef.current)

    return () => observer.disconnect()

    // - [ ] 切换对话后会初始化 observer，可正常加载前一页内容
    // - [ ] 新页面加载完后会初始化 observer，若加载完新页面后不能触发 scroll 则自动加载下一页
  }, [enabled, ref, loadingPlaceholderRef, setSize, messages])
}

/**
 * 多加一个 messages state，设置前后记录并恢复滚动位置
 *
 * - [ ] 加载前一页后消息顺序正确
 * - [ ] 加载前一页后能 scroll 位置正确，切换对话后会 scroll 到底部
 * - [ ] 切换对话后列表内容正确，可正常渲染之前加载的消息，也可正常加载前一页
 * - [ ] 底部添加消息后（有新消息）自动滚动到底部
 */
function useMessages({conversationId, ref}) {
  const {data, size, error, ...rest} = useMessagesInfinite(conversationId)

  const [[messages, messagesConversationId], setMessages] = useState([])
  const scrollBottomRef = useRef()

  const lastMessage = messages?.[messages.length - 1]
  const lastReadMessageId = lastMessage?.id
  useEffect(() => {
    const contentElement = ref.current
    if (!contentElement) {
      return
    }

    contentElement.scrollTop = contentElement.scrollHeight
  }, [lastReadMessageId, ref])

  useEffect(() => {
    const contentElement = ref.current
    if (!contentElement) {
      return
    }

    contentElement.scrollTop = contentElement.scrollHeight
  }, [conversationId, ref])

  useEffect(() => {
    const contentElement = ref.current
    if (!contentElement) {
      return
    }

    // - [ ] 切换到未加载的对话后消息列表内容正确
    if (!data) {
      scrollBottomRef.current = 0
      setMessages([undefined, undefined])
      return
    }

    scrollBottomRef.current =
      contentElement.scrollHeight -
      Math.max(contentElement.scrollTop, 0) -
      contentElement.clientHeight

    setMessages([
      [...data].reverse().reduce((items, body) => [...items, ...body.data], []),
      conversationId,
    ])
  }, [data, ref, conversationId])

  useLayoutEffect(() => {
    const contentElement = ref.current
    if (!contentElement) {
      return
    }

    if (scrollBottomRef.current === undefined) {
      return
    }

    const scrollTop =
      contentElement.scrollHeight -
      contentElement.clientHeight -
      scrollBottomRef.current

    contentElement.scrollTop = scrollTop
  }, [messages, ref])

  const hasNext = !data || data[data.length - 1].pagination.hasPrev

  // - [ ] 请求成功后但 messages 未更新 isLoading 为 true
  // - [ ] 最后一页请求成功后 isLoading 为 false
  // - [ ] 切换对话后（data & messages）不一致时 isLoading 为 true
  let isLoading
  if (!messages || !data) {
    isLoading = true
  } else if (messages[0].id !== data[data.length - 1].data[0].id) {
    isLoading = true
  } else {
    isLoading = messages.length === data[0].data.length * (size - 1)
  }

  return {
    ...rest,
    messages,
    messagesConversationId,
    data,
    size,
    error,
    hasNext,
    isLoading,
    isEmpty: !hasNext && messages?.length === 0,
  }
}

export default MessagesList
