/**
 * 创作者中心根页面：
 * - [ ] 访问 /creator 页面时会先要求登录、再要求填写个人资料、再要求创建 newsletter
 * - [ ] 自动跳转到已发布内容页面
 */

import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom'
import ContactButton from 'components/ContactButton'
import selfPublicationRequired from 'hocs/selfPublicationRequired'
import CreatorContentPage from './CreatorContentPage'
import CreatorSubscribersPage from './CreatorSubscribersPage'
import CreatorRevenuePage from './CreatorRevenuePage'
import CreatorMembershipPage from './CreatorMembershipPage'

function CreatorPage({publication}) {
  const {path} = useRouteMatch()

  return (
    <>
      <Switch>
        <Route path={path} exact>
          <Redirect to={`${path}/posts/published`} />
        </Route>
        <Route path={`${path}/posts`}>
          <CreatorContentPage publication={publication} />
        </Route>
        <Route path={`${path}/subscribers`}>
          <CreatorSubscribersPage publication={publication} />
        </Route>
        <Route path={`${path}/membership`}>
          <CreatorMembershipPage publication={publication} />
        </Route>
        <Route path={`${path}/revenue`}>
          <CreatorRevenuePage publication={publication} />
        </Route>
      </Switch>
      <ContactButton />
    </>
  )
}

export default selfPublicationRequired(CreatorPage)
