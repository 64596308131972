// - [ ] 可正常显示/绑定/修改邮箱
// - [ ] 不同宽度下样式正常（邮箱可缩略）

import cx from 'classnames'
import {useState} from 'react'
import SettingsField, {SettingsTip} from './SettingsField'
import {TextButton} from 'components/Button'
import EmailBindingModal from 'components/EmailBindingModal'
import styles from './SelfEmailField.module.css'

function SelfEmailField({self}) {
  const [showModal, setShowModal] = useState(false)

  return (
    <SettingsField
      name="邮箱"
      renderContent={() => (
        <>
          <div className={styles.content}>
            {self.email ? (
              <>
                <div className={styles.email}>{self.email}</div>
                <TextButton
                  className={cx(styles.button, styles.edit)}
                  onClick={() => setShowModal(true)}
                >
                  修改
                </TextButton>
              </>
            ) : (
              <TextButton
                className={cx(styles.button, styles.bind)}
                onClick={() => setShowModal(true)}
              >
                绑定邮箱
              </TextButton>
            )}
            {showModal && <EmailBindingModal setVisible={setShowModal} />}
          </div>
          <SettingsTip>
            {self.publication
              ? '绑定后即可接收来自邮箱订阅者回复的邮件'
              : '绑定后即可通过邮箱订阅其他专栏'}
          </SettingsTip>
        </>
      )}
    />
  )
}

export default SelfEmailField
