// - [ ] 正常加载个人信息，访问需要首先设置个人信息并创建 publciation
// - [ ] 没有专栏时可以访问设置页面，但不显示专栏相关的设置

import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import ContactButton from 'components/ContactButton'
import PageTitle from 'components/PageTitle'
import selfRequired from 'hocs/selfRequired'
import PublicationNameField from './PublicationNameField'
import PublicationDescriptionField from './PublicationDescriptionField'
import PublicationDomainField from './PublicationDomainField'
import SelfNameField from './SelfNameField'
import SelfDescriptionField from './SelfDescriptionField'
import SelfAvatarField from './SelfAvatarField'
import styles from './SettingsPage.module.css'
import SelfEmailField from './SelfEmailField'
import SubscriptionMethodsField from './SubscriptionMethodsField'
import SelfMessagesField from './SelfMessagesField'

function SettingsPage({self}) {
  return (
    <Layout.Page>
      <PageTitle title="我的设置" />
      <GlobalTopNav />
      <Layout.PageContent>
        <Layout.View className={styles.page}>
          <h1 className={styles.title}>设置</h1>
          {self.publication && (
            <section className={styles.section}>
              <h2 className={styles.subtitle}>专栏信息</h2>
              <PublicationNameField self={self} />
              <PublicationDescriptionField self={self} />
              <PublicationDomainField self={self} />
              <SubscriptionMethodsField self={self} />
            </section>
          )}
          <section className={styles.section}>
            <h2 className={styles.subtitle}>个人信息</h2>
            <SelfAvatarField self={self} />
            <SelfNameField self={self} />
            <SelfDescriptionField self={self} />
            <SelfEmailField self={self} />
            <SelfMessagesField self={self} />
          </section>
        </Layout.View>
        <ContactButton />
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default selfRequired(SettingsPage)
