import {useLayoutEffect} from 'react'
import {useLocation} from 'react-router-dom'

const locationHistory = []

export function useLoactionHistory() {
  const location = useLocation()

  // 避免在在 useEffect 中使用 locationHistory 没有更新
  useLayoutEffect(() => {
    locationHistory.push(location.pathname)
  }, [location.pathname])
}

export function getLocationHistory() {
  return locationHistory
}
