/**
 * 支持粘贴富文本
 *
 * - [ ] 编辑器内能够正常复制粘贴各种富文本（Safari & Chrome)
 * - [ ] 编辑器内/外单独选中图片时，能够正常复制粘贴（Safari & Chrome)
 * - [ ] 从编辑器内/外粘贴时，首段的块级样式能够正确保留（待支持）
 * - [ ] 可直接复制站内的图片
 * - [ ] 拖拽图片文件、粘贴外站图片、粘贴外站包含图片的 HTML 时，自动上传并渲染 png、jpg、gif 图片。
 *
 * @see https://www.slatejs.org/examples/paste-html
 */

import pickBy from 'lodash.pickby'
import {Element, Text, Transforms} from 'slate'
import {jsx} from 'slate-hyperscript'
import {insertImage} from './ImagePlugin'

function withPastingHTML(editor) {
  const {insertData} = editor

  // 见：https://github.com/ianstormtaylor/slate/blob/c217dbb5b9190753298bbc117a49af940a3a0d53/packages/slate-react/src/components/editable.tsx#L380
  editor.insertData = (data) => {
    const {files} = data
    const html = data.getData('text/html')

    if (files && files.length > 0) {
      for (const file of files) {
        const [mime] = file.type.split('/')
        const reader = new FileReader()
        if (mime === 'image') {
          reader.addEventListener('load', () => {
            const downloadURL = reader.result
            insertImage({editor, downloadURL})
          })
          reader.readAsDataURL(file)
        }
      }
      return
    } else if (html) {
      try {
        const parsed = new DOMParser().parseFromString(html, 'text/html')
        // 保证能够完整粘贴块级元素
        // 见：https://github.com/ianstormtaylor/slate/blob/95f402c59414331b2eeca9a19bd2c73c0ab6cd6c/packages/slate/src/transforms/text.ts#L400
        const fragment = [{text: ''}, ...deserialize(parsed.body, editor)].map(
          (node, index) => {
            // - [ ] 粘贴富文本时没有父级块级元素的文本节点或链接节点可正常粘贴，位置正确
            // - [ ] 粘贴富文本时非法父级块级元素（例如 <foo></foo>）的文本节点位置正确
            if ((Text.isText(node) || editor.isInline(node)) && index !== 0) {
              return {type: 'paragraph', children: [node]}
            } else {
              return node
            }
          }
        )

        if (process.env.REACT_APP_DEV === 'true') {
          console.log(html)
          console.log(parsed)
          console.log(fragment)
        }

        // - [ ] 如果粘贴的内容中包含了付费墙分隔线，则先移除文档中现有的分割线
        const hasPaywallDivider =
          fragment.filter((node) => node.type === 'paywall-divider').length > 0
        if (hasPaywallDivider) {
          const dividers = editor.children
            .map((node, index) => [node, index])
            .filter(([node]) => node.type === 'paywall-divider')
            .map(([, index]) => [index])

          if (dividers.length > 0) {
            dividers.reverse().forEach((path) => {
              Transforms.removeNodes(editor, {at: [path]})
            })
          }
        }

        Transforms.insertFragment(editor, fragment)
      } catch (e) {
        console.error(e)
      }
      return
    }

    insertData(data)
  }

  return editor
}

function deserialize(el, editor) {
  const {tagName} = el

  // 见：https://developer.mozilla.org/en-US/docs/Web/API/Node/nodeType
  if (el.nodeType === 3) {
    // 避免 HTML 未压缩从而生成的大量仅有 \n 的 text node，例如 Notes。
    // TODO: 这样实现应该会有 bad case，例如误删掉 block-code 中的 \n。
    if (/^\n+$/.test(el.textContent)) {
      return null
    }

    return el.textContent
  } else if (el.nodeType !== 1) {
    return null
  } else if (tagName === 'BR') {
    return '\n'
  } else if (tagName === 'NOSCRIPT') {
    // 复制知乎限制转载的文章不会有重复图片:
    // https://www.zhihu.com/question/499271472/answer/2227974178
    return null
  }

  // 一些兼容逻辑，待观察看要不要加
  // let parent = el
  // if (
  //   tagName === 'PRE' &&
  //   el.childNodes[0] &&
  //   el.childNodes[0].tagName === 'CODE'
  // ) {
  //   parent = el.childNodes[0]
  // }
  // const children = Array.from(parent.childNodes).map(deserialize).flat()

  const children = Array.from(el.childNodes).map((node) =>
    deserialize(node, editor)
  )

  // 如果为空或全为 null，则插入一个空 text node 从而保证选区，例如：
  // - [ ] 复制一个没有文本的不支持的块级样式
  // - [ ] 从编辑器外单独选中并复制一个图片
  // - [ ] 选中付费墙分割线且分隔线为最后一个元素，处理前有一个 paragraph 元素 children 为 `[null]`，可正常复制
  if (
    children.length === 0 ||
    children.filter((child) => Boolean(child)).length === 0
  ) {
    children.push({text: ''})
  }

  if (tagName === 'BODY') {
    return jsx('fragment', {}, children)
  }

  const elementProps = getElementProps(tagName, el)
  if (elementProps) {
    // - [ ] 可以正常粘贴图片、付费墙分隔线，且 children 正确（包含一个空的 text node）
    // - [ ] 拖拽选中付费墙分隔线且分隔线为最后一个元素时，可正常粘贴
    // - [ ] 可正常从从编辑器内或作品详情页粘贴图片且结构正确
    const {isVoid, ...props} = elementProps
    return jsx('element', {...props}, isVoid ? [{text: ''}] : children)
  }

  const textProps = getTextProps(tagName, el)

  // - [x] 可复制嵌套结构的行内样式，例如 substack 加粗 > 斜体 > 代码，移除对应代码后能够正常复制（会吞掉嵌套部分）
  // - [x] 可复制被行内样式嵌套链接，且保留加粗的样式，移除对应代码后能够正常复制（会吞掉链接）
  function deserializeTextNode(textProps, children) {
    return children.flat().map((child) => {
      try {
        if (Element.isElement(child) && child.type === 'link') {
          return {
            ...child,
            children: deserializeTextNode(textProps, child.children),
          }
        } else {
          return jsx('text', textProps, child)
        }
      } catch (e) {
        console.error(e)
        return {text: ''}
      }
    })
  }

  if (textProps) {
    return deserializeTextNode(textProps, children)
  }

  // 对于不支持的 html tag 忽略并返回 textContent
  return children
}

function getElementProps(tagName, element) {
  let elementProps
  switch (tagName) {
    case 'BLOCKQUOTE':
      elementProps = {type: 'block-quote'}
      break
    case 'H1':
      elementProps = {type: 'heading-one'}
      break
    case 'H2':
    case 'H3':
    case 'H4':
    case 'H5':
    case 'H6':
      elementProps = {type: 'heading-two'}
      break
    case 'LI':
      elementProps = {type: 'list-item'}
      break
    case 'OL':
      elementProps = {type: 'numbered-list'}
      break
    case 'UL':
      elementProps = {type: 'bulleted-list'}
      break
    case 'PRE':
      elementProps = {type: 'block-code'}
      break
    case 'DIV':
      // 仅解析最顶级的 DIV，避免多余空行
      if (element.parentNode.tagName === 'BODY') {
        elementProps = {type: 'paragraph'}
      }

      // - [ ] Safari 可以正常粘贴付费墙分隔线，且 children 正确（为空数组）
      if (element.dataset?.slateElementType === 'paywall-divider') {
        elementProps = {type: 'paywall-divider', isVoid: true}
      }

      break
    case 'SECTION':
    case 'P':
    case 'TR': // 简单支持一下表格
      elementProps = {type: 'paragraph'}
      break
    case 'A':
      elementProps = {type: 'link', url: element.getAttribute('href')}
      break
    case 'FIGURE':
      if (
        element.getAttribute('data-url') &&
        element.getAttribute('data-url').startsWith('https://imgs.zhubai')
      ) {
        elementProps = {
          type: 'image',
          url: element.getAttribute('data-url'),
          alt: element.getAttribute('data-alt'),
          isVoid: true,
        }
      } else {
        elementProps = {type: 'paragraph'}
      }

      break
    case 'IMG':
      if (element.src && element.src.startsWith('https://imgs.zhubai')) {
        elementProps = {
          type: 'image',
          url: element.src,
          alt: element.alt,
          isVoid: true,
        }
      }

      if (element.src && !element.src.startsWith('https://imgs.zhubai')) {
        elementProps = {
          type: 'image',
          downloadURL: element.src,
          alt: element.alt,
          isVoid: true,
        }
      }
      break
    case 'HR':
      elementProps = {type: 'divider'}
      break
    default:
      break
  }
  return elementProps
}

function getTextProps(tagName, element) {
  let textProps

  switch (tagName) {
    case 'STRONG':
    case 'B':
    case 'MARK':
      textProps = {bold: true}
      break
    case 'CODE':
      textProps = {code: true}
      break
    case 'I':
    case 'EM':
      textProps = {italic: true}
      break
    case 'U':
      textProps = {underline: true}
      break
    case 'S':
    case 'DEL':
      textProps = {strikethrough: true}
      break
    case 'SPAN':
      const {fontWeight, fontFamily, fontStyle, textDecoration} = element.style
      const props = pickBy(
        {
          bold:
            fontWeight === '500' ||
            fontWeight === '600' ||
            fontWeight === '700' ||
            fontWeight === 'bold',
          code: fontFamily.includes('monospace'),
          italic: fontStyle === 'italic',
          underline: textDecoration === 'underline',
          strikethrough: textDecoration === 'line-through',
        },
        (value) => value
      )

      // 过滤空 span
      // - [x] 可复制 span > p 的结构（例如 https://rabbit.zoepi.online/ 中的参考（脚注）部分）
      textProps = Object.keys(props).length > 0 ? props : undefined
      break
    default:
      break
  }
  return textProps
}

/**
 * 判断是否是编辑器内部复制粘贴：
 *
 * - [ ] 编辑器内部分复制粘贴判断为 true
 * - [ ] Read-only 编辑器（目前的内容页）复制粘贴判断为 false
 * - [ ] 从站外复制粘贴判断为 false
 */
// function isCopyFromInternal(html) {
//   return Boolean(getSlateFragmentAttribute(html))
// }

// Copy from https://github.com/ianstormtaylor/slate/blob/d06706c9e15bbbdd7cdd9a1bbb38c87d37c85ea1/packages/slate-react/src/utils/dom.ts#L240
// const catchSlateFragment = /data-slate-fragment="(.+?)"/m
// const catchSlateNode = /data-slate-node="(.+?)"/m
// function getSlateFragmentAttribute(html) {
//   const [, fragment] =
//     html.match(catchSlateFragment) || html.match(catchSlateNode) || []
//   return fragment
// }

export default withPastingHTML
