import cx from 'classnames'
import styles from './Subscribe.module.css'

export function SubscribeView({children, className}) {
  return <div className={cx(styles.view, className)}>{children}</div>
}

export function SubscribeViewTitle({children}) {
  return <h1 className={styles.title}>{children}</h1>
}

export function SubscribeViewDescription({children}) {
  return <h2 className={styles.description}>{children}</h2>
}
