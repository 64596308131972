/**
 * 全局通知：
 *
 * - [ ] 出现位置正确（导航栏之下）
 * - [ ] 出现 2s 后会自动消失
 * - [ ] 消失前显示新通知时原地改变文案，并重新等待 2 秒后消失
 * - [ ] warning 为 true 时样式正确
 * - [ ] 文字过长会缩略
 * - [ ] 通知能出现在所有组件（例如弹窗）之上
 * - [ ] 通知出现时可点击选中，消失（包括过程中）无法点击选中
 */

import cx from 'classnames'
import {useEffect, useState} from 'react'
import {atom, useRecoilState, useSetRecoilState} from 'recoil'
import {useSpring, animated, config} from 'react-spring'
import styles from './Notification.module.css'

export const notificationState = atom({
  key: 'notificationState',
  default: {
    visible: false,
    text: '',
    warning: false,
  },
})

function Notification() {
  const [visible, setVisible] = useState(false)
  const [noti] = useRecoilState(notificationState)

  const props = useSpring({
    transform: `translateX(-50%) translateY(${visible ? 0 : -20}px)`,
    opacity: visible ? 1 : 0,
    config: config.stiff,
    // Tip: Safari 中 onRest 时机有问题，经常导致通知无法正常显示
    // https://github.com/great-products/davinci-webapp/pull/374
    // visibility: noti.text.length > 0 ? 'visible' : 'hidden',
    // onRest: () => {
    //   if (!visible) {
    //     setNoti({text: '', warning: false})
    //   }
    // },
  })

  useEffect(() => {
    if (noti.text.length > 0) {
      setVisible(true)
      const timeout = window.setTimeout(() => {
        setVisible(false)
      }, 2000)
      return () => window.clearTimeout(timeout)
    }

    setVisible(false)
  }, [noti, setVisible])

  return (
    <animated.div
      className={cx(styles.notification, {
        [styles.isWarning]: noti.warning,
        [styles.visible]: visible,
      })}
      style={props}
    >
      {noti.text}
    </animated.div>
  )
}

export function useNotification() {
  const setNoti = useSetRecoilState(notificationState)
  return (text, {warning} = {warning: false}) => setNoti({text, warning})
}

export default Notification
