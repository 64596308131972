/**
 * - [ ] 访问创作者中心时，若未绑定手机号则绑定后再填个人资料、创建专栏
 */

import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'
import {useSelf} from 'swr/self'
import authRequired from 'hocs/authRequired'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import ContactButton from 'components/ContactButton'
import PhoneBindingView from 'components/PhoneBindingView'
import Error from 'components/Error'

function AuthProfilePage() {
  const {self, error, loading, mutate} = useSelf()

  const location = useLocation()
  const history = useHistory()
  function handleSuccess() {
    const query = queryString.parse(location.search)
    const {next} = query

    history.push(next || '/creator')
  }

  return (
    <Layout.Page>
      <GlobalTopNav loading={loading} />
      <Layout.PageContent>
        {self && (
          <PhoneBindingView
            title="验证手机号"
            subtitle="需要先验证并绑定手机号"
            onSuccess={handleSuccess}
          />
        )}
        <Error error={error} handleRetry={() => mutate()} />
      </Layout.PageContent>
      <ContactButton />
    </Layout.Page>
  )
}

export default authRequired(AuthProfilePage)
