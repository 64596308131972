// - [ ] 点击邮箱中的链接自动请求绑定，加载/错误/成功状态正确

import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import http from 'lib/http'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Error from 'components/Error'

function EmailBindingPage() {
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const query = queryString.parse(location.search) || {}

    setLoading(true)
    setError(null)
    http('/self/email', {
      method: 'PUT',
      body: {...query},
    }).then(
      () => setLoading(false),
      (error) => {
        setLoading(false)
        setError(error)
      }
    )
  }, [location])

  return (
    <Layout.Page>
      <Layout.PageContent>
        {loading && <Spinner />}
        {error && (
          <Error error={error} handleRetry={() => window.location.reload()} />
        )}
        {!loading && !error && (
          <div style={{margin: 'auto', fontSize: '22px'}}>绑定成功 🎉</div>
        )}
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default EmailBindingPage
