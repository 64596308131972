import cx from 'classnames'
import {LaunchWechatAppMask} from 'lib/wechat'
import {PrimaryButton} from 'components/Button'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import {useSubscribeModal} from './SubscribeModal'
import styles from './SubscribeButton.module.css'

// - [ ] 已登录状态下免费专栏未订阅 & 已订阅状态正确
// - [ ] 付费专栏未订阅显示「订阅更新」
// - [ ] 付费专栏免费订阅显示「未付费订阅」
// - [ ] 付费专栏付费订阅但没开启微信 & 邮箱订阅显示「未开启订阅」
// - [ ] 付费专栏付费订阅且没开启微信/邮箱订阅显示「已订阅」
// - [ ] 订阅/取消订阅时上述状态能正确切换·
// - [ ] 微信内访问时点击直接跳转小程序对应专栏页，若已订阅或付费专栏则不跳转
function SubscribeButton({publication, className, isPreview}) {
  const hasSubscribed = publication.subscription.hasSubscribed

  let text = hasSubscribed ? '已订阅' : '订阅更新'
  const hasMembership = Boolean(publication.membership)

  const isFreeSubscribed =
    hasMembership && hasSubscribed && publication.subscription.type !== 'paid'

  const noApproaches =
    hasMembership &&
    hasSubscribed &&
    !publication.subscription.approach.wechat &&
    !publication.subscription.approach.email

  if (isFreeSubscribed) {
    text = '未付费订阅'
  } else if (noApproaches) {
    text = '未开启订阅'
  }

  const showSubscribeModal = useSubscribeModal()
  function handleClick() {
    if (isPreview) {
      window.alert('预览时暂无法订阅')
      return
    }

    showSubscribeModal()
  }

  return (
    <PrimaryButton
      className={cx(styles.button, className, {
        [styles.hasSubscribed]: hasSubscribed,
      })}
      onClick={handleClick}
    >
      <span className={styles.content}>
        {text}
        {hasSubscribed && !isFreeSubscribed && !noApproaches && (
          <ArrowIcon className={styles.icon} />
        )}
      </span>
      {!hasMembership && !hasSubscribed && (
        <LaunchWechatAppMask
          width="100px"
          height="46px"
          path={`/pages/publication/publication?token=${publication.token}`}
        />
      )}
    </PrimaryButton>
  )
}

export default SubscribeButton
