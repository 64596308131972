import {Tooltip as AntDTooltip} from 'antd'
import 'antd/es/tooltip/style/index.css'

function Tooltip({tip, children, overlayInnerStyle, ...props}) {
  return (
    <AntDTooltip
      {...props}
      title={tip}
      overlayInnerStyle={{
        padding: '10px 15px',
        minHeight: 'auto',
        borderRadius: '10px',
        fontSize: '15px',
        ...overlayInnerStyle,
      }}
    >
      {children}
    </AntDTooltip>
  )
}

export default Tooltip
