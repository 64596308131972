/**
 * - [ ] Toolbar 能够正常 sticky 到顶部
 * - [ ] Toolbar 按钮能够正常 toogle 行内/块级样式，且 active 样式正常
 * - [ ] 编辑器工具栏各按钮添 tooltip 文案正确且出现在按钮下方
 */

import {useEffect, useRef, useState} from 'react'
import cx from 'classnames'
import {Editor as SlateEditor, Transforms, Element as SlateElement} from 'slate'
import {useSlate} from 'slate-react'
import isUrl from 'is-url'
import {IconButton} from 'components/Button'
import {FormInput, FormSubmit} from 'components/Form'
import {ModalDialog} from 'components/Modal'
import Tooltip from 'components/Tooltip'
import {ReactComponent as BoldIcon} from 'icons/editor-bold.svg'
import {ReactComponent as ItalicIcon} from 'icons/editor-italic.svg'
import {ReactComponent as HeadingOneIcon} from 'icons/editor-heading-one.svg'
import {ReactComponent as HeadingTwoIcon} from 'icons/editor-heading-two.svg'
import {ReactComponent as BlockquoteIcon} from 'icons/editor-blockquote.svg'
import {ReactComponent as BlockcodeIcon} from 'icons/editor-blockcode.svg'
import {ReactComponent as OrderedListIcon} from 'icons/editor-ordered-list.svg'
import {ReactComponent as UnorderedListIcon} from 'icons/editor-unordered-list.svg'
import {ReactComponent as DividerIcon} from 'icons/editor-divider.svg'
import {ReactComponent as LinkIcon} from 'icons/editor-link.svg'
import {ReactComponent as ImageIcon} from 'icons/editor-image.svg'
import {insertLink, isLinkActive, unwrapLink} from './LinkPlugin'
import {insertImage} from './ImagePlugin'
import {insertDivider} from './DividerPlugin'
import {isMarkActive, toggleMark} from './EditorUtil'
import {PaywallToolbarButton} from './PaywallPlugin'
import styles from './EditorToolbar.module.css'

const LIST_TYPES = ['numbered-list', 'bulleted-list']

function Toolbar({className, enablePaywall}) {
  return (
    <div className={cx(styles.toolbar, className)}>
      <MarkButton format="bold" tip="加粗">
        <BoldIcon />
      </MarkButton>
      <MarkButton format="italic" tip="斜体">
        <ItalicIcon />
      </MarkButton>
      <BlockButton format="heading-one" tip="一级标题">
        <HeadingOneIcon />
      </BlockButton>
      <BlockButton format="heading-two" tip="二级标题">
        <HeadingTwoIcon />
      </BlockButton>
      <BlockButton format="bulleted-list" tip="无序列表">
        <UnorderedListIcon />
      </BlockButton>
      <BlockButton format="numbered-list" tip="有序列表">
        <OrderedListIcon />
      </BlockButton>
      <BlockButton format="block-quote" tip="引用">
        <BlockquoteIcon />
      </BlockButton>
      <BlockButton format="block-code" tip="代码块">
        <BlockcodeIcon />
      </BlockButton>
      <DividerButton />
      <LinkButton />
      <ImageButton />
      {enablePaywall && <PaywallToolbarButton />}
    </div>
  )
}

function MarkButton({format, children, tip}) {
  const editor = useSlate()
  return (
    <Tooltip tip={tip} placement="bottom">
      <IconButton
        className={cx(styles.button, {
          [styles.isActive]: isMarkActive(editor, format),
        })}
        // 点击按钮后不会转移焦点
        onMouseDown={(e) => {
          e.preventDefault()
          toggleMark(editor, format)
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}

function BlockButton({format, children, tip}) {
  const editor = useSlate()
  return (
    <Tooltip tip={tip} placement="bottom">
      <IconButton
        className={cx(styles.button, {
          [styles.isActive]: isBlockActive(editor, format),
        })}
        disabled={isBlockDisabled(editor, format)}
        // 点击按钮后不会转移焦点
        onMouseDown={(e) => {
          e.preventDefault()
          toggleBlock(editor, format)
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}

// - [ ] 列表中 disabled 插入按钮
function DividerButton() {
  const editor = useSlate()

  const [match] = SlateEditor.nodes(editor, {
    match: (node) => node.type === 'list-item',
  })

  const disabled = Boolean(match)

  function handleMouseDown(event) {
    event.preventDefault()
    insertDivider(editor)
  }

  return (
    <Tooltip tip="分割线" placement="bottom">
      <IconButton
        className={cx(styles.button)}
        // 点击按钮后不会转移焦点
        onMouseDown={handleMouseDown}
        disabled={disabled}
      >
        <DividerIcon />
      </IconButton>
    </Tooltip>
  )
}

/**
 * - [ ] 选中并上传多张图片时，上传成功后均能正确替换为上传后的 URL
 * - [ ] 能够重复上传同一张图片
 * - [ ] 选中列表中时上传图片按钮 disabled
 */
function ImageButton() {
  const inputRef = useRef()
  const editor = useSlate()

  const [match] = SlateEditor.nodes(editor, {
    match: (node) => node.type === 'list-item',
  })

  const disabled = Boolean(match)

  function handleMouseDown(event) {
    event.preventDefault()
    inputRef.current.click()
  }

  function handleChange(event) {
    const images = event.target.files

    for (let i = 0; i < images.length; i++) {
      insertImage({editor, file: images[i]})
    }

    // 可以重复选择并上传同一个文件
    event.target.value = ''
  }

  return (
    <>
      <Tooltip tip="图片" placement="bottom">
        <IconButton
          className={cx(styles.button)}
          // 点击按钮后不会转移焦点
          onMouseDown={handleMouseDown}
          disabled={disabled}
        >
          <ImageIcon />
          <input
            ref={inputRef}
            type="file"
            name="image"
            accept="image/png, image/jpeg, image/gif"
            style={{display: 'none'}}
            onChange={handleChange}
            multiple
          />
        </IconButton>
      </Tooltip>
    </>
  )
}

/**
 * 链接按钮：
 * - [ ] 弹窗样式正确，能自动聚焦到 URL input
 * - [ ] 未输入 URL 时提示错误，无法添加
 * - [ ] 输入文本时插入的链接文本正确，如果未输入则直接使用 URL
 * - [ ] URL input 失去焦点时，如果输入的 URL 不合法，提示但不阻拦添加
 * - [ ] 能够正确处理选区
 *   - [ ] isActive 样式正确，isActive 点击按钮能正常取消当前链接
 *   - [ ] 未选中文本，通过弹窗插入链接（编辑器已经失去焦点），在光标处插入链接后光标出现在链接末尾
 *   - [ ] 选中文本，通过弹窗插入链接（编辑器已经失去焦点），将选中文本替换为链接后光标出现在链接末尾
 *   - [ ] 选中文本中包含行内样式，粘贴、弹窗均能正常插入链接，且焦点正确
 *   - [ ] 选中文本中跨域多个普通块级样式，粘贴、弹窗均能正常插入链接（链接会被拆分），且焦点正确
 *   - [ ] 选中文本中包含 Void Element（图片、分割线），按钮 disabled
 *   - [ ] 选中文本时无法再编辑 text
 *   - [ ] 未聚焦编辑器直接通过弹窗插入链接，在文档末尾插入链接后光标出现在链接末尾
 */
function LinkButton() {
  const [showDialog, setShowDialog] = useState(false)
  const [selection, setSelection] = useState(false)
  const [text, setText] = useState('')

  const editor = useSlate()
  const isActive = isLinkActive(editor)

  const [match] = SlateEditor.nodes(editor, {
    match: (node) => editor.isVoid(node),
  })

  const disabled = Boolean(match)

  function handleMouseDown(event) {
    if (isActive) {
      event.preventDefault()
      unwrapLink(editor)
    } else {
      if (editor.selection?.focus) {
        setSelection({...editor.selection})
        setText(SlateEditor.string(editor, editor.selection))
      }

      setShowDialog(true)
    }
  }

  return (
    <>
      <Tooltip tip="链接" placement="bottom">
        <IconButton
          className={cx(styles.button, {[styles.isActive]: isActive})}
          // 点击按钮后不会转移焦点
          onMouseDown={handleMouseDown}
          disabled={disabled}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      {showDialog && (
        <LinkDialog
          setVisible={setShowDialog}
          editor={editor}
          selection={selection}
          defaultText={text}
        />
      )}
    </>
  )
}

function LinkDialog({editor, setVisible, selection, defaultText}) {
  const [url, setUrl] = useState('')
  const [text, setText] = useState(defaultText)
  const [error, setError] = useState(null)
  const urlInputRef = useRef()

  useEffect(() => {
    urlInputRef.current?.focus()
  }, [urlInputRef])

  function handleUrlInputChange(event) {
    setUrl(event.target.value)
    setError(null)
  }

  function handleUrlInputBlur(event) {
    const url = event.target.value
    if (url.length > 0 && !isUrl(url)) {
      setError({url: '可能输入了错误的 URL'})
    }
  }

  function handleTextInputChange(event) {
    setError(null)
    setText(event.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (url.length === 0) {
      setError({url: true})
      urlInputRef.current.focus()
      return
    }

    insertLink(editor, {url, text, selection})
    setVisible(false)
  }

  return (
    <ModalDialog setVisible={setVisible} hasCloseButton={true}>
      <form className={styles.dialog} onSubmit={handleSubmit}>
        <h1 className={styles.title}>添加链接</h1>
        <FormInput
          className={styles.input}
          type="text"
          placeholder={error?.url ? '输入链接地址' : '链接地址'}
          value={url}
          error={error?.url}
          name="url"
          autoComplete="off"
          ref={urlInputRef}
          onChange={handleUrlInputChange}
          onBlur={handleUrlInputBlur}
        />
        <FormInput
          className={styles.input}
          type="text"
          placeholder={'链接文本'}
          value={text}
          name="text"
          autoComplete="off"
          onChange={handleTextInputChange}
          disabled={defaultText.length > 0}
        />

        <FormSubmit className={styles.submit}>添加</FormSubmit>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          className={styles.tip}
          target="_blank"
          href="https://www.loom.com/share/a1a40a80c6614620b150d14e50cc9318"
        >
          选中文字粘贴链接即可快速添加 (视频)
        </a>
      </form>
    </ModalDialog>
  )
}

// - [ ] 点击按钮后不会转移焦点
// - [ ] 右键点击时不会触发
export function ToolbarButton({onClick, tip, disabled, children, ...props}) {
  function handleMouseDown(event) {
    if (event.button !== 0) {
      return
    }

    event.preventDefault()
    onClick()
  }

  return (
    <Tooltip tip={tip} placement="bottom">
      <IconButton
        {...props}
        className={cx(styles.button)}
        onMouseDown={handleMouseDown}
        disabled={disabled}
      >
        {children}
      </IconButton>
    </Tooltip>
  )
}

function isBlockActive(editor, format) {
  const [match] = SlateEditor.nodes(editor, {
    match: (n) =>
      !SlateEditor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === format,
  })

  return !!match
}

function isBlockDisabled(editor) {
  const [matchNestedList] = SlateEditor.nodes(editor, {
    match: (node, path) =>
      node.type === 'list-item' &&
      (path.length > 2 || LIST_TYPES.includes(node.children[1]?.type)),
  })

  return Boolean(matchNestedList)
}

function toggleBlock(editor, format) {
  const isActive = isBlockActive(editor, format)
  const LIST_TYPES = ['numbered-list', 'bulleted-list']
  const isList = LIST_TYPES.includes(format)

  let newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }

  if (!isActive && isList) {
    newProperties = {...newProperties, temp: true}
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block)
    Transforms.unsetNodes(editor, 'temp')
  }
}

export default Toolbar
