export function formatNumber(number, withSign) {
  const sign = number < 0 ? '-' : '+'

  if (typeof number === 'undefined') {
    return '-'
  } else if (Math.abs(number) < 9999) {
    return `${withSign && number > 0 ? sign : ''}${number}`
  } else {
    return `${withSign && number > 0 ? sign : ''}${Number.parseFloat(
      number / 1000
    ).toFixed(1)}k`
  }
}
