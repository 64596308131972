/**
 * Newsletter 主页：
 * - [ ] publication 加载中、请求错误、重试状态均正常
 * - [ ] 作者链接能正常跳转
 * - [ ] 作者引导分享，读者引导订阅
 * - [ ] 不同宽度下样式正常
 * - [ ] 页面 title 为 publication name
 * - [ ] 文章列表样式正常，整个内容区点击正确跳转详情页，没有内容时空状态正常
 * - [ ] 跳转详情页后页面会自动 scroll 到顶部
 * - [ ] 缩略逻辑正确：提取纯文本（无换行），缩略时显示「阅读全文」按钮，如果恰好折行，左侧没有间隙
 * - [ ] 缩略无换行，软换行会被展示成空格键
 * - [ ] 根据初始屏幕宽度同步缩略字数正确，不会随着屏幕宽度变化
 * - [ ] Posts 有 prefetch（和 publication 同时请求），第一页只会请求一次
 */

import cx from 'classnames'
import React from 'react'
import {Link} from 'react-router-dom'
import {getRelativeTimeString} from 'lib/time'
import {getZhubaiURL} from 'lib/url'
import {useShareInfo} from 'lib/wechat'
import getPublicationToken from 'lib/getPublicationToken'
import {useSWRInfinite} from 'swr'
import {usePublication} from 'swr/publication'
import withWechatMiniAppPage from 'hocs/withWechatMiniAppPage'
import Avatar from 'components/Avatar'
import {LinkButton} from 'components/Button'
import Layout from 'components/Layout'
import Error from 'components/Error'
import LoadingBar from 'components/LoadingBar'
import PageTitle from 'components/PageTitle'
import {serializeToPlainText} from 'components/Editor/serialize'
import InfiniteList from 'components/InfiniteList'
import LoadingPlaceholder from 'components/LoadingPlaceholder'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import SubscribeModal from 'components/Subscribe/SubscribeModal'
import {usePageShow} from 'hooks/usePageShow'
import useThemeColor from 'hooks/useThemeColor'
import PublicationPageHeader from './PublicationPageHeader'
import styles from './PublicationPage.module.css'

const maxCharCount = window.screen.width >= 600 ? 200 : 110

function PostItem({post}) {
  const content = serializeToPlainText(JSON.parse(post.content))
  const {summary, isTruncated} = getSummary(maxCharCount, content)

  return (
    <Link className={styles.item} to={`/posts/${post.id}`}>
      <h2 className={styles.title}>{post.title}</h2>
      <div className={styles.info}>
        <Avatar
          src={post.author.avatar}
          size={28}
          alt="头像"
          className={styles.avatar}
        />
        <div className={styles.author}>{post.author.name}</div>
        <div className={styles.time}>
          <span className={styles.dot}>·</span>
          {getRelativeTimeString(post.createdAt)}
        </div>
        {post.isPaidContent && <div className={styles.paidTag}>付费内容</div>}
      </div>
      <div className={styles.content}>
        {summary} {isTruncated && <span className={styles.more}>阅读全文</span>}
      </div>
    </Link>
  )
}

const Loading = React.forwardRef((props, ref) => (
  <div className={styles.item} ref={ref}>
    <LoadingPlaceholder.Rectangle style={{height: '30px', width: '25%'}} />
    <div className={styles.info}>
      <LoadingPlaceholder.Circle style={{width: '28px', height: '28px'}} />
      <LoadingPlaceholder.Rectangle
        style={{height: '28px', width: '10%'}}
        className={styles.time}
      />
    </div>
    <div className={styles.content}>
      <LoadingPlaceholder.Rectangle style={{height: '27px', width: '50%'}} />
      <LoadingPlaceholder.Rectangle
        style={{marginTop: '10px', height: '27px', width: '25%'}}
      />
    </div>
  </div>
))

function PublicationPage() {
  const token = getPublicationToken()
  const {publication, loading, error, mutate, isValidating} =
    usePublication(token)

  // Prefetch
  const fetchPostsAPI = `/publications/${token}/posts?publication_id_type=token`
  useSWRInfinite(fetchPostsAPI)

  const entity = publication ? {type: 'Publication', id: publication.id} : null
  usePageShow({entity, isSubscriber: publication?.subscription.hasSubscribed})

  useThemeColor(publication?.theme?.primaryColor)

  useShareInfo(
    publication
      ? {
          title: `${publication.name} | ${publication.author.name}`,
          link: window.location.href,
          imgUrl: publication.author.avatar,
          desc: publication.description,
        }
      : null
  )

  if (!publication) {
    return (
      <Layout.Page>
        {(loading || isValidating) && <LoadingBar />}
        <Error error={error} handleRetry={() => mutate()} />
      </Layout.Page>
    )
  }

  return (
    <Layout.Page>
      <ScrollToTopOnMount />
      <PageTitle title={publication.name} />
      <PublicationPageHeader publication={publication} />
      <Layout.View slim className={styles.list}>
        <InfiniteList
          api={fetchPostsAPI}
          renderItem={({item}) => <PostItem post={item} key={item.id} />}
          renderLoading={(ref) => <Loading ref={ref} />}
          renderEmpty={() => (
            <Empty isAuthor={publication.relationship.isAuthor} />
          )}
          renderError={(error, handleRetry) => (
            <Error
              className={cx(styles.item, styles.error)}
              error={error}
              handleRetry={handleRetry}
            />
          )}
        />
      </Layout.View>
      <SubscribeModal publication={publication} />
    </Layout.Page>
  )
}

function Empty({isAuthor}) {
  return (
    <div className={styles.empty}>
      <div>暂时还没有作品</div>
      {isAuthor && (
        <LinkButton
          className={styles.emptyButton}
          href={getZhubaiURL('/creator')}
          target="_blank"
        >
          去创作
        </LinkButton>
      )}
    </div>
  )
}

function getSummary(maxCharCount, string) {
  let baseSummary = string.substring(0, maxCharCount * 2).split('')
  let count = 0
  let summary = ''

  for (let i = 0; i < baseSummary.length; i++) {
    const char = baseSummary[i]

    if (/[a-z0-9\s]/.test(char)) {
      count += 0.5
    } else {
      count += 1
    }

    if (count <= maxCharCount) {
      summary += char
    } else {
      break
    }
  }

  const isTruncated = count > maxCharCount
  return {summary: `${summary}${isTruncated ? '...' : ''}`, isTruncated}
}

// - [ ] 小程序内点击专栏页链接可正常跳转到小程序
export default withWechatMiniAppPage(PublicationPage, () => {
  const token = getPublicationToken()
  return `/pages/publication/publication?token=${token}`
})
