/**
 * 获取当前字符数：
 * - [ ] 空格、换行、软换行、空列表均返回 0
 */

import {Node} from 'slate'

function getCharactersCount(nodes) {
  return nodes
    .map((n) => Node.string(n))
    .join('')
    .replace(/\s+/, '').length
}

export default getCharactersCount
