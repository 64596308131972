/**
 * 内容编辑页：
 * - [ ] 未登录访问跳去登陆
 * - [ ] 同时请求 draft & post，请求状态、错误状态、重试逻辑正常
 * - [ ] 内容没有草稿时（返回 404）则使用已发布状态编辑
 * - [ ] 非作者访问提示错误
 * - [ ] 能够正常编辑、预览、发布
 */

import {useParams} from 'react-router-dom'
import Layout from 'components/Layout'
import GlobalSideMenu from 'components/GlobalSideMenu'
import TopNav from 'components/TopNav'
import Error from 'components/Error'
import selfRequired from 'hocs/selfRequired'
import {usePost, usePostDraft} from 'swr/post'
import WritePage from 'pages/WritePage'

function PostEditPage({self}) {
  const {postId} = useParams()

  const {
    draft,
    error: draftError,
    loading: draftLoading,
    isValidating: draftValidating,
    mutate: mutateDraft,
  } = usePostDraft(postId)

  const {
    post,
    error: postError,
    loading: postLoading,
    isValidating: postValidating,
    mutate: postMutate,
  } = usePost(postId)

  // - [ ] 没有草稿/草稿被删除（恢复已发布内容）时可正常编辑，而不是显示错误页面
  if (typeof draft === 'undefined' && draftError?.status !== 404) {
    return (
      <Layout.Page>
        <TopNav loading={draftLoading || draftValidating} />
        <Layout.PageContent>
          <Error error={draftError} handleRetry={() => mutateDraft()} />
        </Layout.PageContent>
        <GlobalSideMenu />
      </Layout.Page>
    )
  }

  if (!post) {
    return (
      <Layout.Page>
        <TopNav loading={postLoading || postValidating} />
        <Layout.PageContent>
          <Error error={postError} handleRetry={() => postMutate()} />
        </Layout.PageContent>
        <GlobalSideMenu />
      </Layout.Page>
    )
  }

  const isAuthor = post.publication.id === self.publication.id

  if (!isAuthor) {
    return (
      <Layout.Page>
        <TopNav />
        <Layout.PageContent>
          <Error error={{message: '仅作者才能编辑该内容'}} />
        </Layout.PageContent>
        <GlobalSideMenu />
      </Layout.Page>
    )
  }

  return <WritePage post={post} draft={draft} mutateDraft={mutateDraft} />
}

export default selfRequired(PostEditPage)
