import cx from 'classnames'
import {useState, useEffect, useCallback, useRef} from 'react'
import {Editor, Node, Transforms, Range} from 'slate'
import {useSelected, useFocused, useSlate} from 'slate-react'
import http from 'lib/http'
import Error from 'components/Error'
import Spinner from 'components/Spinner'
import styles from './RichText.module.css'

export function withImages(editor) {
  const {isVoid} = editor

  editor.isVoid = (element) => {
    return element.type === 'image' ? true : isVoid(element)
  }

  return editor
}

/**
 * 插入图片：
 *
 * - [ ] 默认插入到下一个行
 * - [ ] 如果当前行是一个空段落，则插入后删除前一个空行（在原位置插入）
 */
export function insertImage({editor, file, downloadURL}) {
  let image

  if (file) {
    image = {
      type: 'image',
      objectURL: createObjectURL(file),
      children: [{text: ''}],
    }
  } else if (downloadURL) {
    image = {
      type: 'image',
      downloadURL,
      children: [{text: ''}],
    }
  }

  Transforms.insertNodes(editor, image)

  const previousPath = [editor.selection.focus.path[0] - 1]

  const [match] = Editor.nodes(editor, {
    at: previousPath,
    match: (node) =>
      node.type === 'paragraph' && Node.string(node).length === 0,
  })

  if (Boolean(match)) {
    Transforms.removeNodes(editor, {at: previousPath})
  }
}

// 编辑器内图片组件：
// - [ ] 选择图片后能正常显示图片并上传图片，上传中/失败/重试逻辑正确
// - [ ] 上传中/失败/成功的图片可以正常选中删除（点击、方向键、退格等）
// - [ ] 选中图片时按 `Enter` 在图片之后插入一个空行并选中
// - [ ] 上传中/失败的图片可以复制，复制后一张图片上传成功所有图片均成功且正确替换 url
// - [ ] 拖拽图片文件、粘贴外站图片、粘贴外站包含图片的 HTML 时，自动上传并渲染 png、jpg、gif 图片
// - [ ] 自动下载 & 上传时加载、错误状态正常，可正常重试
export function EditableImage({attributes, children, element}) {
  const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()
  const [error, setError] = useState(null)

  // - [ ] 上传图片成功或粘贴竹白已有图片后 image naturalHeight 和 naturalWidth 正确
  const imageRef = useRef()
  useEffect(() => {
    if (!element.url) {
      return
    }

    new Promise((reslove) => {
      if (imageRef.current.complete) {
        reslove({
          naturalHeight: imageRef.current.naturalHeight,
          naturalWidth: imageRef.current.naturalWidth,
        })

        return
      }

      imageRef.current.onload = () =>
        reslove({
          naturalHeight: imageRef.current.naturalHeight,
          naturalWidth: imageRef.current.naturalWidth,
        })
    }).then(({naturalHeight, naturalWidth}) => {
      editor.children.forEach((node, index) => {
        if (node.type === 'image' && node.url === element.url) {
          Transforms.setNodes(
            editor,
            {naturalHeight, naturalWidth},
            {at: [index]}
          )
        }
      })
    })
  }, [element.url, editor])

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{text: ''}],
        })
        return
      }

      // TODO：部分情况下无法选中删除，例如上传失败后，待确认原因后移除此事件
      // - [ ] 上传失败后可以正常选中删除
      // - [ ] 选中多个元素（包括图片）时退格能够正常删除
      if (event.key === 'Backspace' && Range.isCollapsed(editor.selection)) {
        event.preventDefault()
        Transforms.removeNodes(editor, {at: [editor.selection.focus.path[0]]})
        return
      }
    }

    if (selected && focused) {
      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selected, focused, editor])

  const download = useCallback(() => {
    setError(null)
    fetch(element.downloadURL)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject({message: '无法下载该图片'})
        }

        return response.blob().then((blob) => {
          const type = response.headers.get('content-type')
          const ext = {
            'image/jpeg': 'jpg',
            'image/png': 'png',
            'image/gif': 'gif',
            // - [ ] Safari 从剪贴板粘贴截屏时能正常转换 tiff 格式图片
            'image/tiff': 'png',
          }[type]

          const name = `image.${ext}`

          if (!type) {
            return Promise.reject({message: '无法识别该格式的图片'})
          }

          return new File([blob], name, {type})
        })
      })
      .then(
        (file) => {
          const objectURL = createObjectURL(file)
          editor.children.forEach((node, index) => {
            if (
              node.type === 'image' &&
              node.downloadURL === element.downloadURL
            ) {
              Transforms.setNodes(
                editor,
                {objectURL},
                {
                  at: [index],
                }
              )
            }
          })
        },
        (error) =>
          setError({
            message:
              error.message === 'Failed to fetch'
                ? '图片下载失败'
                : error.message || '图片下载失败',
          })
      )
  }, [element, editor])

  const upload = useCallback(() => {
    setError(null)
    uploadImage(getFile(element.objectURL)).then(
      (url) => {
        editor.children.forEach((node, index) => {
          if (node.type === 'image' && node.objectURL === element.objectURL) {
            Transforms.setNodes(editor, {url}, {at: [index]})
          }
        })
      },
      (error) => {
        setError(error)
      }
    )
  }, [element, editor])

  useEffect(() => {
    if (element.url) {
      return
    }

    if (element.objectURL) {
      upload()
      return
    }

    if (element.downloadURL) {
      download()
      return
    }
  }, [element, upload, download])

  let imageURL
  if (element.url) {
    imageURL = element.url
  } else if (element.objectURL) {
    imageURL = element.objectURL
  } else if (
    element.downloadURL &&
    element.downloadURL.startsWith('data:image')
  ) {
    // - [ ] 拖拽图片文件 & 粘贴外站图片时可直接看到图片，不会有加载过程。
    imageURL = element.downloadURL
  }

  return (
    <figure {...attributes} data-url={element.url} data-alt={element.alt}>
      <div
        contentEditable={false}
        style={{
          position: 'relative',
        }}
      >
        <img
          src={element.url || element.objectURL || element.imageURL}
          alt={element.alt}
          style={{
            boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
            height: imageURL ? 'auto' : '300px',
            width: imageURL ? 'auto' : '720px',
            background: imageURL ? 'none' : '#aaa',
          }}
          ref={imageRef}
        />
        {!element.url && (
          <div
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              background: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              pointerEvents: error ? 'auto' : 'none', // HACK：选中后无法粘贴
            }}
          >
            {error ? (
              <Error
                error={error}
                handleRetry={element.objectURL ? upload : download}
              />
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </div>
      {children}
    </figure>
  )
}

// - [ ] 图片加载成功前正确显示 loading 样式，占位尺寸正确，加载成功后页面不会有抖动
// - [ ] 图片加载成功后不同屏幕宽度下图片尺寸、比例正确
// - [ ] 当图片过小时 loading 样式正确，加载成功后尺寸正确
// - [ ] 图片可正确 lazyload
// - [ ] 没有尺寸信息的旧图片加载中、加载后样式、尺寸正确正确
export function Image({element}) {
  const imgRef = useRef()
  const [src, setSrc] = useState('')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const img = imgRef.current

    function handleLoad() {
      setLoading(false)
    }

    img.addEventListener('load', handleLoad)
    setSrc(element.url)

    return () => img.removeEventListener('load', handleLoad)
  }, [element.url])

  let width
  let height
  const minWidth = 120
  const minHeight = 30
  const hasNaturalSize = element.naturalHeight > 0 && element.naturalWidth > 0
  if (loading && hasNaturalSize) {
    width = Math.max(element.naturalWidth, minWidth)
    height = Math.max(element.naturalHeight, minHeight)
  } else if (loading) {
    width = minWidth
    height = minHeight
  } else if (hasNaturalSize) {
    width = element.naturalWidth
    height = element.naturalHeight
  }

  return (
    <figure>
      <div
        style={{
          position: 'relative',
        }}
      >
        <img
          ref={imgRef}
          src={src}
          alt={element.alt}
          width={width}
          height={height}
          loading="lazy"
        />
        <div
          className={cx(styles.imgLoading, {
            [styles.isVisible]: loading,
            // - [ ] 图片尺寸过小时加载完成后不会有渐隐的动画，从而不会因为尺寸变小而文字溢出
            [styles.withAnimation]: width >= minWidth,
          })}
        >
          图片加载中
        </div>
      </div>
    </figure>
  )
}

function uploadImage(image) {
  return new Promise((reslove, reject) => {
    const formData = new FormData()
    formData.append('file', image)

    http(`/uploading/images`, {
      method: 'POST',
      body: formData,
    }).then(
      ({url}) => reslove(url),
      (error) =>
        reject({
          message: error.body?.message || error.message || '上传失败',
        })
    )
  })
}

// function isImageUrl(url) {
//   if (!url) return false
//   if (!isUrl(url)) return false
//   const ext = new URL(url).pathname.split('.').pop()

//   const imageExtensions = ['png', 'jpg', 'jpeg']
//   return imageExtensions.includes(ext)
// }

const fileMap = {}

function createObjectURL(file) {
  const url = window.URL.createObjectURL(file)
  fileMap[url] = file
  return url
}

function getFile(objectURL) {
  return fileMap[objectURL]
}
