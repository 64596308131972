// - [ ] 默认值正确
// - [ ] 上传图片后显示操作按钮
// - [ ] 点击取消后操作按钮消失，错误状态重置，重置为修改前的状态
// - [ ] 非空校验、服务端校验错误提示正常，错误信息展示不会导致页面抖动，编辑后重置错误状态
// - [ ] 保存时加载状态正常，重置错误状态，操作按钮不可点击
// - [ ] 保存成功后提示成功，其他地方也能正常更新，操作按钮消失
// - [ ] 不同宽度下样式正常

import {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {useSelf} from 'swr/self'
import {notificationState} from 'components/Notification'
import AvatarEditor from 'components/AvatarEditor'
import SettingsField, {
  SettingsSaveButton,
  SettingsCancelButton,
} from './SettingsField'
import styles from './SettingsField.module.css'

function SelfAvatarField({self}) {
  const [editing, setEditing] = useState(false)
  const [avatar, setAvatar] = useState(self.avatar)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const setNoti = useSetRecoilState(notificationState)
  const {mutate} = useSelf()

  function handleCancel() {
    setError(null)
    setEditing(false)
    setAvatar(self.avatar)
  }

  function handleSave() {
    if (avatar.length === 0) {
      setError(true)
      return
    }

    setLoading(true)
    setError(null)
    http('/self', {
      method: 'PUT',
      body: {avatar, name: self.name},
    })
      .then(
        () => {
          setEditing(false)
          setNoti({
            visible: true,
            text: '保存成功',
          })
          mutate({...self, avatar})
        },
        (error) =>
          setError({
            message:
              error.body?.validationErrors?.['avatar'][0] ||
              error.body?.message ||
              error.message ||
              '保存失败',
          })
      )
      .finally(() => setLoading(false))
  }

  return (
    <SettingsField
      name="头像"
      className={styles.avatarField}
      contentClass={styles.avatarContent}
      renderContent={() => (
        <AvatarEditor
          avatar={avatar}
          setAvatar={setAvatar}
          error={error}
          setError={setError}
          onChange={() => setEditing(true)}
          style={{marginLeft: 0}}
        />
      )}
      renderAction={() => (
        <>
          {editing && (
            <SettingsSaveButton onClick={handleSave} loading={loading} />
          )}
          {editing && (
            <SettingsCancelButton onClick={handleCancel} loading={loading} />
          )}
        </>
      )}
    />
  )
}

export default SelfAvatarField
