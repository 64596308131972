import {useCallback, useEffect, useState} from 'react'
import QRCode from 'qrcode.react'
import http from 'lib/http'
import {parseUA} from 'lib/userAgent'
import {getURLWithExtraQuery} from 'lib/url'
import {useOrder} from 'swr/order'
import {usePublication} from 'swr/publication'
import {useSelf} from 'swr/self'
import useInterval from 'hooks/useInterval'
import {useNotification} from 'components/Notification'
import Button, {IconButton, PrimaryButton} from 'components/Button'
import Spinner from 'components/Spinner'
import Error from 'components/Error'
import {ReactComponent as BackIcon} from 'icons/arrow.svg'
import {ReactComponent as CloseIcon} from 'icons/close.svg'
import {SubscribeView} from './SubscribeView'
import styles from './SubscribePaymentView.module.css'

const userAgent = parseUA(window.navigator.userAgent)
const showQRCode = !userAgent.iPhone && !userAgent.Android

// - [ ] 请求创建订单加载/错误状态正确，可正常重试
// - [ ] 成功创建订单后 PC 端能够正确渲染二维码，Mobile Web 正确跳转（微信内需手动点击跳转），URL 及 next 参数正确
// - [ ] PC 端能够正常重新下单并生成二维码，加载/错误状态正确，可正常重试
// - [ ] 轮询请求订单支付状态，若已支付则显示订阅方式弹窗
// - [ ] 可正常关闭弹窗、返回至选择订阅方式弹窗
// - [ ] 关闭弹窗后再次订阅能够正常重新获取订单并支付
// - [ ] 不同屏幕宽度下样式正常
function SubscribePaymentView({
  publication,
  setView,
  plan,
  setModalVisible,
  isRenew,
}) {
  return (
    <SubscribeView className={styles.view}>
      <div className={styles.nav}>
        <IconButton
          className={styles.button}
          onClick={() => setView('select-plans')}
        >
          <BackIcon className={styles.icon} />
        </IconButton>
        <div className={styles.title}>微信支付</div>
        <IconButton
          className={styles.button}
          onClick={() => setModalVisible(false)}
        >
          <CloseIcon className={styles.icon} />
        </IconButton>
      </div>
      <div className={styles.content}>
        <Content
          setView={setView}
          plan={plan}
          isRenew={isRenew}
          setModalVisible={setModalVisible}
          publication={publication}
        />
      </div>
    </SubscribeView>
  )
}

function Content({setView, plan, isRenew, setModalVisible, publication}) {
  const {self} = useSelf()
  const {mutate: mutatePublication} = usePublication(publication.token)

  const [orderId, setOrderId] = useState()
  const {order, mutate} = useOrder(orderId)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const createOrder = useCallback(() => {
    setLoading(true)
    setError(null)

    if (!isRenew && self && !self.account.cellphone) {
      setView('phone-binding')
      return
    }

    http('/orders', {
      method: 'POST',
      body: {objectId: plan.id, objectType: 'plan'},
    })
      .then(
        (order) => {
          setError(null)
          setOrderId(order.id)
          mutate(order, false)
        },
        (error) =>
          setError({
            message: error?.body?.message || error?.message || '创建订单失败',
          })
      )
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan])

  let platform = 'web'
  if (userAgent.Wechat) {
    platform = 'wechat'
  } else if (userAgent.iPhone || userAgent.Android) {
    platform = 'mobile web'
  }

  const redirectURL = orderId
    ? `https://zhubai.love/pay/orders/${orderId}?next=${encodeURIComponent(
        getURLWithExtraQuery({
          url: window.location.href,
          extraQuery: {subscribe: '1'},
        })
      )}&platform=${encodeURIComponent(platform)}`
    : null

  useEffect(() => {
    if (redirectURL && !showQRCode && !userAgent.Wechat) {
      window.location.href = redirectURL
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectURL])

  const noti = useNotification()
  useEffect(() => {
    if (order?.status !== 'purchased') {
      return
    }

    // - [ ] 已绑定邮箱、微信时，付费后默认开启订阅
    // - [ ] 若绑定微信但没有关注公众号，付费后不会自动开启微信订阅
    // - [ ] 自动开启订阅时订阅状态正确，不会有闪动
    // - [ ] 若已开启一种订阅方式（例如邮箱订阅），付费后即便已绑定也不会自动开启另外一种订阅方式（例如微信订阅）
    if (
      self &&
      !publication.subscription.approach.wechat &&
      !publication.subscription.approach.email
    ) {
      mutatePublication({
        ...publication,
        subscription: {
          ...publication.subscription,
          hasSubscribed: true,
          type: 'paid',
          approach: {
            email: Boolean(self.account.email),
            wechat: Boolean(
              self.account.hasBoundWechat && self.hasSubscribedMp
            ),
          },
        },
      })
    } else {
      mutatePublication()
    }

    if (isRenew) {
      noti('已续订 🎉')
      setModalVisible(false)
    } else {
      setView('select-approach')
      noti('已付费订阅 🎉')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])

  useInterval(() => {
    mutate()
  }, 2000)

  useEffect(() => {
    createOrder()
  }, [createOrder])

  if (error) {
    return <Error error={error} handleRetry={createOrder} />
  }

  if (loading) {
    return <Spinner size={30} />
  }

  if (order && showQRCode) {
    return (
      <>
        <QRCode
          value={`https://zhubai.love/pay/orders/${orderId}?qrcode=1&platform=web`}
          size={250}
          level="M"
          fgColor="#222"
          imageSettings={{
            src: 'https://img.zhubai.love/91ebc70a4a144660b2e03b9f0120cd7f.jpg',
            x: null,
            y: null,
            height: 60,
            width: 60,
          }}
        />
        <Button className={styles.retry} onClick={createOrder}>
          重新生成二维码
        </Button>
      </>
    )
  }

  if (order && userAgent.Wechat) {
    return (
      <>
        <div className={styles.description}>
          支付成功后返回当前页面即可查看会员专属内容
        </div>
        <PrimaryButton
          onClick={() => {
            window.location.href = redirectURL
          }}
        >
          前往支付
        </PrimaryButton>
      </>
    )
  }

  return <Spinner size={30} />
}

export default SubscribePaymentView
