/**
 * - [ ] 输入文本时插入的链接文本正确，如果未输入则直接使用 URL
 * - [ ] isActive 样式正确，isActive 点击按钮能正常取消当前链接
 * - [ ] 能够正确处理选区
 *   - [ ] 未选中文本，直接粘贴链接（编辑器有焦点），在光标处插入链接后光标出现在链接末尾
 *   - [ ] 未选中文本，通过弹窗插入链接（编辑器已经失去焦点），在光标处插入链接后光标出现在链接末尾
 *   - [ ] 选中文本，直接粘贴链接（编辑器有焦点)，将选中文本替换为链接后光标出现在链接末尾
 *   - [ ] 选中文本，通过弹窗插入链接（编辑器已经失去焦点），将选中文本替换为链接后光标出现在链接末尾
 *   - [ ] 选中文本中包含行内样式，粘贴、弹窗均能正常插入链接，且焦点正确
 *   - [ ] 选中文本中跨域多个普通块级样式，粘贴、弹窗均能正常插入链接（链接会被拆分），且焦点正确
 *   - [ ] 选中文本中包含链接和 Void Element（图片、分割线），粘贴链接无反应（log 错误）
 *   - [ ] 未聚焦编辑器直接通过弹窗插入链接，在文档末尾插入链接后光标出现在链接末尾
 */

import isUrl from 'is-url'
import {Transforms, Editor, Range, Point} from 'slate'
import {ReactEditor} from 'slate-react'

export function withLinks(editor) {
  const {insertData, insertText, isInline} = editor

  editor.isInline = (element) => {
    return element.type === 'link' ? true : isInline(element)
  }

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      try {
        wrapLink(editor, text)
      } catch (e) {
        console.error(e)
      }
    } else {
      insertText(text)
    }
  }

  editor.insertData = (data) => {
    const text = data.getData('text/plain')

    if (text && isUrl(text)) {
      try {
        wrapLink(editor, text)
      } catch (e) {
        console.error(e)
      }
    } else {
      insertData(data)
    }
  }

  return editor
}

export function insertLink(editor, {url, text, selection}) {
  try {
    wrapLink(editor, url, text, selection)
  } catch (e) {
    console.error(e)
  }
}

export function isLinkActive(editor) {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === 'link',
  })
  return Boolean(match)
}

function wrapLink(editor, url, text, selection) {
  if (!url) {
    return
  }

  const wrapSelection = {...(selection || editor.selection)}

  if (wrapSelection.focus) {
    const [match] = Editor.nodes(editor, {
      at: wrapSelection,
      match: (node) => node.type === 'link' || editor.isVoid(node),
    })

    if (match) {
      throw new Error()
    }
  }

  const isCollapsed =
    !wrapSelection.focus ||
    (wrapSelection.focus && Range.isCollapsed(wrapSelection))

  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{text: text || url}] : [{text}],
  }

  if (isCollapsed && selection?.focus) {
    // 未选中文本，通过弹窗插入链接（编辑器已经失去焦点）
    ReactEditor.focus(editor, editor)
    Transforms.insertNodes(editor, link, {at: selection, select: true})
  } else if (isCollapsed && Boolean(editor.selection?.focus)) {
    // 未选中文本，直接插入链接（编辑器有焦点）
    Transforms.insertNodes(editor, link)
  } else if (isCollapsed) {
    // 未聚焦编辑器直接通过弹窗插入链接
    ReactEditor.focus(editor, editor)
    Transforms.insertNodes(editor, link, {select: true})
  } else if (selection?.focus) {
    // 选中文本，通过弹窗插入链接（编辑器已经失去焦点）
    ReactEditor.focus(editor, editor)
    Transforms.select(editor, selection)

    Transforms.wrapNodes(editor, link, {
      split: true,
      at: selection,
    })
    Transforms.collapse(editor, {edge: 'end'})
  } else if (Boolean(editor.selection?.focus)) {
    // 选中文本，直接插入链接（编辑器已有焦点）
    Transforms.wrapNodes(editor, link, {
      split: true,
      at: selection,
    })
    Transforms.collapse(editor, {edge: 'end'})
  }
}

export function unwrapLink(editor) {
  Transforms.unwrapNodes(editor, {
    match: (n) => n.type === 'link',
  })
}

/**
 * 当光标出现在链接末时，修改选区到链接后一个 Point，从而：
 *
 * 1. 让链接 isActive 状态为 false（在链接末尾输入会插入到链接后，符合预期）
 * 2. 修复 Chrome 用中文输入法输入时错乱的问题
 *
 * 测试用例：
 * - [ ] 通过各种方式添加链接之后（光标在链接末），链接 isActive 是 false，且用中文输入法不会错乱
 * - [ ] 退格删除掉链接几个字符后，链接 isActive 是 false，且用中文输入法不会错乱
 * - [ ] 从链接末尾选中一半链接后，用中文输入法不会错乱
 * - [ ] 不论当前编辑器有无焦点，通过鼠标或键盘将光标放在链接末时，链接 isActive 是 false，且用中文输入法不会错乱
 */
export function fixLinkSelection(editor) {
  const [match] = Editor.nodes(editor, {
    match: (node) => node.type === 'link',
    mode: 'lowest',
  })

  if (match) {
    const [, path] = match
    const linkEndPoint = Editor.end(editor, path)

    const {anchor, focus} = editor.selection
    const isAnchorLinkEnd = Point.equals(linkEndPoint, anchor)
    const isFocusLinkEnd = Point.equals(linkEndPoint, focus)

    if (isAnchorLinkEnd || isFocusLinkEnd) {
      const afterLinkEndPoint = Editor.after(editor, linkEndPoint)
      Transforms.select(editor, {
        anchor: isAnchorLinkEnd ? afterLinkEndPoint : anchor,
        focus: isFocusLinkEnd ? afterLinkEndPoint : focus,
      })
    }
  }
}
