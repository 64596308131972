// - [ ] 默认值正确
// - [ ] 聚焦后显示操作按钮，失去焦点时若内容没有改变则隐藏操作按钮，否则保留
// - [ ] 点击取消后操作按钮消失，错误状态重置，重置为修改前的状态
// - [ ] 非空校验、服务端校验错误提示正常，错误信息展示不会导致页面抖动，编辑后重置错误状态
// - [ ] 保存时加载状态正常，重置错误状态，操作按钮不可点击
// - [ ] 保存成功后提示成功，其他地方也能正常更新，操作按钮消失

import {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {useSelf} from 'swr/self'
import {FormInput} from 'components/Form'
import {notificationState} from 'components/Notification'
import SettingsField, {
  SettingsSaveButton,
  SettingsCancelButton,
} from './SettingsField'

function PublicationNameField({self}) {
  const {publication} = self
  const [editing, setEditing] = useState(false)
  const [name, setName] = useState(publication.name)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const setNoti = useSetRecoilState(notificationState)
  const {mutate} = useSelf()

  function handleChange(e) {
    setName(e.target.value)
    setError(null)
  }

  function handleCancel() {
    setError(null)
    setEditing(false)
    setName(publication.name)
  }

  function handleSave() {
    if (name.length === 0) {
      setError(true)
      return
    }

    setLoading(true)
    setError(null)
    http(`/publications/${publication.id}`, {
      method: 'PUT',
      body: {name, description: publication.description},
    })
      .then(
        () => {
          setEditing(false)
          setNoti({
            visible: true,
            text: '保存成功',
          })
          mutate({...self, publication: {...self.publication, name}})
        },
        (error) =>
          setError({
            message:
              error.body?.validationErrors?.['name'][0] ||
              error.body?.message ||
              error.message ||
              '保存失败',
          })
      )
      .finally(() => setLoading(false))
  }

  return (
    <SettingsField
      name="名称"
      renderContent={() => (
        <FormInput
          placeholder="输入名称"
          name="name"
          value={name}
          autoComplete="off"
          onChange={handleChange}
          onFocus={() => setEditing(true)}
          onBlur={(e) => setEditing(e.target.value !== publication.name)}
          error={error}
        />
      )}
      renderAction={() => (
        <>
          {editing && (
            <SettingsSaveButton onClick={handleSave} loading={loading} />
          )}
          {editing && (
            <SettingsCancelButton onClick={handleCancel} loading={loading} />
          )}
        </>
      )}
    />
  )
}

export default PublicationNameField
