import styles from './CreatorPageNav.module.css'

function CreatorPageNav({renderLeft, renderRight}) {
  return (
    <div className={styles.nav}>
      <div className={styles.left}>{renderLeft()}</div>
      <div className={styles.right}>{renderRight()}</div>
    </div>
  )
}

export default CreatorPageNav
