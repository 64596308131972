/**
 * 全局顶部导航栏：
 * - [ ] 点击 Logo 回到首页
 * - [ ] 未登录时右侧显示「开始创作」，已登录时显示其他导航
 * - [ ] 已登录状态下未获取当前用户信息时不显示顶部导航
 * - [ ] 已登录状态下获取个人信息后后若有专栏则显示「创作」、「订阅者」，若无专栏则显示「开始创作」
 * - [ ] 已登录状态若有专栏则头像下拉菜单显示「专栏」，否则不显示
 * - [ ] 各导航 active 样式正确，底部的提示条会被 loading bar 遮盖
 * - [ ] 调慢网络打开 /creator 页面，loading bar 样式正常
 */

import cx from 'classnames'
import {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import loadingState from 'state/loading'
import {useSelf, useSelfMessagesUnreadStatus} from 'swr/self'
import {isLoggedIn} from 'lib/auth'
import {getPublicationURL} from 'lib/url'
import http from 'lib/http'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Avatar from 'components/Avatar'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {notificationState} from 'components/Notification'
import {ReactComponent as Logo} from 'icons/logo.svg'
import {ReactComponent as SettingsIcon} from 'icons/settings.svg'
import {ReactComponent as LogoutIcon} from 'icons/logout.svg'
import {ReactComponent as ColumnIcon} from 'icons/pen.svg'
import {ReactComponent as DefaultAvatar} from 'icons/default-avatar.svg'
import styles from './GlobalTopNav.module.css'

// - [ ] 已登录状态下未设置头像、未成功加载 self 时显示默认头像
function GlobalTopNav({loading, transparent, ...props}) {
  const loggedIn = isLoggedIn()

  return (
    <TopNav {...props} loading={loading} transparent={transparent}>
      <Layout.View
        className={cx(styles.navContent, {[styles.isLoggedIn]: loggedIn})}
      >
        <a href="/" className={styles.logo}>
          <Logo />
        </a>
        <div className={styles.navs}>
          <Navs />
        </div>
        {isLoggedIn() && <SelfAvatar />}
      </Layout.View>
    </TopNav>
  )
}

// - [ ] 未登录时显示关注我们、开始创作，移动端显示 logo
// - [ ] 已登录时若未成功请求 self，则不显示导航
// - [ ] 已登录且未开通专栏，显示开始创作和消息，移动端居左不显示 logo
// - [ ] 已登录且已开通专栏，显示创作、订阅者、收入创作和消息，移动端居左不显示 logo
// - [ ] 仅测试专栏帐号显示消息 Tab
function Navs() {
  const {self} = useSelf()
  const loggedIn = isLoggedIn()

  if (!loggedIn) {
    return (
      <>
        <a className={cx(styles.link)} href="https://news.zhubai.love">
          关注我们
        </a>
        <Link to="/creator" className={cx(styles.link, styles.unlogin)}>
          开始创作
        </Link>
      </>
    )
  }

  if (!self) {
    return null
  }

  if (!self.publication) {
    return (
      <>
        <Link to="/creator" className={cx(styles.link, styles.unlogin)}>
          开始创作
        </Link>
        <MessagesNav self={self} />
      </>
    )
  }

  return (
    <>
      <NavLink
        to="/creator/posts"
        className={styles.link}
        activeClassName={styles.active}
      >
        创作
      </NavLink>
      <NavLink
        to="/creator/subscribers"
        className={styles.link}
        activeClassName={styles.active}
      >
        订阅者
      </NavLink>
      <NavLink
        to="/creator/revenue"
        className={styles.link}
        activeClassName={styles.active}
      >
        收入
      </NavLink>
      <MessagesNav self={self} />
    </>
  )
}

// - [ ] 首次点击「消息」可正常前端路由跳转
// - [ ] 若当前页面已经是「消息」Tab，则刷新当前页面（重新标记已读）
function MessagesNav({self}) {
  const {status} = useSelfMessagesUnreadStatus()

  const showMessageTab =
    self?.publication?.id === '2037548906639921152' ||
    self?.publication?.id === '2037547249919201280' ||
    self?.publication?.id === '2048864589898522624' ||
    self?.publication?.id === '2038021340585398272'

  function handleClick(e) {
    if (window.location.pathname.startsWith('/messages')) {
      e.preventDefault()
      window.location.reload()
    }
  }

  if (!showMessageTab) {
    return null
  }

  return (
    <NavLink
      to="/messages"
      className={cx(styles.link, {[styles.hasBadge]: status?.hasUnreadBadge})}
      activeClassName={styles.active}
      onClick={handleClick}
    >
      消息
    </NavLink>
  )
}

/**
 * 自己的头像菜单：
 *
 * - [ ] 菜单样式样式正确，宽屏居中，窄屏局右，链接正确
 * - [ ] 异步请求 /self，未成功时头像显示为灰色，不可点击，不特殊处理错误
 * - [ ] 点击退出加载状态/错误提示正常，成功退出后清除 cookie（主/子域名）并重载当前页面
 */
function SelfAvatar() {
  const [visible, setVisible] = useState(false)

  const {self} = useSelf()

  function handleClick() {
    setVisible(true)
  }

  const setLoading = useSetRecoilState(loadingState)
  const setNoti = useSetRecoilState(notificationState)
  function handleLogout() {
    setLoading(true)
    http('/auth/logout', {method: 'POST'}).then(
      () => window.location.reload(),
      (error) => {
        setLoading(false)
        setNoti({
          visible: true,
          warning: true,
          text: error.body?.message || error.message || '退出失败',
        })
      }
    )
  }

  return (
    <div className={styles.avatar} onClick={handleClick}>
      {self?.avatar ? (
        <Avatar src={self.avatar} size={36} />
      ) : (
        <DefaultAvatar width={36} height={36} />
      )}
      {visible && (
        <Dropdown
          className={styles.dropdown}
          defaultPlacement="bottomRight"
          setShowDropdown={setVisible}
        >
          {self?.publication && (
            <DropdownMenu
              className={styles.dropdownMenu}
              href={getPublicationURL(self?.publication)}
            >
              <ColumnIcon className={styles.menuIcon} />
              专栏
            </DropdownMenu>
          )}
          <DropdownMenu className={styles.dropdownMenu} to="/settings">
            <SettingsIcon className={styles.menuIcon} />
            设置
          </DropdownMenu>
          <DropdownMenu className={styles.dropdownMenu} onClick={handleLogout}>
            <LogoutIcon className={styles.menuIcon} />
            退出
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}

export default GlobalTopNav
