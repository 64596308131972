import {useSWRInfinite} from 'swr'

export default function useMessagesInfinite(conversationId) {
  return useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (conversationId === undefined) {
        return null
      }

      if (pageIndex === 0) {
        return `/conversations/${conversationId}/messages`
      }

      const {pagination} = previousPageData

      if (pagination.hasPrev) {
        return pagination.prev
          .replace(window.location.origin, '')
          .replace(process.env.REACT_APP_PROXY, '')
          .replace(process.env.REACT_APP_API_PREFIX, '')
      }

      return null
    },
    {
      revalidateOnFocus: false,
      // 保留 size 会导致加载多页后切换到到新对话时连续多个请求，暂时禁掉 cache
      // persistSize: true,
    }
  )
}
