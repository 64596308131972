import {useEffect} from 'react'
import {Editor, Node, Range, Transforms} from 'slate'
import {useSelected, useFocused, useSlate} from 'slate-react'
import {ReactComponent as PaywallIcon} from 'icons/paywall.svg'
import {ReactComponent as PaywallDividerIcon} from 'icons/paywall-divider.svg'
import {ToolbarButton} from './EditorToolbar'
import {serializeToHTML} from './serialize'
import styles from './RichText.module.css'

// - [ ] 分割线内容不可编辑，选中范围正确
export function withPaywall(editor) {
  const {isVoid} = editor

  editor.isVoid = (element) => {
    return element.type === 'paywall-divider' ? true : isVoid(element)
  }

  return editor
}

// - [ ] 列表内禁用工具栏按钮不能插入
export function PaywallToolbarButton() {
  const editor = useSlate()

  const [match] = Editor.nodes(editor, {
    match: (node) => node.type === 'list-item',
  })

  const disabled = Boolean(match)

  function handleClick() {
    insertPaywallDivider(editor)
  }

  return (
    <ToolbarButton tip="设置付费墙" onClick={handleClick} disabled={disabled}>
      <PaywallIcon />
    </ToolbarButton>
  )
}

// - [ ] 插入前先移除文档中已有的分割线
// - [ ] 如果当前行有内容，默认插入到下一行
// - [ ] 如果当前行为空段落，则插入到当前行（先删除当前空行再插入分割线）
// - [ ] 如果当前行为空段落且为文档首行，则保留当前空行插入下一行
// - [ ] 默认插入后选中分割线
// - [ ] 如果在文档最后一行（下一行没有元素），则在下一行插入空行并选中
function insertPaywallDivider(editor) {
  const dividers = editor.children
    .map((node, index) => [node, index])
    .filter(([node]) => node.type === 'paywall-divider')
    .map(([, index]) => [index])

  if (dividers.length > 0) {
    dividers.reverse().forEach((path) => {
      Transforms.removeNodes(editor, {at: [path]})
    })
  }

  const [matchEmptyParagraph] = Editor.nodes(editor, {
    match: (node, path) =>
      path[0] !== 0 &&
      node.type === 'paragraph' &&
      Node.string(node).length === 0,
  })

  if (Boolean(matchEmptyParagraph)) {
    Transforms.removeNodes(editor, {at: matchEmptyParagraph[1]})
  }

  Transforms.insertNodes(editor, {
    type: 'paywall-divider',
    children: [{text: ''}],
  })

  const {path} = editor.selection.focus
  const [matchNext] = Editor.next(editor) || []
  if (!matchNext) {
    const nextPath = [path[0] + 1]
    Transforms.insertNodes(
      editor,
      {type: 'paragraph', children: [{text: ''}]},
      {at: nextPath}
    )
    Transforms.select(editor, {
      anchor: Editor.end(editor, nextPath),
      focus: Editor.end(editor, nextPath),
    })
  }
}

// 编辑器内付费墙组件：
// - [ ] 点击可以选中块级组件，选中样式正确
// - [ ] 选中后可退格删除
// - [ ] 选中后回车在下一行插入空行
export function PaywallDivider({attributes, children}) {
  const editor = useSlate()
  const selected = useSelected()
  const focused = useFocused()

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        Transforms.insertNodes(editor, {
          type: 'paragraph',
          children: [{text: ''}],
        })
        return
      }

      // TODO：焦点不在编辑器内时插入分割线无法正常删除，待确认原因后移除此事件
      // - [ ] 焦点不在编辑器内时通过工具栏插入分割线点击单独选中分割线后可以正常删除
      // - [ ] 选中多个元素（包括分割线）时退格能够正常删除
      if (event.key === 'Backspace' && Range.isCollapsed(editor.selection)) {
        event.preventDefault()
        Transforms.removeNodes(editor, {at: [editor.selection.focus.path[0]]})
        return
      }
    }

    if (selected && focused) {
      document.addEventListener('keydown', handleKeyDown)
      return () => document.removeEventListener('keydown', handleKeyDown)
    }
  }, [selected, focused, editor])

  return (
    <div {...attributes} data-slate-element-type="paywall-divider">
      <div
        contentEditable={false}
        className={styles.paywallDividerRoot}
        style={{
          boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none',
        }}
      >
        <hr className={styles.paywallDivider} />
        <span className={styles.paywallDividerText}>
          <PaywallDividerIcon />
          以下内容仅付费会员可见
        </span>
      </div>
      {children}
    </div>
  )
}

// 提取编辑器中的付费墙元素并将其转化为 paywall 对象
// - [ ] 可正常保存付费墙分隔线到草稿，再编辑时分隔线可见且位置正确
export function extractPaywall(content) {
  const index = content.findIndex((node) => node.type === 'paywall-divider')

  if (index === -1) {
    return {
      content,
      paywall: null,
    }
  } else {
    const filteredContent = content.filter(
      (node) => node.type !== 'paywall-divider'
    )
    return {
      content: filteredContent,
      paywall: {
        startAt: index,
        endAt: filteredContent.length - 1,
        intro: '',
      },
    }
  }
}

// 转换服务端存取的 content & paywall 为可编辑器 content 对象（有分隔线）
// - [ ] 编辑有设置付费墙的作品/草稿时分隔线可见且位置正确
export function parsePaywallToEditable(content, paywall) {
  const result = [...content]
  result.splice(paywall.startAt, 0, {
    type: 'paywall-divider',
    children: [{text: ''}],
  })
  return result
}

// 根据 pawall 对象获取所有人可见内容
// - [ ] 网页预览免费版本作品时仅可查看所有人可见内容
export function getFreeContent(content, paywall) {
  if (paywall) {
    return content.slice(0, paywall.startAt)
  }

  return content
}

// 根据 pawall 对象获取仅付费会员可见内容
export function getPaidContent(content, paywall) {
  if (paywall) {
    return content.slice(paywall.startAt)
  }

  return []
}

// 获取作品的 content，根据需要插入付费墙卡片，支持仅获取免费内容（哪怕返回了完整内容）：
// - [ ] 付费会员访问付费作品页时，正常渲染完整内容
// - [ ] 非付费会员访问付费作品页时，渲染公开内容并渲染付费墙卡片
// - [ ] 访问免费作品页时，正常渲染完整内容
// - [ ] 预览付费内容时，默认渲染完整内容，切换免费版本后仅渲染公开内容和付费墙卡片
export function getContent({post, publication, onlyFreeContent = false}) {
  const {paywall} = post
  const content =
    typeof post.content === 'string' ? JSON.parse(post.content) : post.content
  const isPreview = Boolean(post.postId)

  if (!paywall) {
    return content
  }

  const paywallElement = {
    type: 'paywall',
    paywall,
    publication,
    isPreview,
    children: [{text: ''}],
  }

  if (onlyFreeContent) {
    return [...content.slice(0, paywall.startAt), paywallElement]
  }

  return content.map((node) =>
    node.type === 'paywall' ? paywallElement : node
  )
}

// - [ ] 未设置付费墙内容邮件预览正确，发布邮件正确
// - [ ] 设置付费墙内容免费版本 & 付费版本邮件预览正确，发布邮件正确
// - [ ] 设置付费墙但发布时设置为免费内容，推送的邮件内容正确
export function getContentHTML({
  content,
  paywall,
  publication,
  ignorePaywall = false,
}) {
  const fullContent = getContent({post: {content}})
  const filteredContent = getContent({
    post: {content, paywall},
    publication,
    onlyFreeContent: true,
  })

  const fullHTML = serializeToHTML(fullContent, publication.theme?.primaryColor)

  return {
    content: fullHTML,
    paywallContent: ignorePaywall
      ? fullHTML
      : serializeToHTML(filteredContent, publication.theme?.primaryColor),
  }
}

export function setAllContentPaidOnly(editor) {
  Transforms.insertFragment(
    editor,
    [
      {
        type: 'paragraph',
        children: [{text: ''}],
      },
      {
        type: 'paywall-divider',
        children: [{text: ''}],
      },
    ],
    {at: [0]}
  )
}
