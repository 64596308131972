/**
 * Self Publication Required:
 * - [ ] 能正常请求获取当前帐号创建的专栏，加载/错误状态正确，可正常重试
 * - [ ] 没有创建专栏时会跳转去创建专栏
 * - [ ] 新用户访问 /creator 页面时会先要求登录、再要求填写个人资料、再要求创建专栏
 */
import {Redirect, useLocation} from 'react-router-dom'
import getDisplayName from 'lib/getDisplayName'
import {usePublication} from 'swr/publication'
import selfRequired from 'hocs/selfRequired'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Error from 'components/Error'

function selfPublicationRequired(Page) {
  function SelfPublicationRequiredPage({self, ...props}) {
    const location = useLocation()
    const next = encodeURIComponent(location.pathname + location.search)

    const {publication, error, isValidating, mutate} = usePublication(
      self.publication?.token
    )

    if (!self.publication) {
      return <Redirect to={`/create-publication?next=${next}`} />
    } else if (publication) {
      return <Page {...props} self={self} publication={publication} />
    } else {
      return (
        <Layout.Page>
          <TopNav loading={!error || isValidating} />
          <Error error={error} handleRetry={() => mutate()} />
        </Layout.Page>
      )
    }
  }

  SelfPublicationRequiredPage.displayName = `SelfPublicationRequired${getDisplayName(
    Page
  )}`

  return selfRequired(SelfPublicationRequiredPage)
}

export default selfPublicationRequired
