import cx from 'classnames'
import {IconButton} from 'components/Button'
import Tooltip from 'components/Tooltip'
import {ReactComponent as Icon} from 'icons/contact.svg'
import styles from './ContactButton.module.css'

function ContactButton({className}) {
  return (
    <Tooltip tip="联系我们" placement="left">
      <IconButton
        className={cx(styles.button, className)}
        href="/contact"
        target="_blank"
      >
        <Icon className={styles.icon} />
      </IconButton>
    </Tooltip>
  )
}

export default ContactButton
