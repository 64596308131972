import useSWR from 'swr'

export function useOrder(id) {
  const {data, error, ...rest} = useSWR(id ? `/orders/${id}` : null)

  return {
    ...rest,
    error,
    order: data,
  }
}

export function usePayment(orderId, paymentId) {
  const {data, error, ...rest} = useSWR(
    orderId && paymentId ? `/orders/${orderId}/payments/${paymentId}` : null
  )

  return {
    ...rest,
    error,
    payment: data,
  }
}
