// - [ ] 发布新作品时按钮为「下一步」，点击后显示发布设置弹窗
// - [ ] 点击「下一步」时若没有绑定邮箱则先绑定邮箱，绑定成功后关闭弹窗即可继续发布
// - [ ] 未开通会员计划专栏编辑已发布作品时按钮为「提交更新」，点击后直接更新
// - [ ] 已开通会员计划编辑已发布作品时按钮为「下一步」，点击后设置作品类型后并更新
// - [ ] 已开通会员计划编辑已发布作品时，若未变更内容，按钮为「设置类型」，点击后可设置作品类型后发布
// - [ ] 发布免费内容，推送/不推送逻辑正确，邮件/网页/小程序端内容正确
// - [ ] 发布付费内容，推送/不推送。仅推送付费订阅者逻辑正确，邮件/网页/小程序端内容正确
// - [ ] 编辑已发布作品后内容类型正确，不会重复推送，不论有无付费墙邮件/网页/小程序端内容正确
// - [ ] 设置付费作品时，若没有仅付费订阅者可见内容（未插入付费墙），则弹窗引导设置
// - [ ] 插入付费墙分隔线后点「下一步」默认选择为付费内容，否则没有默认值
// - [ ] 发布/设置为免费作品时，忽略编辑内的付费墙设置，网页/邮件内容正确
// - [ ] 发布加载/错误状态正确，成功后跳到作品页

import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import {useEffect, useState} from 'react'
import http from 'lib/http'
import {getPublicationURL} from 'lib/url'
import {PrimaryButton} from 'components/Button'
import {useNotification} from 'components/Notification'
import Tooltip from 'components/Tooltip'
import EmailBindingModal from 'components/EmailBindingModal'
import {
  extractPaywall,
  getFreeContent,
  getPaidContent,
} from 'components/Editor/PaywallPlugin'
import {getCharactersCount} from 'components/Editor'
import {parseContent} from './parseContent'
import WritePageSubmitDialog from './WritePageSubmitDialog'
import styles from './WritePage.module.css'

function WritePageSubmitButton({
  self,
  publication,
  isEditing,
  post,
  draft,
  title,
  content,
  setPublishStatus,
  disableStatus,
  editor,
}) {
  const noti = useNotification()
  const [showEmailModal, setShowEmailModal] = useState(false)
  const [showSettingModal, setShowSettingModal] = useState(false)

  const {paywall, content: filteredContent} = extractPaywall(content)
  const hasPaidContent =
    getCharactersCount(getPaidContent(filteredContent, paywall)) > 0
  const hasFreeContent =
    getCharactersCount(getFreeContent(filteredContent, paywall)) > 0

  const hasMembership = Boolean(publication.membership)

  const location = useLocation()
  const query = queryString.parse(location.search)
  const editingType = query['edit-type'] === '1'
  useEffect(() => {
    if (editingType) {
      setShowSettingModal(true)
    }
  }, [editingType])

  const submitDisableStatus = {...disableStatus}
  if (disableStatus.type === 'no-change' && hasMembership) {
    submitDisableStatus.disable = false
  }

  let buttonText = '下一步'
  if (isEditing && !hasMembership) {
    buttonText = '提交更新'
  } else if (isEditing && !draft && hasMembership) {
    buttonText = '设置类型'
  }

  function handleClick() {
    if (!self.email) {
      setShowEmailModal(true)
    } else if (isEditing && !hasMembership) {
      handlePublish({shouldNotify: false, isPaidContent: false})
    } else {
      setShowSettingModal(true)
    }
  }

  function handlePublish({shouldNotify, isPaidContent, pushTo}) {
    setShowSettingModal(false)
    setPublishStatus({loading: true, error: null})

    const parsedContent = parseContent(content, publication, !isPaidContent)

    const draftPromise = draft
      ? Promise.resolve(draft)
      : http('/drafts', {
          method: 'POST',
          body: {
            title,
            ...parsedContent,
            postId: post?.id,
          },
        })

    draftPromise
      .then((draft) =>
        http(`/drafts/${draft.id}/publish`, {
          method: 'POST',
          body: {
            title,
            ...parsedContent,
            isPaidContent,
            // - [ ] 发布免费内容时即便设置了付费墙也重置为 null
            ...(isPaidContent ? {} : {paywall: null}),
            pushTo,
          },
        })
      )
      .then(
        ({postId}) => {
          const postURL = getPublicationURL(publication, `/posts/${postId}`)
          window.location.href = postURL
        },
        (error) => {
          setPublishStatus({loading: false, error: null})
          noti(
            error.body?.validationErrors?.title ||
              error.body?.message ||
              error.message ||
              error,
            {
              warning: true,
            }
          )
        }
      )
  }

  return (
    <>
      <PrimaryButton
        className={styles.submitButton}
        onClick={handleClick}
        disabled={submitDisableStatus.disable}
      >
        {buttonText}
      </PrimaryButton>
      {submitDisableStatus.disable && (
        <Tooltip tip={submitDisableStatus.tip}>
          <div className={styles.nextButtonDisableMask}></div>
        </Tooltip>
      )}
      {showEmailModal && <EmailBindingModal setVisible={setShowEmailModal} />}
      {showSettingModal && (
        <WritePageSubmitDialog
          handlePublish={handlePublish}
          setDialogVisible={setShowSettingModal}
          publication={publication}
          hasPaidContent={hasPaidContent}
          hasFreeContent={hasFreeContent}
          editor={editor}
          isEditing={isEditing}
          submitDisableStatus={submitDisableStatus}
          post={post}
        />
      )}
    </>
  )
}

export default WritePageSubmitButton
