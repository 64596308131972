// - [x] 可正常加载数据概览，加载状态、错误状态、重试逻辑正确
// - [x] 列表能正常加载、渲染、翻页
// - [x] 打开时自动补全 URL query 为 `?page=1`
// - [x] 通过 URL query 也可以翻页
// - [x] 翻页时标题上的计数不会闪动
// - [x] 自动预加载前、后一页
// - [x] Loading 样式正常
// - [x] 没有上、下页时按钮 disabled
// - [x] 错误状态及重试逻辑正常
// - [x] 确认后能够正常移除订阅者（默认不会触发请求更新），且加载状态、错误提示正确
// - [x] 可正常添加订阅者，添加成功后自动更新当前页
// - [ ] 免费订阅者可以正常从列表中移除，付费订阅者则提示无法移除
// - [ ] 免费订阅者编辑文案为「设为付费订阅」、付费订阅者编辑文案为「延长有效期」
// - [ ] 最后一行的操作菜单可正常显示
// - [ ] 聚焦搜索框时，不论是否有搜索词均显示搜索结果，失去焦点时若无搜索词才隐藏搜索结果
import {useCallback, useState} from 'react'
import {useSetRecoilState} from 'recoil'
import debounce from 'lodash.debounce'
import http from 'lib/http'
import {getDateString, getRelativeTimeString} from 'lib/time'
import {
  useSearchSubscribers,
  useSubscribersAnalyticsV2,
  useSubscribersSummaryAnalytics,
} from 'swr/analytic'
import {useSelf} from 'swr/self'
import loadingState from 'state/loading'
import {DefaultButton, PrimaryButton} from 'components/Button'
import GlobalTopNav from 'components/GlobalTopNav'
import Layout from 'components/Layout'
import {useLoadingBar} from 'components/TopNav'
import {useNotification, notificationState} from 'components/Notification'
import PageTitle from 'components/PageTitle'
import ComingSoonModal from 'components/ComingSoonModal'
import Tooltip from 'components/Tooltip'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {ModalView} from 'components/Modal'
import {Input, FormSubmit, FormError, FormInput} from 'components/Form'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import CreatorTable, {
  CreatorTableLoading,
  CreatorTableError,
  CreatorTableEmpty,
} from './CreatorTable'
import CreatorAddSubsciberModal from './CreatorAddSubscriberModal'
import CreatorPagination, {usePageIndex} from './CreatorPagination'
import CreatorDataSummary, {
  CreatorDataSummaryItem,
  CreatorDataSummarySection,
} from './CreatorDataSummary'
import CreatorPageNav from './CreatorPageNav'
import {getSubscriptionName} from './CreatorPageUtils'
import CreatorDataTip from './CreatorDataTip'
import styles from './CreatorSubscribersPage.module.css'

function CreatorSubscribersPage({publication}) {
  const [showSearchResult, setShowSearchResult] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchQuery = useCallback(debounce(setSearchQuery, 500), [])

  return (
    <Layout.Page>
      <PageTitle title="我的订阅者" />
      <GlobalTopNav publication={publication} />
      <Layout.PageContent>
        <Layout.View className={styles.pageContent}>
          <Nav
            publication={publication}
            setSearchQuery={debouncedSetSearchQuery}
            setShowSearchResult={setShowSearchResult}
          />
          <Summary publication={publication} />
          <CreatorSubscribersTable
            publication={publication}
            showSearchResult={showSearchResult}
            searchQuery={searchQuery}
          />
        </Layout.View>
      </Layout.PageContent>
    </Layout.Page>
  )
}

function Nav({publication, setSearchQuery, setShowSearchResult}) {
  const [visible, setVisible] = useState()
  const {self} = useSelf()

  function handleAdd() {
    setVisible(true)
  }

  return (
    <CreatorPageNav
      renderLeft={() => '订阅者'}
      renderRight={() => (
        <>
          <FormInput
            className={styles.searchInput}
            placeholder="搜索订阅者"
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={() => setShowSearchResult(true)}
            onBlur={(e) => setShowSearchResult(e.target.value.length > 0)}
          />
          <PrimaryButton onClick={handleAdd}>添加</PrimaryButton>
          {visible && (
            <CreatorAddSubsciberModal
              setVisible={setVisible}
              self={self}
              publication={publication}
            />
          )}
        </>
      )}
    />
  )
}

function CreatorSubscribersTable({publication, searchQuery, showSearchResult}) {
  const pageIndex = usePageIndex()

  const {analytics, data, loading, isValidating, error, mutate} =
    useSubscribersAnalyticsV2(pageIndex)

  const {
    searchResults,
    loading: searchLoading,
    error: searchError,
    mutate: mutateSearch,
    data: searchData,
  } = useSearchSubscribers(searchQuery)

  useLoadingBar(isValidating)

  // Prefetch
  useSubscribersAnalyticsV2(pageIndex - 1)
  useSubscribersAnalyticsV2(pageIndex + 1)

  const setLoading = useSetRecoilState(loadingState)
  const setNoti = useSetRecoilState(notificationState)

  function handleRemove(subscription) {
    setLoading(true)
    http(`/publications/${publication.id}/subscriptions/${subscription.id}`, {
      method: 'DELETE',
    }).then(
      () => {
        setLoading(false)
        mutate({
          ...data,
          data: analytics.filter(({id}) => id !== subscription.id),
        })
        mutateSearch({
          ...searchData,
          data: searchData.data.filter(({id}) => id !== subscription.id),
        })
      },
      (error) => {
        setLoading(false)
        setNoti({
          visible: true,
          warning: true,
          text: error.body?.message || error.message || '移除失败，请重试',
        })
      }
    )
  }

  function handleEdit(editedSubscription) {
    mutate({
      ...data,
      data: analytics.map((subscription) => {
        if (subscription.id === editedSubscription.id) {
          return {
            ...subscription,
            ...editedSubscription,
          }
        }

        return subscription
      }),
    })

    mutateSearch({
      ...searchData,
      data: searchData.data.map((subscription) => {
        if (subscription.id === editedSubscription.id) {
          return {
            ...subscription,
            ...editedSubscription,
          }
        }

        return subscription
      }),
    })
  }

  if (showSearchResult) {
    return (
      <SubscribersTable
        publication={publication}
        subscribers={searchResults}
        error={searchError}
        loading={searchLoading}
        mutate={mutateSearch}
        pagination={null}
        emptyString="没有匹配的订阅者"
        handleRemove={handleRemove}
        handleEdit={handleEdit}
      />
    )
  }

  return (
    <SubscribersTable
      publication={publication}
      subscribers={analytics}
      error={error}
      loading={loading}
      mutate={mutate}
      pagination={data?.pagination}
      emptyString="还没有人订阅"
      handleRemove={handleRemove}
      handleEdit={handleEdit}
    />
  )
}

function SubscribersTable({
  loading,
  error,
  subscribers,
  emptyString,
  pagination,
  publication,
  mutate,
  handleRemove,
  handleEdit,
}) {
  const [showComingSoon, setShowComingSoon] = useState(false)

  return (
    <div className={styles.tableRoot}>
      <CreatorTable rootClass={styles.table}>
        <thead>
          <tr>
            <th scope="col">订阅者</th>
            <th scope="col">订阅方式</th>
            <th scope="col">
              累计付费总额
              <CreatorDataTip
                tip={!publication.membership && '当前专栏暂未开通付费会员计划'}
                offsetY="-2px"
              />
            </th>
            <th scope="col">
              付费有效期至
              <CreatorDataTip
                tip={!publication.membership && '当前专栏暂未开通付费会员计划'}
                offsetY="-2px"
              />
            </th>
            <th scope="col">订阅时间</th>
            <th scope="col">
              <DefaultButton onClick={() => setShowComingSoon(true)}>
                导入/导出
              </DefaultButton>
              {showComingSoon && (
                <ComingSoonModal setVisible={setShowComingSoon} />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading && <CreatorTableLoading />}
          {error && (
            <CreatorTableError
              error={error}
              handleRetry={() => mutate()}
              colSpan={6}
            />
          )}
          {subscribers?.length === 0 && (
            <CreatorTableEmpty colSpan={6}>{emptyString}</CreatorTableEmpty>
          )}
          {subscribers?.map((subscriber) => (
            <SubscriberItem
              key={subscriber.id}
              subscriber={subscriber}
              onRemove={handleRemove}
              onEdit={handleEdit}
              publication={publication}
            />
          ))}
        </tbody>
      </CreatorTable>
      <div className={styles.footer}>
        <CreatorPagination pagination={pagination} />
      </div>
    </div>
  )
}

function Summary({publication}) {
  const hasMembership = Boolean(publication.membership)

  const {data, error, loading, isValidating, mutate} =
    useSubscribersSummaryAnalytics()

  useLoadingBar(loading || isValidating)

  return (
    <CreatorDataSummary error={error} onRetry={() => mutate()}>
      <CreatorDataSummarySection>
        <CreatorDataSummaryItem
          number={data?.totalSubscriberCount}
          diff={
            data &&
            data.totalSubscriberCount - data.sevenDaysAgoTotalSubscriberCount
          }
          text={hasMembership ? '总会员数' : '总订阅者数'}
          style={{whiteSpace: 'pre'}}
        />
        <CreatorDataSummaryItem
          number={data?.paidSubscriberCount}
          diff={
            data &&
            data.paidSubscriberCount - data.sevenDaysAgoPaidSubscriberCount
          }
          text={hasMembership ? '付费会员数' : '付费订阅者数'}
          style={{whiteSpace: 'pre'}}
          tooltip={!hasMembership && '当前专栏暂未开通付费会员计划'}
        />
      </CreatorDataSummarySection>
      <CreatorDataSummarySection>
        <CreatorDataSummaryItem
          number={data?.emailSubscriberCount}
          diff={
            data &&
            data.emailSubscriberCount - data.sevenDaysAgoEmailSubscriberCount
          }
          text="邮箱订阅数"
        />
        <CreatorDataSummaryItem
          number={data?.wechatSubscriberCount}
          diff={
            data &&
            data.wechatSubscriberCount - data.sevenDaysAgoWechatSubscriberCount
          }
          text="微信订阅数"
        />
      </CreatorDataSummarySection>
    </CreatorDataSummary>
  )
}

function SubscriberItem({subscriber, onRemove, publication, onEdit}) {
  const hasMembership = Boolean(publication.membership)

  const {subscriberEmail, approach, createdAt, totalPurchased, expiredAt} =
    subscriber

  const type =
    typeof subscriber.type === 'string' ? subscriber.type : subscriber.type[0]

  const [showDropdown, setShowDropdown] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)

  let name = getSubscriptionName(subscriber)

  let method = ''
  if (approach.email && approach.wechat) {
    method = '邮箱、微信'
  } else if (approach.email) {
    method = '邮箱'
  } else if (approach.wechat) {
    method = '微信'
  } else {
    method = '-'
  }

  function handleRemove() {
    if (window.confirm('确认要移除该订阅者？')) {
      onRemove(subscriber)
    }
  }

  function hanldeEdit(subscription) {
    onEdit(subscription)
  }

  return (
    <tr>
      <th scope="row">
        <Tooltip
          tip={subscriberEmail}
          overlayInnerStyle={{whiteSpace: 'nowrap'}}
        >
          <span>{name}</span>
        </Tooltip>
      </th>
      <td>{method}</td>
      <td>{totalPurchased === 0 ? '-' : `¥${totalPurchased / 100}`}</td>
      <td>{type === 'paid' ? getDateString(expiredAt) : '-'}</td>
      <td>{getRelativeTimeString(createdAt)}</td>
      <td>
        <div className={styles.actionButtonRoot}>
          <DefaultButton
            className={styles.actionButton}
            onClick={() => setShowDropdown(true)}
          >
            操作
            <ArrowIcon className={styles.actionButtonIcon} />
          </DefaultButton>
          {showDropdown && (
            <Dropdown
              className={styles.dropdown}
              setShowDropdown={setShowDropdown}
              defaultPlacement="bottomRight"
            >
              {hasMembership && (
                <DropdownMenu onClick={() => setShowEditModal(true)}>
                  {type === 'paid' ? '延长有效期' : '设为付费会员'}
                </DropdownMenu>
              )}
              <DropdownMenu danger onClick={handleRemove}>
                {hasMembership ? '移除会员' : '移除订阅者'}
              </DropdownMenu>
            </Dropdown>
          )}
          {showEditModal && (
            <EditModal
              setVisible={setShowEditModal}
              subscription={subscriber}
              publication={publication}
              onSuccess={hanldeEdit}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

function EditModal({setVisible, subscription, publication, onSuccess}) {
  const {expiredAt} = subscription

  const type =
    typeof subscription.type === 'string'
      ? subscription.type
      : subscription.type[0]

  const [expires, setExpires] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const isValidExpires =
    Number.isInteger(Number(expires)) && Number(expires) > 0

  let targetExpires = type === 'paid' ? expiredAt : null
  if (isValidExpires) {
    targetExpires =
      (type === 'paid' ? expiredAt : Date.now()) +
      Number(expires) * 24 * 3600 * 1000
  }

  const title = type === 'paid' ? '延长付费订阅有效期' : '设置为付费订阅'

  const noti = useNotification()
  function handleSubmit(e) {
    e.preventDefault()

    if (!isValidExpires) {
      setError({expiredAt: true, message: '有效天数需为大于 0 的整数'})
      return
    }

    setLoading(true)
    http(`/publications/${publication.id}/subscriptions/${subscription.id}`, {
      method: 'PUT',
      body: {duration: expires * 24 * 3600 * 1000},
    }).then(
      () => {
        setVisible(false)
        noti('设置成功')
        onSuccess({...subscription, type: 'paid', expiredAt: targetExpires})
      },
      (error) => {
        if (error.body?.validationErrors?.expiredAt) {
          setError({
            expiredAt: true,
            message: error.body.validationErrors.expiredAt[0],
          })
        } else {
          setError({
            message: error.body?.message || error.message || '设置失败',
          })
        }

        setLoading(false)
      }
    )
  }

  return (
    <ModalView setVisible={setVisible} hasCloseButton>
      <form className={styles.modal} onSubmit={handleSubmit}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>
          <span style={{flexShrink: 0}}>当前订阅者：</span>
          <span className={styles.name}>
            {getSubscriptionName(subscription)}
          </span>
        </div>
        <div className={styles.field}>
          <div className={styles.fieldName}>原有效期：</div>
          <div className={styles.fieldValue}>
            {type === 'paid' ? getDateString(expiredAt) : '暂未付费订阅'}
          </div>
        </div>
        <div className={styles.field}>
          <div className={styles.fieldName}>
            {type === 'paid' ? '延长天数：' : '有效天数'}
          </div>
          <div className={styles.fieldValue}>
            <Input
              className={styles.input}
              name="expires"
              type="tel"
              value={expires}
              placeholder={Boolean(error?.expiredAt) ? '输入' : '30'}
              isInvalid={Boolean(error?.expiredAt)}
              onChange={(e) => {
                setError(null)
                setExpires(e.target.value)
              }}
            />
            天
          </div>
        </div>
        <div className={styles.tip}>
          <div>有效期将会被调整为：</div>
          <div>
            {targetExpires ? getDateString(targetExpires) : '暂未付费订阅'}
          </div>
        </div>
        <FormSubmit loading={loading}>保存</FormSubmit>
        <FormError error={error} placeholder />
      </form>
    </ModalView>
  )
}

export default CreatorSubscribersPage
