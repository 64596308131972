/**
 * - [ ] 不同宽度下样式正常
 * - [ ] 微信内点击「订阅更新」按钮样式正常，点击后能够正常打开小程序 publication 页面
 * - [ ] 微信内「订阅更新」能在有/无设置主题色样式均正确
 */

import {Link} from 'react-router-dom'
import {getDateString} from 'lib/time'
import {LaunchWechatAppMask} from 'lib/wechat'
import useSafariStatusBarColor from 'hooks/useSafariStatusBarColor'
import Avatar from 'components/Avatar'
import ShareButton from 'components/ShareButton'
import GlobalSideMenu from 'components/GlobalSideMenu'
import {SubscribeButton} from 'components/Subscribe'
import Button from 'components/Button'
import {useSubscribeModal} from 'components/Subscribe/SubscribeModal'
import {ReactComponent as NotiIcon} from 'icons/notification.svg'
import {ReactComponent as QuoteIcon} from 'icons/quote.svg'
import styles from './PublicationPageHeader.module.css'

// - [ ] Newsletter 页为主题色为淡紫色，跳转作品页后为白色
function PublicationPageHeader({publication}) {
  let safariStatusBarColor
  if (publication?.theme?.primaryColor) {
    safariStatusBarColor = `#fff`
  } else if (publication) {
    safariStatusBarColor = `#f7f5ff`
  }
  useSafariStatusBarColor(safariStatusBarColor)

  if (!publication) {
    return null
  }

  return (
    <header className={styles.header}>
      <div className={styles.view}>
        <div className={styles.name}>
          <Link to="/">{publication.name}</Link>
        </div>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <div className={styles.avatar}>
          <Avatar src={publication.author.avatar} size={68} />
        </div>
        <div className={styles.description}>
          <QuoteIcon className={styles.quote} />
          <div>{publication.description}</div>
          <div className={styles.sign}>
            —— 由 {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <span className={styles.author}>
              {publication.author.name}
            </span>{' '}
            创作
          </div>
        </div>
        <Actions publication={publication} />
        <Tip publication={publication} />
      </div>
      <GlobalSideMenu
        isAuthor={publication.relationship.isAuthor}
        buttonClass={styles.menuButton}
      />
    </header>
  )
}

function Actions({publication}) {
  return (
    <div className={styles.actions}>
      <SubscribeButton publication={publication} className={styles.button} />
      <ShareButton
        className={styles.shareButtonRoot}
        buttonClass={styles.shareButton}
        page="pages/publication/publication"
        scene={`token=${publication.token}`}
        publication={publication}
        dropdownPlacement="bottomRight"
        hidePoster={true}
      >
        分享
        <LaunchWechatAppMask
          width="68px"
          height="46px"
          path={`/pages/publication/publication?token=${publication.token}`}
        />
      </ShareButton>
    </div>
  )
}

// - [ ] 未微信和邮箱订阅时，根据订阅渠道设置提示 `支持微信、邮箱订阅`, `支持邮箱订阅`, `支持微信订阅`
// - [ ] 已付费订阅但未微信和邮箱订阅时，提示也如上述所示
// - [ ] 已付费订阅，且用微信或邮箱任意方式订阅，提示 `xxxx-xx-xx 到期`
// - [ ] 开通会员计划的专栏仅免费订阅时，提示 `付费订阅后即可查看专属内容`
// - [ ] 未开通开通会员计划的专栏，订阅后不显示提示
function Tip({publication}) {
  const {subscription} = publication

  const showSubscribeModal = useSubscribeModal()

  if (!subscription.approach.wechat && !subscription.approach.email) {
    return (
      <div className={styles.tip}>
        <NotiIcon className={styles.tipIcon} />
        {publication.approach.email &&
          publication.approach.wechat &&
          '支持邮箱、微信订阅'}
        {publication.approach.email &&
          !publication.approach.wechat &&
          '支持邮箱订阅'}
        {!publication.approach.email &&
          publication.approach.wechat &&
          '支持微信订阅'}
      </div>
    )
  } else if (publication.subscription.type === 'paid') {
    return (
      <div className={styles.tip}>
        <NotiIcon className={styles.tipIcon} />
        <span>{getDateString(publication.subscription.expiredAt)} 到期</span>
        <span>&nbsp;·&nbsp;</span>
        <Button
          className={styles.tipButton}
          onClick={() => showSubscribeModal({isRenew: true})}
        >
          续订
        </Button>
      </div>
    )
  } else if (
    publication.membership &&
    publication.subscription.type !== 'paid'
  ) {
    return (
      <div className={styles.tip}>
        <NotiIcon className={styles.tipIcon} />
        付费订阅后即可查看专属内容
      </div>
    )
  }

  return null
}

export default PublicationPageHeader
