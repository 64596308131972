/**
 * Self Required:
 * - [ ] 访问 /users/self 页面时会先要求登录再要求填写个人资料
 * - [ ] /self 请求错误时能正常展示，且重试后可恢复
 */

import {Redirect, useLocation} from 'react-router-dom'
import getDisplayName from 'lib/getDisplayName'
import {getPublicationURL, getZhubaiURL, isZhubaiURL} from 'lib/url'
import getPublicationToken from 'lib/getPublicationToken'
import {useSelf} from 'swr/self'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Error from 'components/Error'
import authRequired from 'hocs/authRequired'

function selfRequired(Page) {
  function SelfRequiredPage(props) {
    const location = useLocation()
    const next = encodeURIComponent(location.pathname + location.search)

    const {self, loading, error, isValidating, mutate} = useSelf()

    if (self?.name && self?.avatar && self?.account.cellphone) {
      return <Page {...props} self={self} />
    } else if (self?.account.cellphone) {
      if (isZhubaiURL()) {
        return <Redirect to={`/auth/profile?next=${next}`} />
      } else {
        const token = getPublicationToken()
        window.location.href = getZhubaiURL(
          `/auth/profile?next=${getPublicationURL({token}, next)}`
        )
      }
    } else if (self) {
      if (isZhubaiURL()) {
        return <Redirect to={`/auth/bind-phone?next=${next}`} />
      } else {
        const token = getPublicationToken()
        window.location.href = getZhubaiURL(
          `/auth/bind-phone?next=${getPublicationURL({token}, next)}`
        )
      }
    } else {
      return (
        <Layout.Page>
          <TopNav loading={loading || isValidating} />
          <Error error={error} handleRetry={() => mutate()} />
        </Layout.Page>
      )
    }
  }

  SelfRequiredPage.displayName = `SelfRequired${getDisplayName(Page)}`

  return authRequired(SelfRequiredPage)
}

export default selfRequired
