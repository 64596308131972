// - [ ] 不同宽度屏幕下各种数量的付费周期样式正确
// - [ ] 点击订阅按钮后正常显示订阅弹窗
// - [ ] 已登录用户访问付费作品页，显示「阅读完整会员计划」链接，不显示「已订阅」，不同屏幕宽度下样式正确
// - [ ] 未登录用户访问付费作品页，显示「阅读完整会员计划」和「已订阅」，不同屏幕宽度下样式正确
// - [ ] 已登录用户访问会员计划详情页，不显示「阅读完整会员计划」链接，也不显示「已订阅」
// - [ ] 未登录用户访问会员计划详情页，不显示「阅读完整会员计划」链接，显示「已订阅」

import cx from 'classnames'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {getDiscount, parsePlans} from 'lib/membership'
import {isLoggedIn} from 'lib/auth'
import {getURLWithExtraQuery} from 'lib/url'
import http from 'lib/http'
import Button, {PrimaryButton} from 'components/Button'
import {useLoginModal} from 'components/LoginModal'
import {useLoadingBar} from 'components/TopNav'
import {useNotification} from 'components/Notification'
import {getCharactersCount} from 'components/Editor'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import {useSubscribeModal} from './SubscribeModal'
import styles from './SubscribePaywall.module.css'

export function SubscribePaywall({
  paywall,
  publication,
  isPreview,
  title,
  isMembershipPage,
}) {
  const {membership} = publication
  const plans = parsePlans(membership.plans)
  const plansCount = membership.plans.length

  let description = []
  try {
    description = JSON.parse(membership.description)
  } catch (e) {}
  const showDescriptionLink = getCharactersCount(description) > 0

  const showSubscribeModal = useSubscribeModal()
  function handleClick() {
    if (isPreview) {
      window.alert('预览时暂无法订阅')
      return
    }

    showSubscribeModal()
  }

  return (
    <div className={styles.root}>
      <div contentEditable={false}>
        {paywall?.startAt > 0 && <div className={styles.topMask} />}
        <div className={styles.paywall}>
          <div className={styles.title}>{title || '以下为会员专属内容'}</div>
          <div className={styles.subtitle}>
            订阅 <b>{publication.name}</b>，即可查看{' '}
            <b>{publication.author.name}</b> 创作的专属内容
          </div>
          <div
            className={cx(styles.plans, {
              [styles.one]: plansCount === 1,
              [styles.two]: plansCount === 2,
              [styles.three]: plansCount === 3,
              [styles.four]: plansCount === 4,
            })}
          >
            <PlanButton
              type="monthly_plan"
              plans={plans}
              onClick={handleClick}
            />
            <PlanButton
              type="three_month_plan"
              plans={plans}
              onClick={handleClick}
            />
            <PlanButton
              type="six_month_plan"
              plans={plans}
              onClick={handleClick}
            />
            <PlanButton
              type="twelve_month_plan"
              plans={plans}
              onClick={handleClick}
            />
            <PrimaryButton className={styles.button} onClick={handleClick}>
              订阅会员
            </PrimaryButton>
          </div>
          {!isMembershipPage && showDescriptionLink && (
            <div className={styles.descriptionLinkRoot}>
              <Link className={styles.descriptionLink} to="/membership">
                阅读完整会员计划
                <ArrowIcon className={styles.arrowIcon} />
              </Link>
            </div>
          )}
          <Footer
            onSubscribe={handleClick}
            isMembershipPage={isMembershipPage}
          />
        </div>
      </div>
    </div>
  )
}

function PlanButton({type, plans, onClick}) {
  const {price, enabled} = plans[type]
  const discount = getDiscount(type, plans)

  if (!enabled) {
    return null
  }

  const name = {
    monthly_plan: '月度订阅',
    three_month_plan: '季度订阅',
    six_month_plan: '半年订阅',
    twelve_month_plan: '年度订阅',
    free: '免费订阅',
  }[type]

  const showDiscount = discount > 0 && discount < 10

  return (
    <Button className={styles.plan} onClick={onClick}>
      <span className={styles.planPrice}>
        <span>¥</span>
        <span>{price}</span>
      </span>
      <span className={styles.planName}>{name}</span>
      {showDiscount && <span className={styles.discount}>{discount} 折</span>}
    </Button>
  )
}

function Footer({isMembershipPage, onSubscribe}) {
  const loggedIn = isLoggedIn()
  const [loading, setLoading] = useState(false)
  useLoadingBar(loading)
  const noti = useNotification()
  const showLoginModal = useLoginModal()
  function handleLogin() {
    if (loggedIn) {
      setLoading(true)
      http('/auth/logout', {method: 'POST'}).then(
        () => {
          window.location.href = getURLWithExtraQuery({
            url: window.location.href,
            extraQuery: {login: 2},
          })
        },
        (error) => {
          setLoading(false)
          noti(error.body?.message || error.message || '退出失败', {
            warning: true,
          })
        }
      )
    } else {
      showLoginModal({
        title: '登录',
        subtitle: '使用已订阅的手机号或邮箱登录',
        enableEmailLogin: true,
      })
    }
  }

  if (isMembershipPage) {
    return (
      <div className={styles.footer}>
        <Button className={styles.footerButton} onClick={onSubscribe}>
          免费订阅
        </Button>
        <span className={styles.footerDot}>·</span>
        <Button className={styles.footerButton} onClick={handleLogin}>
          已订阅过？
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.footer}>
      <span>已经订阅过？</span>
      <Button
        className={styles.footerButton}
        onClick={handleLogin}
        style={{color: '#060E4B'}}
      >
        {isLoggedIn() ? '切换帐号' : '登录'}
      </Button>
    </div>
  )
}
