// - [ ] 不同宽度下样式正确
// - [ ] 设置页点击开关可设置通知方式，点击后开关状态立刻改变，导航栏显示加载中，成功后有提示。
// - [ ] 设置失败时能正常提示错误消息，并撤回按钮状态。
// - [ ] 未绑定邮箱/微信时始终显示为关，点击后提示绑定。
import {useState} from 'react'
import http from 'lib/http'
import {ToggleButton} from 'components/Button'
import {useNotification} from 'components/Notification'
import {useSelfMessagesSetting} from 'swr/self'
import {useLoadingBar} from 'components/TopNav'
import SettingsField, {SettingsTip} from './SettingsField'
import styles from './SettingsField.module.css'

function SelfMessagesField({self}) {
  const enabled =
    self.publication?.id === '2037548906639921152' ||
    self.publication?.id === '2037547249919201280' ||
    self.publication?.id === '2048864589898522624' ||
    self.publication?.id === '2038021340585398272'

  const noti = useNotification()
  const {data, mutate} = useSelfMessagesSetting()
  const [loading, setLoading] = useState(false)

  // - [ ] 开启邮件通知时，设置字段正确，可正常开关微信通知。
  const setting = data
    ? {
        email: data.email && Boolean(self.account.email),
        wechat: data.wechat && self.account.hasBoundWechat,
      }
    : null

  useLoadingBar(loading)

  if (!enabled) {
    return null
  }

  if (!setting) {
    return null
  }

  function handleSetSetting({email, wechat}) {
    if (email && !self.account.email) {
      noti('请先绑定邮箱', {
        warning: true,
      })
      return
    }

    if (wechat && !self.account.hasBoundWechat) {
      noti('请先绑定微信', {
        warning: true,
      })
      return
    }

    mutate({email, wechat}, false)

    setLoading(true)
    http(`/settings/message_notification`, {
      method: 'PUT',
      body: {email, wechat},
    })
      .then(
        () => {
          noti('设置成功')
          mutate()
        },
        (error) => {
          noti(error.body?.message || error.message || '设置失败', {
            warning: true,
          })

          mutate({...setting})
        }
      )
      .finally(() => setLoading(false))
  }

  return (
    <SettingsField
      name="消息"
      className={styles.messagesSetting}
      renderContent={() => (
        <>
          <div className={styles.toggle}>
            <span>开启邮箱通知</span>
            <ToggleButton
              value={setting.email}
              onClick={() =>
                handleSetSetting({
                  wechat: setting.wechat,
                  email: !setting.email,
                })
              }
            />
          </div>
          <div className={styles.toggle}>
            <span>开启微信通知</span>
            <ToggleButton
              value={setting.wechat}
              onClick={() =>
                handleSetSetting({
                  wechat: !setting.wechat,
                  email: setting.email,
                })
              }
            />
          </div>
          <SettingsTip>
            消息通知将会每天汇总后发送一次，关闭后只能访问消息页面主动查收
          </SettingsTip>
        </>
      )}
    />
  )
}

export default SelfMessagesField
