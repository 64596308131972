/**
 * 创作者中心 - 创作页
 *
 * - [ ] /creator/posts 自动 redirect /creator/posts/published
 * - [ ] 导航链接、isActive 样式正确
 * - [ ] 创作能够正常跳转，导入作品提示 coming soon
 * - [ ] 窄屏时不显示导入、创作按钮
 */

import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'
import {LinkButton, PrimaryButton} from 'components/Button'
import GlobalTopNav from 'components/GlobalTopNav'
import Layout from 'components/Layout'
import CreatorPostsPage from './CreatorPostsPage'
import CreatorDraftsPage from './CreatorDraftsPage'
import CreatorPageNav from './CreatorPageNav'
import styles from './CreatorContentPage.module.css'

function CreatorContentPage({publication}) {
  const {path} = useRouteMatch()

  return (
    <Layout.Page>
      <GlobalTopNav publication={publication} />
      <Layout.PageContent>
        <Nav />
        <Switch>
          <Route path={path} exact>
            <Redirect to={`${path}/published`} />
          </Route>
          <Route path={`${path}/published`}>
            <CreatorPostsPage publication={publication} />
          </Route>
          <Route path={`${path}/drafts`}>
            <CreatorDraftsPage publication={publication} />
          </Route>
        </Switch>
      </Layout.PageContent>
    </Layout.Page>
  )
}

function Nav() {
  const {path} = useRouteMatch()
  const history = useHistory()

  function handleWrite() {
    history.push('/write')
  }

  return (
    <Layout.View>
      <CreatorPageNav
        renderLeft={() => (
          <>
            <Route path={`${path}/published`}>已发布作品</Route>
            <Route path={`${path}/drafts`}>草稿箱</Route>
          </>
        )}
        renderRight={() => (
          <>
            <Route path={`${path}/published`}>
              <LinkButton className={styles.link} to={`${path}/drafts`}>
                草稿箱
              </LinkButton>
            </Route>
            <Route path={`${path}/drafts`}>
              <LinkButton className={styles.link} to={`${path}/published`}>
                已发布作品
              </LinkButton>
            </Route>
            <PrimaryButton className={styles.writeLink} onClick={handleWrite}>
              创作
            </PrimaryButton>
          </>
        )}
      />
    </Layout.View>
  )
}

export default CreatorContentPage
