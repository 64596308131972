/**
 * 无限列表：
 *
 * - [ ] 页面滚动到底部能正常自动加载直至加载完成（Dev & Prod）
 * - [ ] 如果第一页内容高度不足以超出屏幕会自动加载直至超出
 * - [ ] 加载完成之后不会再有请求并不再显示 loading
 * - [ ] 没有内容时能正常显示空状态
 * - [ ] 默认始终显示 loading（第一页、非第一页），只有出错或加载完成之是才不显示 loading
 * - [ ] Error 展示 & 重试逻辑正常（第一页、非第一页）
 * - [ ] 能够正常删除、更新
 */

import uniq from 'lodash.uniq'
import {useEffect, useRef} from 'react'
import {useSWRInfinite} from 'swr'

function InfiniteList({
  api,
  renderItem,
  renderEmpty,
  renderLoading,
  renderError,
  revalidateOnFocus,
  onSuccess,
}) {
  const {data, size, setSize, error, mutate} = useSWRInfiniteList({
    api,
    revalidateOnFocus,
    onSuccess,
  })

  const rawItems = data
    ? data.reduce((items, body) => [...items, ...body.data], [])
    : []
  const items = uniq(rawItems, 'id')
  const hasNext = !data || data[data.length - 1].pagination.hasNext
  const isEmpty = !hasNext && items.length === 0
  const isLoading =
    (!data && !error) || (data && typeof data[size - 1] === 'undefined')

  const loadingPlaceholderRef = useRef()

  useEffect(() => {
    if (!loadingPlaceholderRef.current) {
      return
    }

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading) {
        setSize(size + 1)
      }
    })
    observer.observe(loadingPlaceholderRef.current)

    return () => observer.disconnect()
  }, [size, setSize, isLoading])

  function onDelete(deletedItem) {
    const newData = data.map((page) => ({
      ...page,
      data: page.data.filter((item) => item.id !== deletedItem.id),
    }))
    mutate(newData, false)
  }

  return (
    <>
      {isEmpty && renderEmpty()}
      {items.map((item) => renderItem({item, onDelete}))}
      {hasNext &&
        !error &&
        renderLoading(loadingPlaceholderRef, items.length === 0)}
      {error && renderError(error, () => mutate(), items.length === 0)}
    </>
  )
}

export function useSWRInfiniteList({
  api,
  revalidateOnFocus = true,
  onSuccess = () => {},
}) {
  return useSWRInfinite(
    (pageIndex, previousPageData) => {
      if (pageIndex === 0) {
        return api
      }

      const {pagination} = previousPageData

      if (pagination.hasNext) {
        return pagination.next
          .replace(window.location.origin, '')
          .replace(process.env.REACT_APP_PROXY, '')
          .replace(process.env.REACT_APP_API_PREFIX, '')
      }

      return null
    },
    {
      revalidateOnFocus,
      onSuccess,
    }
  )
}

export default InfiniteList
