/**
 * 个人页（自己）
 * - [ ] 访问 /users/self 时，会替换为自己的 id
 * - [ ] 未登录时则跳去登录
 * - [ ] 未填写个人资料是则跳去填写
 */

import {useLayoutEffect} from 'react'
import {useHistory} from 'react-router-dom'
import selfRequired from 'hocs/selfRequired'
import EmptyPage from 'pages/EmptyPage'

function SelfProfilePage({self}) {
  const history = useHistory()

  useLayoutEffect(() => {
    history.push(`/users/${self.id}`)
  }, [history, self])

  return <EmptyPage />
}

export default selfRequired(SelfProfilePage)
