import useSWR from 'swr'

export function usePost(id) {
  const {data, error, ...rest} = useSWR(id ? `/posts/${id}` : null)

  return {
    ...rest,
    error,
    post: data,
    loading: !error && !data,
  }
}

// - [ ] 获取作品草稿 404 时不会重试
export function usePostDraft(id) {
  const {data, error, ...rest} = useSWR(`/posts/${id}/draft`, {
    shouldRetryOnError: false,
  })

  return {
    ...rest,
    error,
    draft: data,
    loading: !error && !data,
  }
}

export function useDraft(id) {
  const {data, error, ...rest} = useSWR(`/drafts/${id}`)

  return {
    ...rest,
    error,
    draft: data,
    loading: !error && !data,
  }
}

export function usePreview(drafId, token) {
  const {data, error, ...rest} = useSWR(
    `/drafts/${drafId}/preview?token=${token}`
  )

  if (data) {
    data.publication = {id: data.publicationId}
  }

  return {
    ...rest,
    error,
    post: data,
    loading: !error && !data,
  }
}
