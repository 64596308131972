// 会员计划页面（开通 & 修改）
// - [ ] 请求获取会员计划后才渲染页面，加载/错误状态正确，可正常重试
// - [ ] 返回 404 错误不显示错误，而是开通计划
// - [ ] 已开通后访问时为修改计划，能够正常显示已填写的字段
// - [ ] 会员计划详情样式正确（包括 placeholder），可正常编辑和保存
// - [ ] 提交时所有字段正确，价格单位正确，加载状态正确，成功和失败提示，能正常展示服务端校验错误信息
// - [ ] 修改内容后跳转/关闭页面前会有确认
// - [ ] 创建（所有字段为空）、编辑时能正常预览选择订阅计划弹窗
// - [ ] 不同宽度下样式正常
import {useEffect, useState} from 'react'
import {Prompt, useHistory} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import round from 'lodash.round'
import {dequal} from 'dequal'
import mapValues from 'lodash.mapvalues'
import cx from 'classnames'
import http from 'lib/http'
import {getDiscount, getBasePrice, parsePlans} from 'lib/membership'
import loadingState from 'state/loading'
import {usePublication} from 'swr/publication'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import {PrimaryButton, ToggleButton} from 'components/Button'
import {FormError, FormTextarea} from 'components/Form'
import {useNotification} from 'components/Notification'
import SubscribePlansView from 'components/Subscribe/SubscribePlansView'
import Tooltip from 'components/Tooltip'
import Editor from 'components/Editor'
import {removeEdgedEmptyParagraphs} from 'components/Editor/RichTextUtils'
import PageTitle from 'components/PageTitle'
import styles from './CreatorMembershipPage.module.css'

const DEFAULT_PLANS = {
  monthly_plan: {
    price: '',
    enabled: true,
  },
  three_month_plan: {
    price: '',
    enabled: true,
  },
  six_month_plan: {
    price: '',
    enabled: true,
  },
  twelve_month_plan: {
    price: '',
    enabled: true,
  },
}

function CreatorMembershipPage({publication}) {
  const history = useHistory()

  const {membership} = publication
  const {mutate} = usePublication(publication.token)

  const [plans, setPlans] = useState(parsePlans(membership?.plans))
  const [plansError, setPlansError] = useState(null)
  const [summary, setSummary] = useState(membership?.summary || '')
  const [summaryError, setSummaryError] = useState(null)
  const [description, setDescription] = useState(
    getIntialDescription(membership)
  )
  // - [ ] 已开通后访问时为修改计划，能够正常显示已填写的字段，编辑时不会因为请求更新而重置
  useEffect(() => {
    if (membership) {
      setPlans(parsePlans(membership.plans))
      setSummary(membership.summary)
    }
  }, [membership])

  // - [ ] 设置价格开关、修改价格、修改简介后，跳转/关闭页面前会有确认
  // - [ ] 修改后如果值没有改变，则不会有提示
  // - [ ] 确认 & 跳转后，再跳转/关闭页面不会再有确认
  // - [ ] 开通 & 修改时逻辑正确
  const [prompt, setPrompt] = useState(false)
  useEffect(() => {
    function handleUnload(event) {
      event.preventDefault()
      event.returnValue = ''
    }

    if (!membership && (summary.length > 0 || !dequal(DEFAULT_PLANS, plans))) {
      setPrompt(true)
      window.addEventListener('beforeunload', handleUnload)
      return () => window.removeEventListener('beforeunload', handleUnload)
    }

    if (
      membership &&
      (membership.summary !== summary ||
        !dequal(parsePlans(membership.plans), plans))
    ) {
      setPrompt(true)
      window.addEventListener('beforeunload', handleUnload)
      return () => window.removeEventListener('beforeunload', handleUnload)
    }

    setPrompt(false)
  }, [membership, plans, summary])

  const noti = useNotification()
  const setLoading = useSetRecoilState(loadingState)

  let hasPlansError = false
  for (const type of Object.keys(plansError || {})) {
    // - [ ] 提示错误后禁用该周期后可以正常提交
    if (plansError[type] && plans[type].enabled) {
      hasPlansError = true
    }
  }
  const disabled = hasPlansError || Boolean(summaryError)

  function handleSummaryChange(e) {
    setSummaryError(null)
    setSummary(e.target.value)

    if (e.target.value.length > 150) {
      setSummaryError({message: `已超出 ${e.target.value.length - 150} 字`})
    }
  }

  function handleSubmit() {
    let requiredPrice = false
    for (const [type, plan] of Object.entries(plans)) {
      if (plan.enabled && plan.price === '') {
        setPlansError((error) => ({...error, [type]: {message: '需设置价格'}}))
        requiredPrice = true
      }
    }

    if (requiredPrice) {
      noti('需设置价格', {warning: true})
      return
    }

    if (summary === '') {
      setSummaryError({message: '需填写简介'})
      noti('需填写简介', {warning: true})
      return
    }

    setLoading(true)
    http(`/publications/${publication.id}/membership`, {
      method: Boolean(membership) ? 'PUT' : 'POST',
      body: {
        ...mapValues(plans, (plan) => ({
          enabled: plan.enabled,
          // - [ ] 禁用后即便价格不合法也能够正常提交，不会报错
          // - [ ] 19.9 的价格可以正常设置
          price:
            plan.price === '' || !plan.enabled
              ? null
              : Math.round(plan.price * 100),
        })),
        summary,
        description: JSON.stringify(removeEdgedEmptyParagraphs(description)),
        freeSubscriptionEnabled: true,
      },
    })
      .then(
        () => {
          noti(`${membership ? '修改' : '开通'}成功`)
          mutate()
          setPrompt(false)
          history.push('/creator/revenue')
        },
        (error) => {
          const validationErrors = error?.body?.validationErrors
          if (validationErrors) {
            setPlansError({
              ...plansError,
              monthly_plan: validationErrors.monthlyPlan?.['price'][0],
              three_month_plan: validationErrors.threeMonthPlan?.['price'][0],
              six_month_plan: validationErrors.sixMonthPlan?.['price'][0],
              twelve_month_plan: validationErrors.twelveMonthPlan?.['price'][0],
            })

            setSummaryError(validationErrors.summary?.[0])
          }

          noti(error?.body?.message || error?.message || '保存失败', {
            warning: true,
          })
        }
      )
      .finally(() => setLoading(false))
  }

  return (
    <Layout.Page>
      <PageTitle title="我的会员计划" />
      <Prompt when={prompt} message="有未保存的修改，确认是否要离开？" />
      <GlobalTopNav publication={publication} />
      <Layout.PageContent className={styles.page}>
        <div className={styles.view}>
          <h1 className={styles.title}>
            {membership ? '修改' : '开通'}会员计划
          </h1>
          <h2 className={styles.subtitle}>
            合理的价格和完善的介绍可以帮助获得更多付费会员
          </h2>
          <Section>
            <SectionTitle>订阅价格:</SectionTitle>
            <SectionDescription>
              提供适当的折扣可以吸引更多付费会员（折扣基于最短周期价格计算）
            </SectionDescription>
            <PriceField
              type="monthly_plan"
              plans={plans}
              setPlans={setPlans}
              plansError={plansError}
              setPlansError={setPlansError}
            />
            <PriceField
              type="three_month_plan"
              plans={plans}
              setPlans={setPlans}
              plansError={plansError}
              setPlansError={setPlansError}
            />
            <PriceField
              type="six_month_plan"
              plans={plans}
              setPlans={setPlans}
              plansError={plansError}
              setPlansError={setPlansError}
            />
            <PriceField
              type="twelve_month_plan"
              plans={plans}
              setPlans={setPlans}
              plansError={plansError}
              setPlansError={setPlansError}
            />
          </Section>
          <Section style={{marginBottom: '-40px'}}>
            <SectionTitle>会员计划简介：</SectionTitle>
            <SectionDescription>
              言简意赅的会员权益简介可以更好地提升付费转化率，读者订阅时会能够直接看到简介，最多
              150 字。
            </SectionDescription>
            <FormTextarea
              className={styles.summary}
              rows={5}
              value={summary}
              onChange={handleSummaryChange}
              error={Boolean(summaryError)}
              placeholder="例如，付费后可享受如下权益：&#10;1. 除免费内容外，每周接收一篇专属内容；&#10;2. 加入付费用户微信群。"
            />
            <FormError
              error={summaryError}
              placeholder={true}
              style={{textAlign: 'left'}}
            />
          </Section>
          <Section>
            <SectionTitle>会员计划详情（可选）：</SectionTitle>
            <SectionDescription>
              更详细的会员计划介绍描述等，该内容将会以链接形式与简介同时展示。
            </SectionDescription>
            <div className={styles.editorRoot}>
              <Editor
                className={styles.editor}
                toolbarClassName={styles.toolbar}
                placeholderStyles={{opacity: 0.4}}
                placeholder="填写需要付费会员知道的任何内容，包括概要的扩展、付费内容的创作方向、已有的订阅者群体等能够提升吸引力的内容。"
                value={description}
                setValue={setDescription}
                enablePaywall={false}
              />
            </div>
          </Section>
        </div>
        <div className={styles.preview}>
          <div className={styles.previewText}>预览</div>
          <SubscribePlansView
            publication={publication}
            className={styles.previewView}
            membership={{
              plans,
              summary,
              description: JSON.stringify(description),
            }}
            isPreview={true}
          />
        </div>
        <div className={styles.view}>
          <div>
            <PrimaryButton
              className={styles.submit}
              onClick={handleSubmit}
              disabled={disabled}
            >
              {membership ? '保存' : '开启会员计划'}
            </PrimaryButton>
          </div>
          <div className={styles.tip}>
            <div className={styles.tipTitle}>注意事项</div>
            <div>1. 会员计划可随时修改，仅影响新付费的会员和续费的会员</div>
            <div>2. 订阅到期时，付费会员将会在订阅渠道收到续费提醒</div>
            <div>3. 所有收入将会在付费后 24 小时内直接转入银行账户</div>
          </div>
        </div>
      </Layout.PageContent>
    </Layout.Page>
  )
}

function Section({children, ...props}) {
  return (
    <div {...props} className={styles.section}>
      {children}
    </div>
  )
}

function SectionTitle({children}) {
  return <div className={styles.sectionTitle}>{children}</div>
}

function SectionDescription({children}) {
  return <div className={styles.sectionDescription}>{children}</div>
}

// - [ ] 所有订阅周期默认开启，价格为空，不显示折扣
// - [ ] 服务端价格单位为分，展示 & 编辑 & 提交的价格正确
// - [ ] 编辑时前端校验：合法数字、最多保留两位小数、价格大于 0，输入时清空错误，价格为空时不显示错误
// - [ ] 能够正常设置开关，关闭时禁止编辑不显示错误信息。关闭时保留价格但会提交为 null
// - [ ] 根据基础价格计算折扣，最多 1 位小数，没有价格或没有折扣不显示
// - [ ] 价格高于最短周期的价格时提示错误（仍然可能高于非最短的周期，暂不处理）
// - [ ] 不同宽度下样式正常（包括错误信息）
function PriceField({type, plans, setPlans, plansError, setPlansError}) {
  const plan = plans[type]
  const {price, enabled} = plan

  const [basePrice] = getBasePrice(plans)
  const monthCount = getPriceMonthCount(type)
  const discount = getDiscount(type, plans)

  const error = plansError?.[type]

  useEffect(() => {
    if (!enabled) {
      return
    }

    const priceNumber = Number(price)

    // - [ ] 能同时校验处多个价格错误，例如，月度价格设为 1，关闭月度价格，设置其他价格高于 1/月，再开启月度价格，会提示多个错误
    // - [ ] 避免因为精度问题误报，例如，最短周期价格为半年 59.99 不会报「高于最短周期价格」
    if (Number.isNaN(priceNumber)) {
      setPlansError((error) => ({...error, [type]: {message: '非法数字'}}))
    } else if (priceNumber <= 0 && price !== '') {
      setPlansError((error) => ({...error, [type]: {message: '需大于 0'}}))
    } else if (priceNumber > round(basePrice * monthCount, 2)) {
      setPlansError((error) => ({
        ...error,
        [type]: {message: '高于最短周期价格'},
      }))
    } else {
      setPlansError((error) => ({...error, [type]: null}))
    }
  }, [price, basePrice, monthCount, enabled, setPlansError, type])

  function handlePriceChange(e) {
    const price = e.target.value

    setPlans({
      ...plans,
      [type]: {...plan, price},
    })
  }

  function handleToggle() {
    setPlans({
      ...plans,
      [type]: {...plan, enabled: !enabled},
    })
  }

  return (
    <div className={cx(styles.priceField, {[styles.isDisabled]: !enabled})}>
      <div className={styles.priceFieldLeft}>
        <div className={styles.priceFieldName}>{getPriceName(type)}: </div>
        <input
          className={cx(styles.priceFieldInput, {
            [styles.isError]: Boolean(error),
          })}
          value={price}
          onChange={handlePriceChange}
          disabled={!enabled}
        />
        {discount > 0 && discount < 10 && !error && (
          <div className={styles.priceFieldDiscount}>≈ {discount} 折</div>
        )}
        {error && enabled && (
          <div className={styles.priceFieldError}>{error.message || error}</div>
        )}
      </div>
      <Tooltip tip={enabled ? '禁用该订阅周期' : '启用该订阅周期'}>
        <ToggleButton value={enabled} onClick={handleToggle} />
      </Tooltip>
    </div>
  )
}

function getPriceName(type) {
  return {
    monthly_plan: '月度价格',
    three_month_plan: '季度价格',
    six_month_plan: '半年价格',
    twelve_month_plan: '年度价格',
  }[type]
}

function getPriceMonthCount(type) {
  return {
    monthly_plan: 1,
    three_month_plan: 3,
    six_month_plan: 6,
    twelve_month_plan: 12,
  }[type]
}

function getIntialDescription(membership) {
  let description = [{type: 'paragraph', children: [{text: ''}]}]

  try {
    description = JSON.parse(membership?.description)
  } catch (e) {}

  return description
}

export default CreatorMembershipPage
