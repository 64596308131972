/**
 * 登录弹窗：
 * - [ ] 发送验证码非空错误、邮箱校验错误、网络请求错误、服务端错误显示正常。
 * - [ ] 发送验证码后倒计时 60s 才能重发（前端限制）。
 * - [ ] 登录加载状态、非空错误、网络请求错误、服务端错误显示正常。
 * - [ ] 登录成功后 reload 当前页面，指定 nextQuery 时（选择会员计划）调转后 query 正确
 * - [ ] 当页面有 `experiment=1` 参数时， 通过选择订阅弹窗、付费墙卡片的「已订阅」入口登录时支持邮箱登录/注册。
 * - [ ] 支持邮箱登录时，发送验证码非空错误、邮箱/手机号校验错误、网络请求错误、服务端错误显示正常。
 * - [ ] 支持邮箱登录时，登录请求非空错误、邮箱/手机号校验错误、验证码校验错误、网络请求错误、服务端错误显示正常。
 */
import React, {useEffect, useState} from 'react'
import {atom, useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {getURLWithExtraQuery, useQuery} from 'lib/url'
import loadingState from 'state/loading'
import useCountdown from 'hooks/useCountdown'
import Form, {
  FormTitle,
  FormInput,
  FormSubmit,
  FormError,
} from 'components/Form'
import {DefaultButton} from 'components/Button'
import {ModalView} from 'components/Modal'
import styles from './LoginModal.module.css'

const loginModalState = atom({
  key: 'showLoginModal',
  default: {
    visible: false,
    title: null,
    subtitle: null,
    nextQuery: null,
    enableEmailLogin: false,
  },
})

// - [ ] 先点击会员计划打开登录弹窗，关闭后再点击已订阅打开登录弹窗 title, subtitle, nextQuery 正确
export function useLoginModal() {
  const setLoginModalState = useSetRecoilState(loginModalState)
  return (options) =>
    setLoginModalState({
      visible: true,
      ...options,
    })
}

// - [ ] 关闭登录弹窗后再次打开内部状态能够被正确重置，例如输入的邮箱
// - [ ] 当有 login=2 为 query 时，自动显示登录弹窗，文案正确
function LoginModal() {
  const [{visible}, setLoginModalState] = useRecoilState(loginModalState)

  const loginQuery = useQuery('login', {clean: true})

  useEffect(() => {
    if (loginQuery === '2') {
      setLoginModalState({
        visible: true,
        title: '登录',
        subtitle: '使用已订阅的手机号或邮箱登录',
        enableEmailLogin: true,
      })
    }
  }, [loginQuery, setLoginModalState])

  if (!visible) {
    return null
  }

  return (
    <LoginModalView setVisible={(visible) => setLoginModalState({visible})} />
  )
}

function LoginModalView({setVisible}) {
  const {nextQuery, title, subtitle, enableEmailLogin} =
    useRecoilValue(loginModalState)

  const [cellphone, setCellphone] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [error, setError] = useState(null)
  const [countdown, setCountdown] = useState(0)
  const setLoading = useSetRecoilState(loadingState)

  const phoneInputRef = React.createRef()
  const verificationCodeInputRef = React.createRef()

  useEffect(() => {
    setCountdown(0)
  }, [cellphone])

  function handleSendverificationCode() {
    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    setError(null)
    setCountdown(Date.now() + 60 * 1000)
    sendVerificationCode({input: cellphone, enableEmailLogin}).catch(
      (error) => {
        setError({
          cellphone:
            error.body?.validationErrors?.cellphone?.[0] ||
            error.body?.validationErrors?.email?.[0],
          message:
            !error.body?.validationErrors &&
            (error.body?.message || '发送验证码失败'),
        })
        setCountdown(0)
      }
    )
  }

  function handlePhoneInputChange(e) {
    setCellphone(e.target.value)
    setError(null)
  }

  function handleVerificationCodeInputChange(e) {
    setVerificationCode(e.target.value)
    setError(null)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    if (verificationCode.length === 0) {
      verificationCodeInputRef.current.focus()
      setError({verificationCode: true})
      return
    }

    setLoading(true)
    setError(null)
    requestLogin({input: cellphone, verificationCode, enableEmailLogin}).then(
      () => {
        if (!nextQuery) {
          window.location.reload()
          return
        }

        window.location.href = getURLWithExtraQuery({
          url: window.location.href,
          extraQuery: nextQuery,
        })
      },
      (error) => {
        setError({
          cellphone:
            error.body?.validationErrors?.cellphone?.[0] ||
            error.body?.validationErrors?.email?.[0],
          verificationCode: error.body?.validationErrors?.verificationCode?.[0],
          message:
            !error.body?.validationErrors &&
            (error.body?.message || '登录失败'),
        })
        setLoading(false)
      }
    )
  }

  let placeholder
  if (enableEmailLogin) {
    placeholder = error?.cellphone ? '输入手机号/邮箱' : '手机号/邮箱'
  } else {
    placeholder = error?.cellphone ? '输入手机号' : '手机号'
  }

  return (
    <ModalView setVisible={setVisible} hasCloseButton={true}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <FormTitle title={title} subtitle={subtitle} />
        <FormInput
          rootClass={styles.phoneInputRoot}
          className={styles.phoneInput}
          placeholder={placeholder}
          type={enableEmailLogin ? 'text' : 'tel'}
          ref={phoneInputRef}
          value={cellphone}
          error={error?.cellphone}
          name="cellphone"
          onChange={handlePhoneInputChange}
          style={{paddingLeft: enableEmailLogin ? '' : '66px'}}
          renderRight={() =>
            !enableEmailLogin && (
              <div
                className={styles.areaCode}
                onClick={() =>
                  window.alert('暂时仅支持中国手机号，如有需求请联系我们')
                }
              >
                +86
              </div>
            )
          }
        />
        <FormInput
          placeholder={error?.verificationCode ? '输入验证码' : '验证码'}
          type="tel"
          ref={verificationCodeInputRef}
          value={verificationCode}
          name="verification_code"
          onChange={handleVerificationCodeInputChange}
          error={error?.verificationCode}
          autoComplete="off"
          renderRight={() => (
            <VerificationCodeButton
              handleClick={handleSendverificationCode}
              countdown={countdown}
            />
          )}
        />
        <FormSubmit>下一步</FormSubmit>
        <FormError error={error} placeholder />
      </Form>
    </ModalView>
  )
}

function VerificationCodeButton({handleClick, countdown}) {
  const {done, seconds} = useCountdown(countdown)

  return (
    <DefaultButton
      className={styles.verificationCodeButton}
      onClick={done ? handleClick : () => {}}
      type="button"
      disabled={!done}
    >
      {done ? '发送验证码' : `已发送 (${seconds})`}
    </DefaultButton>
  )
}

function sendVerificationCode({input, enableEmailLogin}) {
  if (enableEmailLogin && input.includes('@')) {
    return http('/auth/email_verification_code', {
      method: 'POST',
      body: {email: input},
    })
  }

  return http('/auth/verification_code', {
    method: 'POST',
    body: {cellphone: input},
  })
}

function requestLogin({input, verificationCode, enableEmailLogin}) {
  if (enableEmailLogin && input.includes('@')) {
    return http('/auth/email_login', {
      method: 'POST',
      body: {email: input, verificationCode},
    })
  }

  return http('/auth/login', {
    method: 'POST',
    body: {cellphone: input, verificationCode},
  })
}

export default LoginModal
