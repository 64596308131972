const {useEffect} = require('react')

// - [ ] 可正常自定义页面标题
// - [ ] 从有有自定义标题的页面跳转到没有自定义标题的页面后会恢复为「竹白」
function PageTitle({title}) {
  useEffect(() => {
    if (title?.length > 0) {
      setTitle(title)

      return () => setTitle('竹白')
    }
  }, [title])

  return null
}

export function usePageTitle(title) {
  useEffect(() => {
    if (title?.length > 0) {
      setTitle(title)

      return () => setTitle('竹白')
    }
  }, [title])
}

function setTitle(title) {
  window.document.title = title

  const ogMeta = document.querySelector('meta[property="og:title"]')
  ogMeta?.setAttribute('content', title)
}

export default PageTitle
