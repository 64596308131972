import useSWR from 'swr'

export function useSelf() {
  const {data, error, ...rest} = useSWR('/self')

  return {
    ...rest,
    error,
    self: data,
    loading: !error && !data,
  }
}

export function useSelfDrafts() {
  const {data, error, ...rest} = useSWR('/self/drafts')

  return {
    ...rest,
    error,
    data,
    drafts: data?.data,
    loading: !error && !data,
  }
}

export function useSelfMembershipApplication() {
  const {data, ...rest} = useSWR('/self/membership_application', {
    shouldRetryOnError: false,
  })

  return {
    ...rest,
    application: data,
  }
}

export function useSelfMessagesUnreadStatus() {
  const {data, ...rest} = useSWR('/conversation_unread_badge', {
    errorRetryCount: 3,
    refreshInterval: 10000,
  })

  return {
    ...rest,
    status: data,
  }
}

export function useSelfMessagesSetting() {
  const {data, ...rest} = useSWR('/settings/message_notification')

  return {
    ...rest,
    setting: data,
    data,
  }
}
