/**
 * 添加订阅者：
 * - [ ] 未开通会员计划的专栏可正常添加
 * - [ ] 开通会员计划的专栏添加时需设置会员类型和过期时间，可正常添加
 * - [ ] Loading、非空错误逻辑正确
 * - [ ] Email、expires 校验错误，服务错误、网络错误可正常显示
 * - [ ] 成功后弹窗自动消失，并出现顶部 Notification 提示添加成功并执行 onSucess（更新订阅者列表）
 */

import {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {notificationState} from 'components/Notification'
import {ModalDialog} from 'components/Modal'
import {FormInput, FormError, FormSubmit, Input} from 'components/Form'
import Spinner from 'components/Spinner'
import {DefaultButton} from 'components/Button'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import styles from './CreatorAddSubsciberModal.module.css'

function CreatorAddSubsciberModal({self, setVisible, onSuccess, publication}) {
  const hasMembership = Boolean(publication.membership)
  const [email, setEmail] = useState('')
  const [type, setType] = useState('free')
  const [expires, setExpires] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showDropdown, setShowDropdown] = useState(false)

  const setNoti = useSetRecoilState(notificationState)

  function handleEmailInputChange(event) {
    setError(null)
    setEmail(event.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (email.length === 0) {
      setError({email: true})
      return
    }

    if (type === 'paid' && expires.length === 0) {
      setError({expires: true})
      return
    }

    if (type === 'paid' && !Number.isInteger(Number(expires))) {
      setError({expires: true, message: '有效天数需为大于 0 的整数'})
      return
    }

    const body = hasMembership
      ? {
          email,
          type,
          expiredAt:
            type === 'paid'
              ? Date.now() + Number(expires) * 24 * 3600 * 1000
              : null,
        }
      : {email}

    setLoading(true)
    http(
      `/publications/${self.publication.id}/subscription_confirmation_email`,
      {
        method: 'POST',
        body,
      }
    ).then(
      () => {
        setVisible(false)
        setNoti({
          visible: true,
          text: '已发送确认邮件，确认后即可成功订阅',
        })
        onSuccess && onSuccess()
      },
      (error) => {
        if (error.body?.validationErrors?.email) {
          setError({email: true, message: error.body.validationErrors.email[0]})
        } else if (error.body?.validationErrors?.expires) {
          setError({
            expires: true,
            message: error.body.validationErrors.expires[0],
          })
        } else {
          setError({
            message: error.body?.message || error.message || '添加失败',
          })
        }

        setLoading(false)
      }
    )
  }

  return (
    <ModalDialog setVisible={setVisible}>
      <form className={styles.dialog} onSubmit={handleSubmit}>
        <h1 className={styles.title}>
          添加{hasMembership ? '会员' : '订阅者'}
        </h1>
        <div className={styles.description}>
          添加后对方会收到确认邮件，确认后即可成功订阅
        </div>
        <FormInput
          className={styles.input}
          type="text"
          placeholder={Boolean(error?.email) ? '输入邮箱' : '邮箱'}
          value={email}
          error={Boolean(error?.email)}
          name="email"
          onChange={handleEmailInputChange}
        />
        {hasMembership && (
          <>
            <div className={styles.typeSetting}>
              <div>会员类型：</div>
              <div className={styles.typeButtonRoot}>
                <DefaultButton
                  className={styles.typeButton}
                  type="button"
                  onClick={() => setShowDropdown(true)}
                >
                  {type === 'free' ? '免费会员' : '付费会员'}
                  <ArrowIcon className={styles.arrowIcon} />
                </DefaultButton>
                {showDropdown && (
                  <Dropdown
                    className={styles.dropdown}
                    setShowDropdown={setShowDropdown}
                    defaultPlacement="bottomleft"
                  >
                    <DropdownMenu onClick={() => setType('free')}>
                      免费会员
                    </DropdownMenu>
                    <DropdownMenu onClick={() => setType('paid')}>
                      付费会员
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            </div>
            {type === 'paid' && (
              <div className={styles.typeSetting}>
                <div>会员有效期：</div>
                <div className={styles.expires}>
                  <Input
                    className={styles.expiresInput}
                    name="expires"
                    type="tel"
                    value={expires}
                    placeholder={Boolean(error?.expires) ? '输入' : '30'}
                    isInvalid={Boolean(error?.expires)}
                    onChange={(e) => {
                      setError(null)
                      setExpires(e.target.value)
                    }}
                  />
                  天
                </div>
              </div>
            )}
          </>
        )}
        <FormSubmit className={styles.button}>添加</FormSubmit>
        <FormError error={error} placeholder />
        {loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
      </form>
    </ModalDialog>
  )
}

export default CreatorAddSubsciberModal
