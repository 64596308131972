// 草稿列表页：
// - [ ] 列表能够自动加载，加载状态、空状态、错误状态及重试正常
// - [ ] 能够正常编辑
// - [ ] 能够正常删除（加载状态、错误状态），删除后本地更新
// - [ ] 聚焦页面后列表不会自动更新，避免删除时 window.confirm 后自动更新导致数据错乱
// - [ ] 最后一个 item 的菜单能正常显示

import React, {useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import http from 'lib/http'
import {getRelativeTimeString} from 'lib/time'
import loadingState from 'state/loading'
import {DefaultButton} from 'components/Button'
import Layout from 'components/Layout'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import {notificationState} from 'components/Notification'
import InfiniteList from 'components/InfiniteList'
import PageTitle from 'components/PageTitle'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import CreatorTable, {
  CreatorTableError,
  CreatorTableLoading,
} from './CreatorTable'
import styles from './CreatorDraftsPage.module.css'

function CreatorDraftsPage() {
  return (
    <Layout.View className={styles.page}>
      <PageTitle title="我的草稿" />
      <CreatorTable rootClass={styles.table}>
        <thead>
          <tr>
            <th scope="col">草稿</th>
            <th scope="col">最后保存时间</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <InfiniteList
            api="/self/drafts"
            renderItem={({item, onDelete}) => (
              <DraftItem key={item.id} draft={item} onDelete={onDelete} />
            )}
            renderLoading={(ref) => <CreatorTableLoading ref={ref} />}
            renderEmpty={() => <EmptyItem />}
            renderError={(error, handleRetry) => (
              <CreatorTableError
                error={error}
                handleRetry={handleRetry}
                colSpan={3}
              />
            )}
            revalidateOnFocus={false} // 避免 window.confirm 后自动更新导致数据错乱
          />
        </tbody>
      </CreatorTable>
    </Layout.View>
  )
}

function DraftItem({draft, onDelete}) {
  const url = `/drafts/${draft.id}/edit`
  const [showDropdown, setShowDropdown] = useState(false)

  const history = useHistory()
  function handleEdit() {
    history.push(url)
  }

  const setNoti = useSetRecoilState(notificationState)
  const setLoading = useSetRecoilState(loadingState)
  function handleDelete() {
    if (window.confirm('确认要删除草稿？')) {
      setLoading(true)
      http(`/drafts/${draft.id}`, {method: 'DELETE'}).then(
        () => {
          setLoading(false)
          onDelete(draft)
        },
        (error) => {
          setNoti({
            visible: true,
            warning: true,
            text: error.body?.message || error.message || '删除失败，请重试',
          })
          setLoading(false)
        }
      )
    }
  }

  return (
    <tr>
      <th scope="row">
        <Link to={url}>{draft.title || '无标题'}</Link>
      </th>
      <td>{getRelativeTimeString(draft.updatedAt)}</td>
      <td>
        <div className={styles.buttonRoot}>
          <DefaultButton
            className={styles.button}
            onClick={() => setShowDropdown(true)}
          >
            操作
            <ArrowIcon className={styles.buttonIcon} />
          </DefaultButton>
          {showDropdown && (
            <Dropdown
              className={styles.dropdown}
              setShowDropdown={setShowDropdown}
            >
              <DropdownMenu onClick={handleEdit}>编辑</DropdownMenu>
              <DropdownMenu danger onClick={handleDelete}>
                删除
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </td>
    </tr>
  )
}

function EmptyItem() {
  return (
    <tr>
      <td colSpan={3}>
        <div className={styles.empty}>暂无草稿</div>
      </td>
    </tr>
  )
}

export default CreatorDraftsPage
