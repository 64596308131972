/**
 * 保证光标合理出现在可视区域之内：
 * - [ ] 编辑器的可视区域会剔除顶栏、底栏、工具栏
 * - [ ] 在文档末尾、中间换行直至超出屏幕，光标始终能保持在屏幕底部。
 * - [ ] 在文档末尾按 Backspace，光标始终能保持在屏幕底部直至显示标题 input。
 * - [ ] 在文档中间按 Backspace 支持超出屏幕，光标始终能保持在屏幕顶部（全部为空行时会有 bad case 待处理）。
 * - [ ] 点击可视的元素则不会有任何 scroll
 * - [ ] 为避免点击长段落时抖动的 bad case，点击聚焦露出一半的元素暂时页不会 scroll，从而在段落内退格或软换行则不会 scroll。
 * - [ ] 按上/下方向键直至超出屏幕，光标始终能保持在屏幕的顶部/底部（目前时系统默认行为，有一点 bad case 待处理）。
 */

export default function scrollIntoViewIfNecessary(
  element,
  scrollRoot,
  marginTop = 0
) {
  if (!scrollRoot) {
    return
  }

  const height = element.offsetHeight

  let top = 0
  do {
    top += element.offsetTop
    element = element.offsetParent
  } while (element !== scrollRoot)

  // Above view
  if (top + height <= scrollRoot.scrollTop + marginTop) {
    scrollRoot.scrollTop = top - marginTop - 20
  }

  // Below view
  if (top >= scrollRoot.scrollTop + scrollRoot.offsetHeight) {
    scrollRoot.scrollTop = top + height - scrollRoot.offsetHeight + 20
  }
}
