// -[ ] Publication 页、作品页、预览页分享按钮 & 菜单样式正常
// -[ ] 可以正常复制当前链接
// -[ ] 可以正常保存海报
// -[ ] 预览页面点击保存海报提示暂不支持
// -[ ] 可以正常加载小程序码，中间是作者头像，扫描能打开对应页面

import cx from 'classnames'
import {useState} from 'react'
import {useSetRecoilState} from 'recoil'
import {notificationState} from 'components/Notification'
import Button from 'components/Button'
import Dropdown, {DropdownMenu} from 'components/Dropdown'
import WechatQRCode from 'components/WechatQRCode'
import {ReactComponent as LinkIcon} from 'icons/share-link.svg'
import {ReactComponent as TimelineIcon} from 'icons/share-timeline.svg'
import {ReactComponent as WechatIcon} from 'icons/share-wechat.svg'
import styles from './ShareButton.module.css'

function ShareButton({
  children,
  className,
  buttonClass,
  scene,
  page,
  poster,
  publication,
  disableSavePoster,
  hidePoster,
  dropdownPlacement = 'bottomLeft',
}) {
  const [showDropdown, setShowDropdown] = useState(false)

  const setNoti = useSetRecoilState(notificationState)
  function handleCopy() {
    window.navigator.clipboard.writeText(window.location.href).then(
      () =>
        setNoti({
          visible: true,
          text: '复制成功',
        }),
      () =>
        setNoti({
          visible: true,
          warning: true,
          text: '复制失败',
        })
    )
  }

  function hanldeSavePoster() {
    if (disableSavePoster) {
      setNoti({
        visible: true,
        warning: true,
        text: disableSavePoster,
      })
    }
  }

  return (
    <div className={cx(styles.root, className)}>
      <Button className={buttonClass} onClick={() => setShowDropdown(true)}>
        {children}
      </Button>
      {showDropdown && (
        <Dropdown
          className={styles.dropdown}
          defaultPlacement={dropdownPlacement}
          setShowDropdown={setShowDropdown}
        >
          <DropdownMenu className={styles.menu} onClick={handleCopy}>
            <LinkIcon className={styles.menuIcon} />
            复制链接
          </DropdownMenu>
          {!hidePoster && (
            <DropdownMenu
              className={styles.menu}
              href={!disableSavePoster && poster}
              onClick={hanldeSavePoster}
              download="poster.jpg"
            >
              <TimelineIcon className={styles.menuIcon} />
              保存海报
            </DropdownMenu>
          )}
          <DropdownMenu className={cx(styles.menu, styles.wechat)} disabled>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <WechatIcon className={styles.menuIcon} />
              微信扫码
            </div>
            <WechatQRCode
              className={styles.qrcode}
              width={200}
              page={page}
              scene={scene}
              publication={publication}
            />
          </DropdownMenu>
        </Dropdown>
      )}
    </div>
  )
}

export default ShareButton
