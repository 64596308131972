/**
 * - [ ] 跟根据上个页面返回不同的地址，作品列表页、草稿页、作品详情页，默认是作品列表页
 */
import {useLocation} from 'react-router-dom'
import {getLocationHistory} from 'lib/locationHistory'
import {LinkButton} from 'components/Button'
import {ReactComponent as BackIcon} from 'icons/arrow.svg'
import styles from './WritePage.module.css'

function WritePageBackLink() {
  let url
  let text

  const location = useLocation()
  const history = getLocationHistory()
  const referrer = history[history.length - 2]

  if (location.pathname.startsWith('/posts')) {
    url = location.pathname.replace('/edit', '')
    text = '返回作品页'
  } else if (referrer && referrer.startsWith('/creator')) {
    url = referrer
    text = '返回创作中心'
  } else {
    url = '/creator'
    text = '返回创作中心'
  }

  return (
    <LinkButton to={url} className={styles.back}>
      <BackIcon className={styles.backIcon} />
      {text}
    </LinkButton>
  )
}

export default WritePageBackLink
