export function getTimeString(timestamp) {
  const time = new Date(timestamp)
  const hourString = time.getHours().toString().padStart(2, '0')
  const minutesString = time.getMinutes().toString().padStart(2, '0')
  return `${hourString}:${minutesString}`
}

export function getRelativeTimeString(timestamp, {precise} = {precise: false}) {
  const time = new Date(timestamp)

  const yearString = time.getFullYear()
  const monthString = (time.getMonth() + 1).toString().padStart(2, '0')
  const dateString = time.getDate().toString().padStart(2, '0')
  const hourString = time.getHours().toString().padStart(2, '0')
  const minutesString = time.getMinutes().toString().padStart(2, '0')

  const now = new Date()

  if (now - time <= 60 * 1000) {
    return '刚刚'
  } else if (now - time <= 3600 * 1000) {
    return `${Math.floor((now - time) / 60 / 1000)} 分钟前`
  } else if (now - time <= 24 * 3600 * 1000) {
    return `${Math.floor((now - time) / 3600 / 1000)} 小时前`
  } else if (isYesterday(timestamp, now)) {
    return precise ? `昨天 ${hourString}:${minutesString}` : '昨天'
  } else if (isSameYear(timestamp, now)) {
    return precise
      ? `${monthString}-${dateString} ${hourString}:${minutesString}`
      : `${monthString}-${dateString}`
  } else {
    return `${yearString}-${monthString}-${dateString}`
  }
}

export function isYesterday(timestamp) {
  const time = new Date(timestamp)
  const now = new Date()
  now.setDate(now.getDate() - 1)
  return now.toDateString() === time.toDateString()
}

export function isSameDay(timestampA, timestampB) {
  const timeA = new Date(timestampA)
  const timeB = new Date(timestampB)
  return timeA.toDateString() === timeB.toDateString()
}

export function isSameYear(timestampA, timestampB) {
  const timeA = new Date(timestampA)
  const timeB = new Date(timestampB)
  return timeA.getFullYear() === timeB.getFullYear()
}

export function getDateString(timestamp) {
  const time = new Date(timestamp)

  const yearString = time.getFullYear()
  const monthString = (time.getMonth() + 1).toString().padStart(2, '0')
  const dateString = time.getDate().toString().padStart(2, '0')

  return `${yearString}-${monthString}-${dateString}`
}
