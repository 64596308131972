/**
 * 自定义 iOS Safair 顶部导航栏颜色
 */

import {useEffect} from 'react'
function useSafariStatusBarColor(color) {
  useEffect(() => {
    if (!color) {
      return
    }

    const meta = document.querySelector('meta[name="theme-color"]')
    meta?.setAttribute('content', color)
    return () => meta?.setAttribute('content', '#ffffff')
  }, [color])
}

export default useSafariStatusBarColor
