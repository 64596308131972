/**
 * 顶级页面路由：
 * - [ ] 普通页面的各个页面可正常访问，404 页面正常
 * - [ ] Newsletter 的各个页面可正常访问，404 页面正常
 */

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import getPublicationToken from 'lib/getPublicationToken'
import {useLoactionHistory} from 'lib/locationHistory'
import HomePage from 'pages/HomePage'
import LoginPage from 'pages/LoginPage'
import SelfProfilePage from 'pages/SelfProfilePage'
import ProfilePage from 'pages/ProfilePage'
import AuthProfilePage from 'pages/AuthProfilePage'
import CreatorPage from 'pages/CreatorPage'
import PublicationCreatePage from 'pages/PublicationCreatePage'
import PublicationPage from 'pages/PublicationPage'
import NotFoundPage from 'pages/NotFoundPage'
import WritePage from 'pages/WritePage'
import PostPage from 'pages/PostPage'
import PostEditPage from 'pages/PostEditPage'
import DraftEditPage from 'pages/DraftEditPage'
import UnsubscribeNewsletterPage from 'pages/UnsubscribeNewsletterPage'
import SettingsPage from 'pages/SettingsPage'
import EmailBindingPage from 'pages/EmailBindingPage'
import EmailSubscriptionPage from 'pages/EmailSubscriptionPage'
import ContactPage from 'pages/ContactPage'
import PaymentPage from 'pages/PaymentPage'
import MembershipDescriptionPage from 'pages/MembershipDescriptionPage'
import AuthPhoneBindingPage from 'pages/AuthPhoneBindingPage'
import WechatReceiptPage from 'pages/WechatReceiptPage'
import MigratePage from 'pages/MigratePage'
import MessagesPage from 'pages/MessagesPage'

function Routes() {
  const token = getPublicationToken()
  const useDefaultRoutes = !token

  return (
    <Router>
      {useDefaultRoutes ? <DefautRoutes /> : <NewsletterRoutes />}
    </Router>
  )
}

function DefautRoutes() {
  useLoactionHistory()
  return (
    <Switch>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route path="/auth/login">
        <LoginPage />
      </Route>
      <Route path="/auth/profile">
        <AuthProfilePage />
      </Route>
      <Route path="/auth/bind-email">
        <EmailBindingPage />
      </Route>
      <Route path="/auth/bind-phone">
        <AuthPhoneBindingPage />
      </Route>
      <Route path="/users/self">
        <SelfProfilePage />
      </Route>
      <Route path="/users/:userId">
        <ProfilePage />
      </Route>
      <Route path="/create-publication">
        <PublicationCreatePage />
      </Route>
      <Route path="/create-newsletter">
        <PublicationCreatePage />
      </Route>
      <Route path="/creator">
        <CreatorPage />
      </Route>
      <Route path="/write">
        <WritePage />
      </Route>
      <Route path="/drafts/:draftId/edit">
        <DraftEditPage />
      </Route>
      <Route path="/settings">
        <SettingsPage />
      </Route>
      <Route path="/contact">
        <ContactPage />
      </Route>
      <Route path="/pay/orders/:orderId">
        <PaymentPage />
      </Route>
      <Route path="/pay/wechat-custom-receipt/:publicationToken">
        <WechatReceiptPage />
      </Route>
      <Route path="/messages/:conversationId">
        <MessagesPage />
      </Route>
      <Route path="/messages">
        <MessagesPage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

function NewsletterRoutes() {
  useLoactionHistory()
  return (
    <Switch>
      <Route path="/" exact>
        <PublicationPage />
      </Route>
      <Route path="/email-subscribe" exact>
        <EmailSubscriptionPage />
      </Route>
      <Route path="/unsubscribe" exact>
        <UnsubscribeNewsletterPage />
      </Route>
      <Route path="/posts/:postId/edit">
        <PostEditPage />
      </Route>
      <Route path="/posts/:postId">
        <PostPage />
      </Route>
      <Route path="/drafts/:draftId/preview">
        <PostPage />
      </Route>
      <Route path="/membership">
        <MembershipDescriptionPage />
      </Route>
      <Route path="/migrate/:token">
        <MigratePage />
      </Route>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  )
}

export default Routes
