// - [ ] 非微信内访问时引导复制链接在微信内打开
// - [ ] 若没有 openid cookie 则自动跳转静默授权后 openid coookie 正确
// - [ ] 静默授权后能正常跳回到原来的地址，query 正确（原有 query & wechat_auth)
// - [ ] 静默授权回调后仍然无法获取 openid cookie（有 wechat_auth=1 query），则不再自动跳转授权
// - [ ] 微信内访问能成功创建微信订单并成功支付，支付中 & 支付成功页面样式正确，若有 next 参数则自动跳转。
// - [ ] 创建订单错误、JS SDK 初始化失败、支付参数错误等均正常显示支付错误页面。
// - [ ] 关闭微信弹窗后可点击重试按钮继续支付
// - [ ] 扫码支付成功原页面能正常更新支付状态
// - [ ] 微信内支付成功后会关闭页面，再次打开原页面支付状态正确（例如成功订阅）
// - [ ] Mobile Web 端非微信内复制链接支付成功后会关闭页面，返回浏览器端后支付状态正确，支付页面会自动跳转回原页面

import Cookie from 'js-cookie'
import queryString from 'query-string'
import QRCode from 'qrcode.react'
import {useCallback, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {parseUA} from 'lib/userAgent'
import wechat from 'lib/wechat'
import http from 'lib/http'
import {useOrder, usePayment} from 'swr/order'
import useInterval from 'hooks/useInterval'
import Layout from 'components/Layout'
import {PrimaryButton} from 'components/Button'
import {useNotification} from 'components/Notification'
import styles from './PaymentPage.module.css'

const userAgent = parseUA(window.navigator.userAgent)

function PaymentPage() {
  const openid = Cookie.get('openid')

  const {orderId} = useParams()
  const [paymentId, setPaymentId] = useState()
  const {order, mutate: mutateOrder} = useOrder(orderId)
  const {payment, mutate: mutatePayment} = usePayment(orderId, paymentId)
  const isSuccess = order?.status === 'purchased'

  const location = useLocation()
  const query = queryString.parse(location.search)

  useWechatAuth()

  const [error, setError] = useState(null)
  const createPayment = useCallback(() => {
    setError(null)
    http(`/orders/${orderId}/payments`, {
      method: 'POST',
      body: {
        openid,
        orderCreatedPlatform: query.platform,
      },
    })
      .then((payment) => {
        setPaymentId(payment.id)

        // - [ ] 支付成功后请求获取 payment 状态
        // - [ ] 支付失败（SDK 初始化失败）后页面正常显示失败状态
        return wechatPay({
          payment,
          handleSuccess: () => mutatePayment(),
          handleFail: () => setError(true),
        })
      })
      .catch((error) =>
        setError({
          message: error?.body?.message || error?.message || '创建订单失败',
        })
      )
  }, [orderId, openid, mutatePayment, query.platform])

  useEffect(() => {
    if (userAgent.Wechat && openid) {
      createPayment()
    }
  }, [createPayment, openid])

  useInterval(() => {
    mutateOrder()
    mutatePayment() // 用于触发服务端向微信查询订单状态
  }, 2000)

  useEffect(() => {
    if (isSuccess && query.next) {
      window.location.href = query.next
    }
  }, [isSuccess, query])

  const noti = useNotification()
  const search = queryString.stringify({
    ...query,
    next: undefined,
    wechat: 0,
  })
  const copyURL = `${window.location.origin}${location.pathname}?${search}`
  function handleCopy() {
    window.navigator.clipboard.writeText(copyURL).then(
      () => noti('复制成功'),
      () => noti('复制失败', {warning: true})
    )
  }

  function handleRetry() {
    wechatPay({
      payment,
      handleSuccess: () => mutatePayment(),
      handleFail: () => setError(true),
    })
  }

  const [qrcodeURL, setQRCodeURL] = useState('')
  useEffect(() => {
    const canvas = window.document.querySelector('#qrcode-canvas')

    if (!canvas) {
      return
    }

    const dataUrl = canvas.toDataURL()
    setQRCodeURL(dataUrl)
  }, [])

  if (!userAgent.Wechat) {
    return (
      <Layout.View slim>
        <div className={styles.icon} style={{marginTop: '30px'}}>
          🐧
        </div>
        <div className={styles.title}>复制链接在微信内打开并支付</div>
        <div className={styles.url}>{copyURL}</div>
        <PrimaryButton className={styles.button} onClick={handleCopy}>
          点击复制
        </PrimaryButton>
        <div className={styles.divider}>
          <div className={styles.dividerText}>或</div>
        </div>
        <div className={styles.title}>长按保存后在微信内扫码支付</div>
        <img
          className={styles.qrcode}
          width="250px"
          height="250px"
          src={qrcodeURL}
          alt="支付二维码"
        />
        <QRCode
          id="qrcode-canvas"
          style={{display: 'none'}}
          value={copyURL}
          size={250}
          level="M"
          fgColor="#222"
        />
        <div className={styles.tip}>
          点击「扫一扫」，然后从「相册」选中二维码
        </div>
      </Layout.View>
    )
  }

  if (isSuccess) {
    return (
      <Layout.View slim>
        <div className={styles.icon}>🎉</div>
        <div className={styles.title}>支付成功</div>
        {query.next && <div className={styles.description}>即将跳转..</div>}
      </Layout.View>
    )
  }

  if (error) {
    return (
      <Layout.View slim>
        <div className={styles.icon}>💔</div>
        <div className={styles.title}>支付失败</div>
        <div className={styles.description}>
          请返回原页面重新{query.qrcode === '1' ? '生成二维码' : '下单'}支付
        </div>
        {query.next && (
          <PrimaryButton
            className={styles.button}
            onClick={() => (window.location.href = query.next)}
          >
            返回
          </PrimaryButton>
        )}
      </Layout.View>
    )
  }

  return (
    <Layout.View slim>
      <div className={styles.icon}>⏳</div>
      <div className={styles.title}>支付中</div>
      <PrimaryButton className={styles.button} onClick={handleRetry}>
        重试
      </PrimaryButton>
    </Layout.View>
  )
}

function useWechatAuth() {
  const openid = Cookie.get('openid')
  const location = useLocation()
  const query = queryString.parse(location.search)

  useEffect(() => {
    if (!userAgent.Wechat) {
      return
    }

    if (openid) {
      return
    }

    // TODO: 提示错误
    if (query.wechat_auth === '1') {
      return
    }

    const next = `${window.location.origin}${
      window.location.pathname
    }?${queryString.stringify({...query, wechat_auth: 1})}`

    const redirectURL = `https://zhubai.love/api/wechat/jssdk_authorize?next=${encodeURIComponent(
      next
    )}`

    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxacb7f756b66f7b6d&redirect_uri=${encodeURIComponent(
      redirectURL
    )}&response_type=code&scope=snsapi_base#wechat_redirect`
  }, [query, openid])
}

function wechatPay({payment, handleSuccess, handleFail}) {
  return wechat.init().then(() =>
    // https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#58
    window.wx.chooseWXPay({
      appId: payment.afterContext.appId,
      timestamp: payment.afterContext.timestamp,
      nonceStr: payment.afterContext.nonce,
      package: payment.afterContext.package,
      signType: payment.afterContext.signType,
      paySign: payment.afterContext.signature,
      success: handleSuccess,
      fail: handleFail,
      // cancel: () => handleFail(),
    })
  )
}

export default PaymentPage
