import omitBy from 'lodash.omitby'
import cx from 'classnames'
import {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'
import http from 'lib/http'
import {parseUA} from 'lib/userAgent'
import {useSelf} from 'swr/self'
import {useNotification} from 'components/Notification'
import {useLoadingBar} from 'components/TopNav'
import {IconButton, PrimaryButton} from 'components/Button'
import {useSWRInfiniteList} from 'components/InfiniteList'
import AutoGrowingTextarea from 'components/AutoGrowingTextarea'
import {ReactComponent as BackIcon} from 'icons/arrow.svg'
import MessagesList from './MessagesList'
import useMessagesInfinite from './useMessagesInfinite'
import styles from './ConversationView.module.css'

const userAgent = parseUA(window.navigator.userAgent)

// - [ ] 对话列表加载成功后显示「请选择对话」，否则不显示
// - [ ] 有专栏时导航栏显示专栏名称且可以点击去往专栏
// - [ ] 输入时按「Enter」发布，「Shift + Enter」输入换行
// - [ ] 发送消息时加载、错误状态正确，输入框 & 发送按钮 disable，报错后重新聚焦输入框
// - [ ] 消息发送成功后更新对话列表第一页，并将列表滚动到顶部
// - [ ] 移动端导航栏显示返回按钮，可正常返回对话列表页，微信内不显示导航栏
function ConversationView({
  conversation,
  loadConverstionsSuccess,
  listElementRef,
}) {
  const history = useHistory()

  const {self} = useSelf()

  const {mutate: mutateConversations, isValidating} = useSWRInfiniteList({
    api: '/conversations',
    revalidateOnFocus: false,
  })

  const {data: messageData, mutate: mutateMessages} = useMessagesInfinite(
    conversation?.id
  )

  const [value, setValue] = useTextareaValue({conversation})

  const staticTextareaRef = useRef()
  const autoGrowingTextareaRef = useRef()

  const [requesting, setRequesting] = useState(false)
  useLoadingBar(requesting || isValidating)

  // - [ ] 首页未加载完成前禁止输入
  const [messsagesLoaded, setMessagesLoaded] = useState(false)

  const contentElementRef = useRef()
  const noti = useNotification()

  // - [ ] 移动端输入时输入框变高/变矮后消息列表自动滚动到底部
  // - [ ] iOS 端输入,即便输入框高度没有变化消息列表滚动依然正确
  // TODO: 能记住当前滚动位置
  useEffect(() => {
    const textarea = getActiveTextarea()

    if (!textarea) {
      return
    }

    const observer = new MutationObserver((mutationList) => {
      mutationList.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          const element = contentElementRef.current
          element.scrollTop = element.scrollHeight
        }
      })
    })
    observer.observe(textarea, {
      attributes: true,
      attributeFilter: ['style'],
    })

    return () => observer.disconnect()
  }, [conversation])

  if (!conversation && loadConverstionsSuccess) {
    return <div className={styles.empty}>请选择对话</div>
  }

  if (!conversation) {
    return null
  }

  const {publication} = conversation

  // - [ ] 输入 Shift + Enter 可正常换行
  function handleKeyDown(e) {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault()
      setValue(value + '\n')
    }
  }

  // - [ ] Mac & Windows 下输入 Enter 直接发送
  // - [ ] Mac Safari & Chrome 使用中文输入法时选词时按 Enter 可正常输入英文
  function hanldeBeforeInput(e) {
    if (
      e.data.length === 1 &&
      (e.data.charCodeAt() === 10 || e.data.charCodeAt() === 13)
    ) {
      e.preventDefault()
      handleSubmit()
      return
    }
  }

  // - [ ] 发布消息后消息列表滚动到底部，自动显示发布消息
  // - [ ] 若移除空格后内容为空，则禁止发布并提示
  function handleSubmit() {
    const text = value

    if (text.replaceAll(/\s/g, '').length === 0) {
      noti('不能发送空内容', {warning: true})
      return
    }

    setRequesting(true)
    setValue('')
    http(`/conversations/${conversation.id}/messages`, {
      method: 'POST',
      body: {type: 'plain', content: {text}},
    })
      .then(
        () => {
          mutateConversations().then(() => {
            listElementRef.current.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          })

          const timestamp = Date.now()

          mutateMessages(
            [
              {
                ...messageData[0],
                data: [
                  ...messageData[0].data,
                  {
                    id: `local${timestamp}`,
                    content: {text},
                    type: 'plain',
                    createdAt: timestamp,
                    updatedAt: timestamp,
                    userId: self.id,
                  },
                ],
              },
              ...messageData.slice(1),
            ],
            false
          )

          // HACK: 上面 mutate 时 revalidate 设为 true 不会请求更新反而是用旧的数据更新
          // - [ ] 发布消息后能请求更新所有列表，并能正确渲染，消息顺序正确
          mutateMessages()
        },
        (error) => {
          noti(error.body?.message || error.message || '发送失败', {
            warning: true,
          })
          setValue(text)
        }
      )
      .finally(() => {
        setRequesting(false)
        const textarea = getActiveTextarea()
        textarea.focus()
      })
  }

  function handleBack() {
    history.replace('/messages')
  }

  function getActiveTextarea() {
    if (!staticTextareaRef.current) {
      return null
    }

    if (
      window.getComputedStyle(staticTextareaRef.current)['display'] !== 'none'
    ) {
      return staticTextareaRef.current
    }

    return autoGrowingTextareaRef.current
  }

  return (
    <>
      <div className={cx(styles.nav, {[styles.isWechat]: userAgent.Wechat})}>
        <IconButton className={styles.backButton} onClick={handleBack}>
          <BackIcon />
        </IconButton>
        {publication ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={styles.navLink}
            href={`https://${publication.token}.zhubai.love`}
            target="_blank"
          >
            {conversation.user.name}
            <span style={{fontWeight: 400}}>
              {publication ? ` · ${publication.name}` : ''}
            </span>
          </a>
        ) : (
          conversation.user.name
        )}
      </div>
      <MessagesList
        conversation={conversation}
        ref={contentElementRef}
        setMessagesLoaded={setMessagesLoaded}
      />
      <div className={styles.editor}>
        <textarea
          ref={staticTextareaRef}
          className={cx(styles.textarea, styles.isStatic)}
          placeholder="输入内容"
          onBeforeInput={hanldeBeforeInput}
          onKeyDown={handleKeyDown}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={requesting || !messsagesLoaded}
        />
        <AutoGrowingTextarea
          ref={autoGrowingTextareaRef}
          className={cx(styles.textarea, styles.isAutoGrowing)}
          placeholder="输入内容"
          value={value}
          rows={1}
          onChange={(e) => setValue(e.target.value)}
          disabled={requesting || !messsagesLoaded}
        />
        <div className={styles.editorFooter}>
          <span className={styles.tip}>使用 Shift + Enter 输入换行</span>
          <PrimaryButton
            className={styles.editorButton}
            disabled={requesting || !messsagesLoaded || value.length === 0}
            onClick={handleSubmit}
          >
            发送
          </PrimaryButton>
        </div>
      </div>
    </>
  )
}

// - [ ] 输入消息但未发送时刷新页面后再选中内容保留
// - [ ] 切换对话后输入消息会被重置，若之前有草稿则恢复为未发布的草稿
// - [ ] localstorage 设置正确，不存在空字符串草稿
function useTextareaValue({conversation}) {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (!conversation?.id) {
      return
    }

    setMessagesDrafts({conversationId: conversation.id, draft: value})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (!conversation?.id) {
      return
    }

    setValue(getMessageDraft(conversation.id) || '')
  }, [conversation?.id])

  return [value, setValue]
}

function getMessagesDrafts() {
  const draftsString = window.localStorage.getItem('messagesDrafts')
  return JSON.parse(draftsString || '{}')
}

function getMessageDraft(conversationId) {
  const drafts = getMessagesDrafts()
  return drafts[conversationId]
}

function setMessagesDrafts({conversationId, draft}) {
  const drafts = getMessagesDrafts()

  window.localStorage.setItem(
    'messagesDrafts',
    JSON.stringify(
      omitBy(
        {
          ...drafts,
          [conversationId]: draft,
        },
        (value) => value.length === 0
      )
    )
  )
}

export default ConversationView
