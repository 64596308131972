import {SWRConfig} from 'swr'
import {RecoilRoot} from 'recoil'
import http from 'lib/http'
import Routes from 'pages/Routes'
import Notification from 'components/Notification'

function App() {
  return (
    <SWRConfig value={{fetcher: http}}>
      <RecoilRoot>
        <Routes />
        <Notification />
      </RecoilRoot>
    </SWRConfig>
  )
}

export default App
