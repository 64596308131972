import {useState, useEffect, useRef} from 'react'
import useInterval from './useInterval'

const diffTime = (end, now) => {
  const diff = (end - now) / 1000
  const days = Math.floor(diff / (3600 * 24))
  const hours = Math.floor((diff / 3600) % 24)
  const minutes = Math.floor((diff / 60) % 60)
  const seconds = Math.floor(diff % 60)
  return {
    done: diff <= 0,
    days,
    hours,
    minutes,
    seconds,
    // 允许传入参数是 number/Date 类型
    get now() {
      return new Date(now)
    },
  }
}

/**
 * 创建一个倒计时，获取进行与结束状态
 *
 * ```js
 * const {done, days, hours, minutes, seconds} = useCountdown(myDate)
 *
 * render(done ? null : `还有 ${days} 天 ${hours} 时 ${minutes} 分 ${seconds} 秒`)
 * ```
 */
const useCountdown = (date, {start, interval = 1000, onTick} = {}) => {
  const startRef = useRef(start || Date.now())
  const endRef = useRef(date)
  const [diffs, setDiffs] = useState(() =>
    diffTime(endRef.current, startRef.current)
  )
  const countRef = useRef()

  useEffect(() => {
    countRef.current = Date.now()
    startRef.current = start || Date.now()
    endRef.current = date
    setDiffs(diffTime(endRef.current, startRef.current))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Number(date), Number(start)])

  useInterval(
    () => {
      const elapsed = Date.now() - countRef.current
      const newDiffs = diffTime(endRef.current, +startRef.current + elapsed)
      setDiffs(newDiffs)
      if (onTick) {
        onTick(newDiffs)
      }
    },
    diffs.done ? null : interval
  )
  return diffs
}

export default useCountdown
