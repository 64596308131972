/**
 * 全局侧栏
 * - [ ] 点击 menu 按钮显示/隐藏侧栏
 * - [ ] 点击其他区域后侧栏消失
 * - [ ] 不会被其他页面元素遮挡
 * - [ ] 未登录、作者、读者链接正确
 * - [ ] 移动端样式正常
 * - [ ] 专栏、作品页侧边栏请求获取当前用户信息，有专栏时显示「创作中心」，无专栏时显示「开始创作」
 */
import cx from 'classnames'
import {useEffect, useState} from 'react'
import {useSpring, animated} from 'react-spring'
import {useSetRecoilState} from 'recoil'
import {isLoggedIn} from 'lib/auth'
import {getZhubaiURL} from 'lib/url'
import http from 'lib/http'
import loadingState from 'state/loading'
import {useSelf} from 'swr/self'
import {IconButton} from 'components/Button'
import {notificationState} from 'components/Notification'
import styles from './GlobalSideMenu.module.css'
import {ReactComponent as MenuIcon} from 'icons/burger.svg'
import {ReactComponent as BackIcon} from 'icons/arrow.svg'

function GlobalSideMenu({buttonClass}) {
  const {self} = useSelf()
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const hide = (e) => setVisible(false)
    window.document.addEventListener('click', hide)

    return () => window.document.removeEventListener('click', hide)
  }, [visible, setVisible])

  const props = useSpring({
    transform: `translateX(${visible ? -60 : -300}px)`,
    config: {mass: 1, tension: 300, friction: 25, velocity: 0.04},
  })

  function handleMenuClick() {
    setVisible((visible) => !visible)
  }

  // 屏蔽 body 的 Click 事件，需要比 body 先注册。
  function hanldeRootClick(e) {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
  }

  const setLoading = useSetRecoilState(loadingState)
  const setNoti = useSetRecoilState(notificationState)
  function handleLogout(e) {
    e.preventDefault()
    setLoading(true)
    http('/auth/logout', {method: 'POST'}).then(
      () => window.location.reload(),
      (error) => {
        setLoading(false)
        setNoti({
          visible: true,
          warning: true,
          text: error.body?.message || error.message || '退出失败',
        })
      }
    )
  }

  return (
    <div onClick={hanldeRootClick}>
      <IconButton
        className={cx(styles.menuButton, buttonClass)}
        onClick={handleMenuClick}
      >
        {visible ? (
          <BackIcon className={styles.menuButtonIcon} />
        ) : (
          <MenuIcon className={styles.menuButtonIcon} />
        )}
      </IconButton>
      <animated.div className={styles.sidebar} style={props}>
        {isLoggedIn() ? (
          <>
            <a href={getZhubaiURL('/creator')} className={styles.link}>
              {self?.publication ? '创作中心' : '开始创作'}
            </a>
            <a href={getZhubaiURL('/settings')} className={styles.link}>
              我的设置
            </a>
            <a
              href={getZhubaiURL('/creator')}
              className={styles.link}
              onClick={handleLogout}
            >
              退出登录
            </a>
          </>
        ) : (
          <>
            <a href={getZhubaiURL('/creator')} className={styles.link}>
              开始创作
            </a>
            <a href={getZhubaiURL('/')} className={styles.link}>
              了解竹白
            </a>
            <a href="https://news.zhubai.love" className={styles.link}>
              关注我们
            </a>
          </>
        )}
        <div className={styles.brand}>
          由
          <a href="https://zhubai.love">
            <img src="https://zhubai.love/favicon.png" alt="logo" />
            竹白
          </a>
          提供支持
        </div>
      </animated.div>
    </div>
  )
}

export default GlobalSideMenu
