import cx from 'classnames'
import {ReactComponent as Icon} from 'icons/spinner.svg'
import styles from './Spinner.module.css'

function Spinner({className, style, fill = '#81C1FC', size = 44}) {
  return (
    <div className={cx(className, styles.spinner)} style={style}>
      <Icon className={styles.svg} fill={fill} width={size} height={size} />
    </div>
  )
}

export default Spinner
