import React, {useEffect, useRef, useState} from 'react'

/**
 * Auto-growing TextArea
 * - [ ] 新增、删减文本时高度会正确变化
 * - [ ] 默认值超出高度时高度正确
 * - [ ] 未通过键盘编辑直接设置 value 高度正确变化
 *
 * TODO：
 * - [ ] 默认值超出初始长度时，第一次渲染是被阶段的高度，然后有闪动
 *
 * 详情见：https://stackoverflow.com/a/24676492
 */
const AutoGrowingTextarea = React.forwardRef(
  ({children, onResize, value, ...props}, ref) => {
    const localRef = useRef()
    const elementRef = ref || localRef

    const previousHeightRef = useRef()
    const [height, setHeight] = useState('auto')

    useEffect(() => {
      setHeight('auto')
    }, [value])

    useEffect(() => {
      setHeight(`${elementRef.current?.scrollHeight}px`)
    }, [height, elementRef])

    useEffect(() => {
      if (height === 'auto') {
        return
      }

      if (!previousHeightRef.current) {
        previousHeightRef.current = height
        return
      }

      if (previousHeightRef.current !== height && onResize) {
        onResize()
      }

      previousHeightRef.current = height
    }, [height, onResize])

    function handleInput() {
      setHeight('auto')
    }

    return (
      <textarea
        {...props}
        value={value}
        onInput={handleInput}
        style={{height: height}}
        ref={elementRef}
      >
        {children}
      </textarea>
    )
  }
)

export default AutoGrowingTextarea
