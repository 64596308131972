// - [ ] 付费专栏显示作品类型设置，选中样式正确，免费专栏不显示
// - [ ] 若有付费内容（插入付费墙 & 付费墙分隔线下方有文字）则默认选中付费作品，若编辑已发布内容则默认为发布时类型，否则没有默认值
// - [ ] 付费专栏没有选择作品类型时点发布提示错误
// - [ ] 选择付费作品后，显示推送给免费订阅者开关，若有免费内容（付费墙分隔线下方上方有文字）默认等同于推送开关
// - [ ] 设置总推送开关时，若有免费内容（付费墙分隔线下方上方有文字）同步设置免费订阅者推送开关，若总推送开关关闭的话 disable 免费订阅者推送开关
// - [ ] 设置付费作品时，若没有付费内容（未插入付费墙，或则付费墙分隔线下方没有文字），则弹窗引导设置
// - [ ] 点击「前往设置」关闭弹窗，退回到编辑器设置
// - [ ] 点击「设置全部内容仅付费订阅者可见」在文档头部插入付费墙分隔线，并返回设置弹窗 & 设置为付费内容 & 关闭免费推送，待保存草稿后可提交
// - [ ] 若没有免费内容时（付费墙分隔线下方上方没有文字），点击「免费推送」开关时提示没有免费内容
// - [ ] 能够正常设置推送开关，发布后推送逻辑正确
// - [ ] 付费专栏编辑已发布作品时，不显示推送设置且默认为 null，设置付费专栏时和发布有相同的付费墙设置引导逻辑

import cx from 'classnames'
import {useEffect, useState} from 'react'
import Button, {PrimaryButton, ToggleButton} from 'components/Button'
import {ModalView} from 'components/Modal'
import {useNotification} from 'components/Notification'
import {setAllContentPaidOnly} from 'components/Editor/PaywallPlugin'
import {ReactComponent as PaidPostIcon} from 'icons/post-paid.svg'
import {ReactComponent as FreePostIcon} from 'icons/post-free.svg'
import styles from './WritePageSubmitDialog.module.css'

function WritePageSubmitDialog({
  handlePublish,
  setDialogVisible,
  publication,
  hasPaidContent,
  hasFreeContent,
  editor,
  isEditing,
  submitDisableStatus,
  post,
}) {
  const hasMembership = Boolean(publication.membership)

  let defaultType
  if (hasPaidContent) {
    defaultType = 'paid'
  } else if (post) {
    defaultType = post.isPaidContent ? 'paid' : 'free'
  }

  const [type, setType] = useState(defaultType)
  const [shouldNotify, setShouldNotify] = useState(isEditing ? false : true)
  const [notifyFreeSubscribers, setNotifyFreeSubscribers] = useState(
    shouldNotify && hasFreeContent
  )
  const [showPaywallGuide, setShowPaywallGuide] = useState(false)

  useEffect(() => {
    if (!hasFreeContent) {
      setNotifyFreeSubscribers(false)
    }
  }, [hasFreeContent])

  function handleSetPaidConent() {
    if (hasPaidContent) {
      setType('paid')
    } else {
      setShowPaywallGuide(true)
    }
  }

  function handleToggleNotify() {
    setShouldNotify(!shouldNotify)
    setNotifyFreeSubscribers(!shouldNotify && hasFreeContent)
  }

  const noti = useNotification()

  function handleToggleNotifyFreeSubscribers() {
    if (!hasFreeContent) {
      noti('未设置免费内容', {warning: true})
      return
    }

    setNotifyFreeSubscribers(!notifyFreeSubscribers)
  }

  function handleSubmit() {
    if (hasMembership && !type) {
      noti('需设置作品类型', {warning: true})
      return
    }

    const pushTo = {
      free: type === 'paid' ? notifyFreeSubscribers : shouldNotify,
      paid: hasMembership ? shouldNotify : false,
    }

    handlePublish({isPaidContent: type === 'paid', shouldNotify, pushTo})
  }

  function handleSetPaywall() {
    if (!editor) {
      return
    }

    setAllContentPaidOnly(editor)
    setType('paid')
    setShowPaywallGuide(false)
  }

  if (showPaywallGuide) {
    return (
      <ModalView setVisible={setDialogVisible}>
        <div className={cx(styles.dialog, styles.fullwidth)}>
          <h1 className={styles.title}>未设置仅付费会员可见内容</h1>
          <h2 className={styles.subtitle}>
            公开部分内容给非付费会员可以更好地提升付费率
          </h2>
          <img
            className={styles.image}
            src="https://img.zhubai.love/7885d796aefa49e3af8dc1eab591241b.png"
            alt="付费墙设置"
          />
          <PrimaryButton
            style={{width: '300px'}}
            className={styles.submitButton}
            onClick={() => setDialogVisible(false)}
          >
            前往设置
          </PrimaryButton>
          <Button className={styles.cancelButton} onClick={handleSetPaywall}>
            设置所有内容仅付费会员可见
          </Button>
        </div>
      </ModalView>
    )
  }

  return (
    <ModalView setVisible={setDialogVisible} hasCloseButton={true}>
      <div className={styles.dialog}>
        {hasMembership && (
          <>
            <h1 className={styles.title}>作品类型设置</h1>
            <div className={styles.types}>
              <Button
                className={cx(styles.type, {
                  [styles.isActive]: type === 'paid',
                })}
                onClick={handleSetPaidConent}
              >
                <PaidPostIcon className={styles.typeIcon} />
                <div>
                  <div className={styles.typeName}>付费作品</div>
                  <div className={styles.typeDescription}>
                    非付费会员仅可查看作品标题和已设置的公开内容
                  </div>
                </div>
              </Button>
              <Button
                className={cx(styles.type, {
                  [styles.isActive]: type === 'free',
                })}
                onClick={() => {
                  setType('free')
                }}
              >
                <FreePostIcon className={styles.typeIcon} />
                <div>
                  <div className={styles.typeName}>免费作品</div>
                  <div className={styles.typeDescription}>
                    所有人均可查看完整内容
                    {hasPaidContent
                      ? '，已设置的仅付费会员可见内容也会被公开'
                      : ''}
                  </div>
                </div>
              </Button>
            </div>
          </>
        )}
        {!isEditing && (
          <>
            <h1 className={styles.title}>推送设置</h1>
            <div className={styles.toggle}>
              <div>
                推送至
                {!hasMembership && '所有订阅者'}
                {hasMembership && type === 'paid' && '付费会员'}
                {hasMembership && type !== 'paid' && '所有会员'}
              </div>
              <ToggleButton
                className={styles.toggleButton}
                value={shouldNotify}
                onClick={handleToggleNotify}
              />
            </div>
            <div className={cx(styles.toggle, styles.secondary)}>
              {type !== 'paid' && (
                <div>关闭推送后订阅者无法接收到邮件和微信通知</div>
              )}
              {type === 'paid' && (
                <>
                  <div>同时推送免费版本至免费会员</div>
                  <ToggleButton
                    className={styles.toggleButton}
                    value={notifyFreeSubscribers}
                    onClick={handleToggleNotifyFreeSubscribers}
                    disabled={!shouldNotify}
                  />
                </>
              )}
            </div>
          </>
        )}
        <PrimaryButton
          className={styles.submitButton}
          onClick={handleSubmit}
          disabled={submitDisableStatus.disable}
        >
          {submitDisableStatus.disable ? submitDisableStatus.tip : '发布'}
        </PrimaryButton>
      </div>
    </ModalView>
  )
}

export default WritePageSubmitDialog
