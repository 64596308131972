import {useCallback, useEffect, useState} from 'react'
import {useSetRecoilState} from 'recoil'
import {useSelf} from 'swr/self'
import {parseUA} from 'lib/userAgent'
import http from 'lib/http'
import {LaunchWechatAppMask} from 'lib/wechat'
import useInterval from 'hooks/useInterval'
import WechatQRCode from 'components/WechatQRCode'
import Spinner from 'components/Spinner'
import Error from 'components/Error'
import {PrimaryButton} from 'components/Button'
import {notificationState} from 'components/Notification'
import {
  SubscribeView,
  SubscribeViewDescription,
  SubscribeViewTitle,
} from './SubscribeView'

// - [ ] 能够正常获取 ticket 并展示小程序码，加载/错误状态正确，可重试
// - [ ] 微信内访问时显示打开小程序按钮并能成功跳转并触发自动绑定逻辑
// - [ ] 绑定成功后提示并返回订阅弹窗
// - [ ] 不同宽度下样式正常
function SubscribeWechatBindingView({publication, setView}) {
  const {self, mutate} = useSelf()
  const userAgent = parseUA(window.navigator.userAgent)
  const [ticket, setTicket] = useState()
  const [error, setError] = useState()

  const fetchTicket = useCallback(() => {
    setError(null)
    http('/self/bind_account/ticket', {method: 'POST'}).then(
      ({ticket}) => setTicket(ticket),
      (error) =>
        setError({
          message: error.body?.message || error.message || '获取鉴权凭证失败',
        })
    )
  }, [])

  useEffect(() => {
    fetchTicket()
  }, [fetchTicket])

  const setNoti = useSetRecoilState(notificationState)
  useEffect(() => {
    if (self.account.hasBoundWechat) {
      setView('select-approach')
      setNoti({visible: true, text: '绑定成功 🎉'})
    }
  }, [self, setView, setNoti])

  useInterval(() => {
    mutate()
  }, 5000)

  let scene = `bt=${ticket}&token=${publication.token}`

  if (scene.length > 32) {
    scene = `b=${ticket}&t=${publication.token}`
  }

  let title = '使用微信扫描二维码'
  if (userAgent.Wechat) {
    title = '打开小程序绑定微信'
  } else if (userAgent.iPhone || userAgent.Android) {
    title = '保存图片后在微信中扫描'
  }

  const path = `/pages/publication/publication?scene=${encodeURIComponent(
    scene
  )}`

  return (
    <SubscribeView>
      <SubscribeViewTitle>{title}</SubscribeViewTitle>
      <SubscribeViewDescription>
        在小程序中绑定微信即可微信订阅
      </SubscribeViewDescription>
      {!ticket && (
        <div
          style={{
            margin: 'auto',
            width: '280px',
            // - [ ] 微信内访问时加载 ticket 加载状态高度正确没有跳动，错误状态高度正常
            height: userAgent.Wechat && !error ? '46px' : '280px',
          }}
        >
          {error ? (
            <Error error={error} handleRetry={fetchTicket} />
          ) : (
            <Spinner size={30} />
          )}
        </div>
      )}
      {ticket && !userAgent.Wechat && (
        <>
          <WechatQRCode
            width={560}
            page="pages/publication/publication"
            scene={scene}
            publication={publication}
          />
        </>
      )}
      {ticket && userAgent.Wechat && (
        <div style={{margin: 'auto'}}>
          <PrimaryButton style={{position: 'relative', width: '100px'}}>
            <span>绑定</span>
            <LaunchWechatAppMask width="100px" height="46px" path={path} />
          </PrimaryButton>
        </div>
      )}
    </SubscribeView>
  )
}

export default SubscribeWechatBindingView
