/**
 * - [ ] 能正确显示连续空格和软换行
 * - [ ] 文本超出宽度限制会按单词折行，默认不会把单词折断
 * - [ ] 如果一个超过宽度限制的长单词，则会从单词中间折断换行避免溢出
 */

import {Text} from 'slate'
import cx from 'classnames'
import {Leaf} from './Leaf'
import {Element} from './Element'
import styles from './RichText.module.css'

function RichText({content, className}) {
  return (
    <div className={cx(className, styles.richtext)}>
      {content.map((node, index) => (
        <Node node={node} key={index} />
      ))}
    </div>
  )
}

function Node({node}) {
  if (Text.isText(node)) {
    return <Leaf leaf={node}>{node.text}</Leaf>
  }

  return (
    <Element element={node}>
      {node.children?.map((node, index) => (
        <Node node={node} key={index} />
      ))}
    </Element>
  )
}

export default RichText
