// - [ ] 未登录时点击兑换按钮弹窗引导用手机号登录，成功后刷新页面
// - [ ] 点击按钮加载、错误状态正确
// - [ ] 请求成功后，按钮正确，可正常跳往专栏页
// - [ ] 已开启/未开启订阅状态下按钮文案正确，未开启订阅时访问专栏后自动显示选择订阅方式弹窗

import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import queryString from 'query-string'
import getPublicationToken from 'lib/getPublicationToken'
import {isLoggedIn} from 'lib/auth'
import http from 'lib/http'
import {usePublication} from 'swr/publication'
import useThemeColor from 'hooks/useThemeColor'
import useSafariStatusBarColor from 'hooks/useSafariStatusBarColor'
import Avatar from 'components/Avatar'
import Layout from 'components/Layout'
import Error from 'components/Error'
import LoadingBar from 'components/LoadingBar'
import PageTitle from 'components/PageTitle'
import GlobalSideMenu from 'components/GlobalSideMenu'
import {PrimaryButton} from 'components/Button'
import LoginModal, {useLoginModal} from 'components/LoginModal'
import {useNotification} from 'components/Notification'
import Spinner from 'components/Spinner'
import {ReactComponent as QuoteIcon} from 'icons/quote.svg'
import styles from './MigratePage.module.css'

function MigratePage() {
  const {token} = useParams()
  const {ticket} = queryString.parse(window.location.search)
  const publicationToken = getPublicationToken()
  const {publication, error, mutate, isValidating} =
    usePublication(publicationToken)

  const {wechat, email} = publication?.subscription.approach || {}
  const hasSubscribed = wechat || email

  useThemeColor(publication?.theme?.primaryColor)

  let safariStatusBarColor
  if (publication?.theme?.primaryColor) {
    safariStatusBarColor = `#fff`
  } else if (publication) {
    safariStatusBarColor = `#f7f5ff`
  }
  useSafariStatusBarColor(safariStatusBarColor)

  const showLoginModal = useLoginModal()

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const noti = useNotification()

  function handleClick() {
    if (!isLoggedIn()) {
      showLoginModal({
        title: '登录',
        subtitle: '验证手机号后即可兑换会员',
      })
      return
    }

    setLoading(true)
    requestSubscribe({publicationId: publication.id, token, ticket})
      .then(
        () => {
          setSuccess(true)
          noti('兑换成功 🎉')
          window.location.href = `${window.location.origin}?subscribe=1`
        },
        (error) =>
          noti(error.body?.message || error.message || '兑换失败', {
            warning: true,
          })
      )
      .finally(() => setLoading(false))
  }

  function handleRedirect() {
    if (hasSubscribed) {
      window.location.href = window.location.origin
    } else {
      window.location.href = `${window.location.origin}?subscribe=1`
    }
  }

  if (!publication) {
    return (
      <Layout.Page>
        {(!error || isValidating) && <LoadingBar />}
        <Error error={error} handleRetry={() => mutate()} />
      </Layout.Page>
    )
  }

  return (
    <Layout.Page>
      <PageTitle title={publication.name} />
      <header className={styles.header}>
        <div className={styles.view}>
          <div className={styles.name}>
            <Link to="/">{publication.name}</Link>
          </div>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <div className={styles.avatar}>
            <Avatar src={publication.author.avatar} size={68} />
          </div>
          <div className={styles.description}>
            <QuoteIcon className={styles.quote} />
            <div>{publication.description}</div>
            <div className={styles.sign}>
              —— 由 {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <span className={styles.author}>
                {publication.author.name}
              </span>{' '}
              创作
            </div>
          </div>
          {success ? (
            <PrimaryButton className={styles.button} onClick={handleRedirect}>
              {hasSubscribed ? '前往专栏' : '开启订阅'}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              className={styles.button}
              onClick={handleClick}
              disabled={loading}
            >
              {loading ? <Spinner size={24} /> : '兑换会员'}
            </PrimaryButton>
          )}
        </div>
        <GlobalSideMenu
          isAuthor={publication.relationship.isAuthor}
          buttonClass={styles.menuButton}
        />
      </header>
      {/* TODO：拆出去 */}
      <LoginModal />
    </Layout.Page>
  )
}

function requestSubscribe({publicationId, token, ticket}) {
  if (token === 'foo' && ticket === 'bar') {
    return Promise.resolve()
  }

  return http(`/publications/${publicationId}/subscription_hopping`, {
    method: 'POST',
    body: {
      token,
      ticket,
      data: {},
    },
  })
}

export default MigratePage
