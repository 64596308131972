// - [ ] 弹窗 & 预览均能正确渲染
// - [ ] 简介内容正确，长单词可正常折行，多于 1 个空行时合并。
// - [ ] 若详情中字数大于 0 显示详情链接，简介字数较多或较少时样式均正确
// - [ ] 预览时详情链接正确显示，点击无效
// - [ ] 价格、折扣正确，禁用后不显示按钮
// - [ ] 点击「免费订阅」显示选择订阅方式弹窗，可正常微信/邮件订阅（暂不显示订阅状态）
// - [ ] 编辑会员计划预览时点击按钮无具体行为也不会报错
// - [ ] 已免费订阅时，免费订阅按钮正常展示已订阅状态
// - [ ] 未登录状态下选择付费计划时显示登录弹窗，可正常免费订阅
// - [ ] 不同宽度下样式正常，高度不够时能够正常滚动
import cx from 'classnames'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {useSelf} from 'swr/self'
import {getDiscount, parsePlans} from 'lib/membership'
import {isLoggedIn} from 'lib/auth'
import http from 'lib/http'
import {getURLWithExtraQuery} from 'lib/url'
import {getDateString} from 'lib/time'
import Button from 'components/Button'
import {getCharactersCount} from 'components/Editor'
import {useLoginModal} from 'components/LoginModal'
import {useLoadingBar} from 'components/TopNav'
import {useNotification} from 'components/Notification'
import {ReactComponent as CheckboxIcon} from 'icons/checkbox.svg'
import {ReactComponent as ArrowIcon} from 'icons/arrow.svg'
import {SubscribeView, SubscribeViewTitle} from './SubscribeView'
import styles from './SubscribePlansView.module.css'

function SubscribePlansView({
  membership,
  isPreview,
  className,
  setView,
  setPlan,
  publication,
  setShowSubscribeModal,
  setShowLoginModal,
  isRenew,
}) {
  const {self} = useSelf()
  const plans = isPreview ? membership.plans : parsePlans(membership.plans)
  const summary = membership.summary.replace(/\n[\n]+/g, '\n\n')
  const loggedIn = isLoggedIn()
  const {subscription} = publication || {}

  let description = []
  try {
    description = JSON.parse(membership.description)
  } catch (e) {}

  const showDescriptionLink = getCharactersCount(description) > 0

  // - [ ] 专栏页/作品页点击「阅读完整会员计划」可正常调转至会员计划详情页且弹窗关闭
  // - [ ] 预览时点击「阅读完整会员计划」无效
  function handleClick(e) {
    if (isPreview) {
      e.preventDefault()
      return
    }

    setShowSubscribeModal(false)
  }

  const showLoginModal = useLoginModal()
  const noti = useNotification()
  const [loading, setLoading] = useState(false)
  useLoadingBar(loading)
  function handleLogin(e) {
    if (isPreview) {
      e.preventDefault()
      return
    }

    if (loggedIn) {
      setLoading(true)
      http('/auth/logout', {method: 'POST'}).then(
        () => {
          window.location.href = getURLWithExtraQuery({
            url: window.location.href,
            extraQuery: {login: 2},
          })
        },
        (error) => {
          setLoading(false)
          noti(error.body?.message || error.message || '退出失败', {
            warning: true,
          })
        }
      )
    } else {
      setShowSubscribeModal(false)

      showLoginModal({
        title: '登录',
        subtitle: '使用已订阅的手机号或邮箱登录',
        enableEmailLogin: true,
      })
    }
  }

  return (
    <SubscribeView className={cx(styles.view, className)}>
      <SubscribeViewTitle>
        {isRenew && '续订'}
        {!isRenew && publication && (
          <span style={{display: 'inline-block'}}>「{publication.name}」</span>
        )}
        {!isRenew && <span style={{display: 'inline-block'}}>会员计划</span>}
      </SubscribeViewTitle>
      <div className={styles.content}>
        <div className={styles.summary}>
          <div>{summary || '会员计划简介'}</div>
          {showDescriptionLink && (
            <Link
              to="/membership"
              className={styles.descriptionLink}
              onClick={handleClick}
            >
              阅读完整会员计划 <ArrowIcon className={styles.arrowIcon} />
            </Link>
          )}
        </div>
        <div className={styles.plans}>
          <PlanButton
            type="monthly_plan"
            plans={plans}
            isPreview={isPreview}
            setView={setView}
            setPlan={setPlan}
            publication={publication}
            setShowSubscribeModal={setShowSubscribeModal}
            setShowLoginModal={setShowLoginModal}
            isRenew={isRenew}
          />
          <PlanButton
            type="three_month_plan"
            plans={plans}
            isPreview={isPreview}
            setView={setView}
            setPlan={setPlan}
            publication={publication}
            setShowSubscribeModal={setShowSubscribeModal}
            setShowLoginModal={setShowLoginModal}
            isRenew={isRenew}
          />
          <PlanButton
            type="six_month_plan"
            plans={plans}
            isPreview={isPreview}
            setView={setView}
            setPlan={setPlan}
            publication={publication}
            setShowSubscribeModal={setShowSubscribeModal}
            setShowLoginModal={setShowLoginModal}
            isRenew={isRenew}
          />
          <PlanButton
            type="twelve_month_plan"
            plans={plans}
            isPreview={isPreview}
            setView={setView}
            setPlan={setPlan}
            publication={publication}
            setShowSubscribeModal={setShowSubscribeModal}
            setShowLoginModal={setShowLoginModal}
            isRenew={isRenew}
          />
          {!isRenew && (
            <PlanButton
              type="free"
              isPreview={isPreview}
              setView={setView}
              publication={publication}
            />
          )}
          {!isRenew && <div className={styles.tip}>数字化商品暂不支持退货</div>}
          {!isRenew && !isPreview && (
            <div className={styles.subscribed}>
              <span>已经订阅过？</span>
              <Button className={styles.loginButton} onClick={handleLogin}>
                {!loggedIn ? '登录' : '切换帐号'}
              </Button>
            </div>
          )}
          {isRenew && loggedIn && (
            <div className={styles.subscribed}>
              <span className={styles.subscribedName}>
                {self?.name || self?.account.email}
              </span>
              {(self?.name || self?.account.email) && (
                <span>&nbsp;·&nbsp;</span>
              )}
              <Button className={styles.loginButton} onClick={handleLogin}>
                切换帐号
              </Button>
            </div>
          )}
          {isRenew && loggedIn && (
            <div className={styles.tip}>
              {subscription.type === 'paid'
                ? `当前有效期至 ${getDateString(subscription.expiredAt)}`
                : '当前未付费订阅'}
            </div>
          )}
        </div>
      </div>
    </SubscribeView>
  )
}

function PlanButton({
  type,
  plans,
  isPreview,
  setView,
  setPlan,
  publication,
  setShowSubscribeModal,
  isRenew,
}) {
  const price = type === 'free' ? 0 : plans[type].price
  const enabled = type === 'free' ? true : plans[type].enabled
  const discount = type === 'free' ? null : getDiscount(type, plans)
  const {subscription} = publication || {}

  const showLoginModal = useLoginModal()

  if (!enabled) {
    return null
  }

  const name = {
    monthly_plan: '月度订阅',
    three_month_plan: '季度订阅',
    six_month_plan: '半年订阅',
    twelve_month_plan: '年度订阅',
    free: '免费订阅',
  }[type]

  const hasFreeSubscribed =
    type === 'free' &&
    subscription?.type === 'free' &&
    subscription.hasSubscribed
  const showDiscount = discount > 0 && discount < 10 && !hasFreeSubscribed

  function hanldeClick() {
    if (isPreview) {
      return
    }

    if (type === 'free') {
      setView('select-approach')
      return
    }

    if (!isLoggedIn()) {
      const enableEmailLogin = isRenew

      showLoginModal({
        title: enableEmailLogin ? '登录' : '验证手机号',
        subtitle: enableEmailLogin
          ? '需验证手机号或邮箱'
          : '用此手机号登录即可查看所有付费内容',
        enableEmailLogin,
        nextQuery: {subscribe: isRenew ? '2' : '1'},
      })
      setShowSubscribeModal(false)
      return
    }

    setPlan(plans[type])
    setView('payment')
  }

  return (
    <Button className={styles.plan} onClick={hanldeClick}>
      <span>{name}</span>
      {hasFreeSubscribed ? (
        <CheckboxIcon fill="#555" />
      ) : (
        <span className={styles.price}>
          <span>¥</span>
          <span>{price}</span>
        </span>
      )}

      {showDiscount && <span className={styles.discount}>{discount} 折</span>}
    </Button>
  )
}

export default SubscribePlansView
