// - [ ] 可正常填写邮箱发送确认邮箱，加载状态、错误状态（非空、邮箱不合法、邮箱被占用）正常
// - [ ] 发送成功后重试按钮暂不可点击，标题文案正确，底部显示没有「没有收到邮件」，倒计时结束后可重试发送
// - [ ] 发送成功后再编辑邮箱，倒计时结束，标题文案正确，底部没有「没有收到邮件」
// - [ ] 重新发送后若有错误则底部正常显示错误而不是「没有收到邮件」
// - [ ] 发送成功后轮询更新 self 对象，判断更新成功后提示成功并停止轮询

import {useEffect, useRef, useState} from 'react'
import http from 'lib/http'
import {ModalDialog} from 'components/Modal'
import {FormInput, FormError, FormSubmit} from 'components/Form'
import Spinner from 'components/Spinner'
import useCountdown from 'hooks/useCountdown'
import {useSelf} from 'swr/self'
import styles from './EmailBindingModal.module.css'

function EmailBindingModal({setVisible}) {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [countdown, setCountdown] = useState(0)
  const {self, mutate} = useSelf()
  const intervalRef = useRef(null)

  const {done, seconds} = useCountdown(countdown)

  useEffect(() => {
    if (self.email === email && success) {
      setConfirmed(true)
      window.clearInterval(intervalRef.current)
    }
  }, [self, email, success])

  useEffect(() => {
    setSuccess(false)
    setCountdown(0)
  }, [email])

  useEffect(() => {
    return () => window.clearInterval(intervalRef.current)
  }, [])

  function handleEmailInputChange(event) {
    setError(null)
    setEmail(event.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (email.length === 0) {
      setError(true)
      return
    }

    setLoading(true)
    http('/self/email_confirmation', {
      method: 'POST',
      body: {email},
    }).then(
      () => {
        setLoading(false)
        setSuccess(true)
        setCountdown(Date.now() + 60 * 1000)
        intervalRef.current = window.setInterval(() => mutate(), 2000)
      },
      (error) => {
        setError({
          message:
            error.body?.validationErrors?.email[0] ||
            error.body?.message ||
            error.message ||
            '发送失败',
        })
        setLoading(false)
      }
    )
  }

  return (
    <ModalDialog setVisible={setVisible} hasCloseButton={true}>
      <form className={styles.dialog} onSubmit={handleSubmit}>
        <h1 className={styles.title}>
          {success ? '已发送验证邮件' : '绑定邮箱'}
        </h1>
        <div className={styles.description}>
          {success
            ? '请前往下方邮箱查收'
            : '绑定后即可接收来自邮箱订阅者回复的邮件'}
        </div>
        <FormInput
          className={styles.input}
          type="text"
          placeholder={Boolean(error) ? '输入邮箱' : '邮箱'}
          value={email}
          error={Boolean(error)}
          name="email"
          onChange={handleEmailInputChange}
          tip="建议使用 Gmail、QQ、163 等主流邮件服务"
        />
        <FormSubmit className={styles.button} disabled={!done}>
          {done ? '发送验证邮件' : `重发验证邮件 (${seconds})`}
        </FormSubmit>
        {error || !success ? (
          <FormError error={error} placeholder />
        ) : (
          <div className={styles.tip}>
            没有收到邮件？
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href="https://news.zhubai.love/posts/2113241697013252096"
              target="_blank"
            >
              查看解决办法
            </a>
          </div>
        )}
        {loading && (
          <div className={styles.loading}>
            <Spinner />
          </div>
        )}
        {confirmed && <div className={styles.confirmed}>绑定成功 🎉</div>}
      </form>
    </ModalDialog>
  )
}

export default EmailBindingModal
