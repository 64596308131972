import React from 'react'
import cx from 'classnames'
import {PrimaryButton} from 'components/Button'
import Spinner from 'components/Spinner'
import styles from './Form.module.css'

export default function Form({children, className, ...props}) {
  return (
    <form {...props} className={cx(styles.form, className)}>
      {children}
    </form>
  )
}

export function FormTitle({className, title, subtitle}) {
  return (
    <div className={className}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
    </div>
  )
}

export function FormField({children, className}) {
  return <div className={cx(styles.field, className)}>{children}</div>
}

export const Input = React.forwardRef(
  ({className, isInvalid, ...props}, ref) => {
    return (
      <input
        {...props}
        className={cx(className, styles.input, {
          [styles.invalid]: isInvalid,
        })}
        ref={ref}
      />
    )
  }
)

export const FormInput = React.forwardRef(
  ({className, rootClass, error, renderRight, tip, ...props}, ref) => {
    const isInvalid = Boolean(error)
    const showErrorMessage =
      typeof error?.message === 'string' || typeof error === 'string'

    return (
      <div
        className={cx(styles.field, {
          [styles.invalid]: isInvalid,
          [styles.withErrorMessage]: showErrorMessage,
        })}
      >
        <div className={cx(styles.inputWrapper, rootClass)}>
          <input
            {...props}
            className={cx(className, styles.input, {
              [styles.invalid]: Boolean(error),
            })}
            ref={ref}
          />
          {renderRight && renderRight()}
        </div>
        {showErrorMessage && (
          <div className={styles.inputError}>{error.message || error}</div>
        )}
        {tip && <div className={styles.inputTip}>{tip}</div>}
      </div>
    )
  }
)

export const FormTextarea = React.forwardRef(
  ({className, error, ...props}, ref) => {
    const isInvalid = Boolean(error)
    const showErrorMessage =
      typeof error?.message === 'string' || typeof error === 'string'

    return (
      <div
        className={cx(styles.field, {
          [styles.invalid]: isInvalid,
          [styles.withErrorMessage]: showErrorMessage,
        })}
      >
        <div className={styles.inputWrapper}>
          <textarea
            rows={3}
            {...props}
            className={cx(className, styles.input, styles.textarea, {
              [styles.invalid]: Boolean(error),
            })}
            ref={ref}
          ></textarea>
        </div>
        {showErrorMessage && (
          <div className={styles.inputError}>{error.message || error}</div>
        )}
      </div>
    )
  }
)

export function FormError({error, placeholder, ...props}) {
  const isVisible =
    typeof error?.message === 'string' || typeof error === 'string'

  if (!isVisible && !placeholder) {
    return null
  }

  return (
    <div
      {...props}
      className={cx(styles.formError, {
        [styles.placeholder]: placeholder,
        [styles.isVisible]: isVisible,
      })}
    >
      {isVisible && (error.message || error)}
    </div>
  )
}

export function FormSubmit({className, children, loading, disabled, ...props}) {
  return (
    <PrimaryButton
      {...props}
      className={cx(className, styles.submit)}
      disabled={disabled || loading}
    >
      {loading ? <Spinner size={20} /> : children}
    </PrimaryButton>
  )
}
