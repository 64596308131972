/**
 * - [ ] 默认弹窗不显示关闭按钮，可通过 hasCloseButton 设置。
 * - [ ] 默认点击背景弹窗
 * - [ ] 默认按 ESC 关闭弹窗
 * - [ ] 弹窗关闭后，keyup 事件能够正确被清理
 */

import {useEffect} from 'react'
import ReactDOM from 'react-dom'
import {IconButton} from 'components/Button'
import styles from './Modal.module.css'
import {ReactComponent as Icon} from 'icons/close.svg'

function Modal({children}) {
  const modalRoot = window.document.querySelector('#modal-root')
  return ReactDOM.createPortal(children, modalRoot)
}

/**
 * 适应不同屏幕宽度的 Modal：
 * - [ ] 默认样式为居中的 modal dialog，窄屏时展示为底部的 modal view。
 * - [ ] 默认弹窗不显示关闭按钮，可通过 hasCloseButton 设置，窄屏时不显示。
 * - [ ] 默认点击背景弹窗
 * - [ ] 默认按 ESC 关闭弹窗
 * - [ ] 弹窗关闭后，keyup 事件能够正确被清理
 */
export function ModalView({children, setVisible, hasCloseButton}) {
  useEffect(() => {
    function handleKeyUp(event) {
      if (event.key === 'Escape') {
        event.preventDefault()
        setVisible(false)
      }
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => document.removeEventListener('keyup', handleKeyUp)
  }, [setVisible])

  function handleClick() {
    setVisible(false)
  }

  return (
    <Modal>
      <div className={styles.background} onClick={handleClick}></div>
      <div className={styles.view}>
        {children}
        {hasCloseButton && (
          <IconButton className={styles.close} onClick={handleClick}>
            <Icon width={24} height={24} />
          </IconButton>
        )}
      </div>
    </Modal>
  )
}

export function ModalDialog({children, setVisible, hasCloseButton}) {
  useEffect(() => {
    function handleKeyUp(event) {
      if (event.key === 'Escape') {
        event.preventDefault()
        setVisible(false)
      }
    }

    document.addEventListener('keyup', handleKeyUp)

    return () => document.removeEventListener('keyup', handleKeyUp)
  }, [setVisible])

  function handleClick() {
    setVisible(false)
  }

  return (
    <Modal>
      <div className={styles.background} onClick={handleClick}></div>
      <div className={styles.dialog}>
        {children}
        {hasCloseButton && (
          <IconButton className={styles.close} onClick={handleClick}>
            <Icon width={24} height={24} />
          </IconButton>
        )}
      </div>
    </Modal>
  )
}
