/**
 * 登录页：
 * - [ ] 已登录访问时直接跳转
 * - [ ] 发送验证码非空错误、网络请求错误、服务端错误显示正常。
 * - [ ] 发送验证码后倒计时 60s 才能重发（前端限制）。
 * - [ ] 登录加载状态、非空错误、网络请求错误、服务端错误显示正常。
 * - [ ] 登录成功后跳转 next 地址，默认则跳首页。
 */
import React, {useLayoutEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import queryString from 'query-string'
import http from 'lib/http'
import {isLoggedIn} from 'lib/auth'
import loadingState from 'state/loading'
import useCountdown from 'hooks/useCountdown'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import Form, {
  FormTitle,
  FormInput,
  FormSubmit,
  FormError,
} from 'components/Form'
import {DefaultButton} from 'components/Button'
import ContactButton from 'components/ContactButton'
import EmptyPage from 'pages/EmptyPage'
import styles from './LoginPage.module.css'

function LoginPage() {
  const [cellphone, setCellphone] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [error, setError] = useState(null)
  const [countdown, setCountdown] = useState(0)
  const location = useLocation()
  const setLoading = useSetRecoilState(loadingState)

  const phoneInputRef = React.createRef()
  const verificationCodeInputRef = React.createRef()

  function handleSendverificationCode() {
    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    setCountdown(Date.now() + 60 * 1000)

    http('/auth/verification_code', {
      method: 'POST',
      body: {cellphone},
    }).catch((error) => {
      setError(error.body?.validationErrors || error.body?.message || error)
      setCountdown(0)
    })
  }

  function handlePhoneInputChange(e) {
    setCellphone(e.target.value)
    setError(null)
  }

  function handleVerificationCodeInputChange(e) {
    setVerificationCode(e.target.value)
    setError(null)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (cellphone.length === 0) {
      phoneInputRef.current.focus()
      setError({cellphone: true})
      return
    }

    if (verificationCode.length === 0) {
      verificationCodeInputRef.current.focus()
      setError({verificationCode: true})
      return
    }

    setLoading(true)
    http('/auth/login', {
      method: 'POST',
      body: {cellphone, verificationCode},
    }).then(
      () => {
        const query = queryString.parse(location.search)
        const {next} = query

        window.location.href = next || '/'
      },
      (error) => {
        setError(error.body?.validationErrors || error.body?.message || error)
        setLoading(false)
      }
    )
  }

  useLayoutEffect(() => {
    if (isLoggedIn()) {
      const query = queryString.parse(location.search)
      const {next} = query

      window.location.href = next || '/'
    }
  }, [location])

  if (isLoggedIn()) {
    return <EmptyPage />
  }

  return (
    <Layout.Page>
      <GlobalTopNav />
      <Layout.PageContent>
        <Form onSubmit={handleSubmit}>
          <FormTitle title="登录" />
          <FormInput
            rootClass={styles.phoneInputRoot}
            className={styles.phoneInput}
            placeholder={error?.cellphone ? '输入手机号' : '手机号'}
            type="tel"
            ref={phoneInputRef}
            value={cellphone}
            error={error?.cellphone?.[0] || error?.cellphone}
            name="cellphone"
            onChange={handlePhoneInputChange}
            renderRight={() => (
              <div
                className={styles.areaCode}
                onClick={() =>
                  window.alert('暂时仅支持中国手机号，如有需求请联系我们')
                }
              >
                +86
              </div>
            )}
          />
          <FormInput
            placeholder={error?.verificationCode ? '输入验证码' : '验证码'}
            type="tel"
            ref={verificationCodeInputRef}
            value={verificationCode}
            name="verification_code"
            onChange={handleVerificationCodeInputChange}
            error={error?.verificationCode?.[0] || error?.verificationCode}
            autoComplete="off"
            renderRight={() => (
              <VerificationCodeButton
                handleClick={handleSendverificationCode}
                countdown={countdown}
              />
            )}
          />
          <FormSubmit>下一步</FormSubmit>
          <FormError error={error} />
        </Form>
      </Layout.PageContent>
      <ContactButton />
    </Layout.Page>
  )
}

function VerificationCodeButton({handleClick, countdown}) {
  const {done, seconds} = useCountdown(countdown)

  return (
    <DefaultButton
      className={styles.verificationCodeButton}
      onClick={done ? handleClick : () => {}}
      type="button"
      disabled={!done}
    >
      {done ? '发送验证码' : `已发送 (${seconds})`}
    </DefaultButton>
  )
}

export default LoginPage
