import mapValues from 'lodash.mapvalues'
import floor from 'lodash.floor'

const DEFAULT_PLANS = {
  monthly_plan: {
    type: 'monthly_plan',
    price: '',
    enabled: true,
  },
  three_month_plan: {
    type: 'three_month_plan',
    price: '',
    enabled: true,
  },
  six_month_plan: {
    type: 'six_month_plan',
    price: '',
    enabled: true,
  },
  twelve_month_plan: {
    type: 'twelve_month_plan',
    price: '',
    enabled: true,
  },
}

// 转换服务端 plans 为前端使用的数据结构
export function parsePlans(plans) {
  if (!plans) {
    return DEFAULT_PLANS
  }

  let result = plans.reduce((result, plan) => {
    result[plan.type] = {...plan, price: plan.price / 100}
    return result
  }, {})

  return {
    ...mapValues(DEFAULT_PLANS, (plan) => ({...plan, enabled: false})),
    ...result,
  }
}

// - [ ] 取 enable 的最短周期计算折扣
// - [ ] 折扣向下保留 1 位小数（少一点就是 9.9 折）
// - [ ] 若周期短于 enabled 最短周期，则返回 null
export function getDiscount(type, plans) {
  const [basePrice, basePlanMonthCount] = getBasePrice(plans)
  const monthCount = getPriceMonthCount(type)

  if (monthCount <= basePlanMonthCount) {
    return null
  }

  return floor((plans[type].price / monthCount / basePrice) * 10, 1)
}

// - [ ] 价格为空时不会计算为基准价格。例如月度价格设为空，季度价格不会报错说高于最短周期价格
export function getBasePrice(plans) {
  const monthlyPlan = plans['monthly_plan']
  const threeMonthPlan = plans['three_month_plan']
  const sixMonthPlan = plans['six_month_plan']
  const twelveMonthPlan = plans['twelve_month_plan']

  return (
    (monthlyPlan.enabled &&
      Number(monthlyPlan.price) > 0 && [monthlyPlan.price, 1]) ||
    (threeMonthPlan.enabled &&
      Number(threeMonthPlan.price) > 0 && [threeMonthPlan.price / 3, 3]) ||
    (sixMonthPlan.enabled &&
      Number(sixMonthPlan.price) > 0 && [sixMonthPlan.price / 6, 6]) ||
    (twelveMonthPlan.enabled &&
      Number(twelveMonthPlan.price) > 0 && [twelveMonthPlan.price / 12, 12]) ||
    []
  )
}

function getPriceMonthCount(type) {
  return {
    monthly_plan: 1,
    three_month_plan: 3,
    six_month_plan: 6,
    twelve_month_plan: 12,
  }[type]
}
