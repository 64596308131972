import cx from 'classnames'
import {Link} from 'react-router-dom'
import styles from './Button.module.css'

export default function Button({children, className, ...props}) {
  return (
    <button {...props} className={cx(styles.button, className)}>
      {children}
    </button>
  )
}

export function DefaultButton({children, className, ...props}) {
  return (
    <Button {...props} className={cx(styles.defaultButton, className)}>
      {children}
    </Button>
  )
}

export function PrimaryButton({children, className, ...props}) {
  return (
    <Button {...props} className={cx(styles.primaryButton, className)}>
      {children}
    </Button>
  )
}

export function SecondaryButton({children, className, ...props}) {
  return (
    <Button {...props} className={cx(styles.secondaryButton, className)}>
      {children}
    </Button>
  )
}

export function TextButton({children, className, ...props}) {
  return (
    <Button {...props} className={cx(styles.textButton, className)}>
      {children}
    </Button>
  )
}

export function LinkButton({children, className, href, to, ...props}) {
  if (href) {
    return (
      <a
        {...props}
        href={href}
        className={cx(styles.button, styles.linkButton, className)}
      >
        {children}
      </a>
    )
  }

  if (to) {
    return (
      <Link
        {...props}
        to={to}
        className={cx(styles.button, styles.linkButton, className)}
      >
        {children}
      </Link>
    )
  }
}

export function IconButton({children, className, href, ...props}) {
  if (href) {
    return (
      <a {...props} href={href} className={cx(styles.iconButton, className)}>
        {children}
      </a>
    )
  }

  return (
    <Button {...props} className={cx(styles.iconButton, className)}>
      {children}
    </Button>
  )
}

export function ToggleButton({className, value, ...props}) {
  return (
    <button
      {...props}
      className={cx(styles.toggleButton, className, {[styles.isOn]: value})}
    />
  )
}
