/**
 * 注册流程的资料编辑页（和个人资料编辑页不同）：
 * - [ ] 未登录时跳去登录
 * - [ ] 如果已经有名字和头像则直接跳转（next 或 /users/self）
 * - [ ] 提供默认头像
 * - [ ] 上传头像加载状态、非空错误、请求错误、服务错误正常
 * - [ ] 更新资料加载状态、非空错误、请求错误、服务错误正常
 * - [ ] 更新成功后跳转
 */

import React, {useCallback, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useSetRecoilState} from 'recoil'
import queryString from 'query-string'
import http from 'lib/http'
import loadingState from 'state/loading'
import {useSelf} from 'swr/self'
import authRequired from 'hocs/authRequired'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import Form, {
  FormTitle,
  FormInput,
  FormSubmit,
  FormError,
  FormField,
} from 'components/Form'
import AvatarEditor from 'components/AvatarEditor'
import ContactButton from 'components/ContactButton'

function AuthProfilePage() {
  const location = useLocation()
  const history = useHistory()

  const {self, loading, mutate} = useSelf()
  const setLoading = useSetRecoilState(loadingState)

  useEffect(() => {
    setLoading(loading)
  }, [setLoading, loading])

  const [avatar, setAvatar] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState(null)

  const nameInputRef = React.createRef()
  const descriptionInputRef = React.createRef()

  const redirect = useCallback(() => {
    const query = queryString.parse(location.search)
    const {next} = query

    history.push(next || '/creator')
  }, [history, location])

  useEffect(() => {
    if (self?.avatar && self?.name) {
      redirect()
      return
    }
  }, [self, setAvatar, redirect])

  function handleNameInputChange(e) {
    setName(e.target.value)
    setError(null)
  }

  function handleDiscriptionInputChange(e) {
    setDescription(e.target.value)
    setError(null)
  }

  function handleSubmit(e) {
    e.preventDefault()

    if (avatar.length === 0) {
      setError({avatar: '上传头像'})
      return
    }

    if (name.length === 0) {
      nameInputRef.current.focus()
      setError({name: true})
      return
    }

    setLoading(true)
    http('/self', {
      method: 'PUT',
      body: {
        avatar,
        name,
        description,
      },
    }).then(
      () => {
        mutate({
          ...self,
          avatar,
          name,
          description,
        })
        setLoading(false)
        redirect()
      },
      (error) => {
        setError(error.body?.validationErrors || error.body?.message || error)
        setLoading(false)
      }
    )
  }

  return (
    <Layout.Page>
      <GlobalTopNav />
      <Layout.PageContent>
        {loading ? null : (
          <Form onSubmit={handleSubmit}>
            <FormTitle title="填写个人资料" subtitle="让大家知道你是谁" />
            <FormField>
              <AvatarEditor
                avatar={avatar}
                error={error}
                setError={setError}
                setAvatar={setAvatar}
              />
            </FormField>
            <FormInput
              type="text"
              placeholder={error?.name ? '输入你的名字' : '你的名字'}
              ref={nameInputRef}
              value={name}
              error={error?.name?.[0] || error?.name}
              name="name"
              autoComplete="off"
              onChange={handleNameInputChange}
            />
            <FormInput
              type="text"
              placeholder={'个人介绍（可选）'}
              ref={descriptionInputRef}
              value={description}
              name="description"
              error={error?.description?.[0] || error?.description}
              autoComplete="off"
              onChange={handleDiscriptionInputChange}
            />
            <FormSubmit>下一步</FormSubmit>
            <FormError error={error} />
          </Form>
        )}
      </Layout.PageContent>
      <ContactButton />
    </Layout.Page>
  )
}

export default authRequired(AuthProfilePage)
