/**
 * 会员计划详情页：
 * - [ ] 页面能过正常加载渲染，加载状态/错误状态/重试正确，未开通付费计划的专栏提示错误
 * - [ ] Newsletter 名称过长时会省略，名称不会和 menu button 重合
 * - [ ] 屏幕较窄时隐藏顶部导航栏 & 菜单按钮
 * - [ ] 标题文本超出宽度限制会按单词折行，默认不会把单词折断
 * - [ ] 标题如果一个超过宽度限制的长单词，则会从单词中间折断换行避免溢出
 * - [ ] 能正确展示各种富文本
 * - [ ] 主题色正确
 * - [ ] 未付费订阅时显示付费墙卡片
 * - [ ] 上下两个订阅按钮、付费墙卡片均可正常订阅
 * - [ ] 从专栏页/作品页跳转到会员计划详情页时，页面会滚动到顶部
 */
import {Link} from 'react-router-dom'
import getPublicationToken from 'lib/getPublicationToken'
import {useShareInfo} from 'lib/wechat'
import {usePublication} from 'swr/publication'
import useThemeColor from 'hooks/useThemeColor'
import withWechatMiniAppPage from 'hocs/withWechatMiniAppPage'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Error from 'components/Error'
import Avatar from 'components/Avatar'
import PageTitle from 'components/PageTitle'
import GlobalSideMenu from 'components/GlobalSideMenu'
import {SubscribeButton} from 'components/Subscribe'
import RichText from 'components/Editor/RichText'
import SubscribeModal from 'components/Subscribe/SubscribeModal'
import {SubscribePaywall} from 'components/Subscribe/SubscribePaywall'
import ScrollToTopOnMount from 'components/ScrollToTopOnMount'
import styles from './MembershipDescriptionPage.module.css'

function MembershipDescriptionPage() {
  const publicationToken = getPublicationToken()
  const {publication, error, isValidating, mutate} =
    usePublication(publicationToken)

  useThemeColor(publication?.theme?.primaryColor)

  useShareInfo(
    publication
      ? {
          title: `${publication.name} - 会员计划`,
          link: window.location.href,
          imgUrl: publication.author.avatar,
          desc: publication.description,
        }
      : null
  )

  if (!publication) {
    return (
      <Layout.Page>
        <TopNav loading={!error || isValidating} rootClass={styles.navRoot} />
        <Layout.PageContent>
          <Error
            error={error}
            handleRetry={error?.status === 404 ? null : () => mutate()}
          />
        </Layout.PageContent>
        <GlobalSideMenu buttonClass={styles.sideMenuButton} />
      </Layout.Page>
    )
  }

  if (publication && !publication.membership) {
    return (
      <Layout.Page>
        <Nav publication={publication} />
        <Layout.PageContent>
          <Error error={{message: '未开通会员计划'}} />
        </Layout.PageContent>
        <GlobalSideMenu buttonClass={styles.sideMenuButton} />
      </Layout.Page>
    )
  }

  const {isAuthor} = publication.relationship
  const author = publication.author

  let content = [{type: 'paragraph', children: [{text: ''}]}]

  try {
    content = JSON.parse(publication.membership.description)
  } catch (e) {}

  const hasPaidSubscribed =
    publication.subscription.hasSubscribed === true &&
    publication.subscription.type === 'paid'

  return (
    <Layout.Page>
      <ScrollToTopOnMount />
      <PageTitle title={`${publication.name} - 会员计划`} />
      <Nav loading={isValidating} publication={publication} />
      <Layout.PageContent>
        <Layout.View slim className={styles.post}>
          <h1 className={styles.title}>{publication.name} - 会员计划</h1>
          <Link className={styles.info} to="/">
            <Avatar src={author.avatar} size={32} className={styles.avatar} />
            <div className={styles.author}>{author.name}</div>
          </Link>
          <RichText className={styles.content} content={content} />
          {!hasPaidSubscribed && (
            <SubscribePaywall
              publication={publication}
              title="成为会员"
              isMembershipPage={true}
            />
          )}
        </Layout.View>
        <Footer publication={publication} />
      </Layout.PageContent>
      <GlobalSideMenu buttonClass={styles.sideMenuButton} isAuthor={isAuthor} />
    </Layout.Page>
  )
}

function Nav({loading, publication}) {
  return (
    <TopNav loading={loading} className={styles.nav} rootClass={styles.navRoot}>
      <Layout.View slim className={styles.navContent}>
        <Link className={styles.publication} to="/">
          {publication.name}
        </Link>
        {publication && <SubscribeButton publication={publication} />}
      </Layout.View>
    </TopNav>
  )
}

// - [ ] Footer 中专栏名字过长省略时按钮样式正常
function Footer({publication}) {
  if (!publication) {
    return null
  }

  return (
    <div className={styles.footer}>
      <Layout.View slim>
        <div className={styles.footerTip}>本文来自</div>
        <div className={styles.footerHead}>
          <Link to="/" className={styles.footerName}>
            {publication.name}
          </Link>
          <SubscribeButton
            className={styles.footerButton}
            publication={publication}
          />
        </div>
        <div className={styles.footerDescription}>
          {publication.description}
        </div>
        <div className={styles.footerSign}>
          —— 由{' '}
          <span className={styles.footerAuthor}>{publication.author.name}</span>{' '}
          创作
        </div>
      </Layout.View>
      <SubscribeModal publication={publication} />
    </div>
  )
}

// - [ ] 小程序内点击会员计划描述页链接可正常跳转到小程序
export default withWechatMiniAppPage(MembershipDescriptionPage, () => {
  const token = getPublicationToken()
  return `/pages/publication-membership/publication-membership?token=${token}`
})
