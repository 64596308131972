function loadScript(url) {
  return new Promise((resolve, reject) => {
    var script = window.document.createElement('script')
    script.type = 'text/javascript'
    script.src = url

    script.onload = () => resolve()
    script.onerror = (error) => reject(error)

    window.document.body.appendChild(script)
  })
}

export default loadScript
