import {useEffect, useState} from 'react'
import {atom, useRecoilState, useSetRecoilState} from 'recoil'
import {useQuery} from 'lib/url'
import {parseUA} from 'lib/userAgent'
import usePrevious from 'hooks/usePrevious'
import {ModalView} from 'components/Modal'
import LoginModal from 'components/LoginModal'
import SubscribeApproachesView from './SubscribeApproachesView'
import EmailSubscribeView from './EmailSubscribeView'
import SubscribeWechatBindingView from './SubscribeWechatBindingView'
import SubscribeOfficalAccountView from './SubscribeOfficalAccountView'
import EmailBindingView from './EmailBindingView'
import WechatSubscribeView from './WechatSubscribeView'
import SubscribePlansView from './SubscribePlansView'
import SubscribePaymentView from './SubscribePaymentView'
import SubscribePhoneBindingView from './SubscribePhoneBindingView'

const subscribeModalState = atom({
  key: 'subscribeModal',
  default: {
    visible: false,
    isRenew: false,
  },
})

export function useSubscribeModal() {
  const setShowSubscribeModal = useSetRecoilState(subscribeModalState)
  return (options) => setShowSubscribeModal({visible: true, ...options})
}

// - [ ] 订阅弹窗不同宽度下样式正常
// - [ ] 点击微信/邮箱订阅后关闭弹窗再次点击订阅会恢复倒默认视图（选择订阅方式）
// - [ ] 访问开通会员计划的专栏时，点击订阅按钮后显示选择订阅计划弹窗
// - [ ] 已付费订阅时点击订阅按钮显示选择订阅方式弹窗
// - [ ] 未登录状态下选择付费计划时显示登录弹窗，登录刷新页面后可直接显示订阅弹窗
// - [ ] 未登录状态下可直接免费订阅
function SubscribeModal({publication}) {
  const [{visible, isRenew}, setSubscribeModalState] =
    useRecoilState(subscribeModalState)

  // 避免因为 query 调整导致微信内 JS-SDK 失效
  // - [ ] 访问带 subscribe 参数的 URL 后可以正常跳转小程序绑定
  const userAgent = parseUA(window.navigator.userAgent)
  const subscribe = useQuery('subscribe', {clean: !userAgent.Wechat})
  useEffect(() => {
    if (subscribe === '1' || subscribe === '2') {
      setSubscribeModalState({visible: true, isRenew: subscribe === '2'})
    }
  }, [subscribe, setSubscribeModalState])

  return (
    <>
      {visible && (
        <Modal
          publication={publication}
          isRenew={isRenew}
          setShowSubscribeModal={(visible) => setSubscribeModalState({visible})}
        />
      )}
      {/* TODO：拆出去 */}
      <LoginModal />
    </>
  )
}

function Modal({publication, setShowSubscribeModal, isRenew}) {
  const hasMembership = Boolean(publication.membership)
  const hasPaidSubscribed = publication.subscription.type === 'paid'

  const [view, setView] = useState(
    hasMembership && (isRenew || !hasPaidSubscribed)
      ? 'select-plans'
      : 'select-approach'
  )
  const [plan, setPlan] = useState(null)
  const previousView = usePrevious(view)

  return (
    <ModalView
      setVisible={setShowSubscribeModal}
      hasCloseButton={view !== 'payment'}
    >
      {view === 'select-plans' && (
        <SubscribePlansView
          membership={publication.membership}
          setView={setView}
          publication={publication}
          setPlan={setPlan}
          setShowSubscribeModal={setShowSubscribeModal}
          isRenew={isRenew}
        />
      )}
      {view === 'select-approach' && (
        <SubscribeApproachesView
          previousView={previousView}
          setView={setView}
          publication={publication}
        />
      )}
      {view === 'wechat' && <WechatSubscribeView publication={publication} />}
      {view === 'email' && <EmailSubscribeView publication={publication} />}
      {view === 'wechat-binding' && (
        <SubscribeWechatBindingView
          publication={publication}
          setView={setView}
        />
      )}
      {view === 'offical-account' && (
        <SubscribeOfficalAccountView
          publication={publication}
          setView={setView}
        />
      )}
      {view === 'email-binding' && <EmailBindingView setView={setView} />}
      {view === 'phone-binding' && (
        <SubscribePhoneBindingView setView={setView} />
      )}
      {view === 'payment' && (
        <SubscribePaymentView
          setView={setView}
          setModalVisible={setShowSubscribeModal}
          plan={plan}
          publication={publication}
          isRenew={isRenew}
        />
      )}
    </ModalView>
  )
}

export default SubscribeModal
