import cx from 'classnames'
import {useCallback, useEffect, useState} from 'react'
import http from 'lib/http'
import {isLoggedIn} from 'lib/auth'
import {useSelf} from 'swr/self'
import useInterval from 'hooks/useInterval'
import {usePublication} from 'swr/publication'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import {useNotification} from 'components/Notification'
import {ReactComponent as WechatIcon} from 'icons/wechat.svg'
import {ReactComponent as MailIcon} from 'icons/mail.svg'
import {ReactComponent as CheckboxIcon} from 'icons/checkbox.svg'
import {
  SubscribeView,
  SubscribeViewDescription,
  SubscribeViewTitle,
} from './SubscribeView'
import styles from './Subscribe.module.css'

// 未登录状态打开订阅弹窗后：
// - [ ] 订阅按钮均为彩色，不显示 checkbox
// - [ ] 微信订阅显示专栏小程序码
// - [ ] 点击邮箱订阅验证邮箱订阅
// - [ ] 禁用订阅方式后按钮样式正确（禁用按钮点击、禁用 hover 样式、不显示 checkbox、底部提示作者禁用）
// - [ ] 已订阅状态下上述逻辑均正确

// 已登录状态打开订阅弹窗后：
// - [ ] 未订阅 & 已订阅样式正常
// - [ ] 点击微信订阅，若已绑定微信 & 已关注公众号，则直接订阅/取消订阅，加载/错误状态正确
// - [ ] 微信订阅/取消订阅时，父级页面（专栏页 & 作品页）会自动同步订阅状态（邮箱已订阅/未订阅）
// - [ ] 点击微信订阅，若未绑定微信，则引导扫码绑定，绑定成功后提示并返回订阅弹窗，并自动更新订阅状态
// - [ ] 点击微信订阅，若已绑定微信但未关注公众号，则引导关注公众号，关注成功后自动订阅
// - [ ] 点击邮箱订阅，若已绑定邮箱，则直接订阅/取消订阅，加载/错误状态正确
// - [ ] 点击邮箱订阅，若未绑定邮箱，则验证邮箱订阅
// - [ ] 邮箱订阅/取消邮箱订阅时，父级页面（专栏页 & 作品页）会自动同步订阅状态（微信已订阅/未订阅）
// - [ ] 禁用订阅方式后，若未订阅则禁用按钮点击，若已订阅则不做任何处理，可正常取消订阅
// - [ ] 定时轮训请求更新当前订阅状态，关闭弹窗后不再请求
function SubscribeApproachesView({setView, publication, previousView}) {
  const hasSubscribedStatus = isLoggedIn()

  let hasSubscribedWechat = true
  let hasSubscribedEmail = true
  if (hasSubscribedStatus) {
    hasSubscribedWechat = publication.subscription.approach.wechat
    hasSubscribedEmail = publication.subscription.approach.email
  }

  const {mutate} = usePublication(publication.token)
  useInterval(() => {
    if (!hasSubscribedStatus) {
      return
    }

    mutate()
  }, 5000)

  return (
    <SubscribeView>
      <SubscribeViewTitle>选择订阅方式</SubscribeViewTitle>
      <SubscribeViewDescription>
        内容更新将会以下列方式推送
      </SubscribeViewDescription>
      <div className={styles.approaches}>
        <WechatButton
          setView={setView}
          publication={publication}
          previousView={previousView}
          hasSubscribedStatus={hasSubscribedStatus}
          hasSubscribedWechat={hasSubscribedWechat}
        />
        <EmailButton
          setView={setView}
          publication={publication}
          previousView={previousView}
          hasSubscribedStatus={hasSubscribedStatus}
          hasSubscribedEmail={hasSubscribedEmail}
        />
      </div>
    </SubscribeView>
  )
}

function EmailButton({
  setView,
  publication,
  hasSubscribedStatus,
  hasSubscribedEmail,
  previousView,
}) {
  const [loading, setLoading] = useState(false)

  const {mutate} = usePublication(publication.token)
  const {self, mutate: mutateSelf} = useSelf()

  const emailDisabled =
    !publication.approach.email &&
    (!hasSubscribedStatus || (hasSubscribedStatus && !hasSubscribedEmail))

  const noti = useNotification()
  function requestUnSubscribe() {
    setLoading(true)
    http(`/publications/${publication.id}/subscription/email`, {
      method: 'DELETE',
    })
      .then(
        () => {
          mutate(
            {
              ...publication,
              subscription: {
                ...publication.subscription,
                hasSubscribed:
                  publication.subscription.type === 'paid' ||
                  publication.subscription.approach.wechat,
                approach: {...publication.subscription.approach, email: false},
              },
            },
            false
          )
          noti('已取消邮箱订阅')
        },
        (error) => {
          noti(error.body?.message || error.message || '取消失败', {
            warning: true,
          })
        }
      )
      .finally(() => setLoading(false))
  }

  // - [ ] 订阅时若未绑定邮箱，则引导绑定邮箱而不是直接报错
  // - [ ] 订阅时若已订阅，则显示订阅成功而不是直接报错
  const requestSubscribe = useCallback(() => {
    setLoading(true)
    http(`/publications/${publication.id}/subscription/email`, {
      method: 'POST',
    })
      .then(
        () => {
          mutate(
            {
              ...publication,
              subscription: {
                ...publication.subscription,
                hasSubscribed: true,
                approach: {...publication.subscription.approach, email: true},
              },
            },
            false
          )
          noti('邮箱订阅成功 🎉')
        },
        (error) => {
          if (error.body?.code === 200007) {
            mutateSelf(
              {...self, account: {...self.account, email: null}},
              false
            )
            setView('email-binding')
            return
          }

          if (error.body?.code === 600003) {
            mutate(
              {
                ...publication,
                subscription: {
                  ...publication.subscription,
                  hasSubscribed: true,
                  approach: {
                    ...publication.subscription.approach,
                    email: true,
                  },
                },
              },
              false
            )
            return
          }

          noti(error.body?.message || error.message || '订阅失败', {
            warning: true,
          })
        }
      )
      .finally(() => setLoading(false))
  }, [mutate, mutateSelf, publication, self, noti, setView])

  async function handleClick() {
    if (!hasSubscribedStatus) {
      setView('email')
      return
    }

    if (hasSubscribedEmail) {
      requestUnSubscribe()
      return
    }

    let hasBoundEmail

    // - [ ] 若点击订阅时没有成功获取 self，则同步请求 `GET /self`，加载/错误状态正确
    if (self) {
      hasBoundEmail = Boolean(self.account.email)
    } else {
      try {
        setLoading(true)
        const tmpSelf = await http('/self')
        hasBoundEmail = Boolean(tmpSelf.account.email)
        mutateSelf(tmpSelf, false)
      } catch (error) {
        noti(error.body?.message || error.message || '获取个人信息失败', {
          warning: true,
        })
        setLoading(false)
        return
      }
    }

    if (!hasBoundEmail) {
      setView('email-binding')
      return
    }

    if (!hasSubscribedEmail) {
      requestSubscribe()
      return
    }
  }

  useEffect(() => {
    if (previousView === 'email-binding') {
      requestSubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousView])

  return (
    <Button
      className={cx(styles.approach, styles.email, {
        [styles.hasSubscribed]: hasSubscribedEmail,
        [styles.loading]: loading,
      })}
      onClick={handleClick}
      disabled={emailDisabled}
    >
      {hasSubscribedStatus && !emailDisabled && (
        <CheckboxIcon
          className={cx(styles.approachCheckbox, {
            [styles.hasSubscribed]: hasSubscribedEmail,
          })}
        />
      )}
      <MailIcon className={styles.approachIcon} />
      <div className={styles.approachText}>邮箱订阅</div>
      <div className={cx(styles.loadingMask, {[styles.isVisible]: loading})}>
        <Spinner size={30} fill="#a7a8ca" />
      </div>
      {emailDisabled && (
        <div className={styles.approachTip}>邮箱订阅已被作者禁用</div>
      )}
    </Button>
  )
}

function WechatButton({
  setView,
  publication,
  previousView,
  hasSubscribedStatus,
  hasSubscribedWechat,
}) {
  const [loading, setLoading] = useState(false)

  const {mutate} = usePublication(publication.token)
  const {self, mutate: mutateSelf} = useSelf()

  const wechatDisabled =
    !publication.approach.wechat &&
    (!hasSubscribedStatus || (hasSubscribedStatus && !hasSubscribedWechat))

  const noti = useNotification()
  function requestUnSubscribe() {
    setLoading(true)
    http(`/publications/${publication.id}/subscription/wechat`, {
      method: 'DELETE',
    })
      .then(
        () => {
          mutate({
            ...publication,
            subscription: {
              ...publication.subscription,
              hasSubscribed:
                publication.subscription.type === 'paid' ||
                publication.subscription.approach.email,
              approach: {...publication.subscription.approach, wechat: false},
            },
          })
          noti('已取消微信订阅')
        },
        (error) => {
          noti(error.body?.message || error.message || '取消失败', {
            warning: true,
          })
        }
      )
      .finally(() => setLoading(false))
  }

  // - [ ] 订阅时若未关注公众号，则引导关注公众号而不是直接报错
  // - [ ] 订阅时若已订阅，则显示订阅成功而不是直接报错
  const requestSubscribe = useCallback(() => {
    setLoading(true)
    http(`/publications/${publication.id}/subscription/wechat`, {
      method: 'POST',
    })
      .then(
        () => {
          mutate(
            {
              ...publication,
              subscription: {
                ...publication.subscription,
                hasSubscribed: true,
                approach: {...publication.subscription.approach, wechat: true},
              },
            },
            false
          )
          noti('微信订阅成功 🎉')
        },
        (error) => {
          if (error.body?.code === 700006) {
            mutateSelf({...self, hasSubscribedMp: false}, false)
            setView('offical-account')
            return
          }

          if (error.body?.code === 600003) {
            mutate(
              {
                ...publication,
                subscription: {
                  ...publication.subscription,
                  hasSubscribed: true,
                  approach: {
                    ...publication.subscription.approach,
                    wechat: true,
                  },
                },
              },
              false
            )
            return
          }

          noti(error.body?.message || error.message || '订阅失败', {
            warning: true,
          })
        }
      )
      .finally(() => setLoading(false))
  }, [mutate, mutateSelf, publication, self, noti, setView])

  useEffect(() => {
    if (previousView === 'offical-account') {
      requestSubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousView])

  async function handleClick() {
    if (!hasSubscribedStatus) {
      setView('wechat')
      return
    }

    if (hasSubscribedWechat) {
      requestUnSubscribe()
      return
    }

    let hasBoundWechat
    let hasSubscribedMp

    // - [ ] 若点击订阅时没有成功获取 self，则同步请求 `GET /self`，加载/错误状态正确
    if (self) {
      hasBoundWechat = self.account.hasBoundWechat
      hasSubscribedMp = self.hasSubscribedMp
    } else {
      try {
        setLoading(true)
        const tmpSelf = await http('/self')
        hasBoundWechat = tmpSelf.account.hasBoundWechat
        hasSubscribedMp = tmpSelf.hasSubscribedMp
        mutateSelf(tmpSelf, false)
      } catch (error) {
        noti(error.body?.message || error.message || '获取个人信息失败', {
          warning: true,
        })
        setLoading(false)
        return
      }
    }

    if (!hasBoundWechat) {
      setView('wechat-binding')
      return
    }

    if (!hasSubscribedMp) {
      setView('offical-account')
      return
    }

    if (!hasSubscribedWechat) {
      requestSubscribe()
      return
    }
  }

  return (
    <Button
      className={cx(styles.approach, styles.wechat, {
        [styles.hasSubscribed]: hasSubscribedWechat,
        [styles.loading]: loading,
      })}
      onClick={handleClick}
      disabled={wechatDisabled}
    >
      {hasSubscribedStatus && !wechatDisabled && (
        <CheckboxIcon
          className={cx(styles.approachCheckbox, {
            [styles.hasSubscribed]: hasSubscribedWechat,
          })}
        />
      )}
      <WechatIcon className={styles.approachIcon} />
      <div className={styles.approachText}>微信订阅</div>
      <div className={cx(styles.loadingMask, {[styles.isVisible]: loading})}>
        <Spinner size={30} fill="#a7a8ca" />
      </div>
      {wechatDisabled && (
        <div className={styles.approachTip}>微信订阅已被作者禁用</div>
      )}
    </Button>
  )
}

export default SubscribeApproachesView
