import {parseUA} from 'lib/userAgent'
import WechatQRCode from 'components/WechatQRCode'
import {
  SubscribeView,
  SubscribeViewDescription,
  SubscribeViewTitle,
} from './SubscribeView'

// - [ ] 不同宽度下样式正常
// - [ ] 扫码能打开小程序对应的专栏页面并弹窗自动微信订阅
function WechatSubscribeView({publication}) {
  const userAgent = parseUA(window.navigator.userAgent)
  return (
    <SubscribeView>
      <SubscribeViewTitle>
        {userAgent.iPhone || userAgent.Android
          ? '保存图片后在微信中扫描'
          : '使用微信扫描二维码'}
      </SubscribeViewTitle>
      <SubscribeViewDescription>
        每期内容将通过公众号通知发送给你
      </SubscribeViewDescription>
      <WechatQRCode
        width={560}
        page="pages/publication/publication"
        scene={`asw=1&token=${publication.token}`}
        publication={publication}
      />
    </SubscribeView>
  )
}

export default WechatSubscribeView
