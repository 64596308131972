import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import loadScript from 'lib/loadScript'
import {PrimaryButton} from 'components/Button'
import styles from './WechatReceiptPage.module.css'

function WechatReceiptPage() {
  const {publicationToken} = useParams()

  useEffect(() => {
    loadScript(
      'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js'
    ).then(() => {
      const data = {action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE'}
      window.parent.postMessage(
        JSON.stringify(data),
        'https://payapp.weixin.qq.com'
      )
    })
  }, [])

  function handleClick() {
    const data = {
      action: 'jumpOut',
      jumpOutUrl: `https://${publicationToken}.zhubai.love?subscribe=1`,
    }
    window.parent.postMessage(
      JSON.stringify(data),
      'https://payapp.weixin.qq.com'
    )
  }

  return (
    <>
      <div className={styles.page}>
        <div className={styles.card}>
          <h1 className={styles.title}>最后一步👇</h1>
          <PrimaryButton className={styles.button} onClick={handleClick}>
            开启订阅
          </PrimaryButton>
          <div className={styles.tip}>
            <span className={styles.tipIcon}></span>未开启订阅则无法接收更新
          </div>
        </div>
      </div>
    </>
  )
}

export default WechatReceiptPage
