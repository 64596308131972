// 表格：
// - [ ] 宽度固定，屏幕较窄时可以 scroll

import cx from 'classnames'
import React from 'react'
import LoadingPlaceholder from 'components/LoadingPlaceholder'
import Error from 'components/Error'
import styles from './CreatorTable.module.css'

function CreatorTable({children, className, rootClass, ...props}) {
  return (
    <div className={cx(styles.root, rootClass)}>
      <table {...props} className={cx(styles.table, className)}>
        {children}
      </table>
    </div>
  )
}

export const CreatorTableLoading = React.forwardRef((props, ref) => {
  return (
    <tr ref={ref} className={styles.isLoading}>
      <td>
        <LoadingPlaceholder.Rectangle
          style={{height: '30px', width: '100%', marginTop: '20px'}}
        />
        <LoadingPlaceholder.Rectangle
          style={{height: '30px', width: '75%', marginTop: '20px'}}
        />
        <LoadingPlaceholder.Rectangle
          style={{height: '30px', width: '50%', marginTop: '20px'}}
        />
      </td>
    </tr>
  )
})

export function CreatorTableError({error, handleRetry, colSpan}) {
  return (
    <tr className={styles.isError}>
      <td colSpan={colSpan}>
        <Error error={error} handleRetry={handleRetry} />
      </td>
    </tr>
  )
}

export function CreatorTableEmpty({colSpan, children}) {
  return (
    <tr className={styles.isEmpty}>
      <td colSpan={colSpan}>{children}</td>
    </tr>
  )
}

export default CreatorTable
