import {ModalDialog} from 'components/Modal'
import styles from './ComingSoonModal.module.css'

function ComingSoonModal({setVisible}) {
  return (
    <ModalDialog setVisible={setVisible} hasCloseButton={true}>
      <div className={styles.dialog}>
        <img
          className={styles.image}
          src="https://img.zhubai.love/9b7f357f94a84ec18304df87f74ffd92.png"
          width="80px"
          height="80px"
          alt="即将到来"
        />
        <h1 className={styles.title}>努力开发中</h1>
        <div className={styles.description}>
          如有需要，请{/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://zhubai.love/contact" target="_blank">
            联系我们
          </a>
        </div>
      </div>
    </ModalDialog>
  )
}

export default ComingSoonModal
