/**
 * 草稿编辑页（/drafts/:id/edit)
 *
 * - [ ] 首先加载草稿，加载状态、错误状态、重试正常
 * - [ ] 能够正常编辑、预览、发布
 */

import {useParams} from 'react-router-dom'
import {useDraft} from 'swr/post'
import authRequired from 'hocs/authRequired'
import Layout from 'components/Layout'
import TopNav from 'components/TopNav'
import Error from 'components/Error'
import WritePage from 'pages/WritePage'

function DraftEditPage() {
  const {draftId} = useParams()

  const {draft, error, loading, isValidating, mutate} = useDraft(draftId)

  if (!draft) {
    return (
      <Layout.Page>
        <TopNav loading={loading || isValidating} />
        <Layout.PageContent>
          <Error error={error} handleRetry={() => mutate()} />
        </Layout.PageContent>
      </Layout.Page>
    )
  }

  return <WritePage draft={draft} mutateDraft={mutate} />
}

export default authRequired(DraftEditPage)
