// 根据编辑器中的 content 生成服务端所需要的内容
import {extractPaywall, getContentHTML} from 'components/Editor/PaywallPlugin'
import {removeEdgedEmptyParagraphs} from 'components/Editor/RichTextUtils'

export function parseContent(rawContent, publication, ignorePaywall = false) {
  const {content, paywall} = extractPaywall(
    removeEdgedEmptyParagraphs(rawContent)
  )

  return {
    content: JSON.stringify(content),
    paywall,
    emailHtml: {
      ...getContentHTML({
        content,
        paywall,
        publication,
        ignorePaywall,
      }),
    },
  }
}
