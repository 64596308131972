// - [ ] 不同宽度下样式正确

import {FormInput} from 'components/Form'
import SettingsField, {SettingsTip} from './SettingsField'
import styles from './SettingsField.module.css'

function PublicationDomainField({self}) {
  const {publication} = self

  return (
    <SettingsField
      name="域名"
      className={styles.disabled}
      renderContent={() => (
        <>
          <FormInput
            className={styles.domainInput}
            placeholder="自定义域名"
            name="subdomain"
            value={publication.token}
            autoComplete="off"
            disabled
            renderRight={() => (
              <div className={styles.domainText}>.zhubai.love</div>
            )}
          />
          <SettingsTip>暂时无法修改，如有需要请联系我们</SettingsTip>
        </>
      )}
    />
  )
}

export default PublicationDomainField
