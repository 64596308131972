import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import cx from 'classnames'
import {getRelativeTimeString} from 'lib/time'
import http from 'lib/http'
import {useSelfMessagesUnreadStatus} from 'swr/self'
import selfRequired from 'hocs/selfRequired'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import {UserAvatar} from 'components/Avatar'
import Error from 'components/Error'
import InfiniteList, {useSWRInfiniteList} from 'components/InfiniteList'
import LoadingPlaceholder from 'components/LoadingPlaceholder'
import {usePageTitle} from 'components/PageTitle'
import {useLoadingBar} from 'components/TopNav'
import ConversationView from './ConversationView'
import styles from './MessagesPage.module.css'

function MessagesPage() {
  const {conversationId} = useParams()
  const [selectedConversation, setSelectedConversation] = useState(null)
  const [loadConverstionsSuccess, setLoadConverstionsSuccess] = useState(false)
  const history = useHistory()

  const listElementRef = useRef()

  // - [ ] 首次进入消息页面时可正常选中对话
  // - [ ] 选中对话后后退可以返回列表页
  useEffect(() => {
    if (!conversationId) {
      setSelectedConversation(null)
    }
  }, [conversationId])

  // - [ ] 直接访问 /messages/:id 则替换 URL 为 /messages
  useEffect(() => {
    if (conversationId && !selectedConversation) {
      history.replace('/messages')
    }
  }, [conversationId, selectedConversation, history])

  // - [ ] 对话列表页 title 正确
  // - [ ] 有/无专栏时消息列表页 title 正确
  let title = '消息列表'
  if (selectedConversation) {
    title = `${selectedConversation.user.name}${
      selectedConversation.publication
        ? ' · ' + selectedConversation.publication.name
        : ''
    }`
  }

  usePageTitle(title)

  return (
    <Layout.Page
      className={cx(styles.page, {
        [styles.isSelected]: Boolean(selectedConversation),
      })}
    >
      <GlobalTopNav rootClass={styles.topNav} />
      <Layout.View className={styles.pageContent}>
        <div className={styles.table}>
          <div className={styles.left}>
            <div className={styles.nav}>消息列表</div>
            <div className={styles.list} ref={listElementRef}>
              <ConversationsList
                setSelectedConversation={setSelectedConversation}
                loadConverstionsSuccess={loadConverstionsSuccess}
                selectedConversation={selectedConversation}
                setLoadConverstionsSuccess={setLoadConverstionsSuccess}
              />
            </div>
          </div>
          <div className={styles.right}>
            <ConversationView
              conversation={selectedConversation}
              loadConverstionsSuccess={loadConverstionsSuccess}
              listElementRef={listElementRef}
            />
          </div>
        </div>
      </Layout.View>
    </Layout.Page>
  )
}

const LoadingItem = React.forwardRef((props, ref) => (
  <div className={styles.conversation} ref={ref}>
    <LoadingPlaceholder.Circle style={{width: '50px', height: '50px'}} />
    <div className={styles.description}>
      <LoadingPlaceholder.Rectangle style={{height: '21px', width: '40%'}} />
      <LoadingPlaceholder.Rectangle
        style={{marginTop: '8px', height: '21px', width: '75%'}}
      />
    </div>
  </div>
))

const Loading = React.forwardRef(({isEmpty}, ref) => {
  if (isEmpty) {
    return (
      <>
        <LoadingItem ref={ref} />
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
        <LoadingItem />
      </>
    )
  }

  return <LoadingItem ref={ref} />
})

function ConversationsList({
  selectedConversation,
  setSelectedConversation,
  loadConverstionsSuccess,
  setLoadConverstionsSuccess,
}) {
  const {
    isValidating,
    mutate: mutateConversations,
    data,
  } = useSWRInfiniteList({
    api: '/conversations',
    revalidateOnFocus: false,
  })

  useLoadingBar(isValidating)

  // - [ ] 选择对话后清除小红点
  // - [ ] 选择对话可正常加载下一页对话
  const mutateConversation = useCallback(
    (newConversation) => {
      const updatedData = data.map((page) => ({
        ...page,
        data: page.data.map((conversation) => {
          if (conversation.id === newConversation.id) {
            return {
              ...conversation,
              ...newConversation,
            }
          }

          return conversation
        }),
      }))

      mutateConversations(updatedData, false)
    },
    [data, mutateConversations]
  )

  // - [ ] 列表加载成功后标记已读，并更新状态
  // - [ ] 加载下一页时不会重复标记
  // - [ ] 列表加载失败不标记已读
  const {mutate: mutateUnreadStatus} = useSelfMessagesUnreadStatus()
  function handleSuccss() {
    if (loadConverstionsSuccess) {
      return
    }

    setLoadConverstionsSuccess(true)

    http('/conversation_unread_badge', {
      method: 'PUT',
      body: {hasUnreadBadge: false},
    })
      .then(() => mutateUnreadStatus({hasUnreadBadge: false}))
      .catch(() => {})
  }

  return (
    <InfiniteList
      api="/conversations"
      renderItem={({item: conversation}) => (
        <ConversationItem
          key={conversation.id}
          conversation={conversation}
          setSelectedConversation={setSelectedConversation}
          isSelected={conversation.id === selectedConversation?.id}
          mutateConversation={mutateConversation}
        />
      )}
      renderLoading={(ref, isEmpty) => <Loading ref={ref} isEmpty={isEmpty} />}
      renderEmpty={() => <div className={styles.empty}>还没有收到消息</div>}
      renderError={(error, handleRetry, isEmpty) => (
        <Error
          className={cx(styles.error, {[styles.isInline]: !isEmpty})}
          error={error}
          handleRetry={handleRetry}
        />
      )}
      onSuccess={handleSuccss}
      revalidateOnFocus={false}
    />
  )
}

function ConversationItem({
  conversation,
  isSelected,
  setSelectedConversation,
  mutateConversation,
}) {
  const {user, publication, lastMessage, unreadBadge} = conversation
  const history = useHistory()

  function handleClick() {
    setSelectedConversation(conversation)
    mutateConversation({
      ...conversation,
      unreadBadge: false,
    })
    history.push(`/messages/${conversation.id}`)
  }

  return (
    <div
      className={cx(styles.conversation, {[styles.isSelected]: isSelected})}
      onClick={handleClick}
    >
      <div className={cx(styles.avatar, {[styles.withBadge]: unreadBadge})}>
        <UserAvatar user={user} size="50px" />
      </div>
      <div className={styles.description}>
        <div className={styles.info}>
          <div className={styles.user}>
            <span className={styles.username}>{user.name}</span>
            {publication && <span> · {publication.name}</span>}
          </div>
          <div className={styles.time}>
            {getRelativeTimeString(
              lastMessage?.createdAt || conversation.createdAt
            )}
          </div>
        </div>
        <div className={styles.lastMessage}>
          {lastMessage?.content.text || '还没有收到消息'}
        </div>
      </div>
    </div>
  )
}

export default selfRequired(MessagesPage)
