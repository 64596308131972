/**
 * 通用错误组件：
 * - [ ] 正常显示服务端错误、网络错误信息
 * - [ ] 有重试 handler 时显示错误按钮
 */

import cx from 'classnames'
import {DefaultButton} from 'components/Button'
import styles from './Error.module.css'

function Error({error, handleRetry, className}) {
  if (!error) {
    return null
  }

  return (
    <div className={cx(styles.error, className)}>
      <div className={styles.message}>
        {error?.body?.message || error?.message || error || '出现未知错误'}
      </div>
      {handleRetry && (
        <DefaultButton className={styles.retry} onClick={handleRetry}>
          重试
        </DefaultButton>
      )}
    </div>
  )
}

export default Error
