import {Text} from 'slate'
import {Divider, EditableDivider} from './DividerPlugin'
import {Image, EditableImage} from './ImagePlugin'
import {SubscribePaywall} from 'components/Subscribe/SubscribePaywall'
import {PaywallDivider} from './PaywallPlugin'

export function EditableElement({attributes, children, element}) {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'block-code':
      return <pre {...attributes}>{children}</pre>
    case 'link':
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a {...attributes} href={element.url} target="_blank">
          {children}
        </a>
      )
    case 'image':
      return (
        <EditableImage attributes={attributes} element={element}>
          {children}
        </EditableImage>
      )
    case 'divider':
      return (
        <EditableDivider attributes={attributes}>{children}</EditableDivider>
      )
    case 'paywall-divider':
      return <PaywallDivider attributes={attributes}>{children}</PaywallDivider>
    default:
      return <p {...attributes}>{children}</p>
  }
}

// - [ ] 空段落可正常渲染，空 <span> 会被过滤
export function Element({children, element}) {
  const {type, ...props} = element
  switch (type) {
    case 'block-quote':
      return <blockquote>{children}</blockquote>
    case 'heading-one':
      return <h1>{children}</h1>
    case 'heading-two':
      return <h2>{children}</h2>
    case 'numbered-list':
      return <ol>{children}</ol>
    case 'bulleted-list':
      return <ul>{children}</ul>
    case 'list-item':
      return <li>{children}</li>
    case 'block-code':
      return <pre>{children}</pre>
    case 'link':
      return (
        // eslint-disable-next-line react/jsx-no-target-blank
        <a href={element.url} target="_blank">
          {children}
        </a>
      )
    case 'image':
      return <Image element={element} />
    case 'divider':
      return <Divider />
    case 'paragraph':
      if (
        element.children?.length === 1 &&
        Text.isText(element.children[0]) &&
        element.children[0].text === ''
      ) {
        return (
          <p>
            <br />
          </p>
        )
      }
      return <p>{children}</p>
    case 'paywall':
      return <SubscribePaywall {...props} />
    default:
      return null
  }
}
