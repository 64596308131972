/**
 * 自定义主题色：
 *
 * - [ ] 在 Newsletter 页面 & 作品页面添加预览 query 能够改变主题色 ?theme_primary_color=214%2C86%2C0
 * - [ ] 从有 query 的 Newsletter 页面跳去无 query 的作品页能正常恢复主题色（作品页 -> 编辑页面）
 * - [ ] 透明的主题色展示正常，例如 Newsletter 页面头部背景色
 * - [ ] 优先级：预览 query > 用户设置的主题色
 *
 */

import {useEffect} from 'react'
import queryString from 'query-string'

function useThemeColor(themePrimaryColor) {
  useEffect(() => {
    const rootElement = document.querySelector(':root')

    const query = queryString.parse(window.location.search)
    const color = query.theme_primary_color || themePrimaryColor

    if (color) {
      rootElement.style.setProperty('--theme-primary-color', color)

      return () =>
        rootElement.style.setProperty('--theme-primary-color', '86,59,218')
    }
  }, [themePrimaryColor])
}

export default useThemeColor
