/**
 * 微信小程序码：
 * - [ ] 可正常加载和展示二维码，扫码可正常打开小程序
 * - [ ] 图片默认为 2x 图，展示的宽度正确
 * - [ ] 图片加载状态、错误状态、重试逻辑正确
 */

import queryString from 'query-string'
import cx from 'classnames'
import {useState} from 'react'
import Error from 'components/Error'
import Spinner from 'components/Spinner'
import styles from './WechatQRCode.module.css'

function WechatQRCode({
  page,
  scene,
  envVersion,
  width,
  autoColor,
  lineColor,
  isHyaline,
  className,
  publication,
}) {
  const [success, setSuccess] = useState(false)
  function handleLoad() {
    setSuccess(true)
  }

  const [error, setError] = useState(null)
  function handleError() {
    setError({message: '获取小程序码失败'})
    setSuccess(false)
  }

  const [timestamp, setTimestamp] = useState('')
  function handleRetry() {
    setTimestamp(`&t=${new Date().getTime()}`)
    setError(null)
  }

  const query = queryString.stringify({
    page,
    scene,
    env_version: envVersion,
    width,
    auto_color: autoColor,
    line_color: lineColor,
    is_hyaline: isHyaline,
    publication_id: publication?.id,
  })
  const url = `/api/wechat/miniprogram_qrcode?${query}${timestamp}`

  return (
    <div
      className={cx(styles.root, className)}
      style={{height: `${width / 2}px`, minWidth: `${width / 2}px`}}
    >
      {!error && (
        <div className={styles.loading}>
          <Spinner size={30} />
        </div>
      )}
      {width >= 400 && <Error error={error} handleRetry={handleRetry} />}
      {width < 400 && error && (
        <div className={styles.error} onClick={handleRetry}>
          点击重试
        </div>
      )}
      {!error && (
        <img
          className={cx(styles.image, {[styles.isVisible]: success})}
          width={`${width / 2}px`}
          height={`${width / 2}px`}
          onLoad={handleLoad}
          onError={handleError}
          alt="竹白小程序码"
          src={url}
        />
      )}
    </div>
  )
}

export default WechatQRCode
