/**
 * 退订页面：
 *
 * - [ ] 默认显示 loading
 * - [ ] 错误显示错误信息，并支持重试（自动刷新页面）
 * - [ ] 成功提示退订成功
 */
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import http from 'lib/http'
import getPublicationToken from 'lib/getPublicationToken'
import Layout from 'components/Layout'
import Spinner from 'components/Spinner'
import Error from 'components/Error'
import {usePublication} from 'swr/publication'

function UnsubscribeNewsletterPage() {
  const token = getPublicationToken()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const {publication, error: publicationError} = usePublication(token)

  useEffect(() => {
    if (!publication) {
      return
    }

    const query = queryString.parse(location.search) || {}

    setLoading(true)
    setError(null)
    http(`/publications/${publication.id}/subscribers/email`, {
      method: 'DELETE',
      body: {...query},
    }).then(
      () => setLoading(false),
      (error) => {
        setLoading(false)
        setError(error)
      }
    )
  }, [location, publication])

  return (
    <Layout.Page>
      <Layout.PageContent>
        {loading && !publicationError && <Spinner />}
        {(error || publicationError) && (
          <Error
            error={error || publicationError}
            handleRetry={() => window.location.reload()}
          />
        )}
        {!loading && !error && !publicationError && (
          <div style={{margin: 'auto', fontSize: '22px'}}>退订成功</div>
        )}
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default UnsubscribeNewsletterPage
