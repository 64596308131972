import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import PageTitle from 'components/PageTitle'
import styles from './ContactPage.module.css'

function ContactPage() {
  return (
    <Layout.Page>
      <PageTitle title="联系我们" />
      <GlobalTopNav transparent />
      <Layout.PageContent className={styles.page}>
        <h1 class={styles.title}>联系我们</h1>
        <div class={styles.description}>
          可以用任意方式找到我们，我们会及时回复
        </div>
        <div class={styles.contacts}>
          <div class={styles.item}>
            <img
              class={styles.image}
              src="https://img.zhubai.love/d84e9831ce8f4a2fa84f0e57d7efe76e.png"
              width="200px"
              height="200px"
              alt="竹白企业微信二维码"
            />
            <div class={styles.text}>
              通过微信联系我们<b>（推荐）</b>
            </div>
          </div>
          <div class={styles.item}>
            <img
              class={styles.image}
              src="https://img.zhubai.love/df6c084d828a44509b675e6fa547ffe1.png"
              width="200px"
              height="200px"
              alt="liqi@zhubai.love"
            />
            <div class={styles.text}>
              通过
              <a href="mailto:liqi@zhubai.love?subject=%E9%97%AE%E9%A2%98%E5%92%A8%E8%AF%A2">
                邮件
              </a>
              联系我们
            </div>
          </div>
        </div>
      </Layout.PageContent>
    </Layout.Page>
  )
}

export default ContactPage
