// - [ ] 操作按钮在宽屏下出现在右侧，窄屏下出现在下方，间距正确
// - [ ] input 宽度自适应
// - [ ] 保存按钮 loading 样式正确

import cx from 'classnames'
import Button, {PrimaryButton} from 'components/Button'
import Spinner from 'components/Spinner'
import styles from './SettingsField.module.css'

function Field({name, renderContent, renderAction, className, contentClass}) {
  return (
    <div className={cx(styles.field, className)}>
      <div className={styles.name}>{name}：</div>
      <div className={cx(styles.content, contentClass)}>
        <div className={styles.inputRoot}>{renderContent?.()}</div>
        <div className={styles.actions}>{renderAction?.()}</div>
      </div>
    </div>
  )
}

export function SettingsSaveButton({loading, ...props}) {
  return (
    <PrimaryButton {...props} className={styles.saveButton} disabled={loading}>
      {loading ? <Spinner size={20} fill="#fff" /> : '保存'}
    </PrimaryButton>
  )
}

export function SettingsCancelButton({loading, ...props}) {
  return (
    <Button {...props} className={styles.cancelButton} disabled={loading}>
      取消
    </Button>
  )
}

export function SettingsTip({children}) {
  return <div className={styles.tip}>{children}</div>
}

export default Field
