const blocklistSubdomains = ['www', 'dev']

export default function getPublicationToken() {
  const baseHost = window.location.hostname.includes('zhubai.love')
    ? process.env.REACT_APP_BASE_HOST
    : process.env.REACT_APP_BASE_HOST_OLD

  const token = window.location.hostname.replace(baseHost, '').split('.')[0]

  if (token.length === 0 || blocklistSubdomains.includes(token)) {
    return null
  } else {
    return token
  }
}
