import {useCallback, useRef, useState} from 'react'
import cx from 'classnames'
import http from 'lib/http'
import Spinner from 'components/Spinner'
import Avatar from 'components/Avatar'
import styles from './AvatarEditor.module.css'
import {ReactComponent as DefaultAvatar} from 'icons/default-avatar.svg'
import {ReactComponent as CameraIcon} from 'icons/camera.svg'

function AvatarEditor({avatar, error, setAvatar, setError, onChange, style}) {
  const [uploading, setUploading] = useState(false)
  const inputRef = useRef()
  const handleClick = useCallback(() => inputRef.current.click(), [])
  const handleChange = useCallback(
    (e) => {
      const image = e.target.files[0]

      if (!image) {
        return
      }

      const formData = new FormData()
      formData.append('file', image)

      setUploading(true)
      http(`/uploading/images`, {
        method: 'POST',
        body: formData,
      }).then(
        ({url}) => {
          setUploading(false)
          setError({avatar: null})
          setAvatar(url)
        },
        (error) => {
          // 上传失败后 reset 从而可以重复选择并上传同一个文件
          e.target.value = ''
          setUploading(false)
          setError({avatar: error.body?.message || error.message})
        }
      )

      if (onChange) {
        onChange()
      }
    },
    [setError, setAvatar, onChange]
  )

  return (
    <div className={styles.wrapper} onClick={handleClick} style={style}>
      <div className={cx(styles.avatarEditor, {[styles.uploading]: uploading})}>
        {avatar ? (
          <Avatar src={avatar} alt="avatar" size="86px" />
        ) : (
          <DefaultAvatar
            width={86}
            height={86}
            className={cx(styles.avatar, {
              [styles.invaildAvatar]: error?.avatar,
            })}
          />
        )}
        <div className={styles.avatarEditorIcon}>
          {uploading ? <Spinner /> : <CameraIcon size={18} fill="#563BDA" />}
        </div>
        <input
          ref={inputRef}
          type="file"
          name="avatar"
          accept="image/png, image/jpeg"
          style={{display: 'none'}}
          onChange={handleChange}
          disabled={uploading}
        />
      </div>
      <div
        className={cx(styles.text, {
          [styles.isInvaild]: error?.avatar,
        })}
      >
        {error?.avatar?.message ||
          error?.avatar ||
          `${avatar ? '编辑' : '上传'}头像`}
      </div>
    </div>
  )
}

export default AvatarEditor
