import useSWR from 'swr'

export function usePublication(token) {
  const {data, error, ...rest} = useSWR(
    token ? `/publications/${token}?id_type=token` : null
  )

  return {
    ...rest,
    error,
    publication: data,
    loading: !error && !data,
  }
}

export function usePublicationPosts(token) {
  const {data, error, ...rest} = useSWR(
    token ? `/publications/${token}/posts?publication_id_type=token` : null
  )

  return {
    ...rest,
    error,
    data,
    posts: data?.data,
    loading: !error && !data,
  }
}

export function usePublicationMembership(id) {
  const {data, ...rest} = useSWR(id ? `/publications/${id}/membership` : null, {
    shouldRetryOnError: false,
  })

  return {
    ...rest,
    membership: data,
  }
}
