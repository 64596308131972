/**
 * 页面结构：
 * - [ ] 页面最小高度是窗口大小（404 页面文字能正常居中），且能适应浏览器不同的导航栏高度（Chrome, iOS Safair）
 * - [ ] 页面内容区能够占据除了导航栏之外的所有空间
 * - [ ] 页面内容区左右间距在不同窗口宽度下样式正常
 */

import React from 'react'
import cx from 'classnames'
import styles from './Layout.module.css'

function Page({children, className}) {
  return <div className={cx(className, styles.page)}>{children}</div>
}

const PageContent = React.forwardRef(({children, className}, ref) => {
  return (
    <div className={cx(className, styles.content)} ref={ref}>
      {children}
    </div>
  )
})

function View({children, className, slim, ...props}) {
  return (
    <div
      className={cx(className, styles.view, {[styles.isSlim]: slim})}
      {...props}
    >
      {children}
    </div>
  )
}

const Layout = {
  Page,
  PageContent,
  View,
}

export default Layout
