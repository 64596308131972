import {Editor, Node, Range, Transforms} from 'slate'
import {HistoryEditor} from 'slate-history'
import {isMarkActive} from './EditorUtil'

const SHORTCUTS = {
  '#': 'heading-one',
  '##': 'heading-two',
  '>': 'block-quote',
  '```': 'block-code',
  '1.': 'numbered-list',
  '*': 'bulleted-list',
  '-': 'bulleted-list',
  '+': 'bulleted-list',
  // '---': 'divider',
}

/**
 * 支持 Markdown 语法的快捷键
 *
 * 块级样式：
 * - [ ] 在行首可以通过快捷键转换块级样式
 * - [ ] 快捷键默认按空格后触发，按 `-` 不会触发
 * - [ ] 默认支持空行 & 非空行（分割线仅能在空行触发）
 * - [ ] 转换后可以通过 Command + Z 恢复为原来的样式 & 文本，文本保留空格
 * - [ ] 分割线快捷键无需按空格触发，Command + Z 能正常恢复
 *
 * 行内样式：
 * - [ ] 从前到后输入 `code`, _italic_, *italic* 能够生效，Comman + Z 能正常恢复
 * - [ ] 从前到后输入 __italic__, **italic** 能够生效，Comman + Z 能正常恢复
 * - [ ] 在现有文本前后插各上述快捷能够生效，Comman + Z 能正常恢复
 * - [ ] 从前到后输入 `code1`code2` 时（中间需按 Command + Z 撤销），code 2 生效，其他快捷键类似。
 * - [ ] 连续输入各快捷键（`*_）时不会生效
 * - [ ] 从前到后输入 `code1* 时不会生效
 * - [ ] 支持行内元素的嵌套
 *   - [ ] 例如在斜体文本中可以使用加粗快捷键
 *   - [ ] 在已生效的行内样式文本中输入快捷键不会生效
 *   - [ ] 不可跨多个内节元素使用（暂时会导致无法通过快捷键给链接添加样式）
 */
export function withShortcuts(editor) {
  const {insertText} = editor

  editor.insertText = (text) => {
    const {selection} = editor

    if (
      (text === ' ' || text === '-') &&
      selection &&
      Range.isCollapsed(selection)
    ) {
      const {anchor} = selection
      const block = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      })
      const node = block ? block[0] : []
      const path = block ? block[1] : []
      const start = Editor.start(editor, path)
      const range = {anchor, focus: start}
      const beforeText = Editor.string(editor, range)

      let type
      if (text === '-' && beforeText === '--') {
        type = 'divider'
      } else if (text === ' ') {
        type = SHORTCUTS[beforeText]
      }

      // 分割线仅能在空行触发
      if (type === 'divider' && Node.string(node).length > 3) {
        insertText(text)
        return
      }

      if (type) {
        insertText(text)

        const previousUndosCount = editor.history?.undos.length
        HistoryEditor.withoutMerging(editor, () => {
          Transforms.select(editor, {
            focus: range.focus,
            anchor: {...range.anchor, offset: range.anchor.offset + 1},
          })
          Transforms.delete(editor)

          if (type === 'numbered-list' || type === 'bulleted-list') {
            const newProperties = {type: 'list-item', temp: true}
            Transforms.setNodes(editor, newProperties)
            Transforms.wrapNodes(editor, {type, children: []})
            Transforms.unsetNodes(editor, 'temp')
          } else if (type === 'divider') {
            const newProperties = {type}
            Transforms.setNodes(editor, newProperties, {
              match: (n) => Editor.isBlock(editor, n),
            })
          } else {
            const newProperties = {type}
            Transforms.setNodes(editor, newProperties, {
              match: (n) => Editor.isBlock(editor, n),
            })
          }
        })
        const currentUndosCount = editor.history?.undos.length

        try {
          const mergeCount = currentUndosCount - previousUndosCount
          const mergedUndos = editor.history.undos
            .splice(-mergeCount, mergeCount)
            .flat()
          editor.history.undos.push(mergedUndos)
        } catch (e) {
          console.error(e)
        }

        return
      }
    }

    if (text === '*' || text === '`' || text === '_') {
      const {anchor} = selection
      const [, textPath] = Editor.leaf(editor, anchor)
      const start = Editor.start(editor, textPath)
      const range = {anchor: start, focus: anchor}
      const beforeText = Editor.string(editor, range)

      const match = matchMarkShortcut(beforeText, text)

      if (match) {
        insertText(text)

        if (isMarkActive(editor, match.type)) {
          return
        }

        const range = {
          anchor: {path: anchor.path, offset: match.index},
          focus: {path: anchor.path, offset: anchor.offset + 1},
        }

        const previousUndosCount = editor.history?.undos.length
        HistoryEditor.withoutMerging(editor, () => {
          Transforms.select(editor, range)
          Editor.addMark(editor, match.type, true)
          Editor.insertText(editor, match.text)
          Editor.removeMark(editor, match.type)
        })
        const currentUndosCount = editor.history?.undos.length

        try {
          const mergeCount = currentUndosCount - previousUndosCount
          const mergedUndos = editor.history.undos
            .splice(-mergeCount, mergeCount)
            .flat()
          editor.history.undos.push(mergedUndos)
        } catch (e) {
          console.error(e)
        }

        return
      }
    }

    insertText(text)
  }

  return editor
}

function matchMarkShortcut(string, inputText) {
  const endingChar = string[string.length - 1]

  if (inputText === '`') {
    const match = Array.from(string.matchAll(/`/g))
    if (match.length === 0) {
      return
    }

    const {index} = match[match.length - 1]
    if (index === string.length - 1) {
      return
    }

    return {type: 'code', index, text: string.substring(index + 1)}
  }

  if (inputText === '*') {
    if (endingChar === '*') {
      const match = Array.from(string.matchAll(/\*\*/g))

      if (match.length === 0) {
        return
      }

      const {index} = match[match.length - 1]

      if (index > string.length - 4) {
        return
      }

      return {
        type: 'bold',
        index,
        text: string.substring(index + 2, string.length - 1),
      }
    } else {
      const match = Array.from(string.matchAll(/\*/g))
      if (match.length > 0) {
        const {index} = match[match.length - 1]

        if (index === string.length - 1) {
          return
        }

        if (string[index - 1] === '*') {
          return
        }

        return {type: 'italic', index, text: string.substring(index + 1)}
      }
    }
  }

  if (inputText === '_') {
    if (endingChar === '_') {
      const match = Array.from(string.matchAll(/__/g))

      if (match.length === 0) {
        return
      }

      const {index} = match[match.length - 1]

      if (index > string.length - 4) {
        return
      }

      return {
        type: 'bold',
        index,
        text: string.substring(index + 2, string.length - 1),
      }
    } else {
      const match = Array.from(string.matchAll(/_/g))
      if (match.length > 0) {
        const {index} = match[match.length - 1]

        if (index === string.length - 1) {
          return
        }

        if (string[index - 1] === '_') {
          return
        }

        return {type: 'italic', index, text: string.substring(index + 1)}
      }
    }
  }
}
