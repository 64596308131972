// - [ ] 能够正常翻页并更新 query。
// - [ ] 0 页（未加载完成）或 1 页时不显示页码。
// - [ ] 始终显示第一页、最后一页和当前页（可能和第一页、最后一页重复）。
// - [ ] 默认显示当前页前后一页，current - 2 和第一页相差不止一页时，显示「...」，点击后时 current - 2 页，current + 3 类似。
import cx from 'classnames'
import queryString from 'query-string'
import {useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import Button from 'components/Button'
import styles from './CreatorSubscribersPage.module.css'

function CreatorPagination({pagination, className}) {
  const history = useHistory()
  const location = useLocation()
  const parsedSearch = queryString.parse(location.search)

  const pageIndex = usePageIndex()

  const pageCount = pagination
    ? Math.ceil(pagination.totalCount / pagination.limit)
    : 0

  const disablePrevious = pageIndex <= 1
  const disableNext = pageCount <= pageIndex

  if (pageCount < 2) {
    return null
  }

  function handlePrevious() {
    const search = {
      ...parsedSearch,
      page: pageIndex - 1,
    }
    history.replace(`${location.pathname}?${queryString.stringify(search)}`)
  }

  function handleNext() {
    const search = {
      ...parsedSearch,
      page: pageIndex + 1,
    }
    history.replace(`${location.pathname}?${queryString.stringify(search)}`)
  }

  function goTo(pageIndex) {
    const search = {
      ...parsedSearch,
      page: pageIndex,
    }
    history.replace(`${location.pathname}?${queryString.stringify(search)}`)
  }

  return (
    <div className={cx(styles.pagination, className)}>
      <Button
        className={styles.paginationButton}
        onClick={handlePrevious}
        disabled={disablePrevious}
      >
        上一页
      </Button>

      <Button
        className={styles.paginationButton}
        onClick={() => goTo(1)}
        disabled={pageIndex === 1}
      >
        1
      </Button>

      {pageIndex - 2 > 1 && (
        <Button
          className={styles.paginationButton}
          onClick={() => goTo(pageIndex - 2)}
        >
          {pageIndex - 2 === 2 ? 2 : '...'}
        </Button>
      )}

      {pageIndex - 1 > 1 && (
        <Button
          className={styles.paginationButton}
          onClick={() => goTo(pageIndex - 1)}
        >
          {pageIndex - 1}
        </Button>
      )}

      {pageIndex > 1 && pageIndex < pageCount && (
        <Button className={styles.paginationButton} disabled={true}>
          {pageIndex}
        </Button>
      )}

      {pageIndex + 1 < pageCount && (
        <Button
          className={styles.paginationButton}
          onClick={() => goTo(pageIndex + 1)}
        >
          {pageIndex + 1}
        </Button>
      )}

      {pageIndex + 2 < pageCount && (
        <Button
          className={styles.paginationButton}
          onClick={() => goTo(pageIndex + 2)}
        >
          {pageIndex + 2 === pageCount - 1 ? pageCount - 1 : '...'}
        </Button>
      )}

      <Button
        className={styles.paginationButton}
        onClick={() => goTo(pageCount)}
        disabled={pageIndex === pageCount}
      >
        {pageCount}
      </Button>

      <Button
        className={styles.paginationButton}
        onClick={handleNext}
        disabled={disableNext}
      >
        下一页
      </Button>
    </div>
  )
}

export function usePageIndex() {
  const history = useHistory()
  const location = useLocation()
  const parsedSearch = queryString.parse(location.search)

  const {page} = parsedSearch
  useEffect(() => {
    if (!page) {
      const search = {
        ...queryString.parse(location.search),
        page: 1,
      }
      history.replace(`${location.pathname}?${queryString.stringify(search)}`)
    }
  }, [page, history, location])

  return page ? Number(page) : 1
}

export default CreatorPagination
