import {useState} from 'react'
import {SecondaryButton} from 'components/Button'
import {ModalDialog} from 'components/Modal'
import styles from './CreatorDetailButton.module.css'
import CreatorDataSummary, {
  CreatorDataSummaryItem,
  CreatorDataSummarySection,
} from './CreatorDataSummary'
import CreatorTable from './CreatorTable'
import CreatorDataTip from './CreatorDataTip'

function CreatorDetailButton({analytic}) {
  const {post, data} = analytic
  const [visible, setVisible] = useState(false)

  const emailOpenedUserCount =
    typeof data.openedEmailUsersCountViaPush === 'undefined'
      ? data.emailOpenedUserCount
      : data.openedEmailUsersCountViaPush

  const wechatOpenedUserCount =
    typeof data.openedWechatUsersCountViaPush === 'undefined'
      ? data.wechatOpenedUserCount
      : data.openedWechatUsersCountViaPush

  return (
    <>
      <SecondaryButton onClick={() => setVisible(true)}>详情</SecondaryButton>
      {visible && (
        <ModalDialog setVisible={setVisible} hasCloseButton={true}>
          <div className={styles.root}>
            <div className={styles.view}>
              <div className={styles.title}>{post.title}</div>
              <CreatorDataSummary>
                <CreatorDataSummarySection>
                  <CreatorDataSummaryItem
                    number={
                      data.subscriberViewCount + data.nonsubscriberViewCount
                    }
                    diff={
                      data.subscriberViewCount +
                      data.nonsubscriberViewCount -
                      data.sevenDaysAgoSubscirberViewCount -
                      data.sevenDaysAgoNonsubscirberViewCount
                    }
                    text="总阅读量"
                    style={{whiteSpace: 'pre'}}
                    tooltip="所有作品的访问次数"
                  />
                  <CreatorDataSummaryItem
                    number={data.emailSubscriberViewCount}
                    diff={
                      data.emailSubscriberViewCount -
                      data.sevenDaysAgoEmailSubscriberViewCount
                    }
                    text="邮件阅读量"
                    tooltip="邮箱内所有订阅者的访问次数"
                  />
                  <CreatorDataSummaryItem
                    number={data.wechatSubscriberViewCount}
                    diff={
                      data.wechatSubscriberViewCount -
                      data.sevenDaysAgoWechatSubscriberViewCount
                    }
                    text="微信阅读量"
                    tooltip="微信内所有订阅者的访问次数"
                  />
                  <CreatorDataSummaryItem
                    number={data.nonsubscriberViewCount}
                    diff={
                      data.nonsubscriberViewCount -
                      data.sevenDaysAgoNonsubscirberViewCount
                    }
                    text="非订阅者阅读量"
                    tooltip="所有非订阅者的访问次数"
                  />
                </CreatorDataSummarySection>
              </CreatorDataSummary>
              <CreatorTable className={styles.table}>
                <thead>
                  <tr>
                    <th scope="col">推送渠道</th>
                    <th scope="col">
                      推送送达人数
                      <CreatorDataTip offsetY="-2px" />
                    </th>
                    <th scope="col">
                      推送打开人数
                      <CreatorDataTip offsetY="-2px" />
                    </th>
                    <th scope="col">
                      推送点击链接人数
                      <CreatorDataTip offsetY="-2px" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">邮箱</th>
                    <td>{data.emailDeliveredUserCount}</td>
                    <td>{emailOpenedUserCount}</td>
                    <td>{data.linkClickedEmailSubscriberCount}</td>
                  </tr>
                  <tr>
                    <th scope="row">微信</th>
                    <td>{data.wechatDeliveredUserCount}</td>
                    <td>{wechatOpenedUserCount}</td>
                    <td>{data.linkClickedWechatSubscriberCount}</td>
                  </tr>
                </tbody>
              </CreatorTable>
            </div>
          </div>
        </ModalDialog>
      )}
    </>
  )
}

export default CreatorDetailButton
