/**
 * 创作者中心数据摘要模块：
 *
 * - [ ] 不同宽度下样式正常
 * - [ ] 大于等于 1 万的数字会被缩略成千，保留 1 位小数，例如 12.5k
 * - [ ] diff 大于、小于、等于 0 时颜色正确
 * - [ ] 能够正常显示错误
 * - [ ] 支持不显过去 7 天变化，显示的加载过程中不会抖动
 */

import cx from 'classnames'
import {formatNumber} from 'lib/numbers'
import Error from 'components/Error'
import CreatorDataTip from './CreatorDataTip'
import styles from './CreatorDataSummary.module.css'

function CreatorDataSummary({children, error, onRetry}) {
  return (
    <div className={styles.summary}>
      {children}
      <Error className={styles.error} error={error} handleRetry={onRetry} />
    </div>
  )
}

// - [ ] 默认为横排的两列，屏幕较窄的时候改为竖排，例如开通会员计划的订阅者列表页数据概览
// - [ ] 默认两排之间有间距，支持 consecutive 模式可以被显示为一个卡片，例如作品列表页数据概览
// - [ ] 可以只使用一个 Section，则始终显示为横排，例如作品列表页作品详情
// - [ ] 可以用多个 Section，默认为横排，屏幕较窄的时候改为竖排，例如旧版作品列表页作品详情
export function CreatorDataSummarySection({children, consecutive}) {
  return (
    <div className={cx(styles.section, {[styles.consecutive]: consecutive})}>
      {children}
    </div>
  )
}

export function CreatorDataSummaryItem({
  number,
  diff,
  text,
  style,
  tooltip,
  prefixUnit,
  format,
}) {
  return (
    <div className={styles.item} style={style}>
      <NumberString number={number} prefixUnit={prefixUnit} format={format} />
      {diff !== null && (
        <div
          className={cx(styles.diff, {
            [styles.isPositive]: diff > 0,
            [styles.isNegative]: diff < 0,
          })}
        >
          过去 7 天 {formatNumber(diff, true)}
        </div>
      )}
      <div className={styles.text}>
        {text}
        <CreatorDataTip tip={tooltip} />
      </div>
    </div>
  )
}

// - [ ] 未加载成功时显示为 `-`
// - [ ] 可正常显示包括 0 在内的数字
// - [ ] 可自定义单位 & 数字格式化逻辑，例如收入
function NumberString({number, prefixUnit, format}) {
  if (Number.isNaN(Number(number))) {
    return <div className={styles.number}>-</div>
  }

  return (
    <div className={styles.number}>
      {prefixUnit && <span className={styles.prefixUnit}>{prefixUnit}</span>}
      {format ? format(number) : formatNumber(number)}
    </div>
  )
}

export default CreatorDataSummary
