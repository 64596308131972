/** 个人页 */

import {useParams} from 'react-router-dom'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import styles from './HomePage.module.css'

function ProfilePage() {
  const {userId} = useParams()

  return (
    <Layout.Page>
      <GlobalTopNav />
      <div className={styles.content}>User ID: {userId}</div>
    </Layout.Page>
  )
}

export default ProfilePage
