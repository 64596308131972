/**
 * 绑定手机号：
 * - [ ] 可正常绑定手机号，绑定成功后提示并刷新页面自动显示订阅弹窗
 */

import {getURLWithExtraQuery} from 'lib/url'
import {useNotification} from 'components/Notification'
import PhoneBindingView from 'components/PhoneBindingView'

function SubscribePhoneBindingView() {
  const noti = useNotification()
  function handleSuccess() {
    noti('绑定成功 🎉')
    window.location.href = getURLWithExtraQuery({
      url: window.location.href,
      extraQuery: {subscribe: '1'},
    })
  }

  return (
    <PhoneBindingView
      title="验证手机号"
      subtitle="用此手机号登录即可查看所有付费内容"
      onSuccess={handleSuccess}
    />
  )
}

export default SubscribePhoneBindingView
