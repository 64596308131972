// - [ ] 非微信内不会初始化
// - [ ] SDK 加载失败后换备用地址重试一次
// - [ ] 页面跳转（非新标签页）时不会重新加载 SDK，但会重新获取签名
// - [ ] 作品页 & Newsletter 页面分享文案正确

import {useEffect} from 'react'
import http from 'lib/http'
import {parseUA} from 'lib/userAgent'

// TODO: 避免依赖 useShareInfo 初始化
// TODO: 避免重复初始化
function init() {
  return new Promise((reslove, reject) =>
    loadSDK()
      .then(() =>
        http('/wechat/jssdk_signature', {
          method: 'POST',
          body: {
            url: window.location.href,
          },
        })
      )
      .then(({appId, nonce, signature, timestamp}) => {
        window.wx.config({
          // debug: true,
          appId,
          timestamp,
          nonceStr: nonce,
          signature,
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData',
            'chooseWXPay',
          ],
          openTagList: ['wx-open-launch-weapp'],
        })

        window.wx.ready(reslove)
        window.wx.error(reject)
      })
      .catch((error) => reject(error))
  )
}

export function useShareInfo(shareInfo) {
  useEffect(() => {
    const userAgent = parseUA(window.navigator.userAgent)

    if (!userAgent.Wechat) {
      return
    }

    if (!shareInfo) {
      return
    }

    const {title, link, imgUrl, desc} = shareInfo

    init().then(
      () => {
        window.wx.updateTimelineShareData({
          title,
          link,
          imgUrl,
        })
        window.wx.updateAppMessageShareData({
          title,
          link,
          imgUrl,
          desc,
        })
      },
      (error) => console.error(error)
    )
  }, [shareInfo])
}

// - [ ] 在微信内访问指定的 publication 页和作品页订阅按钮会变成打开小程序按钮，能够正常打开测试小程序
// - [ ] 微信 JS-SDK 初始化前后页面不会有变化
// - [ ] 非微信内访问不渲染
export function LaunchWechatAppMask({width, height, path}) {
  const userAgent = parseUA(window.navigator.userAgent)

  if (!userAgent.Wechat) {
    return null
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        width,
        height,
      }}
    >
      <wx-open-launch-weapp username="gh_7bba0c531ee1" path={path}>
        <script type="text/wxtag-template">
          <div
            style={{
              width,
              height,
              // background: '#000',
            }}
          />
        </script>
      </wx-open-launch-weapp>
    </div>
  )
}

function loadSDK() {
  if (window.wx) {
    return Promise.resolve()
  }

  return loadScript('https://res.wx.qq.com/open/js/jweixin-1.6.0.js').catch(
    () => loadScript('https://res2.wx.qq.com/open/js/jweixin-1.6.0.js')
  )
}

function loadScript(url) {
  return new Promise((resolve, reject) => {
    var script = window.document.createElement('script')
    script.type = 'text/javascript'
    script.src = url

    script.onload = () => resolve()
    script.onerror = (error) => reject(error)

    window.document.body.appendChild(script)
  })
}

const wechat = {
  init,
  loadSDK,
}

export default wechat
