import getCharactersCount from './getCharactersCount'

export function removeEdgedEmptyParagraphs(content) {
  if (content.length <= 1) {
    return content
  }

  const firstNode = content[0]
  if (isEmptyParagraph(firstNode)) {
    return removeEdgedEmptyParagraphs(content.slice(1))
  }

  const lastNode = content[content.length - 1]
  if (isEmptyParagraph(lastNode)) {
    return removeEdgedEmptyParagraphs(content.slice(0, -1))
  }

  return content
}

function isEmptyParagraph(node) {
  return node.type === 'paragraph' && getCharactersCount([node]) === 0
}
