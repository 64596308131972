import escapeHtml from 'escape-html'
import {Text, Node} from 'slate'

// - [ ] 若付费内容没有公开内容则缩略为「该付费内容仅会员可见」
export function serializeToPlainText(nodes, noNewline) {
  let hasPaywall = false
  let result = ''

  result = nodes
    .map((n) => {
      if (n.type === 'paywall') {
        hasPaywall = true
      }

      let string

      try {
        string = Node.string(n)
      } catch (e) {
        string = ''
        // console.error(e)
      }

      return string
    })
    .join(noNewline ? '' : '\n')

  if (result.replace(/\s/g, '').length === 0 && hasPaywall) {
    return '该付费内容仅会员可见'
  }

  return result
}

// - [ ] 邮件能正常渲染连续空段落，且空段落高度正确
// - [ ] 付费墙卡片在各主流邮件服务/客户端样式正常可正常点击
export function serializeToHTML(nodes, themePrimaryColor = '86, 59, 218') {
  let nodesHTML = nodes
    .map((n, index) => serialize(n, null, nodes[index - 1]))
    .join('')

  // HACK: Spark 客户端渲染引用后会导致后面的样式丢失，但若有一个列表就没问题
  nodesHTML += `<ul style="display:none"><li></li></ul>`
  const styleNode = `
  <style>
    @media (prefers-color-scheme: dark) {
      .root {
        background-color: unset !important;
      }

      @media (pointer: fine) {
        .root {
          background-color: unset !important;
        }
      }
    }

    @media (prefers-color-scheme: dark) {
      .author,
      .title,
      .content {
        color: unset !important;
      }
    
      .inverted-in-dark-mode {
        filter: invert(0.8) !important;
        color: #1a1a1a !important;
      }

      .code-block {
        color: #1a1a1a !important;
      }
    
      .paywall {
        background-color: rgba(255, 255, 255, 0.9) !important;
      }
    }
  </style>
  `.replace(/\s+/g, ' ')

  return `${styleNode}<div class="content" style="margin-top:20px;color:#1a1a1a;font-size:17px;line-height:1.6;word-wrap:break-word">${nodesHTML}</div>`

  function serialize(node, parentNode, previousNode) {
    if (Text.isText(node)) {
      const text = escapeHtml(node.text)
      let style = ''

      if (node.bold) {
        style += 'font-weight: bold;'
      }

      if (node.italic) {
        style += 'font-style: italic;'
      }

      if (node.underline || node.strikethrough) {
        style += `text-decoration: ${node.underline ? 'underline' : ''} ${
          node.strikethrough ? 'line-through' : ''
        };`
      }

      if (node.code) {
        style += 'font-family: monospace;'
      }

      return style.length > 0 ? `<span style="${style}">${text}</span>` : text
    }

    const children = (node.children || [])
      .map((n, index) => serialize(n, node, node.children[index - 1]))
      .join('')

    switch (node.type) {
      case 'block-quote':
        return `<div style="${
          previousNode?.type === 'block-quote'
            ? 'margin: 0; padding-top:1em;'
            : 'margin: 1em 0 0;'
        } padding-left: 1em; border-left: 3px solid rgb(${themePrimaryColor}); white-space: pre-wrap;">${children}</div>`
      case 'heading-one':
        return `<h1 style="margin: 1.3em 0 1em; font-size: 1.5em; line-height: 1.5; font-weight: 600; white-space: pre-wrap;">${children}</h1>`
      case 'heading-two':
        return `<h2 style="margin: 1.3em 0 0.85em; font-size: 1.2em; line-height: 1.5; font-weight: 600; white-space: pre-wrap;">${children}</h2>`
      case 'numbered-list':
        return `<ol style="${
          parentNode?.type === 'list-item'
            ? 'margin: 0 0 0 2em;'
            : 'margin: 1em 0 1em 2em;'
        } padding:0;">${children}</ol>`
      case 'bulleted-list':
        return `<ul style="${
          parentNode?.type === 'list-item'
            ? 'margin: 0 0 0 2em;'
            : 'margin: 1em 0 1em 2em;'
        } padding:0;">${children}</ul>`
      case 'list-item':
        return `<li style="white-space: pre-wrap;">${children}</li>`
      case 'block-code':
        return `<pre class="code-block" style="margin: 1em 0; padding: 0.9em; font-size: 0.9em; font-family: Menlo, Monaco, Consolas, Andale Mono, lucida console, Courier New, monospace; word-break: normal; word-wrap: normal; white-space: pre; overflow: auto; -webkit-overflow-scrolling: touch; background: #f6f6f6; border-radius: 4px;">${children}</pre>`
      case 'paragraph':
        return `<p style="white-space: pre-wrap;min-height: 1.6em;${
          parentNode?.type === 'list-item' ? 'margin: 0' : 'margin: 1em 0;'
        }">${children}</p>`
      case 'link':
        return `<a href="${node.url}" target="_blank" style="${
          parentNode?.type === 'heading-one' ||
          parentNode?.type === 'heading-two'
            ? 'color: inherit; text-decoration: underline;'
            : `color: rgb(${themePrimaryColor}); text-decoration: none;`
        }">${children}</a>`
      case 'image':
        return `<figure style="margin: 1em 0; display: flex;"><img src="${
          node.url
        }" alt="${
          node.alt || ''
        }" style="margin: auto; display: block; max-width: 100%; height: auto;" width="${
          node.naturalWidth > 0 ? node.naturalWidth : ''
        }" height="${
          node.naturalHeight > 0 ? node.naturalHeight : ''
        }" />${children}</figure>`
      case 'divider':
        return `<div class="inverted-in-dark-mode" style="margin: 2em auto; width: 50%; height: 1px; background: #eaeaea; border: none;"></div>`
      case 'paywall':
        return `<a class="paywall" href="https://${node.publication.token}.zhubai.love?subscribe=1" target="_blank" style="display: block;text-decoration:none;margin: 1em 0; padding: 40px 15px 50px; background: #fff; border: 1px solid #dfdfe8; border-radius: 20px;"><div style="font-weight: 700;font-size: 24px;line-height: 1.4;color: #060e4b;text-align: center;">以下为会员专属内容</div><div style="margin-top: 12px;font-size: 16px;line-height: 1.4;color: #5e6088;text-align: center;">订阅 <b>${node.publication.name}</b>，即可查看 <b>${node.publication.author.name}</b> 创作的专属内容</div><div style="margin: 35px auto 0;display: block;color: #fff;background:rgb(${themePrimaryColor});padding: 10px 0px;font-weight: 700;font-size: 16px;line-height: 25px;border-radius: 14px;width:104px;text-align: center;">订阅会员</div></a>`
      default:
        return ''
    }
  }
}
