// 收入页面：
// - [ ] 已开通专栏的登录用户才能访问
// - [ ] 请求获取会员计划申请成功后才显示页面，加载/错误状态正确，能正常重试
// - [ ] 审核未通过时提示「未开启会员计划」
// - [ ] 审核通过时，请求获取会员计划成功后才显示页面，加载/错误状态正确，能正常重试
// - [ ] 审核通过但未开通会员计划（404）时跳转到开通会员计划页面
import {getRelativeTimeString} from 'lib/time'
import Layout from 'components/Layout'
import GlobalTopNav from 'components/GlobalTopNav'
import Error from 'components/Error'
import {LinkButton, PrimaryButton} from 'components/Button'
import {usePublicationMembership} from 'swr/publication'
import {useRevenueOrders, useRevenueSummary} from 'swr/analytic'
import {useLoadingBar} from 'components/TopNav'
import Tooltip from 'components/Tooltip'
import PageTitle from 'components/PageTitle'
import SubscribePlansView from 'components/Subscribe/SubscribePlansView'
import {ReactComponent as Icon} from 'icons/membership.svg'
import CreatorPageNav from './CreatorPageNav'
import CreatorDataSummary, {
  CreatorDataSummaryItem,
  CreatorDataSummarySection,
} from './CreatorDataSummary'
import CreatorTable, {
  CreatorTableEmpty,
  CreatorTableError,
  CreatorTableLoading,
} from './CreatorTable'
import CreatorPagination, {usePageIndex} from './CreatorPagination'
import {getSubscriptionName} from './CreatorPageUtils'
import CreatorDataTip from './CreatorDataTip'
import styles from './CreatorRevenuePage.module.css'

function CreatorRevenuePage({publication}) {
  // const {
  //   application,
  //   error: applicationError,
  //   mutate: mutateApplication,
  // } = useSelfMembershipApplication()

  const {
    membership,
    error: membershipError,
    mutate: mutateMembership,
  } = usePublicationMembership(publication.id)

  if (membershipError?.status === 404) {
    return (
      <PageLayout>
        <PageTitle title="开启会员计划" />
        <Layout.View className={styles.intro}>
          <h1 className={styles.title}>开启会员计划</h1>
          <h2 className={styles.subtitle}>
            让忠实粉丝通过付费订阅来支持自己的持续创作
          </h2>
          <PrimaryButton
            className={styles.button}
            onClick={() =>
              (window.location.href = 'https://survey.zhubai.love/membership')
            }
          >
            申请开启
          </PrimaryButton>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            className={styles.link}
            target="_blank"
            href="https://news.zhubai.love/posts/2146174914725015552"
          >
            查看功能介绍
          </a>
          <div className={styles.previewRoot}>
            <SubscribePlansView
              publication={publication}
              className={styles.preview}
              membership={{
                plans: {
                  monthly_plan: {
                    type: 'monthly_plan',
                    price: 50,
                    enabled: true,
                  },
                  three_month_plan: {
                    type: 'three_month_plan',
                    price: '',
                    enabled: false,
                  },
                  six_month_plan: {
                    type: 'six_month_plan',
                    price: '',
                    enabled: false,
                  },
                  twelve_month_plan: {
                    type: 'twelve_month_plan',
                    price: 500,
                    enabled: true,
                  },
                },
                summary:
                  '免费会员：\n- 每周一篇会员通讯\n\n付费会员：\n- 除免费内容外，每月一篇专属内容\n- 加入付费会员微信群\n- 不定期的线下活动',
                description: JSON.stringify([
                  {type: 'paragraph', children: [{text: 'foo'}]},
                ]),
              }}
              isPreview={true}
            />
          </div>
        </Layout.View>
      </PageLayout>
    )
    // return (
    //   <PageLayout publication={publication}>
    //     <Redirect to="/creator/membership" />
    //   </PageLayout>
    // )
  }

  // if (!application) {
  //   return (
  //     <PageLayout loading={!applicationError} publication={publication}>
  //       <Error
  //         error={applicationError}
  //         handleRetry={() => mutateApplication()}
  //       />
  //     </PageLayout>
  //   )
  // }

  // if (application.status !== 'accepted') {
  //   return (
  //     <PageLayout publication={publication}>
  //       <Error error={'未开通会员计划'} />
  //     </PageLayout>
  //   )
  // }

  if (!membership) {
    return (
      <PageLayout loading={!membershipError} publication={publication}>
        <Error error={membershipError} handleRetry={() => mutateMembership()} />
      </PageLayout>
    )
  }

  return (
    <PageLayout publication={publication}>
      <PageTitle title="我的收入" />
      <Layout.View className={styles.page}>
        <CreatorPageNav
          renderLeft={() => '收入'}
          renderRight={() => (
            <LinkButton className={styles.navLink} to="/creator/membership">
              <Icon style={{marginRight: '2px'}} />
              修改会员计划
            </LinkButton>
          )}
        />
        <Summary />
        <Table />
      </Layout.View>
    </PageLayout>
  )
}

// - [ ] 不同屏幕宽度下能正常加载渲染，加载/错误/重试正常
function Summary() {
  const {summary, error, loading, isValidating, mutate} = useRevenueSummary()

  useLoadingBar(loading || isValidating)

  return (
    <CreatorDataSummary error={error} onRetry={() => mutate()}>
      <CreatorDataSummarySection consecutive>
        <CreatorDataSummaryItem
          number={summary?.totalIncome / 100}
          text={'总收入'}
          diff={null}
          prefixUnit="¥"
          format={(number) =>
            typeof number === 'undefined' ? '-' : number.toFixed(2)
          }
        />
      </CreatorDataSummarySection>
      <CreatorDataSummarySection consecutive>
        <CreatorDataSummaryItem
          number={summary?.paidSubscriberCount}
          text="付费会员数"
          diff={null}
        />
      </CreatorDataSummarySection>
    </CreatorDataSummary>
  )
}

function Table() {
  const pageIndex = usePageIndex()

  const {orders, data, loading, isValidating, error, mutate} =
    useRevenueOrders(pageIndex)

  useLoadingBar(isValidating)

  // Prefetch
  useRevenueOrders(pageIndex - 1)
  useRevenueOrders(pageIndex + 1)

  return (
    <>
      <CreatorTable rootClass={styles.table}>
        <thead>
          <tr>
            <th scope="col">订单号</th>
            <th scope="col">会员名</th>
            <th scope="col">
              金额
              <CreatorDataTip offsetY="-2px" />
            </th>
            <th scope="col">支付时间</th>
          </tr>
        </thead>
        <tbody>
          {loading && <CreatorTableLoading />}
          {error && (
            <CreatorTableError
              error={error}
              handleRetry={() => mutate()}
              colSpan={4}
            />
          )}
          {orders?.length === 0 && (
            <CreatorTableEmpty colSpan={4}>暂无订单</CreatorTableEmpty>
          )}
          {orders?.map((order, index) => (
            <OrderItem key={index} order={order} />
          ))}
        </tbody>
      </CreatorTable>
      <CreatorPagination pagination={data?.pagination} />
    </>
  )
}

function OrderItem({order}) {
  const {id, price, updatedAt, subscriberEmail} = order

  return (
    <tr>
      <th scope="row">{id}</th>
      <td className={styles.nameColumn}>
        <Tooltip
          tip={subscriberEmail}
          overlayInnerStyle={{whiteSpace: 'nowrap'}}
        >
          <span>{getSubscriptionName(order)}</span>
        </Tooltip>
      </td>
      <td>¥{price / 100}</td>
      <td>{getRelativeTimeString(updatedAt)}</td>
    </tr>
  )
}

function PageLayout({children, loading, publication}) {
  return (
    <Layout.Page>
      <GlobalTopNav loading={loading} publication={publication} />
      <Layout.PageContent>{children}</Layout.PageContent>
    </Layout.Page>
  )
}

export default CreatorRevenuePage
